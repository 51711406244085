import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ShapeAndSizeService } from 'src/app/core/services/shape-and-size/shape-and-size.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { RequestModalComponent } from '../../../shared/request-modal/request-modal.component';
import { BespokeMouldModalComponent } from '../../create-product/bespoke-mould-modal/bespoke-mould-modal.component';
import { RealLifeSizeModalComponent } from '../../../shared/real-life-size-modal/real-life-size-modal.component';
import { WebSocketService } from '../../../../core/services/web-socket/web-socket.service';
import { FilterStepService } from '../../../../core/services/filter-step/filter-step.service';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-shape-and-size-step',
  templateUrl: './shape-and-size-step.component.html',
  styleUrls: ['./shape-and-size-step.component.css'],
})
export class ShapeAndSizeStepComponent implements OnInit {
  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  /** This attribute is used to store the description of the current step. */
  @Input() stepDescription = '';

  /** This attribute is used to store the category selected in the basics selection. */
  @Input() category = '';

  /** This array contains all the possible shapes for ingredients only. */
  shapeAndSizeOptions: any[] = [];

  /** This array contains all the shapes properties. */
  shapeAndSizeInfo: any;

  /** The shape clicked by the user. Default values are empty to display on screen. */
  currentShape: any = {};

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  /** This attribute is used to set the number of cells to show. */
  cellsToShow = 0;

  /** This attribute is used to set the width of the carousel. */
  carouselWidth = 0;

  /** The type of chocolate previously selected by the user, to load the shape images of the same type. */
  @Input() chocolateType = '';

  /** An emitter to let the selection step component know when the step is completed. */
  @Output() completed = new EventEmitter<any>();

  /** This array contains the shapes with their image routes. */
  formImages: any[] = [];

  constructor(
    private shapeAndSizeService: ShapeAndSizeService,
    private router: Router,
    private sms: SimpleModalService,
    private webSocketService: WebSocketService,
    private filterStepService: FilterStepService
  ) {}

  async ngOnInit() {
    localStorage.removeItem('comments');
    this.setCurrentFlow();
    this.loadChocolateType();
    await this.getShapesAndSizes();
    this.getShapeAndSizesImages();
    this.listenSocket();
    this.filterSteps();
  }

  /**
   * This function gets the shapes available for ingredients.
   */
  async getShapesAndSizes() {
    await this.shapeAndSizeService.getShapesAndSizes();
    this.shapeAndSizeInfo = this.shapeAndSizeService.shapeAndSizesInfo;
    if (this.currentFlow == 'ingredient') {
      this.shapeAndSizeOptions = this.shapeAndSizeInfo.filter((c: any) => c.ingrediente);
    } else {
      this.shapeAndSizeOptions = this.category
        ? this.shapeAndSizeInfo.filter((s: any) => s.tipo?.split(',').includes(this.category))
        : this.shapeAndSizeInfo;
    }
    this.sortShapeAndSizes();
  }

  /**
   * This function sorts the shape and sizes array by order Id.
   */
  sortShapeAndSizes() {
    let a = this.shapeAndSizeOptions.map((form: any) => {
      return {
        ...form,
        orderId: parseInt(form.formaId.replace('FORM', '')),
      };
    });
    this.shapeAndSizeOptions = _.sortBy(a, ['orderId'], ['asc']);
  }

  async filterSteps() {
    let servicioPasosFiltrados = await this.filterStepService.getFilterSteps();
    let restriccion: any;
    let tipoChocolate;
    let chocolateSeleccionado = JSON.parse(localStorage.getItem('selectedOptions') || '{}');
    let pasosFiltrados = servicioPasosFiltrados.filter((item: any) => {
      return chocolateSeleccionado.chocolateType === item.formaChocolate;
    });
    pasosFiltrados.forEach((item: any) => {
      tipoChocolate = item.formaChocolate;
      restriccion = item.restriccion;
    });
    let arrStrings = restriccion.split(',').map(String);
    if (chocolateSeleccionado.chocolateType === tipoChocolate) {
      arrStrings.forEach((element: any) => {
        this.shapeAndSizeOptions = this.shapeAndSizeOptions.filter((item: any) => {
          return element != item.formaId;
        });
      });
    }
  }

  /**
   * This function listens to the socket, waiting for changes in the selection of the commercial, and selects the shape in the screen of the client.
   */
  listenSocket() {
    this.webSocketService.listen().subscribe((data: any) => {
      if (this.webSocketService.getRole != 'admin' && data.object.step == 'shape' && data.object.index != undefined) {
        for (let i = 0; i < this.shapeAndSizeOptions.length; i++) {
          this.shapeAndSizeOptions[i].selected = false;
        }
        let index = data.object.index;
        this.shapeAndSizeOptions[index].selected = !this.shapeAndSizeOptions[index].selected;
        this.currentShape = this.shapeAndSizeOptions[index];
        this.saveShapeSelection();
      }
    });
  }

  /**
   * This function sets the current flow the user is in.
   */
  setCurrentFlow() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.cellsToShow = 6;
      this.carouselWidth = 950;
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.cellsToShow = 7;
      this.carouselWidth = 950;
      this.currentFlow = 'product';
    }
  }

  /**
   * This function saves in the local storage the shape selected by the user.
   */
  saveShapeSelection() {
    ls.set('shape', JSON.stringify(this.currentShape), { encrypt: true });
    this.completed.emit({ flow: this.currentFlow, step: 1, completed: true });
  }

  /**
   * This function saves all the shapes available in the backend for ingredient use only.
   */
  getShapeAndSizesImages() {
    for (let i = 0; i < this.shapeAndSizeOptions.length; i++) {
      this.shapeAndSizeOptions[i].img = this.getImageFromShape(this.chocolateType, this.shapeAndSizeOptions[i].formaId);
      this.shapeAndSizeOptions[i].selected = false;
    }
  }

  /**
   * This function returns an image route depending on the chocolate type and the id of the shape.
   * @param type The type of chocolate.
   * @param shapeId The id of the shape which image is needed.
   * @returns A route of an image.
   */
  getImageFromShape(type: string, shapeId: string) {
    if (type && shapeId) {
      return `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/${type}/${shapeId}.jpg`;
    }
    return '';
  }

  /**
   * This function handles the click events and the changes of shape selection.
   * @param pos The index of the shape selected.
   */
  handleClickSelection(pos: number) {
    for (let i = 0; i < this.shapeAndSizeOptions.length; i++) {
      this.shapeAndSizeOptions[i].selected = false;
    }
    this.shapeAndSizeOptions[pos].selected = !this.shapeAndSizeOptions[pos].selected;
    this.currentShape = this.shapeAndSizeOptions[pos];
    if (this.webSocketService.active) {
      if (this.webSocketService.getRole == 'admin') {
        this.webSocketService.emitToClient({ type: 'select', step: 'shape', index: pos });
      }
    }
    this.saveShapeSelection();
  }

  /**
   * Checks if the user previously selected a chocolate to load the chocolate type. It also sets the default value of chocolateType as MILK.
   */
  loadChocolateType() {
    if (!this.chocolateType) {
      this.chocolateType = 'MILK';
    }
    this.currentShape.img = `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/${this.chocolateType}/FORM32.jpg`;
    this.currentShape.texto = 'Shapes';
    this.currentShape.descripcion = `Click on a shape to see its details`;
  }

  /**
   * Opens a modal to request another shape.
   */
  openRequestModal() {
    let modal = this.sms.addModal(RequestModalComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }

  /**
   * Opens a modal to show information about tailor made moulds.
   */
  openBespokeMouldsModal() {
    let modal = this.sms.addModal(BespokeMouldModalComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }

  /**
   * Opens a modal to show the real life size of a selected shape.
   * @param shape The id of the selected shape.
   */
  openRealLifeSizeModal(shape: any) {
    let modal = this.sms.addModal(
      RealLifeSizeModalComponent,
      { data: shape.formaId },
      { autoFocus: true, closeOnClickOutside: true }
    );
  }
}
