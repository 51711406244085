import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';

import ls from 'localstorage-slim';

import { AuthService } from '../../../core/services/auth/auth.service';
import { FilterStepService } from '../../../core/services/filter-step/filter-step.service';
import { RegisterTypeService } from '../../../core/services/register-type/register-type.service';
import { TipsService } from '../../../core/services/tips/tips.service';
import { WebSocketService } from '../../../core/services/web-socket/web-socket.service';
import { DownloadQuotePrivateLabelComponent } from '../ready-to-launch/ready-to-launch/download-quote/download-quote-private-label.component';
import { DownloadQuoteComponent } from '../finished-product/download-quote/download-quote.component';
import { QuoteActualProductsService } from 'src/app/core/services/quote-actual-products/quote-actual-products.service';

@Component({
  selector: 'app-selection-step',
  templateUrl: './selection-step.component.html',
  styleUrls: ['./selection-step.component.css'],
})
export class SelectionStepComponent implements OnInit {
  /** This object stores all the steps of the stepper. */
  steps: { name: string; description: string }[] = [
    {
      name: 'Shape & Size',
      description: 'Select the shape and size for your chocolate',
    },
    {
      name: 'Chocolate',
      description: 'Choose your chocolate profile. All our couvertures require tempering',
    },
    {
      name: 'Packaging',
      description: 'Select how you want your ingredients to be packaged',
    },
    {
      name: 'Your Impact Summary',
      description: '',
    },
    {
      name: 'Design Inspiration',
      description:
        'Select a source for your package design. These designs are available for your product. Our amazing design team can customize them for you!',
    },
    {
      name: 'Transport',
      description: '',
    },
  ];

  @ViewChild(DownloadQuoteComponent) downloadComponent!:DownloadQuoteComponent;
  @ViewChild(DownloadQuotePrivateLabelComponent) downloadPrivateLabelComponent!:DownloadQuotePrivateLabelComponent;

  /** This map is used to validate if each step of the create ingredient flow is completed. */
  ingredientCompletionMap = new Map();

  /** This map is used to validate if each step of the create product flow is completed. */
  productCompletionMap = new Map();

  /** This map is used to validate if each step of the ready to launch flow is completed. */
  rtlCompletionMap = new Map();

  /** This map is used to validate if each step of the ready to launch flow is completed. */
  quoteCompletionMap = new Map();

  /** This map is used to store the name of the steps in the tips backend collection, to filter the tips. */
  stepMap = new Map();

  /** This attribute is used to store the current step of the stepper. */
  currentStep = 1;

  /** This object stores the options selected in the basics selections. */
  basicSelections: any;

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  /** This array stores the filtered tips. */
  assistantTips: any[] = [];
  
  versionsTabs: any[] = [];

  queryParams: any = {};

  /** The role of each participant of the active live session. */
  sessionRole = '';

  /** Step validation **/
  stepValidation = false;

  /**Final step of creating chocolate **/
  finalStep: any;

  canSave: boolean = false;
  canUpdate: boolean = false;

  activeUser = false;

  selectedIndex = 0

  initial = true

  downloadOptions = [
    {
        label: 'As PDF',
        command: () => {
          this.downloadComponent.downloadAsPDF();
      }
    },
    {
        label: 'As Excel',
        command: () => {
          this.downloadComponent.downloadAsExcel();
      }
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tipsService: TipsService,
    private webSocketService: WebSocketService,
    private registerTypeService: RegisterTypeService,
    private filterStepService: FilterStepService,
    private authService: AuthService,
    private quoteActualProductsService: QuoteActualProductsService
  ) {this.queryParams = this.activatedRoute.snapshot.queryParams;}

  ngOnInit(): void {
    this.setVersions()
    this.setLiveSessionData();
    this.setCurrentFlow();
    this.loadCompletionMaps();
    this.loadStepMap();
    if (this.currentStep == 1) {
      this.loadAssistantTips();
    }
    this.watchURLChanges();
    this.basicSelections = this.getLocalStorage();
    this.updateByURL();
    this.getFinalStepByRole();
  }

  downloadExcel(){
    this.downloadPrivateLabelComponent.downloadAsExcel();
  }

  saveProject(){
    this.downloadPrivateLabelComponent.saveProject();
  }

  saveActualProductsProject(){
    this.downloadComponent.saveProject();
  }

  createNewVersionProject(){
    this.downloadComponent.createNewVersion();
  }

  /** Validates if the step is completed to allow navigation to next steps. */
  get enableNextStep() {
    if (this.currentFlow == 'ingredient') {
      return this.ingredientCompletionMap.get(this.currentStep);
    } else if (this.currentFlow == 'product') {
      return this.productCompletionMap.get(this.currentStep);
    } else if (this.currentFlow == 'launch') {
      return this.rtlCompletionMap.get(this.currentStep);
    } else if (this.currentFlow === 'quote') {
      return this.quoteCompletionMap.get(this.currentStep);
    }
  }

  /**
   * This function watches the changes of the URL to allow navigation and update the steps.
   */
  watchURLChanges() {
    let url = this.router.url.split('/');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (url.includes('steps')) {
          this.updateByURL();
          this.loadAssistantTips();
        }
      }
    });
  }

  /**
   * This function loads the filtered tips for the current step.
   */
  loadAssistantTips() {
    let url = this.router.url.split('/');
    let queryTips: any = {};
    if (this.currentFlow == 'ingredient' && this.currentStep == 4) {
      this.assistantTips = [];
    } else if (this.currentFlow!='launch') {
      queryTips.paso = url.includes('create-bespoke') ? this.stepMap.get(0) : this.stepMap.get(this.currentStep);
      this.assistantTips = [];
      this.tipsService.filter(queryTips).then((res) => {
        this.assistantTips = res as any[];
      });
    } else if (this.currentFlow == 'launch') {
      queryTips.paso = this.stepMap.get(5);
      this.assistantTips = [];
      this.tipsService.filter(queryTips).then((res) => {
        this.assistantTips = res as any[];
      });
    }

  }

  /**
   * This function loads all the step names for each step number.
   */
  loadStepMap() {
    this.stepMap.set(0, 'BESPOKE CHOCOLATE');
    this.stepMap.set(1, 'SHAPE AND SIZE');
    this.stepMap.set(2, 'CHOCOLATE1');
    this.stepMap.set(3, 'CHOCOLATE2');
    this.stepMap.set(4, 'FLAVORS AND INCLUSIONS');
    this.stepMap.set(5, 'PRIVATE LABEL PORTFOLIO');
  }

  removePreviousProductPortfolio(){
    console.log("ejecta")
    sessionStorage.setItem('materialOnePrices', JSON.stringify({}))
    sessionStorage.setItem('productsList', JSON.stringify([]))
    sessionStorage.setItem('comments', "");
    sessionStorage.setItem('national_freight', "");
    sessionStorage.setItem('travel_expenses', "");
    sessionStorage.setItem('custom_expenses', "");
    sessionStorage.setItem('freight', "");
    sessionStorage.setItem('insurance',"");
    sessionStorage.setItem('others', "");
    sessionStorage.setItem('current_actual_products_project', "{}");
    sessionStorage.setItem('parameters', "{}");
    ls.set('current_actual_products_project', JSON.stringify({}), { encrypt: true });
  }

  async selectVersion(version:any){
    const versionProject = this.versionsTabs[version.index]
    this.removePreviousProductPortfolio()
    this.router.navigateByUrl(`/portfolio-products/steps/1?projectId=${versionProject._id}`)
  .then(() => {
    window.location.reload();
  });
  }

  /**
   * This function loads all the keys and values of the map for each flow.
   */
  loadCompletionMaps() {
    if (this.currentFlow == 'ingredient') {
      for (let i = 1; i < 8; i++) {
        if (i != 3 && i != 5 && i != 6) {
          this.ingredientCompletionMap.set(i, false);
        } else {
          this.ingredientCompletionMap.set(i, true);
        }
      }
    } else if (this.currentFlow == 'product') {
      for (let i = 1; i < 10; i++) {
        if (i != 1 && i != 2 && i != 5) {
          this.productCompletionMap.set(i, true);
        } else {
          this.productCompletionMap.set(i, false);
        }
      }
    } else if (this.currentFlow == 'launch') {
      for (let i = 1; i < 5; i++) {
        if (i == 1 || i == 4) {
          this.rtlCompletionMap.set(i, false);
        } else {
          this.rtlCompletionMap.set(i, true);
        }
      }
    } else if (this.currentFlow === 'quote') {
      for (let i = 1; i < 4; i++) {
        if (i == 1) {
          this.quoteCompletionMap.set(i, false);
        } else {
          this.quoteCompletionMap.set(i, true);
        }
      }
    }
  }

  /**
   * This function sets the current flow the user is in.
   */
  async setCurrentFlow() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.currentFlow = 'product';
    } else if (split.includes('ready-to-launch')) {
      this.currentFlow = 'launch';
    } else if (split.includes('portfolio-products')) {
      this.currentFlow = 'quote';
      await this.handleQuoteOnlineNavigation()
    } else if (split.includes('private-label-projects')) {
      this.currentFlow = 'saved-private-label-quotes';
    } else if (split.includes('actual-products-projects')) {
      this.currentFlow = 'saved-actual-products-quotes';
    }
    this.initial = false

  }

  /**
   * This function is used to change step in the stepper, depending on which flow the user is in.
   */
  changeToNextStep() {
    if (this.currentFlow == 'ingredient') {
      this.handleIngredientNavigation();
    } else if (this.currentFlow == 'product') {
      this.handleProductNavigation();
    } else if (this.currentFlow == 'launch') {
      this.handleRTLNavigation();
    } else if (this.currentFlow == 'quote') {
      this.handleQuoteOnlineNavigation();
    }
  }

  /**Set final step by role and switcase validate the current flow**/
  getFinalStepByRole() {
    let typeUser = this.authService.getTypeUser();
    this.activeUser = typeUser === 'Feria';
    switch (this.currentFlow) {
      case 'launch':
        if (this.activeUser) {
          this.finalStep = 2;
        } else {
          // Used in create a product ready to launch
          this.finalStep = 3;
        }
        break;
      case 'product':
        if (this.activeUser) {
          this.finalStep = 5;
        } else {
          // Used in create a product from scratch
          this.finalStep = 7;
        }
    }
  }

  /**
   * This function watches the url changes and navigates in the create ingredient flow.
   */
  handleIngredientNavigation() {
    if (this.currentStep > 0 && this.currentStep < 5) {
      this.currentStep = this.currentStep + 1;
      if (this.webSocketService.active) {
        this.webSocketService.emitToClient({ type: 'redirect', url: `/create-ingredient/steps/${this.currentStep}` });
      }
      this.router.navigateByUrl(`/create-ingredient/steps/${this.currentStep}`);
    } else if (this.currentStep == 5) {
      this.router.navigateByUrl(`/create-ingredient/product-summary`);
    }
  }

  async setVersions(){
    if(this.queryParams.projectId==null) return;
    try {
      this.versionsTabs = await this.quoteActualProductsService.getVersions(this.queryParams.projectId)
    } catch (error) {
    }
  }

  /**
   * This function watches the url changes and navigates in the create product flow.
   */
  handleProductNavigation() {
    let formShape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    if (this.currentStep > 0 && this.currentStep < this.finalStep) {
      this.currentStep = this.currentStep + 1;
      if (this.webSocketService.active) {
        this.webSocketService.emitToClient({ type: 'redirect', url: `/create-product/steps/${this.currentStep}` });
      }
      this.router.navigateByUrl(`/create-product/steps/${this.currentStep}`);
    } else if (this.currentStep == this.finalStep) {
      this.router.navigateByUrl(`/create-product/product-summary`);
    }
    if (this.currentStep == 3 && formShape.texto === 'Dragees') {
      this.router.navigateByUrl(`/create-product/steps/${4}`);
    }
    this.validate();
  }

  /**
   * This function watches the url changes and navigates in the ready to launch flow.
   */
  async handleRTLNavigation() {
    const typeBrief = localStorage.getItem('origin');
    const user = JSON.parse(localStorage.getItem('usuario') || '{}');
    const savedProject = await JSON.parse(ls.get('current_project', { decrypt: true }) || "{}");
    if (this.currentStep > 0 && this.currentStep < this.finalStep) {
      this.currentStep = this.currentStep + 1;
      if (this.webSocketService.active) {
        this.webSocketService.emitToClient({ type: 'redirect', url: `/ready-to-launch/steps/${this.currentStep}` });
      }
      this.router.navigateByUrl(`/ready-to-launch/steps/${this.currentStep}`);
    } else if (this.currentStep == this.finalStep) {
      let typeRegister: any = {
        tipo: typeBrief,
        usuario: user,
      };
      this.registerTypeService.postRegisterType(typeRegister);
      this.router.navigateByUrl(`/ready-to-launch/product-summary`);
    }

    if (this.currentStep == 2 && Object.keys(savedProject).length > 0) {
      this.canSave = false
    } else {
      this.canSave = true
    }
  }

  async handleQuoteOnlineNavigation() {
    const typeBrief = localStorage.getItem('origin');
    const user = JSON.parse(localStorage.getItem('usuario') || '{}');
    const savedProject = await JSON.parse(sessionStorage.getItem('current_actual_products_project') || "{}");
    if (this.currentStep >= 0 && this.currentStep < 2) {
      if (this.initial==false) {
        this.currentStep = this.currentStep + 1;
        if (this.webSocketService.active) {
          this.webSocketService.emitToClient({ type: 'redirect', url: `/portfolio-products/steps/${this.currentStep}` });
        }
        this.router.navigateByUrl(`/portfolio-products/steps/${this.currentStep}`);
      }
    } else if (this.currentStep == 3) {
      let typeRegister: any = {
        tipo: typeBrief,
        usuario: user,
      };
      this.registerTypeService.postRegisterType(typeRegister);
      this.router.navigateByUrl(`/portfolio-products/product-summary`);
    }

    if (this.currentStep == 2 && Object.keys(savedProject).length > 0) {
      this.canSave = false
      this.canUpdate = true
    } else {
      this.canUpdate = false
      this.canSave = true
    }
  }

  /**
   * This function is used to get all the options selected in the basics selections.
   * @returns The object stored in the local storage with all the selections from the basics selection.
   */
  getLocalStorage() {
    return JSON.parse(localStorage.getItem('selectedOptions') || '{}');
  }

  /**
   * This function updates the stepper when accesing by url and not by button navigation.
   */
  updateByURL() {
    let url = this.router.url;
    let split = url.split('/');
    this.currentStep = parseInt(split[split.length - 1]);
    if (this.webSocketService.active) {
      this.webSocketService.emitToClient({ type: 'redirect', url });
    }
  }

  /**
   * This function detects if any step is completed.
   * @param event The event triggered by the step.
   */
  handleEvent(event: any) {
    if (event.flow == 'ingredient') {
      this.ingredientCompletionMap.set(parseInt(event.step), event.completed);
    } else if (event.flow == 'product') {
      this.productCompletionMap.set(parseInt(event.step), event.completed);
    } else if (event.flow == 'launch') {
      this.rtlCompletionMap.set(parseInt(event.step), event.completed);
    } else if (event.flow == 'quote') {
      this.quoteCompletionMap.set(parseInt(event.step), event.completed);
      if(event.currentVersion!=undefined && event.currentVersion!=null){
        this.selectedIndex = event.currentVersion.version - 1
      }
    }
  }

  /**
   * This function loads the live session data and listens to the socket for navigation.
   */
  setLiveSessionData() {
    this.sessionRole = this.webSocketService.getRole;
    if (this.webSocketService.getSessionId && this.webSocketService.getRole == 'viewer') {
      this.webSocketService.listen().subscribe((data: any) => {
        if (data.object.type == 'redirect') {
          let route = data.object.url;
          this.router.navigateByUrl(route);
        }
      });
    }
  }

  async validate() {
    let aplicationtype = JSON.parse(localStorage.getItem('selectedOptions') || '{}');
    let filterStep;
    let shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    await this.filterStepService.getFilterSteps().then((res) => {
      filterStep = res.map((item: any) => {
        return {
          ...item,
          step: parseInt(item.restriccion.replace('.Flavors', '')),
        };
      });
      this.stepValidation = filterStep.some((x: any) => {
        return (
          (x.step === this.currentStep && x.formaChocolate === shape.formaId) ||
          x.formaChocolate === aplicationtype.application.name
        );
      });
    });
  }
}
