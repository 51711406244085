import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-bespoke-mould-modal',
  templateUrl: './bespoke-mould-modal.component.html',
  styleUrls: ['./bespoke-mould-modal.component.css'],
})
export class BespokeMouldModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  bespokeMoulds: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadImages();
  }

  confirm() {
    this.close();
  }

  loadImages() {
    for (let i = 1; i < 5; i++) {
      this.bespokeMoulds.push({
        img: `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/bespoke/bespoke_mould_${i}.png`,
      });
    }
  }
}
