import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { BasicsSelectionComponent } from './public/create/basics-selection/basics-selection.component';
import { SelectionStepComponent } from './public/create/selection-step/selection-step.component';
import { LoginComponent } from './public/login/login.component';
import { ProjectBriefComponent } from './public/project-brief/project-brief.component';
import { AuthGuard } from './guards/auth.guard';
import { ProductSummaryComponent } from './public/product-summary/product-summary.component';
import { ConsultPriceComponent } from './public/consult-price/consult-price.component';
import { BespokeComponent } from './public/create/bespoke/bespoke.component';
import { GoBeyondComponent } from './public/sustainability/go-beyond/go-beyond.component';
import { OurProjectsComponent } from './public/sustainability/our-projects/our-projects.component';
import { ClientProjectsComponent } from './public/client-projects/client-projects.component';
import { SustainabilityComponent } from './public/sustainability/sustainability.component';
import { OurDreamersComponent } from './public/sustainability/our-dreamers/our-dreamers.component';
import { OurFarmsComponent } from './public/sustainability/our-farms/our-farms.component';
import { ContextComponent } from './public/sustainability/context/context.component';
import { TrendsInspirationComponent } from './public/trends-inspiration/trends-inspiration.component';
import { TrendInfoComponent } from './public/trends-inspiration/trend-info/trend-info.component';
import { SimuladorPvpComponent } from './public/simulador-pvp/simulador-pvp.component';
import { RdProjectsComponent } from './public/rd-projects/rd-projects.component';
import { RdConsultingComponent } from './public/rd-projects/rd-consulting/rd-consulting.component';
import { CompareInternoComponent } from './public/compare-products/compare-interno/compare-interno.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { HomeFeriaComponent } from './public/home-feria/home-feria.component';
import { CategoriesFilterComponent } from './public/create/categories-filter/categories-filter.component';
import { HomeOptionsComponent } from './public/home/home-options/home-options.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
        data: {
          // roles: ['Ryd', 'Comercial', 'EjecutivoSr', 'Administrador', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        component: HomeComponent,
        data: {
          // roles: ['Ryd', 'Comercial', 'EjecutivoSr', 'Administrador', 'ADMIN'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'home-options',
        canActivate: [AuthGuard],
        component: HomeOptionsComponent,
        data: {
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'home-feria',
        canActivate: [AuthGuard],
        component: HomeFeriaComponent,
        data: {
          // roles: ['Feria'],
          roles: ['FERIA'],
        },
      },
      {
        path: 'categories-filter',
        canActivate: [AuthGuard],
        component: CategoriesFilterComponent,
        data: {
          // roles: ['Feria'],
          roles: ['FERIA'],
        },
      },
      {
        path: 'trends',
        canActivate: [AuthGuard],
        component: TrendsInspirationComponent,
        data: {
          // roles: ['Ryd', 'Comercial', 'EjecutivoSr', 'Administrador', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'trends/:key',
        canActivate: [AuthGuard],
        component: TrendInfoComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability',
        canActivate: [AuthGuard],
        component: SustainabilityComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability/context',
        canActivate: [AuthGuard],
        component: ContextComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability/our-dreamers/:brand',
        canActivate: [AuthGuard],
        component: OurDreamersComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability/our-farms',
        canActivate: [AuthGuard],
        component: OurFarmsComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability/go-beyond',
        canActivate: [AuthGuard],
        component: GoBeyondComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'sustainability/our-projects',
        canActivate: [AuthGuard],
        component: OurProjectsComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-ingredient/steps/:step',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-ingredient/product-summary',
        canActivate: [AuthGuard],
        component: ProductSummaryComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-product/steps/:step',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-product/product-summary',
        canActivate: [AuthGuard],
        component: ProductSummaryComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'ready-to-launch/steps/:step',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'private-label-projects',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'actual-products-projects',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'portfolio-products/steps/:step',
        canActivate: [AuthGuard],
        component: SelectionStepComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'SUPERADMIN'],
        },
      },
      {
        path: 'portfolio-products/product-summary',
        canActivate: [AuthGuard],
        component: ProductSummaryComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'SUPERADMIN'],
        },
      },
      {
        path: 'ready-to-launch/product-summary',
        canActivate: [AuthGuard],
        component: ProductSummaryComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-ingredient/basics',
        canActivate: [AuthGuard],
        component: BasicsSelectionComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-product/basics',
        canActivate: [AuthGuard],
        component: BasicsSelectionComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-product/create-bespoke',
        canActivate: [AuthGuard],
        component: BespokeComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'create-ingredient/create-bespoke',
        canActivate: [AuthGuard],
        component: BespokeComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'project-brief',
        canActivate: [AuthGuard],
        component: ProjectBriefComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'consult-price',
        canActivate: [AuthGuard],
        component: ConsultPriceComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'my-projects',
        canActivate: [AuthGuard],
        component: ClientProjectsComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'simulator-pvp/:id',
        canActivate: [AuthGuard],
        component: SimuladorPvpComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
      {
        path: 'rd-projects',
        canActivate: [AuthGuard],
        component: RdProjectsComponent,
        data: {
          // roles: ['Ryd', 'Administrador'],
          roles: ['ADMIN', 'SUPERADMIN', 'EMPAQUES', 'RYD'],
        },
      },
      {
        path: 'rd-consulting/:id',
        canActivate: [AuthGuard],
        component: RdConsultingComponent,
        data: {
          // roles: ['Ryd', 'Administrador'],
          roles: ['ADMIN', 'SUPERADMIN', 'EMPAQUES', 'RYD'],
        },
      },
      {
        path: 'compare-interno/:id/:id2',
        canActivate: [AuthGuard],
        component: CompareInternoComponent,
        data: {
          // roles: ['Ryd', 'Administrador', 'Comercial', 'EjecutivoSr', 'Feria'],
          roles: ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', 'EMPAQUES', 'FERIA', 'PROCESOS', 'RYD', 'SUPERADMIN'],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
