import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { faTrash, faMagnifyingGlass, faFileExcel, faFilePdf, faSheetPlastic } from '@fortawesome/free-solid-svg-icons';

import { SimpleModalService } from 'ngx-simple-modal';

import domtoimage from 'dom-to-image';

import jsPDF from 'jspdf';

import * as XLSX from 'xlsx';

import * as moment from 'moment';

import { ModalConsultComponent } from '../modal-consult/modal-consult.component';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CostService } from '../../../core/services/costs/cost.service';
import { MaterialListService } from '../../../core/services/material-list/material-list.service';
import { MoneyApisService } from '../../../core/services/money-apis/money-apis.service';
import { NutritionTableService } from '../../../core/services/nutrition-table/nutrition-table.service';
import { PackagingService } from '../../../core/services/packaging/packaging.service';
import { RdServicesService } from '../../../core/services/rd-project/rd-services.service';
import { RegisterInfoService } from '../../../core/services/register-info/register-info.service';

@Component({
  selector: 'app-rd-consulting',
  templateUrl: './rd-consulting.component.html',
  styleUrls: ['./rd-consulting.component.css'],
})
export class RdConsultingComponent implements OnInit {
  /**
   * Id of project selected
   * */
  idProject: any;

  /**
   * data of project selected
   **/
  dataProject: any = {};

  packaging = false;

  gramajeProject = 0;

  validationSap = false;

  /** Icons**/
  faTrash = faTrash;
  faMagnifyingGlass = faMagnifyingGlass;
  faFileExcel = faFileExcel;
  faFilePdf = faFilePdf;
  faSheetPlastic = faSheetPlastic;

  buttonsBol = false;
  cacaoPrices: any;
  dollarPrices: any;

  otherCacaco = false;
  otherDollar = false;

  sapCodeExpired: any;
  nameExpired: any;
  optionsDollar: any[] = [];
  
  typeRd: string | null = null;

  messageOneInput: any;
  messageTwoInput: any;

  validateChocolateConsult = false;

  options = [
    { value: 'Ingrediente', label: 'Ingrediente', selected: false },
    { value: 'Empaque', label: 'Empaque', selected: false },
  ];

  optionsRawMaterial = [
    { value: 'Materia prima 1', label: 'Materia prima 1', selected: false, inactive: false },
    { value: 'Materia prima 2', label: 'Materia prima 2', selected: false, inactive: false },
    { value: 'Chocolate', label: 'Chocolate', selected: false, inactive: false },
  ];

  optionsRawMaterialTypeTwo = [
    { value: 'Materia prima 1', label: 'Materia prima 1', selected: false, inactive: false },
    { value: 'Materia prima 2', label: 'Materia prima 2', selected: false, inactive: false },
    { value: 'Chocolate', label: 'Chocolate', selected: false, inactive: true },
  ];

  constructor(
    private registerInfoService: RegisterInfoService,
    private route: ActivatedRoute,
    private rdServices: RdServicesService,
    private costService: CostService,
    private router: Router,
    private packagingService: PackagingService,
    private sms: SimpleModalService,
    private moneyService: MoneyApisService,
    private nutritionService: NutritionTableService,
    private authService: AuthService,
    private materialListService: MaterialListService,
    private _decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.typeRd = this.route.snapshot.queryParamMap.get('type');
    this.getDataProjectRd();
    this.getDollarPrice();
    localStorage.setItem('tipoMateriaPrima', JSON.stringify('Materia prima 1'));
  }

  getExpiredData() {
    for (let item of this.dataProject.listPrices) {
      if (item.expired === 'Vencido') {
        this.sapCodeExpired = item.sapCode;
        this.nameExpired = item.rawMaterial;
      }
    }
  }

  getDollarPrice() {
    this.moneyService.getTrm().subscribe((res: any) => {
      this.dollarPrices = res;
      this.optionsDollar.push(this.dollarPrices);
    });
    this.moneyService.getCocoaPrices().subscribe((res: any) => {
      if (res) {
        let pricesCocoa = [];
        for (const property in res) {
          let price = res[property];
          pricesCocoa.push({
            value: price['Último'].replace('s', ''),
            label: price['Último'].replace('s', '') + ' ' + price['Mes'],
          });
        }
        this.cacaoPrices = pricesCocoa;
      }
    });
  }

  selectOtherCacao() {
    this.otherCacaco = !this.otherCacaco;
  }

  selectOtherDollar() {
    this.otherDollar = !this.otherDollar;
  }

  /**
   * Get data of project selected by route params
   * **/
  getDataProjectRd() {
    this.idProject = this.route.snapshot.paramMap.get('id');
    this.rdServices.getProjectRdById(this.idProject).then((res: any) => {
      this.dataProject = res;
      this.getExpiredData();
      this.otherDollar = res.otherDollar;
      this.otherCacaco = res.otherCacao;
      this.dataProject.typeRd = res.typeRd;
      this.getTypeRd();
    });
  }

  changePackaging(event: any) {
    this.getTypeRd();
    this.dataProject.listPrices.splice(0, this.dataProject.listPrices.length);
    this.dataProject.totalCost = 0;
    this.dataProject.totalPercentage = 0;
  }

  getTypeRd() {
    if (this.dataProject.typeRd === 'Empaque') {
      this.packaging = true;
      this.dataProject.typeRd = 'Empaque';
      this.messageOneInput = 'Configuración del palet:';
      this.messageTwoInput = 'Cajas por palet:';
      this.dataProject.messageOneInput = 'Configuración del palet:';
      this.dataProject.messageTwoInput = 'Cajas por palet:';
    } else {
      this.messageOneInput = 'PROCEDIMIENTO DE PREPARACION EN LABORATORIO:';
      this.dataProject.messageOneInput = 'PROCEDIMIENTO DE PREPARACION EN LABORATORIO:';
      this.messageTwoInput = 'REQUISITOS LEGALES Y REGLAMENTARIOS QUE LE APLICAN:';
      this.dataProject.messageTwoInput = 'REQUISITOS LEGALES Y REGLAMENTARIOS QUE LE APLICAN:';
      this.packaging = false;
      this.dataProject.typeRd = 'Ingrediente';
    }
    localStorage.setItem('tipoConsulta', JSON.stringify(this.dataProject.typeRd));
  }

  /**
   * Function on change to change the value of description of data project
   */
  changeDataInformation() {
    let { nombreProyecto, cacaoPrice, trm } = this.dataProject;
    this.dataProject.nombreProyecto = nombreProyecto;
    this.dataProject.cacaoPrice = cacaoPrice;
    this.dataProject.trm = trm;
  }

  /**
   * Function push data to list prices of raw materials
   */
  addRegister() {
    this.dataProject.listPrices.push({
      typeRawMaterial: '',
      sapCode: '',
      rawMaterial: '',
      percentage: 0,
      provider: '',
      costUnd: 0,
      costKg: 0,
      formula: '',
      area: '',
      gramaje: '',
      comments: '',
      currency: '',
      editable: false,
      validationSap: false,
      expired: '',
      chocolate: false,
    });
    localStorage.setItem('tipoMateriaPrima', JSON.stringify('Materia prima 1'));
  }

  /**
   * Onchange select for type raw material selected
   * **/
  selectOption(event: any, index: any) {
    this.dataProject.listPrices[index].typeRawMaterial = event.value;
    localStorage.setItem('tipoMateriaPrima', JSON.stringify(this.dataProject.listPrices[index].typeRawMaterial));
  }

  /**
   * Async function to get prices and name of raw materials type one and type two
   * @param index index of list prices to consult raw material
   * **/
  async consultRawMaterial(index: any) {
    let { typeRawMaterial, sapCode } = this.dataProject.listPrices[index];
    if (typeRawMaterial === 'Materia prima 2') {
      this.registerInfoService.getAllByMaterial(sapCode).then((res: any) => {
        this.validationSap = !!res;
        if (res.length > 0) {
          this.validationSap = false;
        }
        this.validationRawMaterial(this.validationSap, index);
        this.dataProject.listPrices[index].rawMaterial = res[0].nombreMaterial;
        this.dataProject.listPrices[index].provider = res[0].nombre_proveedor || 'LUKER';
        this.dataProject.listPrices[index].expired = res[0].status_reg_info;
        if (res[0].status_reg_info === 'Vencido') {
          this.sapCodeExpired = this.dataProject.listPrices[index].sapCode;
          this.nameExpired = this.dataProject.listPrices[index].rawMaterial;
        }
        if (res[0].ump === 'G') {
          this.dataProject.listPrices[index].costUnd =
            (parseFloat(res[0].precio_neto) / parseFloat(res[0]?.por)) * (1 + (res[0].factor || 0)) * 1000;
          this.dataProject.listPrices[index].currency = res[0].moneda;
        } else {
          this.dataProject.listPrices[index].costUnd =
            (parseFloat(res[0].precio_neto) / parseFloat(res[0]?.por)) * (1 + (res[0].factor || 0));
          this.dataProject.listPrices[index].currency = res[0].moneda;
        }
      });
    } else if (typeRawMaterial === 'Materia prima 1') {
      this.rdServices.getRawMaterialTypeOne(sapCode).then(async (res: any) => {
        this.validationSap = !!res;
        if (res.length > 0) {
          this.validationSap = false;
        }
        this.validationRawMaterial(this.validationSap, index);
        this.dataProject.listPrices[index].rawMaterial = res[0].nombreComponente;
        this.dataProject.listPrices[index].provider = 'LUKER';
        this.dataProject.listPrices[index].currency = 'COP';
        let costs: any = await this.costService.getRawMaterialTypeOne(
          parseFloat(this.dataProject.trm),
          parseFloat(this.dataProject.cacaoPrice)
        );
        if (res[0].nombreComponente.includes('PS LICOR')) {
          this.dataProject.listPrices[index].costUnd = costs.precioLicor;
        } else if (res[0].nombreComponente.includes('PS MANTECA DESODORIZADA') && res[0].componente == '2000504') {
          this.dataProject.listPrices[index].costUnd = costs.precioMantecaDeso;
        } else if (res[0].nombreComponente.includes('PS MANTECA') && res[0].componente != '2000504') {
          this.dataProject.listPrices[index].costUnd = costs.precioManteca;
        } else if (res[0].nombreComponente.includes('PS COCOA')) {
          this.dataProject.listPrices[index].costUnd = costs.precioCocoa;
        } else if (res[0].componente == '2000926') {
          this.dataProject.listPrices[index].costUnd = costs.precioCafeColombiano;
        }
      });
    } else if (this.packaging) {
      this.registerInfoService.getAllByMaterialPackaging(sapCode).then((res: any) => {
        this.validationSap = !!res;
        if (res.length > 0) {
          this.validationSap = false;
        }
        this.validationRawMaterial(this.validationSap, index);
        this.dataProject.listPrices[index].rawMaterial = res[0].nombreMaterial;
        this.dataProject.listPrices[index].provider = res[0].provider || 'LUKER';
        this.dataProject.listPrices[index].currency = res[0].moneda || 'COP';
        this.dataProject.listPrices[index].expired = res[0].status_reg_info;
        this.dataProject.listPrices[index].costUnd =
          (parseFloat(res[0]?.precio_neto) / parseFloat(res[0]?.por)) * (1 + (res[0]?.factor || 0));
        if (res[0].status_reg_info === 'Vencido') {
          this.sapCodeExpired = this.dataProject.listPrices[index].sapCode;
          this.nameExpired = this.dataProject.listPrices[index].rawMaterial;
        }
        this.packagingService
          .getBySapCode(this.dataProject.listPrices[index].sapCode)
          .then((responsePackaging: any) => {
            this.dataProject.listPrices[index].gramaje = responsePackaging[0].gramajeEstructura || 0;
            this.dataProject.listPrices[index].area = responsePackaging[0].area || 0;
          });
      });
    } else if (typeRawMaterial === 'Chocolate') {
      this.materialListService.getChocolateBySap(sapCode).then((res: any) => {
        this.dataProject.listPrices[index].rawMaterial = res.texto;
        this.dataProject.listPrices[index].provider = 'LUKER';
        this.dataProject.listPrices[index].currency = 'COP';
      });
      this.validateChocolateConsult = true;
      await this.materialListService.getCostByRawMaterialTypeOne(sapCode, 0);
      await this.materialListService.getCostByRawMaterialTypeTwo(sapCode, 0, 0);
      let rawMaterialOne = this.materialListService.rawMaterialsTypeOne;
      let rawMaterialTwo = this.materialListService.rawMaterialsTypeTwo;
      for (let item of rawMaterialTwo) {
        this.dataProject.listPrices.push({
          typeRawMaterial: 'Materia prima 2',
          sapCode: item.codigoSap,
          editable: false,
          chocolate: true,
          factorKg: item.factorKg,
        });
      }
      for (let item of rawMaterialOne) {
        this.dataProject.listPrices.push({
          typeRawMaterial: 'Materia prima 1',
          sapCode: item.codigoSap,
          editable: false,
          chocolate: true,
          factorKg: item.factorKg,
        });
      }
      for (let item in this.dataProject.listPrices) {
        if (
          this.dataProject.listPrices[item].typeRawMaterial === 'Materia prima 1' ||
          this.dataProject.listPrices[item].typeRawMaterial === 'Materia prima 2'
        ) {
          await this.consultRawMaterial(item);
          this.calculatePrices(item);
        }
      }
    }
  }

  isNumberValid(number: number): boolean {
    const numberRegex = /^[0-9]+(.[0-9]{2})?$/;
    return numberRegex.test(number.toString());
  }

  /**
   * Calculate cost total of price raw materials
   * @param index list prices to calculate total cost
   * **/
  calculatePrices(index: any) {
    // Desestructurando valores del arreglo listPrices
    let { costUnd, percentage } = this.dataProject.listPrices[index];

    // Comprobando si el porcentaje es un número entero
    if (/^-?\d+$/.test(this.dataProject.listPrices[index].percentage)) {
      this.dataProject.listPrices[index].percentage = Math.round(this.dataProject.listPrices[index].percentage);
      if (this.dataProject.listPrices[index].percentage > 100) {
        this.dataProject.listPrices[index].percentage = 100;
      }
    } else if (/^-?\d+(\.\d{4,})?$/.test(this.dataProject.listPrices[index].percentage)) {
      // Formateando el porcentaje con hasta 3 decimales
      this.dataProject.listPrices[index].percentage = this._decimalPipe.transform(
        this.dataProject.listPrices[index].percentage,
        '1.3-3'
      );
    }

    // Comprobando si el tipo de materia prima es 'Chocolate'
    if (this.dataProject.listPrices[index].typeRawMaterial === 'Chocolate') {
      let percentageChocolate = Number(this.dataProject.listPrices[index].percentage);

      // Creando opciones para las materias primas, desactivando 'Chocolate'
      this.optionsRawMaterial = [
        { value: 'Materia prima 1', label: 'Materia prima 1', selected: false, inactive: false },
        { value: 'Materia prima 2', label: 'Materia prima 2', selected: false, inactive: false },
        { value: 'Chocolate', label: 'Chocolate', selected: false, inactive: true },
      ];

      // Actualizando porcentaje y costo para las materias primas basado en el porcentaje de Chocolate
      for (let item of this.dataProject.listPrices) {
        if (
          item.typeRawMaterial === 'Materia prima 1' ||
          (item.typeRawMaterial === 'Materia prima 2' && item.chocolate)
        ) {
          // Calculando el nuevo porcentaje para la materia prima basado en el porcentaje de Chocolate
          item.percentage = percentageChocolate * item.factorKg;

          // Calculando el costo por kg de la materia prima
          if (item.currency === 'USD') {
            // Si la moneda es USD, se convierte el costo unitario a la moneda local usando el factor de tasa de cambio 'trm'
            // Luego, se multiplica por el porcentaje para obtener el costo por kg.
            item.costKg = item.costUnd * this.dataProject.trm * (item.percentage / 100);
          } else {
            // Si la moneda no es USD, simplemente se multiplica el costo unitario por el porcentaje para obtener el costo por kg.
            item.costKg = item.costUnd * (item.percentage / 100);
          }

          // Marcando que esta materia prima es de tipo Chocolate
          item.chocolate = true;
        }
      }

      // Actualizando costo total de Chocolate y costo total de no-Chocolate junto con el porcentaje total
      this.dataProject.listPrices[index].costKg = this.dataProject.listPrices
        .filter((item: any) => item.chocolate)
        .reduce((a: any, b: any) => a + b.costKg, 0);
      this.dataProject.totalCost = this.dataProject.listPrices
        .filter((item: any) => !item.chocolate)
        .reduce((a: any, b: any) => a + b.costKg, 0);
      this.dataProject.totalPercentage = this.dataProject.listPrices
        .filter((item: any) => !item.chocolate)
        .reduce((a: any, b: any) => a + b.percentage, 0);
    } else if (
      this.dataProject.listPrices[index].typeRawMaterial === 'Materia prima 1' ||
      this.dataProject.listPrices[index].typeRawMaterial === 'Materia prima 2'
    ) {
      // Actualizando costo por kg para materias primas que no son Chocolate
      this.dataProject.listPrices[index].costKg = costUnd * (percentage / 100);
    } else if (this.packaging) {
      // Si el tipo no es Chocolate ni materia prima no-Chocolate, llama a calculatePackagingCosts
      this.calculatePackagingCosts(index);
    }

    // Si la moneda es USD, convierte el costo según la TRM del proyecto (tasa de cambio)
    if (this.dataProject.listPrices[index].currency === 'USD') {
      this.dataProject.listPrices[index].costKg = this.dataProject.listPrices[index].costKg * this.dataProject.trm;
    }

    // Manejando los porcentajes y costos totales según el tipo de materia prima
    if (this.dataProject.listPrices[index].typeRawMaterial === 'Chocolate') {
      // Estas líneas están comentadas y parecen estar relacionadas con calcular totalPercentage y totalCost para elementos que no son Chocolate
    } else {
      if (
        !this.dataProject.listPrices[index].chocolate &&
        this.dataProject.listPrices[0].typeRawMaterial == 'Chocolate'
      ) {
        // Calcular totalPercentage y totalCost para elementos que no son Chocolate cuando antes había Chocolate
        let filterNoneChocolate = this.dataProject.listPrices.filter((item: any) => !item.chocolate);
        this.dataProject.totalPercentage = filterNoneChocolate.reduce(
          (acc: any, item: any) => acc + item.percentage,
          0
        );
        this.dataProject.totalCost = filterNoneChocolate.reduce((acc: any, item: any) => acc + item.costKg, 0);
      } else if (
        !this.dataProject.listPrices[index].chocolate &&
        this.dataProject.listPrices[0].typeRawMaterial !== 'Chocolate'
      ) {
        // Calcular totalPercentage y totalCost para elementos que no son Chocolate cuando nunca hubo Chocolate
        this.dataProject.totalPercentage = this.dataProject.listPrices
          .map((item: any) => Number(item.percentage))
          .reduce((prev: any, curr: any) => prev + curr, 0);
        this.dataProject.totalCost = this.dataProject.listPrices
          .map((item: any) => item.costKg)
          .reduce((prev: any, curr: any) => prev + curr, 0);
      }
    }
  }

  validationRawMaterial(name?: boolean, index?: any) {
    let { area, formula, gramaje, provider, rawMaterial } = this.dataProject.listPrices[index];
    if (name) {
      this.dataProject.listPrices[index].sapCode = 'NO COD';
      this.dataProject.listPrices[index].editable = true;
      this.dataProject.listPrices[index].area = area;
      this.dataProject.listPrices[index].formula = formula;
      this.dataProject.listPrices[index].gramaje = gramaje;
      this.dataProject.listPrices[index].provider = provider;
      this.dataProject.listPrices[index].rawMaterial = rawMaterial;
      this.dataProject.listPrices[index].validationSap = true;
    }
  }

  calculatePackagingCosts(index: any) {
    let form0 = this.dataProject.listPrices[0].formula;
    let form1 = this.dataProject.listPrices[1]?.formula;
    let form2 = this.dataProject.listPrices[2]?.formula;
    let form3 = this.dataProject.listPrices[3]?.formula;
    let form4 = this.dataProject.listPrices[4]?.formula;
    if (index === 0) {
      this.dataProject.listPrices[index].costKg =
        (1000 / (this.dataProject.gramajeProject * form0)) *
        ((this.dataProject.listPrices[0].gramaje * this.dataProject.listPrices[0].area) / 1000) *
        this.dataProject.listPrices[0].costUnd;
    } else if (index === 1) {
      this.dataProject.listPrices[index].costKg =
        (1000 / (this.dataProject.gramajeProject * form1 * form0)) *
        ((this.dataProject.listPrices[1].gramaje * this.dataProject.listPrices[1].area) / 1000) *
        this.dataProject.listPrices[1].costUnd;
    } else if (index === 2) {
      this.dataProject.listPrices[index].costKg =
        (1000 / (this.dataProject.gramajeProject * form2 * form1 * form0)) *
        ((this.dataProject.listPrices[2].gramaje * this.dataProject.listPrices[2].area) / 1000) *
        this.dataProject.listPrices[2].costUnd;
    } else if (index === 3) {
      this.dataProject.listPrices[index].costKg =
        (1000 / (this.dataProject.gramajeProject * form3 * form2 * form1 * form0)) *
        ((this.dataProject.listPrices[3].gramaje * this.dataProject.listPrices[3].area) / 1000) *
        this.dataProject.listPrices[3].costUnd;
    } else if (index === 4) {
      this.dataProject.listPrices[index].costKg =
        (1000 / (this.dataProject.gramajeProject * form4 * form3 * form2 * form1 * form0)) *
        ((this.dataProject.listPrices[4].gramaje * this.dataProject.listPrices[4].area) / 1000) *
        this.dataProject.listPrices[4].costUnd;
    }
  }

  /**
   * Onchange to change the value of inputs and textarea the descriptions of the project
   * **/
  changeText() {
    let { textoLaboratorio, textoLeyes, variablesCriticas, otros, aprobadoPor } = this.dataProject;
    this.dataProject.textoLaboratorio = textoLaboratorio;
    this.dataProject.textoLeyes = textoLeyes;
    this.dataProject.variablesCriticas = variablesCriticas;
    this.dataProject.otros = otros;
    this.dataProject.aprobadoPor = aprobadoPor;
  }

  removeItem(index: any) {
    if (this.dataProject.listPrices[0].typeRawMaterial === 'Chocolate') {
      this.dataProject.totalPercentage = this.dataProject.listPrices[0].percentage;
      if (this.dataProject.listPrices[index].typeRawMaterial === 'Chocolate') {
        this.dataProject.listPrices = this.dataProject.listPrices.filter((item: any) => item.chocolate === false);
        this.dataProject.totalPercentage = this.dataProject.listPrices
          .filter((item: any) => item.chocolate === false)
          .reduce((acc: any, item: any) => acc + item.percentage, 0);
        this.dataProject.totalCost = this.dataProject.listPrices
          .filter((item: any) => item.chocolate === false)
          .reduce((acc: any, item: any) => acc + item.costKg, 0);
        this.optionsRawMaterial[2].inactive = false;
      }
      if (this.dataProject.listPrices[index].chocolate === false) {
        this.dataProject.listPrices.splice(index, 1);
        this.dataProject.totalCost = this.dataProject.listPrices
          .filter((item: any) => !item.chocolate)
          .reduce((acc: any, item: any) => acc + item.costKg, 0);
        this.dataProject.totalPercentage = this.dataProject.listPrices
          .filter((item: any) => !item.chocolate)
          .reduce((acc: any, item: any) => acc + item.percentage, 0);
      }
    } else {
      this.dataProject.listPrices.splice(index, 1);
      this.dataProject.totalPercentage = this.dataProject.listPrices
        .map((item: any) => Number(item.percentage))
        .reduce((prev: any, curr: any) => prev + curr, 0);
    }
  }

  async openModal(index: any) {
    let sap = await this.sms
      .addModal(ModalConsultComponent, {}, { autoFocus: true, closeOnClickOutside: true })
      .toPromise();
    if (sap) {
      this.dataProject.listPrices[index].sapCode = sap;
    }
    this.consultRawMaterial(index);
    return sap;
  }

  /**
   * This function execute to service to save data of project
   * **/
  saveChanges() {
    let version;
    if (this.dataProject.status === 'Antiguo') {
      version = this.dataProject.version += 1;
    } else if (this.dataProject.status === 'Nuevo') {
      version = 1;
    }
    let project = {
      cacaoPrice: this.dataProject.cacaoPrice,
      elaboradoPor: this.dataProject.elaboradoPor,
      fechaRealizacion: this.dataProject.fechaRealizacion,
      listPrices: this.dataProject.listPrices,
      totalCost: this.dataProject.totalCost,
      totalPercentage: this.dataProject.totalPercentage,
      trm: this.dataProject.trm,
      version: version,
      nombreProyecto: this.dataProject.nombreProyecto,
      typeRd: this.dataProject.typeRd,
      gramajeProject: this.dataProject.gramajeProject,
      status: 'Antiguo',
      textoLaboratorio: this.dataProject.textoLaboratorio,
      textoLeyes: this.dataProject.textoLeyes,
      variablesCriticas: this.dataProject.variablesCriticas,
      otros: this.dataProject.otros,
      otherDollar: this.otherDollar,
      otherCacao: this.otherCacaco,
      aprobadoPor: this.dataProject.aprobadoPor,
    };
    if (this.dataProject.status === 'Antiguo') {
      this.rdServices.postNewRdProject(project).then(() => {
        if (this.typeRd) {
          this.router.navigate(['/rd-projects'], {
            queryParams: { 
              type : this.typeRd
            }
          });
        } else {
          this.router.navigateByUrl('/rd-projects');
        }
      });
    } else if (this.dataProject.status === 'Nuevo') {
      this.rdServices.putProjectRd(this.idProject, project).then(() => {
        if (this.typeRd) {
          this.router.navigate(['/rd-projects'], {
            queryParams: { 
              type : this.typeRd
            }
          });
        } else{
          this.router.navigateByUrl('/rd-projects');
        }
      });
    }
    localStorage.removeItem('tipoConsulta');
    localStorage.removeItem('tipoMateriaPrima');
  }

  convertPdf() {
    this.buttonsBol = true;
    this.otherCacaco = true;
    setTimeout(() => {
      const rd = document.getElementById('contentToConvert')!;
      const rdHeight = rd.clientHeight;
      const rdWidth = rd.clientWidth;
      const options = { background: 'white', width: rdWidth, height: rdHeight };
      domtoimage.toPng(rd, options).then((dataUrl: string) => {
        const doc = new jsPDF(rdWidth > rdHeight ? 'l' : 'p', 'mm', [rdWidth, rdHeight]);
        const imgProps = doc.getImageProperties(dataUrl);
        const pdfWidth = imgProps.width;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        doc.save('RD.pdf');
        this.buttonsBol = false;
        this.otherCacaco = false;
      });
    }, 500);
  }

  dataShowInExcel() {
    let data = [];
    for (let item of this.dataProject.listPrices) {
      data.push({
        'Tipo de materia prima': item.typeRawMaterial,
        'Codigo SAP': item.sapCode,
        'Materias Primas': item.rawMaterial,
        Porcentaje: item.percentage,
        Proveedor: item.provider,
        'Costo und': item.costUnd,
        'Costo kg': item.costKg,
        Formula: item.formula,
        Area: item.area,
        Gramaje: item.gramaje,
        Moneda: item.currency,
      });
    }
    return data;
  }

  dataInfoShowInExcel() {
    return [
      {
        'Nombre del proyecto': this.dataProject.nombreProyecto,
        'Formula nueva': 'Si',
        'Fecha de realizacion': this.dataProject.fechaRealizacion,
        Version: this.dataProject.version,
        'Cacao price': this.dataProject.cacaoPrice,
        'Dolar price': this.dataProject.trm,
        'Tipo de R&D': this.dataProject.typeRd,
        'PROCEDIMIENTO DE PREPARACION EN LABORATORIO': this.dataProject.textoLaboratorio,
        'REQUISITOS LEGALES Y REGLAMENTARIOS QUE LE APLICAN': this.dataProject.textoLeyes,
        'VARIABLES CRITICAS:': this.dataProject.variablesCriticas,
        OTROS: this.dataProject.otros,
        'APROBADO POR': this.dataProject.aprobadoPor,
        'ELABORADO POR': `${this.dataProject.elaboradoPor?.nombre} ${this.dataProject.elaboradoPor?.apellido}`,
      },
    ];
  }

  /**
   * This function call of service to export data to excel of project RD
   * **/
  exportToExcel() {
    this.rdServices.downloadExcel(this.dataProject, this.sapCodeExpired, this.nameExpired);
  }

  /**
   * Function to consult nutritional table, return a object with nutrition data
   * this data of nutrition is sent to service of pdf, this pdf contain information of r&d project
   * **/
  getPdfTechnical() {
    let listPrices = this.dataProject.listPrices;
    let user = this.authService.getUser();
    this.nutritionService.getDataNutritionTable({ rawMaterials: listPrices }).then((res: any) => {
      let data = {
        listMaterials: listPrices
          .sort((a: any, b: any) => b.percentage - a.percentage)
          .map((item: any) => item.rawMaterial)
          .join(', '),
        nameProject: this.dataProject.nombreProyecto,
        nutritionals: res,
        version: this.dataProject.version,
        date: moment(this.dataProject.fechaRealizacion).format('DD/MM/YYYY'),
        name: `${user.nombre} ${user.apellido}`,
        email: user.email,
      };
      this.rdServices.getPdfTechnicalService(data).subscribe((res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        window.open(window.URL.createObjectURL(blob));
      });
    });
  }
}
