<div class="main-container" id="contentToConvert" [ngClass]="{'pdf-mobile': buttonsBol}">
  <span class="title-rd d-flex justify-content-center">R&D Prototype</span>
  <div class="row container__project-info">
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Nombre del Proyecto
      </div>
      <div class="description-field">
        <input type="text" class="edit-input" maxlength="40"  [(ngModel)]="dataProject.nombreProyecto" (change)="changeDataInformation()">
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Fórmula Nueva
      </div>
      <div class="description-field">
        SI
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Fecha realización
      </div>
      <div class="description-field">
        {{dataProject?.fechaRealizacion | date: 'dd/MM/yyyy'}}
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Versión
      </div>
      <div class="description-field">
        {{dataProject?.version}}
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Cacao Price
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="field-description-editable">
        <select [(ngModel)]="dataProject.cacaoPrice" *ngIf="otherCacaco === false">
          <option value="{{item.value}}" *ngFor="let item of cacaoPrices">{{item.label}}</option>
        </select>
        <input type="number" [(ngModel)]="dataProject.cacaoPrice"  class="edit-input" *ngIf="otherCacaco" min="0" oninput="this.value = Math.abs(this.value)">
      </div>
      <div>
        <a (click)="selectOtherCacao()" class="other-type">Other cacao price</a>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Dólar Price
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
      <div class="field-description-editable">
        <select [(ngModel)]="dataProject.trm" *ngIf="otherDollar === false">
          <option value="{{dollarPrices?.value }}">{{dollarPrices?.value}} - TRM</option>
          </select>
        <input type="number" [(ngModel)]="dataProject.trm"  class="edit-input" *ngIf="otherDollar" min="0" oninput="this.value = Math.abs(this.value)">
      </div>
      <div>
        <a (click)="selectOtherDollar()" class="other-type">Other Dolar price</a>
      </div>
    </div>
    <div *ngIf="!typeRd" class="col-12 col-sm-6 col-lg-3">
      <div class="title-field">
        Selecciona tipo de R&D
      </div>
    </div>
    <div *ngIf="!typeRd" class="col-12 col-sm-6 col-lg-3">
      <div class="field-description-editable">
        <select (change)="changePackaging($event)" [(ngModel)]="this.dataProject.typeRd">
          <option value="{{option.value}}" *ngFor="let option of options">{{option.label}}</option>
        </select>
        <!-- <p-dropdown (onChange)="changePackaging($event)" [options]="options" [(ngModel)]="this.dataProject.typeRd" optionLabel="label" optionValue="value"></p-dropdown> -->
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3" *ngIf="packaging === true">
      <div class="title-field">
        Ingresa gramaje del empaque
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3" *ngIf="packaging === true">
      <div class="field-description-editable">
        <input type="number" [(ngModel)]="this.dataProject.gramajeProject"  class="edit-input" min="0" oninput="this.value = Math.abs(this.value)">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div *ngIf="buttonsBol === false" (click)="exportToExcel()">
          <fa-icon [icon]="faFileExcel" class="icon-excel"></fa-icon>
        </div>
        <div style="margin-left: 20px" (click)="convertPdf()" *ngIf="buttonsBol === false">
          <fa-icon [icon]="faFilePdf" class="icon-pdf"></fa-icon>
        </div>
        <div style="margin-left: 20px" (click)="getPdfTechnical()"  *ngIf="packaging === false" [hidden]="buttonsBol">
          <fa-icon [icon]="faSheetPlastic" class="icon-sheet"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <p class="mt-5 note-material">
    Para usar una cobertura como base, seleccione la opción "Chocolate" en la posición 1 y después agregue los demás ingredientes
  </p>
  <div class="row mt-3">
    <div class="col" style="overflow-x: scroll; padding-bottom: 30px;">
      <table class="w-100 table-rd">
        <caption class="mt-4">Table RD</caption>
        <thead>
        <tr>
          <th class="header-table" scope="col" colspan="7">Fórmula</th>
          <th class="header-table" scope="col" colspan="2">Especificaciones</th>
        </tr>
        <tr style="height: 10px;">
        </tr>
        <tr>
          <th class="header-table" id="acciones" scope="col">Acciones</th>
          <th class="header-table" scope="col" *ngIf="packaging === false">Tipo de materia prima</th>
          <th class="header-table" scope="col">Código SAP</th>
          <th class="header-table" scope="col">Materias Primas</th>
          <th class="header-table" scope="col" *ngIf="packaging === true">Formula</th>
          <th class="header-table" scope="col" *ngIf="packaging === true">Gramaje</th>
          <th class="header-table" scope="col" *ngIf="packaging === true">Area</th>
          <th class="header-table" style="min-width: 60px;" scope="col" *ngIf="packaging === false">%</th>
          <th class="header-table" scope="col">Proveedor</th>
          <th class="header-table" scope="col">Currency</th>
          <th class="header-table" scope="col">Costo/UND</th>
          <th class="header-table" scope="col">Costo/Kg</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataProject.listPrices;let i = index">
            <td class="d-flex justify-content-around align-middle">
              <fa-icon [icon]="faMagnifyingGlass" (click)="openModal(i)" *ngIf="item.typeRawMaterial === 'Materia prima 2' || item.typeRawMaterial === 'Chocolate' || packaging || item.typeRawMaterial === 'Materia prima 1'"></fa-icon>
              <fa-icon [icon]="faTrash" [styles]="{'color': 'red'}" (click)="removeItem(i)"></fa-icon>
            </td>
            <td class="field-table" *ngIf="packaging === false">
              <p-dropdown [disabled]="item.chocolate" *ngIf="i === 0" (onChange)="selectOption($event, i)" [options]="optionsRawMaterial" [(ngModel)]="item.typeRawMaterial" optionLabel="label" optionValue="value" optionDisabled="inactive"></p-dropdown>
              <p-dropdown [disabled]="item.chocolate" *ngIf="i > 0" (onChange)="selectOption($event, i)" [options]="optionsRawMaterialTypeTwo" [(ngModel)]="item.typeRawMaterial" optionLabel="label" optionValue="value" optionDisabled="inactive"></p-dropdown>
            </td>
            <td class="field-table" [class.opacity-fields]="item.chocolate">
              <input type="text" class="edit-input" [disabled]="item.chocolate" [(ngModel)]="item.sapCode" (change)="consultRawMaterial(i)">
            </td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate">
              <span *ngIf="item.editable === false || item.validationSap === false">{{item.rawMaterial}}</span>
              <input type="text" [(ngModel)]="item.rawMaterial" *ngIf="item.validationSap === true|| item.editable === true" class="edit-input">
            </td>
            <td class="editable-field" *ngIf="packaging === true">
              <input type="number" class="edit-input" [(ngModel)]="item.formula" (change)="calculatePrices(i)" min="0" oninput="this.value = Math.abs(this.value)">
            </td>
            <td class="editable-field" *ngIf="packaging === true">
              <span *ngIf="item.editable === false || item.validationSap === false">{{item.gramaje}}</span>
              <input type="number" [(ngModel)]="item.gramaje" *ngIf="item.validationSap === true || item.editable === true" class="edit-input" min="0" oninput="this.value = Math.abs(this.value)">
            </td>
            <td class="editable-field" *ngIf="packaging === true">
              <span *ngIf="item.editable === false || item.validationSap === false">{{item.area}}</span>
              <input type="number" [(ngModel)]="item.area" *ngIf="item.validationSap === true || item.editable === true" class="edit-input" min="0" oninput="this.value = Math.abs(this.value)">
            </td>
            <td class="field-table" *ngIf="packaging === false"  [class.opacity-fields]="item.chocolate">
              <input type="number" class="edit-input" [disabled]="item.chocolate" *ngIf="!item.chocolate" [(ngModel)]="item.percentage" (change)="calculatePrices(i)" min="0" maxlength="3">
              <div class="edit-input" *ngIf="item.chocolate">{{item.percentage | number: '1.3-3'}}</div>
            </td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate">
              <span *ngIf="item.editable === false || item.validationSap === false">{{item.provider}}</span>
              <input type="text" class="edit-input" [(ngModel)]="item.provider" *ngIf="item.validationSap === true || item.editable === true">
            </td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate">
              <span *ngIf="item.editable === false || item.validationSap === false" >{{item.currency}}</span>
              <input type="text" class="edit-input" [(ngModel)]="item.currency" *ngIf="item.validationSap === true || item.editable === true">
            </td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate">
              <span *ngIf="item.editable === false || item.validationSap === false">{{item.costUnd | number: item.currency!='COP'?'1.2-2':'1.0-0'}}</span>
              <input type="number" class="edit-input" [(ngModel)]="item.costUnd" *ngIf="item.validationSap === true || item.editable === true" (change)="calculatePrices(i)" min="0" oninput="this.value = Math.abs(this.value)">
            </td>
            <!-- Validate if costKg is null and add empty field -->
            <td class="editable-field"  [class.opacity-fields]="item.chocolate" *ngIf="item.costKg === null" >{{'' | number: '1.3-3'}}</td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate" *ngIf="item.costKg !== null && item.costKg.toString()[0] === '0'" >{{item.costKg | number: '1.3-3'}}</td>
            <td class="editable-field"  [class.opacity-fields]="item.chocolate" *ngIf="item.costKg !== null && item.costKg.toString()[0] !== '0'" >{{item.costKg | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td class="total-field" colspan="3">TOTAL</td>
            <td class="field-table"></td>
            <td class="field-table" *ngIf="dataProject.totalPercentage <= 100 && packaging === false">{{dataProject.totalPercentage}}%</td>
            <td class="total-field" *ngIf="dataProject.totalPercentage > 100 && packaging === false">{{dataProject.totalPercentage}}%</td>
            <td class="field-table"></td>
            <td class="field-table" *ngIf="packaging === true"></td>
            <td class="field-table" *ngIf="packaging === true"></td>
            <td class="field-table" colspan="2" ></td>
            <td class="field-table" colspan >{{dataProject.totalCost | number: '1.0-0'}}</td>
          </tr>
        </tbody>
        <button class="btn-add" (click)="addRegister()" [disabled]="dataProject?.listPrices?.length >= 5 && packaging" *ngIf="buttonsBol === false" >Add +</button>
      </table>
      <p *ngIf="dataProject?.listPrices?.length >= 5 && packaging" class="title-field">No puedes agregar más de 5 registros</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 col-lg-6">
      <div class="title-field">
        {{messageOneInput}}
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="description-field-final">
        <textarea maxlength="200" placeholder="Write..." [(ngModel)]="this.dataProject.textoLaboratorio" (keyup)="changeText()"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title-field">
        {{messageTwoInput}}
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="description-field-final">
        <textarea maxlength="200" placeholder="Write..." [(ngModel)]="this.dataProject.textoLeyes" (keyup)="changeText()"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title-field">
        VARIABLES CRITICAS:
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="description-field-final">
        <textarea maxlength="200" placeholder="Write..." [(ngModel)]="this.dataProject.variablesCriticas" (keyup)="changeText()"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title-field">
        Otros
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="description-field-final">
        <div class="container-expired" *ngIf="sapCodeExpired">
          {{sapCodeExpired}} {{nameExpired}} expired.
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title-field">
        Aprobado por
      </div>
      <div class="description-field">
        <input class="edit-input" [(ngModel)]="this.dataProject.aprobadoPor" (keyup)="changeText()">
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title-field">
        Elaborado por
      </div>
      <div class="description-field">
        {{this.dataProject.elaboradoPor?.nombre + ' ' + this.dataProject.elaboradoPor?.apellido}}
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-center">
      <button class="btn-create" (click)="saveChanges()" [disabled]="dataProject.totalPercentage > 100" *ngIf="buttonsBol === false">Save changes</button>
    </div>
  </div>
</div>
