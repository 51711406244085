import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import ls from 'localstorage-slim';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as fs from 'file-saver';
import { ModalProductDetailsComponent } from '../../../finished-product/modal-product-details/modal-product-details.component';
import { PrivateLabelPortfolioService } from 'src/app/core/services/private-label-portfolio/private-label-portfolio.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { PgService } from 'src/app/core/services/tablepg/pg.service';

@Component({
  selector: 'app-download-quote-private-label',
  templateUrl: './download-quote-private-label.component.html',
  styleUrls: ['./download-quote-private-label.component.css'],
})
export class DownloadQuotePrivateLabelComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  faMagnifyingGlass = faMagnifyingGlass;

  datepipe: DatePipe = new DatePipe('en-US')
  showingSavedQuote = false

  kgToPoundFactor = 1/2.204

  /**Table of costs p&g */
  costsPg: any;

  constructor(
    private sms: SimpleModalService,
    private spinner: NgxSpinnerService,
    private tipsService: TipsService,
    private formBuilder: FormBuilder,
    private privateLabelPortfolioService: PrivateLabelPortfolioService,
    private router: Router,
    private pgService: PgService,
  ) {}

  totalCost = 0
  dollarPrice= 0
  productsList:any=[]
  parameters:any = {}
  project:any = {}

  columns: any[] = [
    { name: 'Actions' },
    { name: 'RTL Code' },
    { name: 'Txt Material' },
    { name: 'Total cost' },
    { name: 'Sale price (COP)' },
    { name: 'Price/box (USD)' },
    { name: 'Cost/Unit' },
    { name: 'Price/Unit (USD)' },
    { name: 'Margin (%)' },
    { name: 'EBITDA (%)' },
    { name: 'Sale price / Kg (USD)' },
    { name: 'Sale price / Pound (USD)' }
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
    ])
  });

  tips:any=[]
  brief:any = {}
  user:any = {}

  comments = new FormControl("", [])

  async ngOnInit() {
    await this.loadPgInformation()
    this.setParameters();
    this.setProject();
    this.setComments()
    this.setProducts();
    this.loadAssistantTips();
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */

  async addNewRow(index: number) {
    const price_usd = this.productsList[index].price_usd || 0
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const cost_per_unit = (this.productsList[index].total_cost/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_unit = (price_usd/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_box = price_per_unit*(this.productsList[index].packaging.unitsPerBox||0)
    const ebitda = this.calculateEbitda(price_usd, this.productsList[index])
    const ebitda_percentage = price_usd!=0?((ebitda / price_cop) * 100):0

    const formGroup = this.formBuilder.group(
      {
        rtlCode: this.productsList[index].rtlCode,
        txt_material: this.productsList[index].txt_material,
        total_cost: Math.round(this.productsList[index].total_cost),
        price_cop: Math.round(price_cop),
        price_per_box: price_per_box.toFixed(2),
        cost_per_unit: Math.round(cost_per_unit),
        price_per_unit: price_per_unit.toFixed(2),
        gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
        ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0,
        price_usd: price_usd!=0?price_usd.toFixed(2):0,
        price_usd_pound: price_usd_pound!=0?price_usd_pound.toFixed(2):0
      }
    );
    this.getFormArray().push(formGroup);
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    formControls.get('price_usd')?.valueChanges.subscribe(v => this.updatePrice(v, index));
    formControls.get('gross_margin')?.valueChanges.subscribe(v => this.updateMargin(v, index));
    formControls.get('ebitda')?.valueChanges.subscribe(v => this.updateEbitda(v, index));
    formControls.get('price_usd_pound')?.valueChanges.subscribe(v => this.updatePriceKgToPound(v, index));

  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {

      const product = this.productsList[index]
      this.totalCost-=this.productsList[index].total_cost;
      this.getFormArray().controls.splice(index, 1);
      await this.productsList.splice(index, 1)
      if (this.productsList.length==0) {
        this.completed.emit({ flow: 'launch', step: 2, completed: false });
        this.completed.emit({ flow: 'launch', step: 1, completed: false });
      }
      localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
      return;
  }

  /**
   * Shows a dialog with the product details.
   *
   * @param index
   */
  async showProductDetails(index: number)  {

    

  }

  setProducts(){
    this.productsList = JSON.parse(localStorage.getItem('rtlProductsList')||"[]");
    for (let index = 0; index < this.productsList.length; index++) {
      this.addNewRow(index)
      this.totalCost+=this.productsList[index].total_cost;
    }
  }

  async saveProject(){
    const cliente = this.project.client || this.brief.cliente;
    const preparadoPor = this.project.user || this.user._id;
    const fecha = this.project.date || this.brief.fecha;
    const pais = this.project.country || this.brief.pais;
    const productsList = this.productsList
    const project = {
      client: cliente,
      user: preparadoPor,
      date: fecha,
      country: pais,
      products: productsList,
      parameters: this.parameters,
      comments: this.comments.value
    }
    if (this.project._id != null) {
      // const result = await this.privateLabelPortfolioService.updateProject(this.project._id, project)
    } else {
      const result:any = await this.privateLabelPortfolioService.saveProject(project)
      if (result.success == true) {
        this.router.navigateByUrl("private-label-projects");
      }
    }
    
  }

  downloadAsPDF(){
    console.log("descargar como pdf")
  }
  async downloadAsExcel(){
    const cliente = this.project.client || this.brief.cliente;
    const preparadoPor = this.project.user != null?(this.project.user.nombre + " " + this.project.user.apellido):(this.user.nombre + " " + this.user.apellido);
    const fecha = this.project.date!=null?(this.datepipe.transform(this.project.date, 'YYYY-MM-dd')):this.brief.fecha;
    const pais = this.project.country || this.brief.pais;
    const productsList = this.productsList
    const res = await fetch("../../../../assets/excel/private_label_quote.xlsx")
    const arrayBuffer = await res.arrayBuffer()
    var workbook = await new Workbook();
    await workbook.xlsx.load(arrayBuffer)
    let worksheet = workbook.getWorksheet(1);

    worksheet.getCell('D9').value = cliente
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H11').value = preparadoPor
    worksheet.getCell('D11').value = pais
    for (let index = 0; index < productsList.length; index++) {
      const product = productsList[index]
        worksheet.getRow(14+index).getCell(2).value = product.rtlCode
        worksheet.getRow(14+index).getCell(3).value = product.txt_material
        worksheet.getRow(14+index).getCell(7).value = product.packaging.weightPerUnit
        worksheet.getRow(14+index).getCell(8).value = product.price_per_box
        worksheet.getRow(14+index).getCell(9).value = product.price_per_unit
      this.createSheet(product, workbook)

    }

    workbook.xlsx.writeBuffer().then((res: any) => {
      const blob = new Blob([res]);
      fs.saveAs(blob, 'private-label-products.xlsx');
    });

  }

  async createSheet(product:any, workbook:any){
    let worksheet = workbook.addWorksheet(product.rtlCode,  {properties:{defaultColWidth:17}});
    worksheet.addRow(["Packaging"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "costo",
      "status",
      "registro",
      "validez",
      "inco1",
      "inco2"
    ])
    const packagingList = product.packaging_list.map(({desperdicios, desperdicio, units, moneda, factorKg,  ...keepAttrs}:any) => keepAttrs)
    const orderPackaging = {sap: null,name: null,precio_neto: null, price:null, status:null, register:null, valid:null, inco1:null, inco2:null} 
    for (let packaging of packagingList) {
      const sortedPackaging = Object.assign(orderPackaging, packaging);
      const values = Object.values(sortedPackaging)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials one"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "factorKg",
      "costo"
    ])
    const rawMaterialOneList = product.raw_material_one_list.map(({fullWaste, desperdicioExtraInclusion, desperdicio, globalMerm, isInclusion, moneda, proportion, nombre, tipoMateriaPrima, ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialOne = {sap: null, name: null,precioNeto: null,factorKg: null, price:null} 
    
    for (let material of rawMaterialOneList) {
      const sortedRawMaterialOne = Object.assign(orderRawMaterialOne, material);
      const values = Object.values(sortedRawMaterialOne)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials two"])
      worksheet.addRow([
        "componente",
        "nombreComponente",
        "precio_neto",
        "factorKg",
        "costo",
        "status",
        "registro",
        "validez",
        "inco1",
        "inco2"
    ])
    const rawMaterialTwoList = product.raw_material_two_list.map(({factor, desperdicio, desperdicioExtraContenidoInclusion, desperdicioExtraContenido, inclusion, desperdicioExtraInclusion, globalMerm, isInclusion, no_convertion_price, proportion, moneda, tipoMateriaPrima, nombre, percentage, registro, validez,  ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialTwo = {sap: null, name: null, precioNeto: null, factorKg: null, price:null, status:null, register:null, valid:null, inco1:null, inco2:null} 
    
    for (let material of rawMaterialTwoList) {
      const sortedRawMaterialTwo = Object.assign(orderRawMaterialTwo, material);
      const values = Object.values(sortedRawMaterialTwo)
      worksheet.addRow(values)
      
    }
    worksheet.addRow()
    const hasPlantaTriunfo = product.planta_triunfo!=null
    worksheet.addRow(["Processes"])
      worksheet.addRow(
        hasPlantaTriunfo?[
        "ltxa_1", "arbid", "oee",
          "zmod",
          "zman",
          "zenr",
          "zgas",
          "zdep",
          "zoin",
          "cif",
          "labour",
          "subtotal"
    ]:[
      "ltxa_1", "arbid", "oee",
        "zmod",
        "zman",
        "zenr",
        "zgas",
        "zdep",
        "zoin",
        "subtotal"
  ])
    const processesList = product.processes_list
    
    for (let process of processesList) {
      const subtotal = process.zmod_costo + process.zman_costo + process.zenr_costo + process.zgas_costo + process.zdep_costo + process.zoin_costo
      
      worksheet.addRow(
        hasPlantaTriunfo?[process.ltxa_1, process.arbid, process.oee, process.zmod_costo, process.zman_costo, process.zenr_costo, process.zgas_costo, process.zdep_costo, process.zoin_costo,"","", subtotal]
      :[process.ltxa_1, process.arbid, process.oee, process.zmod_costo, process.zman_costo, process.zenr_costo, process.zgas_costo, process.zdep_costo, process.zoin_costo, subtotal])
      
    }
    if (hasPlantaTriunfo) {
      const subtotal_planta_triunfo = product.planta_triunfo.cif + product.planta_triunfo.labour
      worksheet.addRow(["Planta triunfo", "", "", "", "", "", "", "", "", product.planta_triunfo.cif, product.planta_triunfo.labour, subtotal_planta_triunfo])
    }

    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow(["Sale Price / Kg (USD)", "Sale Price / Pound (USD)",	"Sale Price (COP)", "Price/Box (USD)", "Cost/Unit", "Price/Unit (USD)", "Total cost", "Gross margin (COP)",	"Gross margin (%)", "EBITDA (COP)",	"EBITDA (%)"])
    worksheet.addRow([product.price_usd||0, ((product.price_usd??0)*this.kgToPoundFactor), product.price_cop||0,  product.price_per_box||0, product.cost_per_unit||0, product.price_per_unit||0, product.total_cost, product.gross_margin||0, product.gross_margin_percentage||0, product.ebitda||0, product.ebitda_percentage||0])
    worksheet.addRow()
    worksheet.addRow(["weight", "Dolar price",	"Cocoa price USD/Ton",	"Cocoa Premium USD/Ton", "Euro price"])
    worksheet.addRow([this.parameters.weight||0, this.parameters.dollarPrice||0, this.parameters.cacaoPrice||0, this.parameters.cacaoBonus||0, this.parameters.euroPrice||0])

    worksheet.mergeCells('A1', 'I1');
    worksheet.mergeCells(`A${(packagingList.length + 4)}`, `E${(packagingList.length + 4)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + 7)}`, `J${(rawMaterialOneList.length + packagingList.length + 7)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`, `${hasPlantaTriunfo?"L":"J"}${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`);
  }

  updatePrice(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_usd = +(value||0)
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const price_per_unit = (price_usd/1000)*this.productsList[index].packaging.weightPerUnit
    const cost_per_unit = (this.productsList[index].total_cost/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_box = price_per_unit*(this.productsList[index].packaging.unitsPerBox||0)
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].price_per_unit = price_per_unit
    this.productsList[index].cost_per_unit = cost_per_unit
    this.productsList[index].price_per_box = price_per_box
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage

    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_per_unit: price_per_unit.toFixed(2),
      price_per_box: price_per_box.toFixed(2),
      price_usd_pound: price_usd_pound.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0,
    }, {emitEvent: false, onlySelf: true})
  }

  updatePriceKgToPound(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_usd_pound = +(value||0) 
    const price_usd = price_usd_pound / this.kgToPoundFactor
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const price_per_unit = (price_usd/1000)*this.productsList[index].packaging.weightPerUnit
    const cost_per_unit = (this.productsList[index].total_cost/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_box = price_per_unit*(this.productsList[index].packaging.unitsPerBox||0)
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].price_per_unit = price_per_unit
    this.productsList[index].cost_per_unit = cost_per_unit
    this.productsList[index].price_per_box = price_per_box
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage

    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_per_unit: price_per_unit.toFixed(2),
      price_per_box: price_per_box.toFixed(2),
      price_usd: price_usd.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0,
    }, {emitEvent: false, onlySelf: true})
  }

  updateMargin(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]

    const gross_margin_percentage = value||0
    const price_cop = gross_margin_percentage==100 || gross_margin_percentage==0?0:(this.productsList[index].total_cost)/(1 - gross_margin_percentage/100)
    const price_usd = price_cop/this.dollarPrice
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const gross_margin = price_cop!=0?price_cop-this.productsList[index].total_cost:0
    const price_per_unit = ((price_usd||0)/1000)*this.productsList[index].packaging.weightPerUnit
    const cost_per_unit = (this.productsList[index].total_cost/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_box = price_per_unit*(this.productsList[index].packaging.unitsPerBox||0)
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].price_per_unit = price_per_unit
    this.productsList[index].cost_per_unit = cost_per_unit
    this.productsList[index].price_per_box = price_per_box
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage


    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_per_unit: price_per_unit.toFixed(2),
      price_per_box: price_per_box.toFixed(2),
      price_usd_pound: price_usd_pound.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0,
     }, {emitEvent: false, onlySelf: true})
  }

  updateEbitda(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_cop = this.calculateNetsalesFromEbitda(value, this.productsList[index])
    const price_usd = price_cop/this.dollarPrice
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const price_per_unit = (price_usd/1000)*this.productsList[index].packaging.weightPerUnit
    const cost_per_unit = (this.productsList[index].total_cost/1000)*this.productsList[index].packaging.weightPerUnit
    const price_per_box = price_per_unit*(this.productsList[index].packaging.unitsPerBox||0)
    const ebitda_percentage = value||0
    const ebitda = ebitda_percentage*price_cop/100

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].price_per_unit = price_per_unit
    this.productsList[index].cost_per_unit = cost_per_unit
    this.productsList[index].price_per_box = price_per_box
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage
    
    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_per_unit: price_per_unit.toFixed(2),
      price_usd_pound: price_usd_pound.toFixed(2),
      price_per_box: price_per_box.toFixed(2),
     }, {emitEvent: false, onlySelf: true})
  }

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }

  setParameters(){
    const parameters =JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
    this.parameters = parameters
    this.brief =JSON.parse(ls.get('brief', { decrypt: true }) || '{}');
    this.user =JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    if (parameters.dollarPrice!=null) {
      this.dollarPrice=parameters.dollarPrice;
    }
  }
  setProject(){
    this.project = JSON.parse(ls.get('current_project', { decrypt: true }) || "{}");
    if (Object.keys(this.project).length > 0) {
      localStorage.setItem('comments', this.project.comments||"");
      this.showingSavedQuote = true
    }
  }

  setComments(){
    this.comments.setValue(localStorage.getItem('comments') || "");
  }

  saveComments(){
    localStorage.setItem('comments',  this.comments.value);
  }
  
  openModalProductInfo(index:number) {
    this.sms.addModal(ModalProductDetailsComponent,
      {product: this.productsList[index]},
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe((res: any) => {
    });
  }

  /**This function load the costs of p&g */
  async loadPgInformation() {
    this.costsPg = await this.pgService.loadPgInformation();
  }

  calculateEbitda(priceUSD:any, product:any){
    if(!(priceUSD>0)) return 0
    const pais = this.project.country || this.brief.pais
    const netSales = priceUSD * this.dollarPrice;
    const DataPgOperationsCostVariables:any = this.pgService.operationsCostVariablesPgObject(
      netSales,
      product.processes.zenr,
      product.processes.zgas,
      product.cost_raw_material_one,
      product.cost_raw_material_two,
      product.cost_packaging
    );
    const DataPgOperationsVariableExpends = this.pgService.operationsVariableExpendsObject(
      pais,
      netSales,
      this.costsPg.industryCommerceNational,
      this.costsPg.taxThousandNational,
      this.costsPg.briefcaseNational,
      this.costsPg.inventoriesMpNational,
      this.costsPg.inventoriesMfNational,
      DataPgOperationsCostVariables.energy,
      product.processes.zgas,
      product.processes.zmod,
      product.processes.zman,
      product.processes.zoin,
      this.costsPg.providersNational,
      product.cost_packaging,
      product.cost_raw_material_one,
      product.cost_raw_material_two,
      this.costsPg.monthlyRateNational,
      this.costsPg.monthlyRateInternational,
      this.costsPg.industryCommerceInternational,
      this.costsPg.taxThousandExport,
      this.costsPg.briefcaseInternational,
      this.costsPg.inventoriesMpInternational,
      this.costsPg.inventoriesMfInternational,
      this.costsPg.providersInternational,
      DataPgOperationsCostVariables.contribVariable,
      0
    );

    const DataPgOperationsFixedDirect:any = this.pgService.operationsFixedDirectObject(
      pais,
      netSales,
      this.costsPg.LogisticsWarehouseSaleNational,
      this.costsPg.marketingNational,
      product.processes.zdep,
      product.processes.zmod,
      product.processes.zman,
      product.processes.zoin,
      DataPgOperationsVariableExpends.contribFijDirec,
      this.costsPg.LogisticsWarehouseSaleInternational,
      this.costsPg.marketingInternational
    );

    const DataPgOperationsFixed = this.pgService.operationFixedPgObject(
      pais,
      netSales,
      this.costsPg.sellingExpensesNational,
      this.costsPg.groupVariableNational,
      this.costsPg.generalCompanyNational,
      DataPgOperationsFixedDirect.contribuCostGast,
      this.costsPg.sellingExpensesInternational,
      this.costsPg.groupVariableInternational,
      this.costsPg.generalCompanyInternational
    );
    const ebitda = DataPgOperationsFixed.contribution + product.processes.zdep + DataPgOperationsVariableExpends.costCapital
    return ebitda
  }

  calculateNetsalesFromEbitda(ebitda_percentage:any, product:any){
    const pais = this.project.country || this.brief.pais
    const netSales = this.pgService.netSalesFromEbitdaPercentage(
      ebitda_percentage,
      pais,
      product.processes.zenr,
      product.processes.zgas,
      product.processes.zmod,
      product.processes.zman,
      product.processes.zoin,
      product.cost_packaging,
      product.cost_raw_material_one,
      product.cost_raw_material_two,
      this.costsPg.sellingExpensesNational,
      this.costsPg.sellingExpensesInternational,
      this.costsPg.groupVariableNational,
      this.costsPg.groupVariableInternational,
      this.costsPg.generalCompanyNational,
      this.costsPg.generalCompanyInternational,
      this.costsPg.industryCommerceNational,
      this.costsPg.industryCommerceInternational,
      this.costsPg.taxThousandNational,
      this.costsPg.taxThousandExport,
      this.costsPg.LogisticsWarehouseSaleNational,
      this.costsPg.LogisticsWarehouseSaleInternational,
      this.costsPg.marketingNational,
      this.costsPg.marketingInternational,
      0,

    )
    return netSales
  }
}