import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { MaterialListService } from '../../../../core/services/material-list/material-list.service';
import { RTLProductService } from 'src/app/core/services/rtl-product/rtlproduct.service';

@Component({
  selector: 'app-modal-filter-material',
  templateUrl: './modal-filter-material.component.html',
  styleUrls: ['../../../rd-projects/modal-consult/modal-consult.component.css'],
})
export class ModalFilterMaterialComponent extends SimpleModalComponent<any, any> implements OnInit {
  nameProduct: any;
  results: any[] = [];
  productFinishedCode: any;
  nameProductFinished: any;
  private_label_portfolio:any;
  rtlProduct:any;
  showSpinner = false

  constructor(private materialList: MaterialListService,
    private rtlProductService: RTLProductService,

  ) {
    super();
  }

  ngOnInit(): void {
  }

  async getProductFinished() {
    this.showSpinner = true
    if (this.private_label_portfolio==true) {
      this.rtlProductService.getRtlProductByName({ nombre: this.nameProduct.toUpperCase()}).then((res: any) => {
        this.results = res;
        this.showSpinner = false
      }, error => this.showSpinner = false);
    } else{
      this.materialList.getMaterialsByName({ nombrePt: this.nameProduct.toUpperCase(), isForPortfolio:1}).then((res: any) => {
        this.results = res;
        this.showSpinner = false
        this.results = this.results.filter((value: any, index: any, self: any) => {
          return (
            self.findIndex((t: any) => {
              return t.productoTerminado === value.productoTerminado;
            }) === index
          );
        });
      }, error => this.showSpinner = false);
    }
  }

  setSelectedFalse() {
    for (const element of this.results) {
      element.selected = false;
    }
  }

  selectItem(index: number) {
    this.setSelectedFalse();
    this.results[index].selected = true;
    if (this.private_label_portfolio) {
      this.rtlProduct = this.results[index]
    }
    this.nameProductFinished = this.results[index].nombrePt;
    this.productFinishedCode = this.results[index].productoTerminado;
  }

  confirmItem() {
    if (this.private_label_portfolio==true) {
      this.result =  this.rtlProduct
    } else {
      this.result = {
        productFinishedCode: this.productFinishedCode,
        nameProductFinished: this.nameProductFinished,
      };
    }
    this.close();
  }
}
