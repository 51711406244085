<div class="main-container">
  <div class="container__content">
    <div class="step-info d-flex ">
      <div>
        <p class="step-title">{{ stepName }}</p>
      </div>
      <div class="bespoke-container">
        <button class="bespoke-btn" (click)="navigateToBespoke()">I want a bespoke formula</button>
      </div>
    </div>
    <div class="chocolate-options" *ngIf="validationShape === false">

      <div class="left-container">
        <div class="main-selection">
          <div class="slider-container">
            <div class="slider-wrapper">
              <input type="range" min="0" max="100" value="0" [(ngModel)]="cacaoPercentage" (ngModelChange)="listenCacaoPercentage()"/>
            </div>
            <div class="slider-info">
              <p class="cacao-percentage">{{cacaoPercentage}}%</p>
              <p class="cacao-label">Cacao</p>
            </div>
          </div>
          <div class="slider-container-mobile d-flex">
            <div class="slider-info">
              <p class="cacao-percentage">{{cacaoPercentage}}%</p>
              <p class="cacao-label">Cacao</p>
            </div>
            <div class="slider-wrapper">
              <input type="range" min="0" max="100" value="0" [(ngModel)]="cacaoPercentage" (ngModelChange)="listenCacaoPercentage()"/>
            </div>
          </div>
        </div>
      </div>

      <div class="selection-container">
        <div class="chocolate-info">
          <div class="info-container">
            <div class="info" *ngFor="let item of displayedChocolateOptions; let i = index">
              <div class="chocolate-card" [class.selected]="item.selected" (click)="handleClickSelection(i)">
                <p class="portfolio-title">{{item.portafolio | titlecase}}</p>
                <p class="chocolate-name">{{item.texto | titlecase}}</p>
              </div>
              <p class="chocolate-description">{{item.descripcion}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedOption?.application.name === 'Dragees' || validationShape" style="height: 75vh;">
      <div class="step-info">
        <div class="d-flex">
          <div class="d-flex flex-column" style="margin-right: 50px">
            <span class="select-title">Select type of dragees</span>
            <select class="select-dragees" (change)="loadPercentage()" [(ngModel)]="typeChocolatePicked">
              <option value="">Select option</option>
              <option value="{{item}}" *ngFor="let item of chocolateTypes">{{item}}</option>
            </select>
          </div>
          <div class="d-flex flex-column">
            <span class="select-title">Select cacao percentage</span>
            <select class="select-dragees" (change)="loadDrageesOptions()" [(ngModel)]="percentageSelected">
              <option>Select option</option>
              <option value="{{item}}"  *ngFor="let item of percentageChocolatePicked">{{item * 100 | number : '1.0' }} %</option>
            </select>
          </div>
        </div>

        <div class="dragees-container mt-4 d-flex justify-content-center">
          <div class="dragees-options d-flex">
              <div class="dragees-card" *ngFor="let item of drageesList; let i = index" [class.selected]="item.selected" (click)="handleClickSelection(i)">
                <p class="title-dragees">{{item.typeDragees | titlecase}}</p>
                <p>Percentage</p>
                <p class="percentage-dragees">{{item.percentage * 100 }}%</p>
                <div>
                  <img src="https://d169su6y068qsd.cloudfront.net/create/basics/{{item.typeDragees}}.png" alt="img-luker">
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
