import { Component, Input, OnInit } from '@angular/core';
import { ArtistProfileComponent } from './artist-profile/artist-profile.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ArtistService } from '../../../../core/services/artist/artist.service';
import { Router } from '@angular/router';
import { PackageDesignService } from '../../../../core/services/package-design/package-design.service';

@Component({
  selector: 'app-design-inspiration',
  templateUrl: './design-inspiration.component.html',
  styleUrls: ['./design-inspiration.component.css'],
})
export class DesignInspirationComponent implements OnInit {
  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  /** This attribute is used to store the description of the current step. */
  @Input() stepDescription = '';

  /** The package designs available for the type of primary package previously selected. */
  designImages: any[] = [];

  /** The list of artists available for designing custom packages. */
  artists: any[] = [];

  /** The design selected by the user. */
  currentDesign: any = {};

  constructor(
    private sms: SimpleModalService,
    private artistService: ArtistService,
    private packageDesignService: PackageDesignService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadArtists();
    this.loadDesigns();
  }

  /**
   * This function loads all the artists.
   */
  async loadArtists() {
    this.artists = await this.artistService.getAllArtists();
  }

  /**
   * This function loads all the package designs by package type.
   */
  async loadDesigns() {
    const packageType = JSON.parse(localStorage.getItem('type') || '{}');
    this.designImages = await this.packageDesignService.filter({ tipo: packageType });
    this.designImages[0].selected = true;
    this.currentDesign = this.designImages[0];
    this.currentDesign.img = `https://d169su6y068qsd.cloudfront.net/create/design/${this.currentDesign.texto}.png`;
    localStorage.setItem('design', JSON.stringify(this.currentDesign));
  }

  /**
   * This function handles the selections of both carousels.
   * @param carouselType The type of carousel clicked. It can be design or artist type.
   * @param pos The index of the clicked item.
   */
  handleClickSelection(carouselType: string, pos: number) {
    if (carouselType == 'design') {
      for (let i = 0; i < this.designImages.length; i++) {
        if (i != pos) {
          this.designImages[i].selected = false;
        }
      }
      this.designImages[pos].selected = !this.designImages[pos].selected;
      this.currentDesign = this.designImages[pos];
      if (this.designImages[pos].selected) {
        this.currentDesign.img = `https://d169su6y068qsd.cloudfront.net/create/design/${this.currentDesign.texto}.png`;
        localStorage.setItem('design', JSON.stringify(this.currentDesign));
      } else {
        this.currentDesign.img = null;
        localStorage.removeItem('design');
      }
    } else if (carouselType == 'artist') {
      for (let i = 0; i < this.artists.length; i++) {
        if (i != pos) {
          this.artists[i].selected = false;
        }
      }
      this.artists[pos].selected = !this.artists[pos].selected;
      if (this.artists[pos].selected) {
        localStorage.setItem('artist', JSON.stringify(this.artists[pos]));
      } else {
        localStorage.removeItem('artist');
      }
    }
  }

  /**
   * This function opens the modal that contains the artist collage with its work and profile.
   * @param pos The index of the selected artist.
   */
  showArtistProfile(pos: number) {
    const modal = this.sms.addModal(
      ArtistProfileComponent,
      { data: this.artists[pos] },
      { autoFocus: true, closeOnClickOutside: true }
    );
  }

  /**
   * This function saves a design inspiration comment and navigates to the next step.
   */
  saveDesginComment() {
    const designMessage = 'My design team will hand in the artwork. Send me the mechanical layout.';
    const comments = JSON.parse(localStorage.getItem('comments') || '{}');
    comments['design'] = comments['design'] ? [...comments['design'], designMessage] : [designMessage];
    localStorage.setItem('comments', JSON.stringify(comments));
    this.router.navigateByUrl('create-product/steps/7');
  }
}
