import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-modal-product-details',
  templateUrl: './modal-product-details.component.html',
  styleUrls: ['../../../rd-projects/modal-consult/modal-consult.component.css', './modal-product-details.component.css'],
})
export class ModalProductDetailsComponent extends SimpleModalComponent<any, any> implements OnInit {
  product:any;
  plantaTriunfo:boolean = false
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.plantaTriunfo = this.getPlantaTriunfoLength()
  }

  getPlantaTriunfoLength() {
    return Object.keys(this.product.planta_triunfo ?? {}).length > 0;
  }
}
