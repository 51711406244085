<div class="modal-dialog" style="max-width: 700px" >
  <div class="modal-content modal-container">
    <div>
      <h4>Search sapcode by name</h4>
    </div>
    <div style="text-align: left">
      <label class="modal-description">Search finished product</label>
      <input type="text" (keydown.enter)="getProductFinished()" (change)="getProductFinished()" [(ngModel)]="nameProduct">
    </div>
    <div class="results">
      <div class="result-row d-flex justify-content-between"
      *ngFor="let item of results; let i = index"
      (click)="selectItem(i)"
      [ngClass]="{'selected': item.selected}">
        <p style="margin-right: 2rem;">{{item.productoTerminado || item.idRtl}}</p>
        <p>{{item.nombrePt || item.nombre}}</p>
      </div>
      <p *ngIf="results.length==0 && !showSpinner" class="text-warning text-center mt-2">No hay resultados</p>
      <div *ngIf="showSpinner" class="container__spinner">
        <div>
          <p-progressSpinner 
            styleClass="spinner" 
            strokeWidth="5" 
            animationDuration="1s" >
          </p-progressSpinner>
          <p class="text-warning text-center mt-2">Cargando...</p>
    
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <div>
        <button type="button" class="submit" (click)="confirmItem()">Submit</button>
      </div>
      <div>
        <button class="cancel mt-3" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>
