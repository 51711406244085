<div class="main-container">
  <div style="margin-bottom: 40px;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <p *ngIf="!(this.params.origin != null && this.params.origin == 'private_label')" class="page-title text-center">Project Brief</p>
          <p *ngIf="(this.params.origin != null && this.params.origin == 'private_label')" class="page-title text-center">Private Label Quote</p>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="display: flex; justify-content: center;">
      <form class="row" [formGroup]="projectForm" (ngSubmit)="onSubmit()">
        <div class="col-md-12 col-xs-12 col-sm-12 col-xl-6 col-lg-12 border-column">
          <div class="container-forms">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="title-section">Information</label>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12">
                <label class="label-input">Date</label>
                <input
                  id="date"
                  type="date"
                  formControlName="date"
                  class="date form-control"
                  [ngClass]="{ 'red-border': fieldIsInvalid('date') }" />
                <div *ngIf="fieldIsInvalid('date')" class="invalid-warning">Obligatory field</div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12">
                <label class="label-input">Prepared by</label>
                <input
                  id="preparedBy"
                  readonly="readonly"
                  type="text"
                  formControlName="preparedBy"
                  class="form-control-lg" />
                <div *ngIf="fieldIsInvalid('preparedBy')" class="invalid-warning">Obligatory field</div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3">
                <label class="label-input">Client</label>
                <input
                  id="client"
                  type="text"
                  formControlName="client"
                  class="form-control-lg"
                  [ngClass]="{ 'red-border': fieldIsInvalid('client') }" />
                <div *ngIf="fieldIsInvalid('client')" class="invalid-warning">Obligatory field</div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3">
                <label class="label-input">Country</label>
                <select formControlName="country" class="form-control-lg" [ngClass]="{'red-border': fieldIsInvalid('country')}">
                  <option value="others">Others</option>
                  <option value="{{item}}" *ngFor="let item of countries">{{item}}</option>
                </select>
                <div *ngIf="fieldIsInvalid('country')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
              <div class="col-12" *ngIf="projectForm.get('otherCountry')">
                <label for="otherCountry" class="label-input">Write a country</label>
                <input id="otherCountry" type="text" formControlName="otherCountry" class="form-control-lg" [ngClass]="{'red-border':fieldIsInvalid('otherCountry')}"/>
                <div *ngIf="fieldIsInvalid('otherCountry')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 col-sm-12 col-xl-6 col-lg-12">
          <div class="container-forms">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="title-section">Product</label>
              </div>
              <div [ngClass]="{'col-12': (this.params.origin != null && this.params.origin == 'private_label'),
                'col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12': !(this.params.origin != null && this.params.origin == 'private_label')}">
                <label class="label-input">Quantity (kg)</label>
                <input id="weight" min=1 type="number" formControlName="weight" class="form-control-lg price-input"  [ngClass]="{'red-border':fieldIsInvalid('weight')}"/>
                <div *ngIf="showWeightAlert" class="alert-values-intput-warning">
                  Remember that the MOQ is 1500 Kg
                </div>
              </div>
              <div *ngIf="!(this.params.origin != null && this.params.origin == 'private_label')" class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12" id="cocoa-premium">
                <label class="label-input">Cocoa Premium (USD/TON)</label>
                <input id="additionalCacaoPrice" min=0 type="number" formControlName="additionalCacaoPrice" class="form-control-lg price-input" [ngClass]="{'red-border':fieldIsInvalid('additionalCacaoPrice')}"/>
                <div *ngIf="fieldIsInvalid('additionalCacaoPrice')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3">
                <label class="label-input"> Cacao Price </label>
                <select formControlName="cacaoPrice" class="price-select" [ngClass]="{'red-border': fieldIsInvalid('cacaoPrice')}">
                  <option value=""></option>
                  <option value="others">Others</option>
                  <option value="{{price.value}}" *ngFor="let price of cacaoPrices">{{price.label}}</option>
                </select>
                <div *ngIf="fieldIsInvalid('cacaoPrice')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3">
                <label class="label-input"> Dollar Price</label>
                <select formControlName="dollarPrice" class="price-select" [ngClass]="{'red-border': fieldIsInvalid('dollarPrice')}">
                  <option value=""></option>
                  <option value="others">Others</option>
                  <option value="{{dollarPrices[0]}}">{{dollarPrices[0]}} - TRM</option>
                </select>
                <div *ngIf="fieldIsInvalid('dollarPrice')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3" *ngIf="projectForm.get('otherCacaoPrice')">
                <label class="label-input">Write a Cacao Price</label>
                <input id="otherCacaoPrice" min=0 type="number" formControlName="otherCacaoPrice" class="form-control-lg price-input" [ngClass]="{'red-border':fieldIsInvalid('otherCacaoPrice')}"/>
                <div *ngIf="fieldIsInvalid('otherCacaoPrice')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-xs-12 col-sm-12 mt-3" *ngIf="projectForm.get('otherDollarPrice')">
                <label class="label-input">Write a Dollar Price</label>
                <input id="otherDollarPrice" min=0 type="number" formControlName="otherDollarPrice" class="form-control-lg price-input" [ngClass]="{'red-border':fieldIsInvalid('otherDollarPrice')}"/>
                <div *ngIf="fieldIsInvalid('otherDollarPrice')" class="invalid-warning">
                  Obligatory field
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center submit-button mb-3">
            <button type="submit">Start Creating</button>
          </div>
        </div>
      </form>
    </div>
    
    <button class="live-session-btn" (click)="generateLiveSession()">Live session</button>
  </div>

  <div class="footer" *ngIf="this.params.origin == 'private_label'">
    <p-button label="Saved Quotes" styleClass="saved-quotes-button" [style]="{'margin-right':'20px', 'width':'162px'}" (onClick)="goToPrivateLabelSavedQuotes()"></p-button>
  </div>
  <div class="footer" *ngIf="this.params.origin == 'actual_products'">
    <p-button label="Saved Quotes" styleClass="saved-quotes-button" [style]="{'margin-right':'20px', 'width':'162px'}" (onClick)="goToActualProductsSavedQuotes()"></p-button>
  </div>
</div>
