<div class="main-container">
  <section class="content">
    <div style="overflow-y: scroll; height: calc(100vh - 140px);">
      <div class="container__accordion" [ngClass]="{'nopadding': !accordionsStates.macroeconomics}">
        <div class="accordion-header" [ngClass]="{'withmarging': !accordionsStates.macroeconomics}" (click)="openCloseAccordion('macroeconomics')">
            <div>
                Macroeconomics
            </div>
            <div>
                <i *ngIf="accordionsStates.macroeconomics" class="pi pi-chevron-up"></i>
                <i *ngIf="!accordionsStates.macroeconomics" class="pi pi-chevron-down"></i>
            </div>
        </div>
        <div *ngIf="accordionsStates.macroeconomics" class="accordion-body">
            <!-- <p-divider styleClass="divider__accordion"></p-divider> -->
            <div class="macroeconomics-accordion-content">
              <div class="macroeconomics-prices">
                <div class="container__price">
                  <div id="price">
                      <div *ngIf="!anotherDolarPrice" class="container__dropdown">
                        <label htmlFor="category">Dolar price</label>
                        <p-dropdown  [showClear]="false" [(ngModel)]="dollarPrice" (ngModelChange)="updatePricesValues()"
                        styleClass="primeng-dropdown" placeholder="Select a dolar price" [options]="dollarPrices">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ dollarPrice }} - TRM</div>
                                </div>
                            </ng-template>
                            <ng-template let-value pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ value }} - TRM</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                      </div>
                      <div *ngIf="anotherDolarPrice" class="container__input">
                          <label htmlFor="clientname">Dolar price</label>
                          <input placeholder="Type a dolar price" appTwoDigitDecimaNumber styleClass="primeng-input" (ngModelChange)="updatePricesValues()" [(ngModel)]="dollarPrice" pInputText/>
                      </div>
                  </div>
                  <div (click)="changeOtherPrice('dolar')" class="container__other-price">
                    <small>Enter Other Price</small>
                  </div>
                </div>
                <div class="container__price">
                  <div id="price">
                    <div *ngIf="!anotherCocoaPrice" class="container__dropdown">
                      <label htmlFor="category">Cocoa price USD/Ton</label>
                      <p-dropdown  [showClear]="false" [(ngModel)]="cacaoPrice"
                      (ngModelChange)="getMonthCocoaPrice(); updatePricesValues();" optionValue="price" 
                      styleClass="primeng-dropdown" placeholder="Select a cocoa price" [options]="cocoaPricesObjects">
                          <ng-template pTemplate="selectedItem">
                              <div class="flex align-items-center gap-2">
                                  <div>{{cacaoPrice + " " + selectedMonth}}</div>
                              </div>
                          </ng-template>
                          <ng-template let-value pTemplate="item">
                              <div class="flex align-items-center gap-2">
                                  <div>{{value.price + " " + value.month}}</div>
                              </div>
                          </ng-template>
                      </p-dropdown>
                    </div>
                    <div *ngIf="anotherCocoaPrice" class="container__input">
                        <label htmlFor="clientname">Cocoa price USD/Ton</label>
                        <input placeholder="Type a cocoa price" styleClass="primeng-input" appTwoDigitDecimaNumber (ngModelChange)="updatePricesValues()" [(ngModel)]="cacaoPrice" [readOnly]="false" pInputText/>
                    </div>
                  </div>
                  <div (click)="changeOtherPrice('cocoa')" class="container__other-price">
                    <small>Enter Other Price</small>
                  </div>
                </div>
                <div class="container__price">
                  <div id="price">
                    <div class="container__input">
                        <label htmlFor="clientname">Cocoa Premium USD/Ton</label>
                        <input placeholder="Type a cocoa premium price" appTwoDigitDecimaNumber styleClass="primeng-input" (ngModelChange)="updatePricesValues()" [(ngModel)]="cacaoBonus" [readOnly]="false" pInputText/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="update-prices">
                <p-button label="Update prices" [disabled]="!canUpdatePrices" (onClick)="updateRawMaterial1Prices()"></p-button>
              </div>
            </div>
        </div>
      </div>
      <div class="container__accordion" id="product" [ngClass]="{'nopadding': false}">
        <div class="accordion-header" [ngClass]="{'withmarging': false}">
            <div>
                Product
            </div>
        </div>

        <div class="accordion-body">
            <div class="macroeconomics-accordion-content">
              <div class="macroeconomics-prices">
                <div class="container__price">
                  <div id="price">
                      <div class="container__input">
                          <label htmlFor="clientname">SAP Code</label>
                          <input (keydown.enter)="validateSapCode()" OnlyNumbers placeholder="Write here" styleClass="primeng-input" [(ngModel)]="sapCode" pInputText/>
                          <small *ngIf="validationSap === false" class="text-danger mt-1 sapcode-validator-mobile">Enter an existing sap code</small>
                          <small *ngIf="alreadyExists == true" class="text-danger mt-1 sapcode-validator-mobile">The product is already in list</small>
                      </div>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button class="btn-modal" (click)="openModal()">
                  <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                  Filter products
                </button>
                <p-button label="Add" styleClass="add-button" [disabled]="sapCode==''" (onClick)="validateSapCode()"></p-button>
              </div>
            </div>
        <small *ngIf="validationSap === false" class="text-danger mt-1 sapcode-validator">Enter an existing sap code</small>
        <small *ngIf="alreadyExists == true" class="text-danger mt-1 sapcode-validator">The product is already in list</small>
        </div>
      </div>


      <p class="added-products">Added products</p>
      <div class="container__added-products">
        <div class="form-table">
          <table aria-describedby="form table">
            <thead>
              <tr>
                <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container [formGroup]="tableForm">
                <ng-container formArrayName="form_array">
                  <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
                    <td>
                      <div class="row-actions">
                          <img src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                          <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="openModalProductInfo(iForm)">
                      </div>
                    </td>
                    <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                      <div>
                        <div>
                          <input class="table-input" *ngIf="(property != 'txt_material' && property != 'sapCode')" currencyMask [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                          <input class="table-input" *ngIf="(property == 'txt_material' || property == 'sapCode')" [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td colspan="3">
                      Total
                      
                    </td>
                    <td>
                      <div>{{ totalCostRawMaterialOne|number:'1.0-0':'en-US' }}</div>
                    </td>
                    <td>
                      <div>{{ totalCostRawMaterialTwo|number:'1.0-0':'en-US' }}</div>
                    </td>
                    <td>
                      <div>{{ totalCostPackaging|number:'1.0-0':'en-US' }}</div>
                    </td>
                    <td>
                      <div>{{ totalCostProcesses|number:'1.0-0':'en-US' }}</div>
                    </td>
                    <td>
                      <div>{{ (totalCost)|number:'1.0-0':'en-US' }}</div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

</div>
