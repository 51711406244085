import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-artist-profile',
  templateUrl: './artist-profile.component.html',
  styleUrls: ['./artist-profile.component.css'],
})
export class ArtistProfileComponent extends SimpleModalComponent<any, boolean> implements OnInit {
  /** This object stores the artist selected by the user. */
  @Input() data: any;

  /** Path of the shape real life size image. */
  artistCollage: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadArtistCollage();
  }

  /**
   * This function closes the modal.
   */
  confirm() {
    this.close();
  }

  /**
   * This function sets the path of the artist's collage.
   */
  loadArtistCollage() {
    this.artistCollage = `https://d169su6y068qsd.cloudfront.net/create/design/artists/collage/${this.data.imagenCollage}.png`;
  }
}
