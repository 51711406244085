import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaterialListService } from '../../../core/services/material-list/material-list.service';
import { PackagingService } from '../../../core/services/packaging/packaging.service';
import { ChocolateService } from '../../../core/services/chocolate/chocolate.service';
import { RegisterInfoService } from '../../../core/services/register-info/register-info.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalFilterMaterialComponent } from './modal-filter-material/modal-filter-material.component';
import ls from 'localstorage-slim';
import { CostService } from 'src/app/core/services/costs/cost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { MoneyApisService } from 'src/app/core/services/money-apis/money-apis.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalProductDetailsComponent } from './modal-product-details/modal-product-details.component';
import { ActivatedRoute } from '@angular/router';
import { QuoteActualProductsService } from 'src/app/core/services/quote-actual-products/quote-actual-products.service';



@Component({
  selector: 'app-finished-product',
  templateUrl: './finished-product.component.html',
  styleUrls: ['./finished-product.component.css'],
})
export class FinishedProductComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  sapCode: string = "";
  validationSap!: boolean;
  faMagnifyingGlass = faMagnifyingGlass;

  constructor(
    private listMaterialService: MaterialListService,
    private packagingService: PackagingService,
    private chocolateService: ChocolateService,
    private registerInfo: RegisterInfoService,
    private sms: SimpleModalService,
    private costService: CostService,
    private spinner: NgxSpinnerService,
    private tipsService: TipsService,
    private moneyService: MoneyApisService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private quoteActualProductsService: QuoteActualProductsService,


  ) {
    this.queryParams = this.route.snapshot.queryParams;
  }

  anotherDolarPrice:boolean = false
  anotherCocoaPrice:boolean = false
  alreadyExists:boolean = false
  showingSavedQuote:boolean = false

  dollarPrices: any = [];
  cacaoOnlyPrices: any = [];
  cocoaPricesObjects: any = [];
  parameters: any = {}
  queryParams:any = {}
  project:any = {}

  totalCostRawMaterialOne = 0
  totalCostRawMaterialTwo = 0
  totalCostPackaging = 0
  totalCostProcesses = 0
  rawMaterialOneCosts: any = {}
  dollarPrice=0
  euroPrice=0
  cacaoPrice=0
  cacaoBonus=0
  totalCost=0
  selectedMonth=''
  productsList:any=[]
  allowContinue=false

  columns: any[] = [
    { name: 'Actions' },
    { name: 'Sapcode' },
    { name: 'Txt Material' },
    { name: 'MP 1' },
    { name: 'MP 2' },
    { name: 'Packaging' },
    { name: 'Processes' },
    { name: 'Total' },
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
      // this.formBuilder.group(this.generateFormGroupFields())
    ])
  });

  tips:any=[]

  accordionsStates:any = {
    macroeconomics: true
  }

  canUpdatePrices=false

  ngOnInit(): void {
    this.loadAssistantTips();
    this.consultDollar();
    this.getCocoaPrices();
    this.setProducts()
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  saveCostParameters() {
    let parameters = JSON.parse(sessionStorage.getItem('parameters') || '{}');
      parameters.cacaoPrice = (+this.cacaoPrice)||0,
      parameters.dollarPrice = (+this.dollarPrice)||0,
      parameters.cacaoBonus = (+this.cacaoBonus)||0,
    sessionStorage.setItem('parameters', JSON.stringify(parameters));
  }

  async setMaterialOnePricesAndParameters(){
    if (this.queryParams.projectId != null) {
      this.showingSavedQuote = true
      this.spinner.show()
      const project:any = await this.quoteActualProductsService.getProjectById(this.queryParams.projectId)
      if(project!=null){
        this.project = project
        sessionStorage.setItem('parameters', JSON.stringify(project.parameters));
        sessionStorage.setItem('current_actual_products_project', JSON.stringify(project));
        sessionStorage.setItem('productsList', JSON.stringify(project.products));
        this.spinner.hide()
      } 
    } else {
      this.parameters = JSON.parse(sessionStorage.getItem('parameters')||"{}");
      if (this.parameters.dollarPrice!=null) {
        this.cacaoPrice=+this.parameters.cacaoPrice;
        this.dollarPrice=+this.parameters.dollarPrice;
        this.euroPrice=+this.parameters.euroPrice;
        this.cacaoBonus=+this.parameters.cacaoBonus;
        this.anotherDolarPrice = true
        this.anotherCocoaPrice = true
      } else {
        let parameters =JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
        sessionStorage.setItem('parameters', JSON.stringify(parameters));
      }
      this.spinner.hide()
    }

  

    this.parameters = JSON.parse(sessionStorage.getItem('parameters')||"{}");
    if (this.parameters.dollarPrice!=null) {
      this.cacaoPrice=+this.parameters.cacaoPrice;
      this.dollarPrice=+this.parameters.dollarPrice;
      this.euroPrice=+this.parameters.euroPrice;
      this.cacaoBonus=+this.parameters.cacaoBonus;
      this.anotherDolarPrice = true
      this.anotherCocoaPrice = true
    }


    const rawMaterialOneCostsInLocal = JSON.parse(sessionStorage.getItem('materialOnePrices')||"{}");
    if (Object.keys(rawMaterialOneCostsInLocal).length) {
      this.rawMaterialOneCosts = rawMaterialOneCostsInLocal
    } else {
      this.updateRawMaterial1Prices()
    }
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */

  async addNewRow(index: number) {
    const formGroup = this.formBuilder.group(
      {
        sapCode: this.productsList[index].sapCode,
        txt_material: this.productsList[index].txt_material,
        cost_raw_material_one: Math.round(this.productsList[index].cost_raw_material_one),
        cost_raw_material_two: Math.round(this.productsList[index].cost_raw_material_two),
        cost_packaging: Math.round(this.productsList[index].cost_packaging),
        cost_processes: Math.round(this.productsList[index].cost_processes),
        total_cost: Math.round(this.productsList[index].total_cost)
      }
    );
    this.totalCostRawMaterialOne += this.productsList[index].cost_raw_material_one
    this.totalCostRawMaterialTwo += this.productsList[index].cost_raw_material_two
    this.totalCostPackaging += this.productsList[index].cost_packaging
    this.totalCostProcesses += this.productsList[index].cost_processes
    this.getFormArray().push(formGroup);

  }

  async setProducts(){
    await this.setMaterialOnePricesAndParameters()
    this.productsList = JSON.parse(sessionStorage.getItem('productsList')||"[]");
    if (this.productsList.length>0) {
      this.completed.emit({ flow: 'quote', step: 1, completed: true, currentVersion: this.project});
    }
    for (let index = 0; index < this.productsList.length; index++) {
      this.addNewRow(index)
      this.totalCost += this.productsList[index].total_cost
    }
  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {

    if (this.productsList.length>0) {
      const product = this.productsList[index]
      this.totalCostRawMaterialOne -= product.cost_raw_material_one
      this.totalCostRawMaterialTwo -= product.cost_raw_material_two
      this.totalCostPackaging -= product.cost_packaging
      this.totalCostProcesses -= product.cost_processes
      this.totalCost = this.totalCostRawMaterialOne + this.totalCostRawMaterialTwo + this.totalCostPackaging+ this.totalCostProcesses
      this.getFormArray().controls.splice(index, 1);
      await this.productsList.splice(index, 1)
      if (this.productsList.length==0) {
        this.allowContinue=false
        this.completed.emit({ flow: 'quote', step: 1, completed: false });
        this.completed.emit({ flow: 'quote', step: 2, completed: false });
      }
      sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
      return;
    }

    this.getFormArray().controls.splice(index, 1);
    this.productsList.splice(index, 1)

  }

  /**
   * Shows a dialog with the product details.
   *
   * @param index
   */
  async showProductDetails(index: number)  {

    

  }

  openCloseAccordion(key:string){
    this.accordionsStates[key] = !this.accordionsStates[key]
  }

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }


  changeOtherPrice(value:string){
    if (value=="dolar") {
      this.anotherDolarPrice=!this.anotherDolarPrice
    } else if (value=="cocoa"){
      this.anotherCocoaPrice=!this.anotherCocoaPrice
    }
  }
;
  async updateRawMaterial1Prices() {

    this.rawMaterialOneCosts = await this.costService.getRawMaterialTypeOne(
      this.dollarPrice||0,
      this.cacaoPrice||0,
      this.cacaoBonus||0
    );
    sessionStorage.setItem('materialOnePrices', JSON.stringify(this.rawMaterialOneCosts));
    this.saveCostParameters()

    let newProductsList=this.productsList
    for (let index = 0; index < this.productsList.length; index++) {
      const product = this.productsList[index];
      const curentRawMaterialoneCost = product.cost_raw_material_one
      const rawMaterialsInfo = await this.setRawMaterialsCosts([...product.raw_material_one_list, ...product.raw_material_two_list]);
      newProductsList[index].raw_material_one_list = rawMaterialsInfo.materiaPrima1List
      newProductsList[index].raw_material_two_list = rawMaterialsInfo.materiaPrima2List
      newProductsList[index].cost_raw_material_one = rawMaterialsInfo.costRawMaterialOne||0,
      newProductsList[index].cost_raw_material_two = rawMaterialsInfo.costRawMaterialTwo,
      newProductsList[index].total_cost = (product.cost_processes+product.cost_packaging+rawMaterialsInfo.costRawMaterials)
      this.totalCostRawMaterialOne = this.totalCostRawMaterialOne - curentRawMaterialoneCost + newProductsList[index].cost_raw_material_one;
      this.totalCost = this.totalCost - curentRawMaterialoneCost + newProductsList[index].cost_raw_material_one
      
      const formArray = this.getFormArray().controls;
      const formControls = formArray[index]
      formControls.patchValue({
        cost_raw_material_one: Math.round( newProductsList[index].cost_raw_material_one),
        cost_raw_material_two: Math.round( newProductsList[index].cost_raw_material_two),
        total_cost: Math.round( newProductsList[index].total_cost)
      })

    }
    this.productsList = newProductsList
    this.canUpdatePrices=false
    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));

  }

  async setRawMaterialsCosts(raw_materials_list:any[]){
    let materiaPrima1List=[]
    let materiaPrima2List=[]
    let costRawMaterialOne=0
    let costRawMaterialTwo=0
    let costRawMaterials=0
    for (let index = 0; index < raw_materials_list.length; index++) {
      const material = raw_materials_list[index];
      if (material.descElemento=="Materia Prima 2") {
        const currencyFactor = material.moneda=="USD"?((+this.dollarPrice)||1):material.moneda=="EUR"?this.euroPrice:1
        const cost = ((material.precio_neto*material.factorKg/(material.factorDivision||1))*currencyFactor)
        costRawMaterials += cost
        costRawMaterialTwo += cost
        const materialWithPrice = {...material, costo: cost}
        materiaPrima2List.push(materialWithPrice)
      } else {
        if (material.nombreComponente.includes('PS LICOR')) {
          raw_materials_list[index].precio_neto = Math.round(this.rawMaterialOneCosts.precioLicor);
          raw_materials_list[index].categoria = "Licor"
        } else if (material.nombreComponente.includes('PS MANTECA') && material.componente != '2000504') {
          raw_materials_list[index].precio_neto = Math.round(this.rawMaterialOneCosts.precioManteca);
          raw_materials_list[index].categoria = "Manteca"
        } else if (material.nombreComponente.includes('PS COCOA')) {
          raw_materials_list[index].precio_neto = Math.round(this.rawMaterialOneCosts.precioCocoa);
          raw_materials_list[index].categoria = "Cocoa"
        } else if (material.nombreComponente.includes('PS NIBS')) {
          raw_materials_list[index].precio_neto = Math.round(this.rawMaterialOneCosts.psNibs);
          raw_materials_list[index].categoria = "Nibs"
        } else if (material.nombreComponente.includes('PS MANTECA DESODORIZADA') && material.componente  == '2000504') {
          raw_materials_list[index].precio_neto = Math.round(this.rawMaterialOneCosts.precioMantecaDeso);
          raw_materials_list[index].categoria = "Manteca desodorizada"
        }
        if (raw_materials_list[index].precio_neto!=undefined && raw_materials_list[index].precio_neto!=null) {
          costRawMaterials += (raw_materials_list[index].precio_neto*material.factorKg)||0
          costRawMaterialOne += (raw_materials_list[index].precio_neto*material.factorKg)||0
          const materialWithPrice = {...material, costo: (raw_materials_list[index].precio_neto*material.factorKg)}
          materiaPrima1List.push(materialWithPrice)
        }

      }
    }

    return {
      materiaPrima1List:materiaPrima1List,
      materiaPrima2List:materiaPrima2List,
      costRawMaterialOne:costRawMaterialOne,
      costRawMaterialTwo:costRawMaterialTwo,
      costRawMaterials:costRawMaterials
    }
  }

  async checkIfUsingSapcode(sapCode:string){
    if (this.productsList.find((product:any) => product.sapCode === sapCode))  return true;;

    return false
  }

  async validateSapCode() {
    this.spinner.show()
    this.alreadyExists = false
    const RepeatedSapcode = await this.checkIfUsingSapcode(this.sapCode);
      if (RepeatedSapcode) {
        this.alreadyExists = true
        this.spinner.hide()
        return;
      }
    this.listMaterialService.getMaterialsByProductFinished(this.sapCode).then(async (res: any) => {
      if (res.rawMaterials==undefined || res.rawMaterials==null) {
        this.spinner.hide()
        return;
      }
      let nameProductFinished=""
      if (res.rawMaterials.length > 0) {
        this.validationSap = true;
        nameProductFinished = res.rawMaterials[0].nombrePt;
        // this.getChocolate();
        sessionStorage.setItem('productFinished', this.sapCode);
        sessionStorage.setItem('nameProductFinished', nameProductFinished);
        // this.packaging(this.registros.packaging);
      } else {
        this.validationSap = false;
        this.spinner.hide()
        return;
      }

      let costPackaging = 0
      let costProcesses = 0
      let packagingList:any[] = []
      let processesList:any[] = []
      const rawMaterialsInfo = await this.setRawMaterialsCosts(res.rawMaterials);
      for (let index = 0; index < res.packaging.length; index++) {
        const material = res.packaging[index];
        const currencyFactor = material.moneda=="USD"?((+this.dollarPrice)||1):material.moneda=="EUR"?this.euroPrice:1
        const cost = (((material.precio_neto??0)*material.factorKg/(material.factorDivision||1))*currencyFactor)
        costPackaging += cost
        const materialWithPrice = {...material, costo: cost}
        packagingList.push(materialWithPrice)
      }

      let zmod = 0
      let zman = 0
      let zenr = 0
      let zgas = 0
      let zdep = 0
      let zoin = 0

      for (let index = 0; index < res.processes.length; index++) {
        let process = res.processes[index];
        zmod += process.zmod;
        zman += process.zman;
        zenr += process.zenr;
        zgas += process.zgas;
        zdep += process.zdep;
        zoin += process.zoin;
        costProcesses += process.zmod;
        costProcesses += process.zman;
        costProcesses += process.zenr;
        costProcesses += process.zgas;
        costProcesses += process.zdep;
        costProcesses += process.zoin;
        processesList.push({...process, subtotal: (process.zmod+process.zman+process.zenr+process.zgas+process.zdep+process.zoin)})
      }

      const processes = {
        zmod: zmod,
        zman: zman,
        zenr: zenr,
        zgas: zgas,
        zdep: zdep,
        zoin: zoin
      }
      
      const productDataToLst = {
        raw_material_one_list: rawMaterialsInfo.materiaPrima1List,
        raw_material_two_list: rawMaterialsInfo.materiaPrima2List,
        packaging_list: packagingList,
        processes_list: processesList,
        processes: processes,
        cost_packaging: costPackaging,
        cost_processes: costProcesses,
        cost_raw_material_one: rawMaterialsInfo.costRawMaterialOne||0,
        cost_raw_material_two: rawMaterialsInfo.costRawMaterialTwo,
        total_cost: (costProcesses+costPackaging+rawMaterialsInfo.costRawMaterials),
        sapCode: this.sapCode,
        txt_material: nameProductFinished

      }
      this.totalCost += (costProcesses+costPackaging+rawMaterialsInfo.costRawMaterials)
      this.productsList.push(productDataToLst)
      sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
      if (this.allowContinue == false) {
        this.completed.emit({ flow: 'quote', step: 1, completed: true });
        this.completed.emit({ flow: 'quote', step: 2, completed: true });
      }
      this.addNewRow(this.productsList.length-1)
      this.sapCode=""
      this.spinner.hide()
    }, error => {
      this.spinner.hide()

    });

  }

  getChocolate(rawMaterials:any) {
      // let chocolateFilter = this.registros.rawMaterials.filter((item: any) => item.grupoArticuloComponente === '2007');
    let chocolateFilter = rawMaterials.filter((item: any) => item.grupoArticuloComponente === '2007');
    this.chocolateService.filter({ materialSap: chocolateFilter[0].componente }).then((res: any) => {
      sessionStorage.setItem('chocolate', JSON.stringify(res));
    });
  }

  async packaging(packaging: any) {
    const brief = JSON.parse(sessionStorage.getItem('brief') || '{}');
    let a = {
      packaging: packaging,
      peso: brief.peso,
    };
    this.registerInfo.getPackagingCost(a).then((res) => {
      sessionStorage.setItem('packagings', JSON.stringify(res));
    });
  }

  openModal() {
    this.sms.addModal(ModalFilterMaterialComponent).subscribe((res: any) => {
      if (res) {
        this.sapCode = res.productFinishedCode;
        // this.nameProductFinished = res.nameProductFinished;
        this.validateSapCode();
      }
    });
  }

  openModalProductInfo(index:number) {
    this.sms.addModal(ModalProductDetailsComponent,
      {product: this.productsList[index]},
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe((res: any) => {
    });
  }

  consultDollar() {
    this.moneyService.returnArrayDollar().then((res: any) => {
      this.dollarPrices.push(res.value);
      if (this.dollarPrice != res.value) {
        this.dollarPrices.push(this.dollarPrice);
      }
      this.anotherDolarPrice = false
    },
    error => {
      this.dollarPrices.push(this.dollarPrice);
      this.anotherDolarPrice = false
    });
  }

  getCocoaPrices() {
      this.moneyService.getCocoaPrices().subscribe((res: any) => {
        if (res) {
          let keysToIterate = Object.keys(res)
          for (let key of keysToIterate) {
            let cleanedPrice = res[key]["Último"].substring(0, res[0]["Último"].length-1)
            this.cocoaPricesObjects.push({month:res[key].Mes,price:+cleanedPrice})
            this.cacaoOnlyPrices.push(+cleanedPrice)
          }
          if (!this.cacaoOnlyPrices.includes(this.cacaoPrice)) {
            this.cocoaPricesObjects.push({month:"Cash", price:this.cacaoPrice})
            this.cacaoOnlyPrices.push(this.cacaoPrice)
          }
          this.getMonthCocoaPrice()
          this.anotherCocoaPrice = false
  
        }
      }, 
    error => {
      if (!this.cacaoOnlyPrices.includes(this.cacaoPrice)) {
        this.cocoaPricesObjects.push({month:"Cash", price:this.cacaoPrice})
        this.cacaoOnlyPrices.push(this.cacaoPrice)
        this.getMonthCocoaPrice()
        this.anotherCocoaPrice = false
      }
    });
  }

  async getMonthCocoaPrice(){
    for (let cacao of this.cocoaPricesObjects) {
      if (cacao.price==this.cacaoPrice.toString()) {
        this.selectedMonth = cacao.month
      }
    }
  }

  async updatePricesValues() {
    this.canUpdatePrices=true
  }

}
