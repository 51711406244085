<div class="main-container row" [ngClass]="{'no-aside': sessionRole == 'viewer' || activeUser === true}">
  <div *ngIf="activeUser === false && currentFlow != 'quote'" class="col-4  col-sm-12 assistant">
    <app-assistant [currentStep]="currentStep" [assistantTips]="assistantTips"></app-assistant>
  </div>
  <div *ngIf="activeUser === false" class="col-4  col-sm-12 assistant-new">
    <app-tips [tips]="assistantTips"></app-tips>
  </div>

  <ng-container *ngIf="currentFlow == 'ingredient'" class="col-8">
    <app-shape-and-size-step [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 1" [stepName]="steps[0].name"
      [stepDescription]="steps[0].description" [chocolateType]="basicSelections.chocolateType"
      (completed)="handleEvent($event)"></app-shape-and-size-step>
    <app-chocolate-pick-step [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 2" [stepName]="steps[1].name"
      [stepDescription]="steps[1].description" [application]="basicSelections.application?.key"
      [portfolio]="basicSelections.recipe" [type]="basicSelections.chocolateType"
      [ingredientSpecs]="basicSelections.ingredientSpecs" (completed)="handleEvent($event)"></app-chocolate-pick-step>
    <app-chocolate-sweeteners [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 3"></app-chocolate-sweeteners>
    <app-packaging-step [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 4" [stepName]="steps[2].name" [stepDescription]="steps[2].description"
      (completed)="handleEvent($event)"></app-packaging-step>
    <!-- <app-impact-summary [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 5" [stepName]="steps[3].name"></app-impact-summary> -->
    <app-transport *ngIf="currentStep == 5" [stepName]="steps[5].name"></app-transport>
  </ng-container>

  <ng-container *ngIf="currentFlow == 'product'" class="col-8">
    <app-shape-and-size-step *ngIf="currentStep == 1" [stepName]="steps[0].name" [ngClass]="{'aside-disable': activeUser === false}"
      [stepDescription]="steps[0].description" [category]="basicSelections.application?.key"
      [chocolateType]="basicSelections.chocolateType" (completed)="handleEvent($event)">
    </app-shape-and-size-step>
    <app-chocolate-pick-step  [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 2" [stepName]="steps[1].name" [type]="basicSelections.chocolateType"
      [stepDescription]="steps[1].description" [portfolio]="basicSelections.recipe" (completed)="handleEvent($event)">
    </app-chocolate-pick-step>
    <app-chocolate-sweeteners [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 3"></app-chocolate-sweeteners>
    <app-inclusions-and-flavors [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 4" [stepValidation]="stepValidation" [ingredientSpecs]="basicSelections.ingredientSpecs">
    </app-inclusions-and-flavors>
    <app-packaging-step [ngClass]="{'aside-disable': activeUser === false}" [stepValidation]="stepValidation" *ngIf="currentStep == 5" [stepName]="steps[2].name" [stepDescription]="steps[2].description"
      (completed)="handleEvent($event)"></app-packaging-step>
    <app-design-inspiration [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 6" [stepName]="steps[4].name"
      [stepDescription]="steps[4].description"></app-design-inspiration>
    <!-- <app-impact-summary [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 7 && activeUser === false" [stepName]="steps[3].name"></app-impact-summary> -->
    <app-transport *ngIf="currentStep == 7  && activeUser === false" [stepName]="steps[5].name" (completed)="handleEvent($event)"></app-transport>
    <!-- <app-salesforce-module *ngIf="currentStep == 9  && activeUser === false" (completed)="handleEvent($event)"></app-salesforce-module> -->
    <app-information-feria *ngIf="currentStep == 7 && activeUser"></app-information-feria>
  </ng-container>

  <ng-container *ngIf="currentFlow == 'launch'" class="col-8 col-sm-12">
    <app-private-label-portfolio *ngIf="currentStep == 1" style="padding: 0px 0px !important;" (completed)="handleEvent($event)"></app-private-label-portfolio>
    <app-download-quote-private-label *ngIf="currentStep == 2" (completed)="handleEvent($event)"></app-download-quote-private-label>
    <!-- <app-ready-to-launch [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 1" (completed)="handleEvent($event)"></app-ready-to-launch> -->
    <!-- <app-product-description [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 2"></app-product-description> -->
    <!-- <app-impact-summary [ngClass]="{'aside-disable': activeUser === false}" *ngIf="currentStep == 3 && activeUser === false" [stepName]="steps[3].name"></app-impact-summary> -->
    <!-- <app-transport (completed)="handleEvent($event)" *ngIf="currentStep == 3 && activeUser === false" [stepName]="steps[5].name"></app-transport> -->
    <!-- <app-information-feria (completed)="handleEvent($event)" *ngIf="currentStep == 3 && activeUser"></app-information-feria> -->
  </ng-container>
  <ng-container *ngIf="currentFlow == 'saved-private-label-quotes'" class="col-8 col-sm-12">
    <app-saved-private-label-quotes></app-saved-private-label-quotes>
  </ng-container>
  <ng-container *ngIf="currentFlow == 'saved-actual-products-quotes'" class="col-8 col-sm-12">
    <app-saved-actual-products-quotes></app-saved-actual-products-quotes>
  </ng-container>

  <ng-container *ngIf="currentFlow === 'quote'" class="col-8 col-sm-12">
    <div *ngIf="versionsTabs.length>0" class="container__versions">
      <p-tabView [activeIndex]="selectedIndex" (onChange)="selectVersion($event)" _activeIndex="1" [scrollable]="true" styleClass="tab-versions">
        <p-tabPanel  *ngFor="let version of versionsTabs" header="{{('Version '+  version.version)}}">
        </p-tabPanel>
    </p-tabView>

    </div>
    <app-finished-product *ngIf="currentStep == 1" style="padding: 0px 0px !important;" (completed)="handleEvent($event)"></app-finished-product>
    <app-download-quote *ngIf="currentStep == 2" (completed)="handleEvent($event)"></app-download-quote>
    <!-- <app-impact-summary *ngIf="currentStep == 2" [stepName]="steps[3].name"></app-impact-summary> -->
    <!-- <app-transport *ngIf="currentStep == 2" [stepName]="steps[5].name"></app-transport> -->
  </ng-container>

  <div class="footer-container"
    *ngIf="(currentFlow == 'product' && currentStep != this.finalStep) || (currentFlow == 'ingredient' && currentStep < 5) || (currentFlow != 'quote' && currentStep != 3) && currentFlow != 'launch' && currentFlow != 'saved-private-label-quotes' && currentFlow != 'saved-actual-products-quotes'">
    <!-- *ngIf="(currentFlow == 'product' && currentStep != this.finalStep) || (currentFlow == 'ingredient' && currentStep < 5) || (currentFlow == 'launch' && currentStep != this.finalStep) || (currentFlow == 'quote' && currentStep != 3)"> -->
    <button (click)="changeToNextStep()" class="footer-button"
      [disabled]="!enableNextStep || sessionRole != 'admin'">Next Step ></button>
  </div>

  <div class="new-footer-container"
    *ngIf="currentFlow == 'quote' || currentFlow == 'launch'">
      <p-button *ngIf="currentStep == 1" label="Continue" styleClass="footer-button" [disabled]="!enableNextStep" (onClick)="changeToNextStep()"></p-button>
      <p-menu #menu [model]="downloadOptions"[popup]="true" appendTo="body" styleClass="download-options"></p-menu>
      <p-button  *ngIf="currentStep == 2  && currentFlow == 'launch'" label="Download Excel" styleClass="footer-button-download-excel" (onClick)="downloadExcel()" [disabled]="!enableNextStep"></p-button>
      <p-button *ngIf="currentStep == 2 && currentFlow=='launch' && canSave" label="Save" styleClass="footer-button" [style]="{'margin-left':'20px', 'width':'162px'}" (onClick)="saveProject()" [disabled]="!enableNextStep"></p-button>
      <p-button *ngIf="currentStep == 2 && currentFlow=='quote' && canUpdate" label="Create new version" styleClass="footer-button" [style]="{'margin-left':'20px', 'width':'162px'}" (onClick)="createNewVersionProject()" [disabled]="!enableNextStep"></p-button>
      <p-button *ngIf="currentStep == 2 && currentFlow=='quote' && canUpdate" label="Update" styleClass="footer-button" [style]="{'margin-left':'20px', 'width':'162px'}" (onClick)="saveActualProductsProject()" [disabled]="!enableNextStep"></p-button>
      <p-button *ngIf="currentStep == 2 && currentFlow=='quote' && canSave" label="Save" styleClass="footer-button" [style]="{'margin-left':'20px', 'width':'162px'}" (onClick)="saveActualProductsProject()" [disabled]="!enableNextStep"></p-button>
      <p-button  *ngIf="currentStep == 2 && currentFlow == 'quote'" label="Download" styleClass="footer-button-download" [style]="{'margin-left':'20px'}" (onClick)="menu.toggle($event)" iconPos="right" icon="pi pi-ellipsis-v" [disabled]="!enableNextStep"></p-button>
  </div>

  <!--
  <div class="footer-container"
    *ngIf="(currentStep == 6 && currentFlow == 'ingredient') || (currentStep == this.finalStep && currentFlow == 'product') || (currentStep == this.finalStep && currentFlow == 'launch') || (currentStep == 3 && currentFlow == 'quote')">
  -->
  <div class="footer-container"
    *ngIf="(currentStep == 8 && currentFlow != 'ingredient') || (currentStep == 5 && currentFlow == 'ingredient') || (currentStep == this.finalStep && currentFlow == 'product') || (currentStep == this.finalStep) || (currentStep == 3 && currentFlow == 'quote')">
    <button (click)="changeToNextStep()" class="footer-button"
      [disabled]="!enableNextStep || sessionRole != 'admin'">Finish Creating ></button>
  </div>
</div>
