import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaterialListService } from '../../../core/services/material-list/material-list.service';
import { PackagingService } from '../../../core/services/packaging/packaging.service';
import { ChocolateService } from '../../../core/services/chocolate/chocolate.service';
import { RegisterInfoService } from '../../../core/services/register-info/register-info.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import ls from 'localstorage-slim';
import { CostService } from 'src/app/core/services/costs/cost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { MoneyApisService } from 'src/app/core/services/money-apis/money-apis.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalFilterMaterialComponent } from '../finished-product/modal-filter-material/modal-filter-material.component';
import { ModalProductDetailsComponent } from '../finished-product/modal-product-details/modal-product-details.component';
import { InclusionsFlavorsService } from 'src/app/core/services/inclusions-flavors/inclusions-flavors.service';
import { RTLProductService } from 'src/app/core/services/rtl-product/rtlproduct.service';
import { DesperdiciosService } from 'src/app/core/services/desperdicios-planta/desperdicios.service';
import { tap } from 'rxjs/operators';
import { BespokeFormulaService } from 'src/app/core/services/bespoke-formula/bespoke-formula.service';
import { ManufactureCombinationService } from 'src/app/core/services/manufacture-combination/manufacture-combination.service';
import { ActivatedRoute } from '@angular/router';
import { PrivateLabelPortfolioService } from 'src/app/core/services/private-label-portfolio/private-label-portfolio.service';

@Component({
  selector: 'app-private-label-portfolio',
  templateUrl: './private-label-portfolio.component.html',
  styleUrls: ['./private-label-portfolio.component.css'],
})
export class PrivateLabelPortfolioComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  rtlCode: string = "";
  validationSap!: boolean;
  faMagnifyingGlass = faMagnifyingGlass;
  queryParams:any = {}
  showingSavedQuote = false
  alreadyExists = false

  constructor(
    private listMaterialService: MaterialListService,
    private packagingService: PackagingService,
    private chocolateService: ChocolateService,
    private registerInfoService: RegisterInfoService,
    private desperdiciosService: DesperdiciosService,
    private sms: SimpleModalService,
    private costService: CostService,
    private spinner: NgxSpinnerService,
    private rtlProductService: RTLProductService,
    private tipsService: TipsService,
    private moneyService: MoneyApisService,
    private bespokeFormulaService: BespokeFormulaService,
    private formBuilder: FormBuilder,
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private materialListService: MaterialListService,
    private manufactureCombService: ManufactureCombinationService,
    private route: ActivatedRoute,
    private privateLabelPortfolioService: PrivateLabelPortfolioService,



  ) {
    this.queryParams = this.route.snapshot.queryParams;
  }

  wastePlantMerm: any;
  wastePlantInclusion: any;

  globalMerm: number = 0;

  anotherDolarPrice:boolean = false
  anotherCocoaPrice:boolean = false

  weight:any = 0

  dollarPrices: any = [];
  cacaoOnlyPrices: any = [];
  cocoaPricesObjects: any = [];
  parameters: any = {}
  project: any = {}

  totalCostRawMaterialOne = 0
  totalCostRawMaterialTwo = 0
  totalCostPackaging = 0
  totalCostProcesses = 0
  rawMaterialOneCosts: any = {}
  dollarPrice=0
  euroPrice=0
  cacaoPrice=0
  cacaoBonus=0
  totalCost=0
  selectedMonth=''
  productsList:any=[]
  allowContinue=false
  currentProductSelected:any = {}
  currentFinishedIngredient:any = {}

  
  arrManuFacturing: any[] = [];
  arrWaste: any[] = [];
  mermWaste: any;
  rawMaterialTypeOneCosts:any = {}
  // arrFactorIncoterm: any[] = [];
  // arrWastePackaging: any[] = [];
  // arrCostsPg: any[] = [];

  rawMaterialCostDetails:any = []
  rawMaterialCostDetailsTypeOne:any = []
  rawMaterialCostDetailsTypeTwo:any = []
  packagesCostsDetails:any = []
  manufacturingCosts:any = {}


  arrCostsStructure: any[] = [];
  rawMaterialsTypeOne:any = []
  rawMaterialsTypeTwo:any = []
  arrMaterialRegisters:any = []
  rawMaterialCostTwo:number = 0
  rawMaterialCostOne:number = 0

  currentInclusions:any = {}
  currentFlavor:any = {}

  columns: any[] = [
    { name: 'Actions' },
    { name: 'RTL Code' },
    { name: 'Txt Material' },
    { name: 'MP 1' },
    { name: 'MP 2' },
    { name: 'Packaging' },
    { name: 'Processes' },
    { name: 'Total' },
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
      // this.formBuilder.group(this.generateFormGroupFields())
    ])
  });

  tips:any=[]

  accordionsStates:any = {
    macroeconomics: true
  }

  canUpdatePrices=false

  ngOnInit(): void {
    this.getWastePlant()
    this.loadAssistantTips();
    this.consultDollar();
    this.getCocoaPrices();
    this.setProducts()
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  saveCostParameters() {
    let parameters =JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
      parameters.cacaoPrice = this.cacaoPrice,
      parameters.dollarPrice = this.dollarPrice,
      parameters.euroPrice = this.euroPrice,
      parameters.cacaoBonus =this.cacaoBonus,
    ls.set('parameters', JSON.stringify(parameters), { encrypt: true });
  }

  async setMaterialOnePricesAndParameters(){
    if (this.queryParams.projectId != null) {
      this.showingSavedQuote = true
      this.spinner.show()
      const project:any = await this.privateLabelPortfolioService.getProjectById(this.queryParams.projectId)
      if(project!=null){
        this.project = project
        ls.set('current_project', JSON.stringify(project), { encrypt: true });
        ls.set('parameters', JSON.stringify(project.parameters), { encrypt: true });
        this.dollarPrices.push(project.parameters.dollarPrice)
        localStorage.setItem('rtlProductsList', JSON.stringify(project.products));
        this.spinner.hide()
      }
    }

    this.parameters =JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
    this.weight = this.parameters.weight
    if (this.parameters.dollarPrice!=null) {
      this.cacaoPrice=+this.parameters.cacaoPrice;
      this.dollarPrice=+this.parameters.dollarPrice;
      this.euroPrice=+this.parameters.euroPrice;
      this.cacaoBonus=+this.parameters.cacaoBonus;
      this.anotherDolarPrice = true
      this.anotherCocoaPrice = true
    }

    const rawMaterialOneCostsInLocal = JSON.parse(localStorage.getItem('materialOnePricesRtl')||"{}");
    if (Object.keys(rawMaterialOneCostsInLocal).length) {
      this.rawMaterialOneCosts = rawMaterialOneCostsInLocal
    } else {
      this.updatePrices()
    }
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */

  async addNewRow(index: number) {
    const formGroup = this.formBuilder.group(
      {
        rtlCode: this.productsList[index].rtlCode,
        txt_material: this.productsList[index].txt_material,
        cost_raw_material_one: Math.round(this.productsList[index].cost_raw_material_one),
        cost_raw_material_two: Math.round(this.productsList[index].cost_raw_material_two),
        cost_packaging: Math.round(this.productsList[index].cost_packaging),
        cost_processes: Math.round(this.productsList[index].cost_processes),
        total_cost: Math.round(this.productsList[index].total_cost)
      }
    );
    this.totalCostRawMaterialOne += this.productsList[index].cost_raw_material_one
    this.totalCostRawMaterialTwo += this.productsList[index].cost_raw_material_two
    this.totalCostPackaging += this.productsList[index].cost_packaging
    this.totalCostProcesses += this.productsList[index].cost_processes
    this.getFormArray().push(formGroup);

  }

  async setProducts(){
    await this.setMaterialOnePricesAndParameters()
    this.productsList = this.project.products || JSON.parse(localStorage.getItem('rtlProductsList')||"[]");
    if (this.productsList.length>0) {
      this.completed.emit({ flow: 'launch', step: 1, completed: true });
    }
    for (let index = 0; index < this.productsList.length; index++) {
      this.addNewRow(index)
      this.totalCost += this.productsList[index].total_cost
    }
  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {

    if (this.productsList.length>0) {
      const product = this.productsList[index]
      this.totalCostRawMaterialOne -= product.cost_raw_material_one
      this.totalCostRawMaterialTwo -= product.cost_raw_material_two
      this.totalCostPackaging -= product.cost_packaging
      this.totalCostProcesses -= product.cost_processes
      this.totalCost = this.totalCostRawMaterialOne + this.totalCostRawMaterialTwo + this.totalCostPackaging+ this.totalCostProcesses
      this.getFormArray().controls.splice(index, 1);
      await this.productsList.splice(index, 1)
      if (this.productsList.length==0) {
        this.allowContinue=false
        this.completed.emit({ flow: 'launch', step: 1, completed: false });
        this.completed.emit({ flow: 'launch', step: 2, completed: false });
      }
      localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
      return;
    }

    this.getFormArray().controls.splice(index, 1);
    this.productsList.splice(index, 1)

  }

  /**
   * Shows a dialog with the product details.
   *
   * @param index
   */
  async showProductDetails(index: number)  {

    

  }

  async priceWaste(bespoke:any, pickedInclusions:any) {
    let priceRawMaterial = 0;
    await this.wastePlantMerm.forEach((merm: any) => {
      if (
        (this.currentFinishedIngredient.chocolatePick?.tipo == merm.tipoChocolate && pickedInclusions.inclusion == merm.inclusion) ||
        bespoke.type == merm.tipoChocolate
      ) {
        this.mermWaste = merm.merma;
        this.globalMerm = merm.merma;
        let desperdicio = merm.merma / this.weight;
        for (const i of this.rawMaterialCostDetailsTypeOne) {
          priceRawMaterial = i.price * (1 + desperdicio);
          i.price = priceRawMaterial;
        }
      }
    });
    // this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a:any, b:any) => a + b.price, 0);
  }

  openCloseAccordion(key:string){
    this.accordionsStates[key] = !this.accordionsStates[key]
  }

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }


  changeOtherPrice(value:string){
    if (value=="dolar") {
      this.anotherDolarPrice=!this.anotherDolarPrice
    } else if (value=="cocoa"){
      this.anotherCocoaPrice=!this.anotherCocoaPrice
    }
  }

  async updatePrices() {

    this.rawMaterialOneCosts = await this.costService.getRawMaterialTypeOne(
      this.dollarPrice||0,
      this.cacaoPrice||0,
      this.cacaoBonus||0
    );
    localStorage.setItem('materialOnePricesRtl', JSON.stringify(this.rawMaterialOneCosts));
    this.saveCostParameters()

    let newProductsList=this.productsList
    for (let index = 0; index < this.productsList.length; index++) {
      let product = this.productsList[index];
      const curentRawMaterialOneCost = product.cost_raw_material_one
      const curentRawMaterialTwoCost = product.cost_raw_material_two
      const curentTotalCost = product.total_cost
      const rawMaterialsInfo = await this.setRawMaterialsCosts(
        product.raw_material_one_list, 
        product.raw_material_two_list,
        product.packaging_list
      );
      newProductsList[index].raw_material_one_list = rawMaterialsInfo.materiaPrima1List
      newProductsList[index].raw_material_two_list = rawMaterialsInfo.materiaPrima2List
      newProductsList[index].cost_raw_material_one = rawMaterialsInfo.costRawMaterialOne||0,
      newProductsList[index].cost_raw_material_two = rawMaterialsInfo.costRawMaterialTwo||0,
      newProductsList[index].total_cost = (product.cost_processes+product.cost_packaging+rawMaterialsInfo.costRawMaterials)
      this.totalCostRawMaterialOne = this.totalCostRawMaterialOne - curentRawMaterialOneCost + newProductsList[index].cost_raw_material_one;
      this.totalCostRawMaterialTwo = this.totalCostRawMaterialTwo - curentRawMaterialTwoCost + newProductsList[index].cost_raw_material_two;
      this.totalCost = this.totalCost - curentTotalCost + this.productsList[index].total_cost
      const formArray = this.getFormArray().controls;
      const formControls = formArray[index]
      formControls.patchValue({
        cost_raw_material_one: Math.round( newProductsList[index].cost_raw_material_one),
        cost_raw_material_two: Math.round( newProductsList[index].cost_raw_material_two),
        total_cost: Math.round( newProductsList[index].total_cost)
      })

    }
    this.productsList = newProductsList
    this.canUpdatePrices=false
    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));

  }

  async setRawMaterialsCosts(rawMaterialsOne:any[], rawMaterialsTwo:any[], packaging:any[]){
    let materiaPrima1List=[]
    let materiaPrima2List=[]
    let costRawMaterialOne=0
    let costRawMaterialTwo=0
    let costRawMaterials=0
    for (let index = 0; index < rawMaterialsOne.length; index++) {
      const material = rawMaterialsOne[index];
      let nombreMateria = material.name;
      if (
        nombreMateria.includes('PS LICOR') ||
        nombreMateria.includes('PS COCOA NIBS') ||
        nombreMateria.includes('PS NIBS CLUSTER') ||
        nombreMateria.includes('Ps Nibs')
      ) {
        rawMaterialsOne[index].precioNeto = this.rawMaterialOneCosts.precioLicor;
      } else if (nombreMateria.includes('PS COCOA') || nombreMateria.includes('PS VIRUTA')) {
        rawMaterialsOne[index].precioNeto = this.rawMaterialOneCosts.precioCocoa;
      } else if (nombreMateria == 'PS MANTECA DESODORIZADA') {
        rawMaterialsOne[index].precioNeto = this.rawMaterialOneCosts.precioMantecaDeso;
      } else if (nombreMateria != 'PS MANTECA DESODORIZADA') {
        rawMaterialsOne[index].precioNeto = this.rawMaterialOneCosts.precioManteca;
      }
      const costoKg = parseFloat(rawMaterialsOne[index].precioNeto) * (parseFloat(material.factorKg) || material.porcentaje);
      const finalPrice = costoKg * (1 + material.fullWaste.percentageExtraContenido + material.globalMerm / 4000);
      rawMaterialsOne[index].price = finalPrice || 0
      if (rawMaterialsOne[index].price!=undefined && rawMaterialsOne[index].price!=null) {
        costRawMaterials += finalPrice||0
        costRawMaterialOne += finalPrice||0
        const materialWithPrice = rawMaterialsOne[index]
        materiaPrima1List.push(materialWithPrice)
      }
    }
    for (let index = 0; index < rawMaterialsTwo.length; index++) {
      const material = rawMaterialsTwo[index];
      if (material.moneda != 'COP') {
        const price = material.precioNeto * (material.factorKg || material.porcentaje || material.percentage) * (1 + material.desperdicio + material.globalMerm / 4000)
        const finalPrice = price * (material.moneda=="USD"?this.dollarPrice:material.moneda=="EUR"?this.euroPrice:1)
        costRawMaterials += finalPrice
        costRawMaterialTwo += finalPrice
        rawMaterialsTwo[index].price = finalPrice
        materiaPrima2List.push(rawMaterialsTwo[index])
      } else {
        materiaPrima2List.push(material)
        costRawMaterials += material.price
        costRawMaterialTwo += material.price
      }
    }

    return {
      materiaPrima1List:materiaPrima1List,
      materiaPrima2List:materiaPrima2List,
      costRawMaterialOne:costRawMaterialOne,
      costRawMaterialTwo:costRawMaterialTwo,
      costRawMaterials:costRawMaterials
    }
  }


  async getBespoke(){
    let bespoke: any = {}
    const materials = await this.bespokeFormulaService.getNewMaterialsList({ llave: `${this.currentProductSelected.notas.replace('.', ',')}${this.currentProductSelected.tipo}` }, [])
    bespoke.type = this.currentProductSelected.tipo;
    bespoke.percentage = this.currentProductSelected.notas;
    bespoke.materials = materials;
    return bespoke
  }

  clearMaterialsLists(){
    this.rawMaterialCostDetails = []
    this.rawMaterialCostDetailsTypeOne = []
    this.rawMaterialCostDetailsTypeTwo = []
    this.arrCostsStructure = [];
    this.rawMaterialsTypeOne = []
    this.rawMaterialsTypeTwo = []
    this.arrMaterialRegisters = []
    this.manufacturingCosts = {}
    this.packagesCostsDetails = []
  }

  async checkIfUsingRtlCode(rtlCode:string){
    if (this.productsList.find((product:any) => product.rtlCode === rtlCode))  return true;;

    return false
  }
  

  async validateRTLProduct(product:any) {
    this.spinner.show()
    this.alreadyExists = false
    this.validationSap = false
    const RepeatedRtlCode = await this.checkIfUsingRtlCode(this.rtlCode.toUpperCase())
    const RepeatedRtlProduct = await this.checkIfUsingRtlCode(product.idRtl||"")
    if (RepeatedRtlCode && Object.keys(product).length == 0) {
      this.alreadyExists = true
      this.spinner.hide()
      return;
    }
    if (RepeatedRtlProduct && Object.keys(product).length > 0) {
      this.spinner.hide()
      alert('The selected product is already in list.');
      return;
    }
    if (Object.keys(product).length==0) {
      product = await this.rtlProductService.getRtlProductByRtlId(this.rtlCode.toUpperCase())
      if(product==null){
        this.spinner.hide()
        this.validationSap = true
        return
      }
    }

    this.clearMaterialsLists()
    const shape = product.formaId
    const packaging: any = (
      await this.packagingService.filterCombination({ combinacionEmpaqueId: product.combEmpaque })
    )[0];
    packaging.unitsPerBox = (product.unitsPerBox || 0)
    this.currentProductSelected = product
    this.currentInclusions = await this.getInclusions()
    this.currentFlavor = await this.getFlavor()
    let chocolate:any = {}
    let bespoke:any = {}
    let milkType = false
    let materialOptionsRTL;
    if (chocolate) {
      materialOptionsRTL = await this.rtlProductService.getMaterialsRTL(chocolate.materialSap);
      if (chocolate.tipo === 'MILK') {
        milkType = true;
      }
    }
    let plantaTriunfoValues:any = {}
    if (product.triunfo == true) {
      plantaTriunfoValues = await this.privateLabelPortfolioService.getPlantaTriunfoValuesByRtlId(product.idRtl) || {};
    }
    this.currentFinishedIngredient = {
      shapePick: shape,
      packagingPick: product.combEmpaque,
      rtl: product.idRtl,
      combEmpaque: product.combEmpaque
    };
    
    const pickedInclusions = await this.validateInclusions()
    if (product.format !=='DRAGEE') {
      chocolate = await this.chocolateService.filter({ chocolateId: product.chocolateId });
      if (chocolate==null) {
        this.spinner.hide()
        return
      }
    } else {
      bespoke = await this.getBespoke()
    }
    if (Object.keys(chocolate).length > 0) {
      this.currentFinishedIngredient.chocolatePick = chocolate;
    }
    if (Object.keys(bespoke).length > 0) {
      this.currentFinishedIngredient.bespoke = bespoke;
    }
    if (Object.keys(this.currentInclusions).length > 0) {
      this.currentFinishedIngredient.inclusions = this.currentInclusions;
    }
    if (Object.keys(this.currentFlavor).length > 0) {
      this.currentFinishedIngredient.flavor = this.currentFlavor;
    }
    await this.priceWaste(bespoke, pickedInclusions)
    await this.getRawMaterialCost(pickedInclusions)
    const costProcesses = await this.getManufacturingCost(pickedInclusions)
    const costPackaging = await this.getPackagingCombinationCost()
    const processesList = this.manufacturingCosts.rutas || []
    delete this.manufacturingCosts.rutas
    const costRawMaterialOne = await this.calculateRawMaterialCostTotal(this.rawMaterialCostDetailsTypeOne)
    const costRawMaterialTwo = await this.calculateRawMaterialCostTotal(this.rawMaterialCostDetailsTypeTwo)
    const productDataToLst:any = {
      raw_material_one_list: this.rawMaterialCostDetailsTypeOne,
      raw_material_two_list: this.rawMaterialCostDetailsTypeTwo,
      packaging_list: this.packagesCostsDetails,
      processes: this.manufacturingCosts,
      processes_list: processesList || [],
      cost_packaging: costPackaging,
      cost_processes: costProcesses || 0,
      cost_raw_material_one: costRawMaterialOne,
      cost_raw_material_two: costRawMaterialTwo,
      total_cost: (costProcesses+costPackaging+costRawMaterialOne+costRawMaterialTwo),
      rtlCode: product.idRtl,
      txt_material: product.nombre,
      packaging: packaging,
    }
    if (Object.keys(plantaTriunfoValues).length > 0) {
      productDataToLst.cost_processes += plantaTriunfoValues.cif;
      productDataToLst.cost_processes += plantaTriunfoValues.labour;
      productDataToLst.planta_triunfo = plantaTriunfoValues
    }
    this.totalCost += (costProcesses+costPackaging+costRawMaterialOne+costRawMaterialTwo)
    this.productsList.push(productDataToLst)
    localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
    if (this.allowContinue == false) {
      this.completed.emit({ flow: 'launch', step: 1, completed: true });
      this.completed.emit({ flow: 'launch', step: 2, completed: true });
    }
    this.addNewRow(this.productsList.length-1)
    this.rtlCode=""
    this.spinner.hide()
  }

  async calculateRawMaterialCostTotal(materialsList:any){
    let materiaCostSubtotal = 0;
    for (let material of materialsList) {
      materiaCostSubtotal += material.price
    }
    return materiaCostSubtotal
  }

  openModal() {
    this.sms.addModal(ModalFilterMaterialComponent, {private_label_portfolio:true}).subscribe((res: any) => {
      if (res) {
        this.validateRTLProduct(res)
      }
    });
  }

  async getInclusions(){
    let inclusion: any = {};
    if (this.currentProductSelected.inclusion1Id) {
      let factorInclusion1 = await this.getFactorBespoke(this.currentProductSelected.inclusion1Id);
      let percentageInclusion1;
      if (percentageInclusion1!=null) {
        if (this.currentProductSelected.format === 'DRAGEE') {
          percentageInclusion1 = factorInclusion1.factorBespoke * 100;
          inclusion.base = {
            percentage: percentageInclusion1,
            inclusion: {
              percentage: percentageInclusion1,
              codigoSap: factorInclusion1.codigoSap,
              nombre: factorInclusion1.nombre,
            },
          };
        } else {
          percentageInclusion1 = this.currentProductSelected.porcentInclusion1;
          inclusion.base = {
            inclusion: this.currentProductSelected.inclusion1Id,
            percentage: percentageInclusion1,
            codigoSap: factorInclusion1.codigoSap,
            nombre: factorInclusion1.nombre,
          };
        }
      }
    }
    if (this.currentProductSelected.inclusion2Id) {
      let factorInclusion2 = await this.getFactorBespoke(this.currentProductSelected.inclusion1Id);
        let percentageInclusion2;
        if (this.currentProductSelected.format === 'DRAGEE') {
          percentageInclusion2 = factorInclusion2.factorBespoke * 100;
          inclusion.extra = {
            inclusion: {
              codigoSap: factorInclusion2.codigoSap,
              nombre: factorInclusion2.nombre,
            },
            percentage: percentageInclusion2,
          };
        } else {
          percentageInclusion2 = this.currentProductSelected.porcentInclusion2;
          inclusion.extra = { inclusion: this.currentProductSelected.inclusion2Id, percentage: percentageInclusion2 };
        }
    }
    return inclusion;
        
  }
  
  async getFlavor(){
    let flavor: any = {};
    if (this.currentProductSelected.saborizanteId) {
      try {
        flavor = await this.inclusionsFlavorsService.filterOne({ ingredienteId: this.currentProductSelected.saborizanteId });
        flavor.pickedConcentration = this.currentProductSelected.porcentSaborizante;
      } catch (error) {
        return {}
      }
    }
    return flavor
  }

  async validateInclusions() {
    // Objeto para almacenar las inclusiones seleccionadas
    let pickedInclusions: any = {};

    // Arreglo para almacenar inclusiones y sabores
    let inclusionsAndFlavors = [];

    // Comprobar si hay inclusiones o sabores seleccionados
    if (Object.keys(this.currentFlavor).length > 0 || Object.keys(this.currentInclusions).length > 0) {
      // Se establece 'SI' en caso de que haya inclusiones o sabores seleccionados
      pickedInclusions.inclusion = 'SI';

      // Comprobar si hay información de sabor y porcentaje de base
      if (this.currentFlavor?.codigoSap !== undefined && parseFloat(this.currentInclusions?.base?.percentage) / 100 !== undefined) {
        // Agregar información del sabor y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: this.currentFlavor?.nombre,
          material: this.currentFlavor?.codigoSap,
          percentage: parseFloat(this.currentFlavor?.pickedConcentration),
          ...this.currentFlavor,
        });
      }

      // Comprobar si hay información de inclusión base y porcentaje de base
      if (
        this.currentInclusions?.base?.inclusion?.codigoSap !== undefined &&
        parseFloat(this.currentInclusions?.base?.percentage) / 100 !== undefined
      ) {
        // Agregar información de la inclusión base y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: this.currentInclusions?.base?.inclusion?.nombre,
          material: this.currentInclusions?.base?.inclusion?.codigoSap,
          percentage: parseFloat(this.currentInclusions?.base?.percentage) / 100,
          ...this.currentFlavor,
        });
      }

      // Comprobar si hay información de inclusión extra y porcentaje de extra
      if (
        this.currentInclusions?.extra?.inclusion?.codigoSap !== undefined &&
        parseFloat(this.currentInclusions?.extra?.percentage) / 100 !== undefined
      ) {
        // Agregar información de la inclusión extra y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: this.currentInclusions?.extra?.inclusion?.nombre,
          material: this.currentInclusions?.extra?.inclusion?.codigoSap,
          percentage: parseFloat(this.currentInclusions?.extra?.percentage) / 100,
          ...this.currentFlavor,
        });
      }
    } else {
      // Se establece 'NO' en caso de que no haya inclusiones ni sabores seleccionados
      pickedInclusions.inclusion = 'NO';
    }

    // Asignar el arreglo de inclusiones y sabores al objeto pickedInclusions
    pickedInclusions.inclusions = inclusionsAndFlavors;

    // Devolver el objeto pickedInclusions que contiene la información seleccionada
    return pickedInclusions;
  }

  async getRawMaterialCost(pickedInclusions:any) {
    let extraPercentage = 0;
    if (pickedInclusions) {
      for (let i = 0; i < pickedInclusions.inclusions.length; i++) {
        if (pickedInclusions.inclusions[i].percentage) {
          extraPercentage += pickedInclusions.inclusions[i].percentage;
        }
      }
      const inclusionsCostsDetails = await this.getInclusionsCostDetails(pickedInclusions);
      await this.getInclusionsCost(inclusionsCostsDetails, pickedInclusions);
      if (this.currentFinishedIngredient.chocolatePick) {
        const finishedProduct = this.currentFinishedIngredient.chocolatePick.materialSap;
        await this.getRawMaterialTypeOneCost(finishedProduct, extraPercentage, inclusionsCostsDetails);
        await this.getRawMaterialTypeTwoCost(finishedProduct, extraPercentage);
      } else {
        let bespokeMaterials = await this.bespokeFormulaService.getBespokeCost(
          this.currentFinishedIngredient.bespoke.materials,
          this.weight,
          extraPercentage
        );
        await this.getBespokeRawMaterialOne(bespokeMaterials, inclusionsCostsDetails);
        await this.getBespokeRawMaterialTwo(bespokeMaterials);
      }
    }
  }

  async getBespokeRawMaterialOne(bespokeMaterials: any[], inclusionsCostsDetails:any) {
    let costs: any = await this.costService.getRawMaterialTypeOne(
      this.dollarPrice,
      parseFloat(this.parameters.cacaoPrice),
      0
    );
    for (let item of inclusionsCostsDetails) {
      if (item.codigoSap === '2000692') {
        item.name = 'Ps Nibs Otros 1B Segundo Tamizado Rx';
        item.tipoMateriaPrima = 'Materia Prima 1';
        item.sap = item.codigoSap;
        item.proportion = item.porcentaje;
        bespokeMaterials.push(item);
      }
    }
    for (const element of bespokeMaterials) {
      if (element.tipoMateriaPrima == 'Materia Prima 1' || element.tipoMateriaPrima === 'SemiElaborados') {
        if (
          element.name.includes('LICOR') ||
          element.name.includes('PS COCOA NIBS') ||
          element.name.includes('PS NIBS CLUSTER') ||
          element.name.includes('Ps Nibs')
        ) {
          element.price = parseFloat(costs.precioLicor);
          element.precioNeto = Math.round(parseFloat(costs.precioLicor));
        } else if (element.name.includes('COCOA') || element.name.includes('VIRUTA')) {
          element.price = parseFloat(costs.precioCocoa);
          element.precioNeto = Math.round(parseFloat(costs.precioCocoa));
        } else if (element.name == 'PS MANTECA DESODORIZADA') {
          element.price = parseFloat(costs.precioMantecaDeso);
          element.precioNeto = Math.round(parseFloat(costs.precioMantecaDeso));
        } else if (element.name != 'PS MANTECA DESODORIZADA') {
          element.price = parseFloat(costs.precioManteca);
          element.precioNeto = Math.round(parseFloat(costs.precioManteca));
        }
        element.price *= parseFloat(element.proportion) || element.porcentaje;
        this.saveDataRawMaterialBespokeTwo(element);
      }
    }
    this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a:any, b:any) => a + b.price, 0);
  }

  saveDataRawMaterialBespokeTwo(element: any) {
    this.rawMaterialCostDetailsTypeOne.push(element);
    this.arrCostsStructure.push({
      codSap: element.sap,
      material: element.name,
      factorKg: element.factorKg || element.porcentaje,
      precioNeto: element.precioNeto,
      costoKg: element.price,
      moneda: element.moneda,
      status: 'Vigente',
      register: element.registro,
      valid: element.validez,
      desperdicio: 0,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
  }

  async getBespokeRawMaterialTwo(bespokeMaterials: any[]) {
    for (const element of bespokeMaterials) {
      element.nombre = element.name;
      if (element.tipoMateriaPrima == 'Materia Prima 2') {
        element.valid = element.validez
        element.register = element.registro
        if (element.moneda == 'USD') {
          element.price *= this.dollarPrice;
          this.rawMaterialCostTwo += element.price;
        } else if (element.moneda == 'EUR') {
          element.price *= this.euroPrice;
          this.rawMaterialCostTwo += element.price;
        } else {
          this.rawMaterialCostTwo += element.price;
        }
        this.setListElements(element);
      }
    }
    this.rawMaterialCostTwo = this.rawMaterialCostDetailsTypeTwo.reduce((a:any, b:any) => a + b.price, 0);
  }

  setListElements(element: any) {
    this.rawMaterialCostDetailsTypeTwo.push(element);
    this.arrCostsStructure.push({
      codSap: element.sap,
      material: element.name,
      factorKg: element.proportion,
      precioNeto: element.precioNeto,
      costoKg: element.price,
      moneda: element.moneda,
      status: element.status,
      register: element.registro,
      valid: element.validez,
      desperdicio: 0,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
    this.rawMaterialCostDetails.push({
      name: element.name,
      sap: element.sap || element.codigoSap,
      register: element.registro,
      valid: element.validez,
      status: element.status,
    });
  }

  async getInclusionsCostDetails(pickedInclusions?: any) {
    let inclusions: any[] = await this.registerInfoService.getInclusionsCost(
      parseFloat(this.weight),
      pickedInclusions
    );
    return inclusions;
  }

  async getInclusionsCost(inclusionsCostsDetails:any, pickedInclusions:any) {
    let rawMaterialsInclusions;
    let listInclusions;
    for (let item of inclusionsCostsDetails) {
      item.precioNeto = item.precioMaterial;
    }
    listInclusions = inclusionsCostsDetails;
    for (const element of listInclusions) {
      let priceFinal = element.precioNeto * parseFloat(element.porcentaje || element.percentage);
      if (element.moneda == 'COP') {
        element.costoKg = priceFinal;
      } else if (element.moneda == 'USD') {
        let conversionCosto = priceFinal * this.dollarPrice;
        element.costoKg = conversionCosto;
      } else {
        let conversionCosto = priceFinal * this.euroPrice;
        element.costoKg = conversionCosto;
      }
      let price = element.costoKg;
      let finalPrice = 0;
      let desperdicioExtraContenido = 0;
      let desperdicioExtraContenidoInclusion = 0;
      let isInclusion = '';
      this.wastePlantInclusion.forEach((item: any) => {
        if (this.currentFinishedIngredient.shapePick == item.idForma && pickedInclusions.inclusion == item.inclusion) {
          price = element.costoKg;
          finalPrice = price * (1 + item.extraContenidoInclusion + item.extraContenido + this.globalMerm / 4000);
          desperdicioExtraContenido = item.extraContenido;
          desperdicioExtraContenidoInclusion = item.extraContenidoInclusion;
          isInclusion = item.inclusion;
        }
      });
      this.saveDataInclusions(
        element,
        finalPrice,
        desperdicioExtraContenido,
        desperdicioExtraContenidoInclusion,
        isInclusion,
        this.globalMerm
      );
      // this.rawMaterialCostTwo += finalPrice;
    }
  }

  saveDataInclusions(
    element: any,
    finalPrice: any,
    desperdicioExtraContenido: any,
    desperdicioExtraContenidoInclusion: any,
    isInclusion: string,
    globalMerm: number
  ) {
    if (element.moneda === 'USD') {
      if (element.codigoSap !== '2000692') {
        this.rawMaterialCostDetailsTypeTwo.push({
          name: element.nombre,
          nombre: element.nombre,
          globalMerm: globalMerm,
          price: finalPrice || element.costoKg,
          precioNeto: element.precioNeto,
          moneda: element.moneda,
          sap: element.codigoSap,
          desperdicioExtraContenido: desperdicioExtraContenido,
          desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
          desperdicio: desperdicioExtraContenidoInclusion,
          percentage: element.porcentaje,
          inclusion: 'inclusion',
          isInclusion: isInclusion,
        });
      }
    } else {
      this.rawMaterialCostDetailsTypeTwo.push({
        name: element.nombre,
        nombre: element.nombre,
        globalMerm: globalMerm,
        price: finalPrice || element.costoKg,
        precioNeto: element.precioNeto,
        moneda: element.moneda,
        sap: element.codigoSap,
        isInclusion: isInclusion,
        desperdicioExtraContenido: desperdicioExtraContenido,
        desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
        percentage: element.porcentaje,
        inclusion: 'inclusion',
      });
    }
    this.arrCostsStructure.push({
      codSap: element.codigoSap,
      material: element.nombre,
      costoKg: finalPrice || element.costoKg,
      factorKg: element.porcentaje,
      moneda: element.moneda,
      precioNeto: element.precioNeto,
      valid: element.validez,
      isInclusion: isInclusion,
      desperdicioExtraContenido: desperdicioExtraContenido,
      desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
      register: element.registro,
      status: element.status,
    });
    this.arrWaste.push({
      name: element.nombre,
      isInclusion: isInclusion,
      desperdicioExtraContenido: desperdicioExtraContenido,
      desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
    });
  }

  /**
   * This function gets the factor bespoke of the inclusions and flavors used for the RTL Product.
   */
  async getFactorBespoke(inclusionId:string) {
    return await this.inclusionsFlavorsService.getByIngredientId(inclusionId);
  }

  async getRawMaterialTypeOneCost(finishedProduct: any, inclusionsPercentage: number, inclusionsCostsDetails:any) {
    // let desperdicio: any = await this.getDesperdicio();
    let finalPrice: any = 0;
    await this.materialListService.getCostByRawMaterialTypeOne(finishedProduct, inclusionsPercentage);
    await this.costService.getRawMaterialTypeOne(
      this.dollarPrice,
      parseFloat(this.parameters.cacaoPrice),
      0
    );
    this.rawMaterialsTypeOne = this.materialListService.rawMaterialsTypeOne;
    this.rawMaterialTypeOneCosts = this.costService.rawMaterialsTypeOne;
    for (let item of inclusionsCostsDetails) {
      if (item.codigoSap === '2000692') {
        item.nombre = 'Ps Nibs Otros 1B Segundo Tamizado Rx';
        this.rawMaterialsTypeOne.push(item);
      }
    }
    for (const element of this.rawMaterialsTypeOne) {
      let nombreMateria = element.nombre;
      if (
        nombreMateria.includes('PS LICOR') ||
        nombreMateria.includes('PS COCOA NIBS') ||
        nombreMateria.includes('PS NIBS CLUSTER') ||
        nombreMateria.includes('Ps Nibs')
      ) {
        element.precioNeto = this.rawMaterialTypeOneCosts.precioLicor;
      } else if (nombreMateria.includes('PS COCOA') || nombreMateria.includes('PS VIRUTA')) {
        element.precioNeto = this.rawMaterialTypeOneCosts.precioCocoa;
      } else if (nombreMateria == 'PS MANTECA DESODORIZADA') {
        element.precioNeto = this.rawMaterialTypeOneCosts.precioMantecaDeso;
      } else if (nombreMateria != 'PS MANTECA DESODORIZADA') {
        element.precioNeto = this.rawMaterialTypeOneCosts.precioManteca;
      }
      element.costoKg = parseFloat(element.precioNeto) * (parseFloat(element.factorKg) || element.porcentaje);
      element.globalMerm = this.globalMerm
      const desperdicio = await this.priceFinalWaste(element.costoKg);
      finalPrice = element.costoKg * (1 + desperdicio.percentageExtraContenido + this.globalMerm / 4000);
      this.saveDataList(element, finalPrice, nombreMateria, desperdicio);
    }

    this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a:any, b:any) => a + b.price, 0);
  }

  async getRawMaterialTypeTwoCost(finishedProduct: any, inclusionsPercentage: number) {
   
    await this.materialListService.getCostByRawMaterialTypeTwo(
      finishedProduct,
      parseFloat(this.parameters.weight),
      inclusionsPercentage
    );
    this.rawMaterialsTypeTwo = this.materialListService.rawMaterialsTypeTwo;
    let finalPrice: any = 0;
    /**
     * Coger el precio base (elemento.precioNeto) y sumar los desperdicios que es (* (1 + desperdicio.percentageExtraContenido))
     */
    for (const element of this.rawMaterialsTypeTwo) {
      const desperdicio = await this.priceFinalWaste(element.precioNeto);
      const price = element.precioNeto * element.factorKg * (1 + desperdicio.percentageExtraContenido + this.globalMerm / 4000)
      if (element.moneda == 'COP') {
        finalPrice = price; // TODO - A esta formula sumar la merma (merma) y el desperdicio extra inclusion solo a las inclusiones
        this.rawMaterialCostDetailsTypeTwo.push({
          globalMerm: this.globalMerm,
          name: element.nombre,
          price: finalPrice,
          sap: element.codigoSap || element.sap,
          proportion: element.factorKg,
          precioNeto: element.precioNeto,
          moneda: element.moneda,
          factorKg: element.factorKg,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          factor: element.factorIncoterm,
          isInclusion: desperdicio.isInclusion, // TODO - Usar esta validación
          desperdicio: desperdicio.percentageExtraContenido,
          desperdicioExtraInclusion: desperdicio.percentageExtraInclusion,
          inco1: element.inco1,
          inco2: element.inco2
        });
        this.rawMaterialCostTwo += finalPrice;
      } else if (element.moneda == 'USD') {
        finalPrice = price * this.dollarPrice;
        this.rawMaterialCostDetailsTypeTwo.push({
          globalMerm: this.globalMerm,
          name: element.nombre,
          price: finalPrice,
          no_convertion_price: price,
          sap: element.codigoSap || element.sap,
          proportion: element.factorKg,
          factorKg: element.factorKg,
          precioNeto: element.precioNeto,
          moneda: element.moneda,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          isInclusion: desperdicio.isInclusion,
          desperdicio: desperdicio.percentageExtraContenido,
          desperdicioExtraInclusion: desperdicio.percentageExtraInclusion,
          inco1: element.inco1,
          inco2: element.inco2
        });
        this.rawMaterialCostTwo += finalPrice;
      } else {
        finalPrice = price * this.euroPrice;
        this.rawMaterialCostDetailsTypeTwo.push({
          globalMerm: this.globalMerm,
          name: element.nombre,
          price: finalPrice,
          no_convertion_price: price,
          sap: element.codigoSap || element.sap,
          proportion: element.factorKg,
          factorKg: element.factorKg,
          precioNeto: element.precioNeto,
          moneda: element.moneda,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          isInclusion: desperdicio.isInclusion,
          desperdicio: desperdicio.percentageExtraContenido,
          desperdicioExtraInclusion: desperdicio.percentageExtraInclusion,
          inco1: element.inco1,
          inco2: element.inco2
        });
        this.rawMaterialCostTwo += finalPrice;
      }
      this.arrWaste.push({
        name: element.nombre,
        isInclusion: desperdicio.isInclusion,
        desperdicioExtraContenido: desperdicio.percentageExtraContenido,
        desperdicioExtraContenidoInclusion: desperdicio.percentageExtraInclusion,
      });
      this.arrCostsStructure.push({
        codSap: element.sap || element.codigoSap,
        material: element.nombre,
        factorKg: element.factorKg,
        precioNeto: element.precioNeto,
        costoKg: finalPrice,
        moneda: element.moneda,
        status: element.status,
        register: element.registro,
        valid: element.validez,
        desperdicio: desperdicio,
        inco1: element.inco1,
        inco2: element.inco2,
        factorIncoterm: element.factorIncoterm,
      });
      this.rawMaterialCostDetails.push({
        name: element.nombre,
        sap: element.sap || element.codigoSap,
        register: element.registro,
        valid: element.validez,
        status: element.status,
      });
      this.arrMaterialRegisters.push(element.registro);
      // this.listFactorIncoterm(element.nombre, element.factorIncoterm, element.inco1, element.inco2);
    }
  }

  async priceFinalWaste(priceMaterial: { percentage: any; finalPrice: any }) {
    const desperdicio = await this.getDesperdicio();
    const finalPrice = priceMaterial.finalPrice + (1 + desperdicio.extraContenido);
    return {
      isInclusion: desperdicio.inclusion,
      percentageExtraContenido: desperdicio.extraContenido,
      percentageExtraInclusion: desperdicio.extraContenidoInclusion,
      finalPrice: finalPrice,
    };
  }

  async getDesperdicio() {
    const formaId = this.currentFinishedIngredient.shapePick;
    const inclusions = await this.validateInclusions();
    const hasInclusions = inclusions.inclusions.length > 0 ? 'SI' : 'NO';
    const item = this.wastePlantInclusion.find(
      (item: any) => item.idForma === formaId && item.inclusion === hasInclusions
    );
    if (item == undefined) {
      return {
        inclusion: 0,
        extraContenido: 0,
        extraContenidoInclusion: 0
      };
    }
    return {
      inclusion: item.inclusion,
      extraContenido: item.extraContenido,
      extraContenidoInclusion: item.extraContenidoInclusion,
    };
  }

  saveDataList(element: any, finalPrice: any, nombreMateria: any, desperdicio: any) {
    this.rawMaterialCostDetailsTypeOne.push({
      name: nombreMateria,
      price: finalPrice,
      precioNeto: Math.round(element.precioNeto),
      sap: element.sap || element.codigoSap,
      moneda: element.moneda,
      proportion: element.factorKg || element.porcentaje || element.proportion,
      desperdicio: desperdicio.percentageExtraContenido || 0,
      desperdicioExtraInclusion: desperdicio.percentageExtraInclusion || 0,
      isInclusion: desperdicio.isInclusion,
      fullWaste: desperdicio,
      factorKg: element.factorKg || element.porcentaje || element.proportion,
      globalMerm: element.globalMerm
    });
    this.rawMaterialCostDetails.push({
      name: nombreMateria,
      sap: element.sap || element.codigoSap,
      register: element.registro,
      valid: element.validez,
      status: element.status,
    });
    this.arrWaste.push({
      name: nombreMateria,
      desperdicioExtraContenido: desperdicio.percentage,
      desperdicioExtraContenidoInclusion: desperdicio.percentageExtraInclusion,
    });
    this.arrCostsStructure.push({
      codSap: element.sap || element.codigoSap,
      material: nombreMateria,
      factorKg: element.factorKg || element.porcentaje || element.proportion,
      precioNeto: element.precioNeto,
      costoKg: finalPrice,
      moneda: element.moneda,
      status: element.status,
      register: element.registro,
      valid: element.validez,
      desperdicio: desperdicio,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
  }

  async getManufacturingCost(pickedInclusions:any) {
    const shapeId = this.currentFinishedIngredient.shapePick;
    const packageId = this.currentFinishedIngredient.combEmpaque;
    let productFinished = '';
    let chocolateType = '';
    if (this.currentFinishedIngredient.chocolatePick) {
      chocolateType = this.currentFinishedIngredient.chocolatePick.tipo;
    } else {
      chocolateType = this.currentFinishedIngredient.bespoke.type;
    }
    await this.manufactureCombService.getCombinationsBySelections(
      shapeId,
      packageId,
      chocolateType,
      pickedInclusions.inclusion,
      productFinished
    );
    let manufacturingCost = this.manufactureCombService.manufacturingCost;
    this.manufacturingCosts = manufacturingCost;
    return manufacturingCost.zmod +
            manufacturingCost.zman +
            manufacturingCost.zgas +
            manufacturingCost.zdep +
            manufacturingCost.zenr +
            manufacturingCost.zoin +
            manufacturingCost.maquila;
  }

  async getPackagingCombinationCost() {
    const combId = this.currentFinishedIngredient.combEmpaque;
    let packagingCost = 0
    await this.packagingService.getPackagingCombinationCosts(combId, this.weight);
    const packagesCosts = this.packagingService.packagingCosts;
    let finalPrice: any = 0;
    for (const element of packagesCosts) {
      if (element.moneda == 'COP') {
        finalPrice = element.costoKg;
        this.packagesCostsDetails.push({
          name: element.nombre,
          moneda: element.moneda,
          price: element.costoKg,
          sap: element.codigoSap,
          units: element.unidades,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          desperdicio: element.calculoDesperdicio,
          desperdicios: element.desperdicios,
          inco1: element.inco1,
          inco2: element.inco2,
          precio_neto: element.precioNeto,
          factorKg: element.factorKg
        });
        packagingCost += element.costoKg;
      } else if (element.moneda == 'USD') {
        let conversionCosto = element.costoKg *this.dollarPrice;
        finalPrice = conversionCosto;
        this.packagesCostsDetails.push({
          name: element.nombre,
          moneda: element.moneda,
          price: conversionCosto,
          no_convertion_price: element.costoKg,
          sap: element.codigoSap,
          units: element.unidades,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          finalPrice: conversionCosto,
          desperdicio: element.calculoDesperdicio,
          desperdicios: element.desperdicios,
          inco1: element.inco1,
          inco2: element.inco2,
          precio_neto: element.precioNeto,
          factorKg: element.factorKg
        });
        packagingCost += conversionCosto;
      } else {
        let conversionCosto = element.costoKg *this.euroPrice;
        finalPrice = conversionCosto;
        this.packagesCostsDetails.push({
          name: element.nombre,
          moneda: element.moneda,
          price: conversionCosto,
          no_convertion_price: element.costoKg,
          sap: element.codigoSap,
          units: element.unidades,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          finalPrice: conversionCosto,
          desperdicio: element.calculoDesperdicio,
          desperdicios: element.desperdicios,
          inco1: element.inco1,
          inco2: element.inco2,
          precio_neto: element.precioNeto,
          factorKg: element.factorKg
        });
        packagingCost += conversionCosto;
      }
    }
    return packagingCost
  }

  openModalProductInfo(index:number) {
    this.sms.addModal(ModalProductDetailsComponent,
      {product: this.productsList[index]},
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe((res: any) => {
    });
  }

  consultDollar() {
    this.moneyService.returnArrayDollar().then((res: any) => {
      this.dollarPrices.push(res.value);
      if (this.dollarPrice != res.value) {
        this.dollarPrices.push(this.dollarPrice);
      }
      this.anotherDolarPrice = false
    },
    error => {
      this.dollarPrices.push(this.dollarPrice);
      this.anotherDolarPrice = false
    });
  }

  getCocoaPrices() {
    this.moneyService.getCocoaPrices().subscribe((res: any) => {
      if (res) {
        let keysToIterate = Object.keys(res)
        for (let key of keysToIterate) {
          let cleanedPrice = res[key]["Último"].substring(0, res[0]["Último"].length-1)
          this.cocoaPricesObjects.push({month:res[key].Mes,price:+cleanedPrice})
          this.cacaoOnlyPrices.push(+cleanedPrice)
        }
        if (!this.cacaoOnlyPrices.includes(this.cacaoPrice)) {
          this.cocoaPricesObjects.push({month:"Cash", price:this.cacaoPrice})
          this.cacaoOnlyPrices.push(this.cacaoPrice)
        }
        this.getMonthCocoaPrice()
        this.anotherCocoaPrice = false
        
      }
      }, 
  error => {
    if (!this.cacaoOnlyPrices.includes(this.cacaoPrice)) {
      this.cocoaPricesObjects.push({month:"Cash", price:this.cacaoPrice})
      this.cacaoOnlyPrices.push(this.cacaoPrice)
    }
    this.getMonthCocoaPrice()
    this.anotherCocoaPrice = false
  });
  }

  /**This function brings the data from the waste tables to calculate in raw materials  */
  async getWastePlant() {
    this.wastePlantMerm = await this.desperdiciosService.getWasteMerm();
    this.wastePlantInclusion = await this.desperdiciosService.getWasteExtraInclusion();
  }

  async getMonthCocoaPrice(){
    for (let cacao of this.cocoaPricesObjects) {
      if (cacao.price==this.cacaoPrice.toString()) {
        this.selectedMonth = cacao.month
      }
    }
  }

  async updatePricesValues() {
    this.canUpdatePrices=true
  }

}
