<div class="main-container">
  <section class="content">
    <div style="overflow-y: scroll; height: 100%;">
      <p class="added-products">Added products</p>
      <div class="container__added-products">
        <div class="form-table">
          <table aria-describedby="form table">
            <thead>
              <tr>
                <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container [formGroup]="tableForm">
                <ng-container formArrayName="form_array">
                  <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
                    <td>
                      <div class="row-actions">
                          <img src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                          <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="openModalProductInfo(iForm)">
                      </div>
                    </td>
                    <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                      <div>
                        <div >
                          <input class="input-table" *ngIf="(property != 'quantity_kg' && property != 'price_usd' && property != 'price_usd_pound' && property != 'gross_margin' && property != 'ebitda' && property != 'price_cop' && property != 'total_cost')"  [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                          <input class="input-table" *ngIf="(property != 'quantity_kg' && property != 'price_usd' && property != 'price_usd_pound' && property != 'gross_margin' && property != 'ebitda' && (property == 'price_cop' || property == 'total_cost'))" currencyMask [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                          <input *ngIf="(property == 'price_usd') || (property == 'price_usd_pound') || (property == 'gross_margin') || (property == 'ebitda') || (property == 'quantity_kg')" 
                            appTwoDigitDecimaNumber
                            class="white-background" 
                            type="text" pInputText placeholder="Enter price" [readonly]="false" [formControlName]="property" id="input-{{ property }}">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td colspan="3">
                      Total
                      
                    </td>
                    <td  colspan="7">
                      <div>{{ totalCost|number:'1.0-0':'en-US' }}</div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <p class="added-products" style="padding: 0px;">Additional costs</p>
        <div class="container__additional-costs">
          <div class="container__price">
            <div class="container__input">
                <label htmlFor="clientname">Freight</label>
                <input placeholder="Type a cocoa premium price" currencyMask [formControl]="freight" styleClass="primeng-input" (ngModelChange)="updateAdditionalCosts()" [readOnly]="false" pInputText/>
            </div>
          </div>
          <div class="container__price">
            <div class="container__input">
              <label htmlFor="clientname">Insurance</label>
              <input placeholder="Type a cocoa premium price" currencyMask [formControl]="insurance" styleClass="primeng-input" (ngModelChange)="updateAdditionalCosts()" [readOnly]="false" pInputText/>
            </div>
          </div>
          <div class="container__price">
            <div class="container__input">
                <label htmlFor="clientname">Others</label>
                <input placeholder="Type a cocoa premium price" currencyMask [formControl]="others" styleClass="primeng-input" (ngModelChange)="updateAdditionalCosts()" [readOnly]="false" pInputText/>
            </div>
          </div>
        </div>
        <p class="added-products" style="padding: 0px;">Logistics Expenses</p>
        <div class="container__logistics-expenses">
          <div class="container__price">
            <div class="container__input">
                <label htmlFor="clientname">National freight</label>
                <input placeholder="Type a cocoa premium price" currencyMask [formControl]="national_freight" styleClass="primeng-input" (ngModelChange)="updateLogisticsExpenses()" [readOnly]="false" pInputText/>
            </div>
          </div>
          <div class="container__price">
            <div class="container__input">
              <label htmlFor="clientname">Travel expenses</label>
              <input placeholder="Type a cocoa premium price" currencyMask [formControl]="travel_expenses" styleClass="primeng-input" (ngModelChange)="updateLogisticsExpenses()" [readOnly]="false" pInputText/>
            </div>
          </div>
          <div class="container__price">
            <div class="container__input">
                <label htmlFor="clientname">Custom expenses</label>
                <input placeholder="Type a cocoa premium price" currencyMask [formControl]="custom_expenses" styleClass="primeng-input" (ngModelChange)="updateLogisticsExpenses()" [readOnly]="false" pInputText/>
            </div>
          </div>
        </div>
        <p class="added-products" style="padding: 0px;">Comments</p>
        <div class="container__comments" style="margin-bottom: 130px;">
          <textarea placeholder="Input text" rows="5" cols="50" [formControl]="comments" (ngModelChange)="saveComments()" pInputTextarea>
          </textarea>
        </div>
      </div>
    </div>
  </section>

</div>
