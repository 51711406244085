<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card-search">
      <div class="d-flex justify-content-center">
        <h3>Search raw material one</h3>
      </div>
      <div class="input-search">
        <label>Select dollar</label>
        <div class="main-input">
          <select [(ngModel)]="selectedDollar" *ngIf="!otherDollar">
            <option value="{{ dollarPrices.value }}">
              {{ dollarPrices.value }}
            </option>
          </select>
          <input type="number" placeholder="Enter dollar price" [(ngModel)]="selectedDollar" *ngIf="otherDollar" />
        </div>
        <a (click)="otherDollar = !otherDollar">Other dollar price</a>
      </div>
      <div class="input-search mt-3">
        <label>Select cacao price</label>
        <div class="main-input">
          <select [(ngModel)]="selectedCocoa" *ngIf="!otherCacao">
            <option value="{{ item.value }}" *ngFor="let item of cocoaPrices">
              {{ item.label }}
            </option>
          </select>
          <input type="number" placeholder="Enter cacao price" [(ngModel)]="selectedCocoa" *ngIf="otherCacao" />
        </div>
        <a (click)="otherCacao = !otherCacao">Other cacao price</a>
      </div>
      <div class="input-search mt-3">
        <label>Consult by SAP code</label>
        <div class="main-input">
          <input type="text" placeholder="Consult" [(ngModel)]="sapCode" />
          <fa-icon style="cursor: pointer" [icon]="faMagnifyingGlass" (click)="openModal()"></fa-icon>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn-search"
          (click)="searchPriceRawMaterial()"
          [disabled]="selectedCocoa === '' || selectedCocoa === '' || sapCode === ''">
          Search
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn-search-other"
          (click)="searchAllRawMaterial()"
          [disabled]="selectedCocoa === '' || selectedCocoa === '' || sapCode !== ''">
          Show costs
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card-prices">
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="d-flex justify-content-center align-items-center h-100" *ngIf="!loading && !validationTypeConsult">
        <div class="container-prices">
          <h4 class="text-center">{{ nameRawMaterial }}</h4>
          <h5 class="text-center" *ngIf="priceRawMaterial">
            $ {{ priceRawMaterial | number: '1.0-0' }}
          </h5>
          <h5 *ngIf="messageError  !== ''">{{ messageError }}</h5>
        </div>
      </div>
      <div *ngIf="!loading && validationTypeConsult">
        <h3 class="text-center">Summary prices</h3>
        <table class="table-prices table-bordered mt-4">
          <caption style="display: none">Prices raw material</caption>
          <tr>
            <th class="yellow-title" scope="custom">Price Cacao</th>
            <th scope="custom">$ {{allCostRawMaterial?.precioCacao | number: '1.0-0'}}</th>
          </tr>
          <tr>
            <th class="yellow-title" scope="custom">Price Cocoa</th>
            <th scope="custom">$ {{allCostRawMaterial?.precioCocoa | number: '1.0-0'}}</th>
          </tr>
          <tr>
            <th class="yellow-title" scope="custom">Price Licor</th>
            <th scope="custom">$ {{allCostRawMaterial?.precioLicor | number: '1.0-0'}}</th>
          </tr>
          <tr>
            <th class="yellow-title" scope="custom">Price Manteca</th>
            <th scope="custom" >$ {{allCostRawMaterial?.precioManteca | number: '1.0-0'}}</th>
          </tr>
          <tr>
            <th class="yellow-title" scope="custom">Price Manteca Desodorizada</th>
            <th scope="custom" >$ {{allCostRawMaterial?.precioMantecaDeso | number: '1.0-0'}}</th>
          </tr>
          <tr>
            <th class="yellow-title" scope="custom">Price Café Colombiano</th>
            <th scope="custom" >$ {{allCostRawMaterial?.precioCafeColombiano | number: '1.0-0'}}</th>
          </tr>
        </table>
        <h5 *ngIf="messageError  !== ''">{{ messageError }}</h5>
      </div>
    </div>
  </div>
</div>
