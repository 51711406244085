import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import { faL, faPencil } from '@fortawesome/free-solid-svg-icons';

import jsPDF from 'jspdf';

import * as XLSX from 'xlsx';

import * as moment from 'moment';

import { SimpleModalService } from 'ngx-simple-modal';

import { PgService } from 'src/app/core/services/tablepg/pg.service';
import { DesperdiciosService } from 'src/app/core/services/desperdicios-planta/desperdicios.service';
import { CubicPackaging } from 'src/app/core/interfaces/cubic-packaging';
import { ProjectComexService } from 'src/app/core/services/project-comex/project-comex.service';

import { NgxSpinnerService } from 'ngx-spinner';

import domtoimage from 'dom-to-image';

import ls from 'localstorage-slim';

import { AseoService } from 'src/app/core/services/aseo/aseo.service';
import { AseoType } from 'src/app/core/services/aseo/aseo.type';

import { forEach } from 'lodash';

import { Images } from '../../core/interfaces/images';
import { AseoDb } from '../../core/services/aseo/aseo.db';
import { AuthService } from './../../core/services/auth/auth.service';
import { BespokeFormulaService } from '../../core/services/bespoke-formula/bespoke-formula.service';
import { CostService } from '../../core/services/costs/cost.service';
import { ManufactureCombinationService } from '../../core/services/manufacture-combination/manufacture-combination.service';
import { MarginService } from '../../core/services/margin/margin.service';
import { MaterialListService } from '../../core/services/material-list/material-list.service';
import { NutritionTableService } from '../../core/services/nutrition-table/nutrition-table.service';
import { PackagingService } from '../../core/services/packaging/packaging.service';
import { ProductService } from '../../core/services/product/product.service';
import { ProjectService } from '../../core/services/project/project.service';
import { RegisterInfoService } from '../../core/services/register-info/register-info.service';
import { SalesforceServicesService } from '../../core/services/salesforce/salesforce-services.service';
import { WebSocketService } from '../../core/services/web-socket/web-socket.service';

import { ChangeDataModalComponent } from './change-data-modal/change-data-modal.component';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { ModalEditPricesComponent } from './modal-edit-prices/modal-edit-prices.component';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { ProjectNameModalComponent } from './project-name-modal/project-name-modal.component';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.3s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ProductSummaryComponent implements OnInit {
  /** Trigger to show the manufacture cost details. */
  displayManufactureDetails = false;

  /** Trigger to show the variable cost details. */
  displayVariableCostDetails = false;
  /** Trigger to show the variable expends details. */
  displayVariableExpendsDetails = false;

  /** Trigger to show the fixed direct details. */
  displayFixedDirectDetails = false;

  /** Trigger to show the fixed details. */
  displayFixedDetails = false;

  /** Trigger to show the raw material cost details. */
  displayRawMaterialDetails = false;
  displayRawMaterialDetailsTwo = false;

  /** Trigger to show the packaging combination cost details. */
  displayPackagingDetails = false;

  /** Trigger to show the packaging combination cost details. */
  displayInclusionDetails = false;

  /** Auxiliar attribute to change the brief shown. */
  briefType = 'client';

  /** Auxiliar attribute to calculate the manufacture cost. */
  manufactureCost = 0;

  /** Auxiliar attribute to calculate the raw material cost. */
  rawMaterialCost = 0;
  rawMaterialCostTwo = 0;
  rawMaterialCostOne = 0;
  rawMaterialCostOneBespoke = 0;

  /** Auxiliar attribute to calculate the packaging combination cost. */
  packagingCost = 0;

  /** Auxiliar attribute to calculate the inclusions and flavors total cost. */
  inclusionsTotal = 0;

  /** The project brief of the current session. */
  projectBrief: any = {};

  /**This array holds the brief object  */
  projectBriefArr: any[] = [];

  /**This array holds the comments  */
  listComments: any[] = [];

  /**This array hold the artist selected */
  artistSelected: any[] = [];

  /** Dollar price in COP, cacao price, additional cacao bonus and weight scale. */
  costParameters: any = {};

  /** The options previously selected by the user as a full ingredient. */
  finishedIngredient: any = {};

  /** The design inspiration selections made by the user. */
  designSelections: any = {};

  /** Object that stores all the Z items costs per material. */
  manufacturingCost: any;
  manufacturingCosts: any[] = [];

  /** Object that stores the costs of the type one raw material. */
  rawMaterialTypeOneCosts: any;

  /** This object stores the inclusions and flavor selected by the user.  */
  pickedInclusions: any;

  /** Object that stores all the type 1.1 raw material items costs per material. */
  rawMaterialsTypeOne: any[] = [];

  /** Object that stores all the type 2 raw material items costs per material. */
  rawMaterialsTypeTwo: any[] = [];

  flagInputChangePrice: any;

  /** Name and costs of the raw materials in COP. */
  rawMaterialCostDetails: any[] = [];
  rawMaterialCostDetailsTypeTwo: any[] = [];
  rawMaterialCostDetailsTypeOne: any[] = [];

  /** Object that stores all the packages costs by combination package. */
  packagesCosts: any;

  /** Name and costs of the packages in COP. */
  packagesCostsDetails: any[] = [];

  /** List of the comments made by the user while using the creation flow. */
  comments: any = {};

  goBeyondProjects: any = {};
  listGoBeyondProjects: any[] = [];

  /** Name and costs of the inclusions and flavors in COP. */
  inclusionsCostsDetails: any[] = [];

  /** This array contains all the expired registers if any ingredient has expired status. */
  expiredRegisters: any[] = [];

  /**Table of costs p&g */
  costsPg: any;
  /**All information of the p&g */
  listDataPg: any;
  listDataPgOperationsCostVariables: any;
  listDataPgOperationsVariableExpends: any;
  listDataPgOperationsFixedDirect: any;
  listDataPgOperationsFixed: any;
  listDataPgOperationsFinal: any;
  /**Important variables in p&g, they are used to add values and subtract */
  netSales: any;

  /**This variable contain the data of waste  */
  wastePlantMerm: any;
  /**This variable contain the data of extra content waste*/
  wastePlantInclusion: any;

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  /** This object stores the prices of the product multiplied by the margin. */
  prices: any = {};

  /**This array stores the price objects multiplied by the margin*/
  pricesArr: any[] = [];

  /**This variable is the name of the file to export to excel */
  currentDate = new Date();
  formattedDate = this.currentDate.toISOString().replace(/[-T:]/g, '_').slice(0, -5);
  fileName = 'Brief_' + this.formattedDate + '.xlsx';

  /**This interface contain all data of cubic packaging */
  interfaceDataCubic = {} as CubicPackaging;

  /**Prices/kg fixed */
  pricesKgFixed: any;

  /**Array of waste in raw materials */
  arrWaste: any[] = [];

  /**Array of raw material waste */
  arrMaterialRegisters: any[] = [];

  /**Information of project comex */
  informationTransporting: any;

  /**price of custom expenses in comex */
  pricesUsd: any;

  /**Price of cop filtered in comex */
  transportDataFilter: any;

  /**Total operation in data of comex */
  totalOperationShipping: any;

  /**Price transport in usd */
  priceUsdTransporting: any;
  /**Price transport in Cop */

  priceCopTransporting: any;

  /**Merm waste in mp1 mp2 */
  mermWaste: any;

  costPerKg: any;
  /**List used in export excel */
  arrCostsStructure: any[] = [];
  arrManuFacturing: any[] = [];
  arrFactorIncoterm: any[] = [];
  arrWastePackaging: any[] = [];
  arrCostsPg: any[] = [];
  dateEarly: any = Date.now();

  /**Images convert in canvas for use in export pdf */
  interfaceImageCanvas = {} as Images;

  /** Icons **/
  faPencil = faPencil;

  origin: any;

  totalNutritionalCost: any;

  typeUser: any;
  sapCodeProductFinished: any;
  nameProductFinished: any;
  messageMissingData = false;

  imagesPackaging: any[] = [];

  optionsSelect = [
    {
      name: 'Project Brief',
      activated: true,
      briefType: true,
      redirect: '#project-brief',
    },
    {
      name: 'My Claims',
      activated: false,
      briefType: true,
      redirect: '#my-claims',
    },
    {
      name: 'Costs Structure',
      activated: false,
      briefType: false,
      redirect: '#costs-structure',
    },
    {
      name: 'Pricing (USD)',
      activated: false,
      briefType: true,
      redirect: '#pricing-usd',
    },
    {
      name: 'Shipping Cost',
      activated: false,
      briefType: true,
      redirect: '#shipping-cost',
    },
    // {
    //   name: 'Product Description',
    //   activated: false,
    //   briefType: false,
    //   redirect: '#product-description',
    // },
    // {
    //   name: 'Impact Summary',
    //   activated: false,
    //   briefType: true,
    //   redirect: '#impact-summary',
    // },
    {
      name: 'Specifications',
      activated: false,
      briefType: false,
      redirect: '#specifications',
    },
    // {
    //   name: 'Nutritional Table',
    //   activated: false,
    //   briefType: false,
    //   redirect: '#nutritional-table',
    // },
  ];

  /** The data of the current live session. */
  sessionData: any = {
    active: false,
    role: 'admin',
  };

  arrInclusions: any[] = [];
  hideDataPdf = false;

  typeUserLogged: any;

  /** Custom certifications added for the user **/
  customClaims: any[] = [];

  otherCommentsValidation: any;
  otherComments = '';

  /**
   * Values used for aseos flow
   */
  globalMerm: number = 0;
  aseoGlobal: number = 0;
  aseoGlobalPersons: number = 0;
  aseoGlobalHours: number = 0;
  aseoIsCocoaPremium: boolean = false;

  @ViewChild('test', { static: false }) viewTest!: ElementRef;
  @ViewChild('project_brief', { static: false }) public project_brief!: ElementRef<any>;
  @ViewChild('my_claims', { static: false }) public my_claims!: ElementRef<any>;
  @ViewChild('pricing_usd', { static: false }) public pricing_usd!: ElementRef<any>;
  @ViewChild('shipping_cost', { static: false }) public shipping_cost!: ElementRef<any>;
  @ViewChild('costs_structure', { static: false }) public costs_structure!: ElementRef<any>;
  @ViewChild('specifications', { static: false }) public specifications!: ElementRef<any>;

  constructor(
    private manufactureCombService: ManufactureCombinationService,
    private materialListService: MaterialListService,
    private bespokeFormulaService: BespokeFormulaService,
    private registerInfoService: RegisterInfoService,
    private packagingService: PackagingService,
    private costService: CostService,
    private productService: ProductService,
    private projectService: ProjectService,
    private marginService: MarginService,
    private webSocketService: WebSocketService,
    private sms: SimpleModalService,
    private router: Router,
    private authService: AuthService,
    private pgService: PgService,
    private desperdiciosService: DesperdiciosService,
    private projectComexService: ProjectComexService,
    private nutritionTableService: NutritionTableService,
    private spinner: NgxSpinnerService,
    private salesForceService: SalesforceServicesService,
    private aseoService: AseoService
  ) {}

  async ngOnInit() {
    this.typeUserLogged = this.authService.getTypeUser();
    if (this.typeUserLogged != 'Feria') {
      this.spinner.show();
    }
    this.setCurrentFlow();
    this.loadProjectBrief();
    this.setSessionRole();
    this.loadUserSelections();
    this.loadImagesPackaging();
    if (this.currentFlow == 'product') this.loadDesignSelections();
    this.loadCostParameters();
    this.loadUserComments();
    this.loadGoBeyondProjects();
    await this.getWastePlant();
    await this.priceWaste();
    await this.getRawMaterialCost();
    await this.getManufacturingCost();
    await this.getPackagingCombinationCost();
    await this.loadBriefPrices();
    await this.getItemsTransporting();
    await this.loadPgInformation();
    this.validateExpiredRegisters();
    await this.operationsCostVariablesPg();
    await this.operationsVariableExpends();
    await this.operationsFixedDirect();
    await this.operationFixedPg();
    await this.operationFinalPg();
    this.loadPricesPackaging();
    this.tableNutritional();
    this.missingData();
    this.spinner.hide();
    await this.loadImages();
  }

  async getAseosValues(aseoType: string) {
    this.aseoService.getAseoByTypeAndProjectWeight(aseoType).then((response) => {
      this.aseoGlobal = response.data.valorAseoGlobal;
      this.aseoGlobalPersons = response.data.aseo.personas;
      this.aseoGlobalHours = response.data.aseo.horas;
    });
  }

  /** The information of the active user. */
  get activeUser() {
    return JSON.parse(ls.get('user', { decrypt: true }) || '{}');
  }

  /**
   * This function sets the session role of each participant of a live session.
   */
  setSessionRole() {
    if (this.webSocketService.active) {
      this.sessionData.active = this.webSocketService.active;
      this.sessionData.role = this.webSocketService.getRole;
    }
  }

  selectItem(item: any) {
    this.optionsSelect.forEach((item) => {
      item.activated = false;
    });
    this.optionsSelect[item].activated = true;
    setTimeout( () => {
      if ( this.optionsSelect[item].name=="Pricing (USD)") {
        this.pricing_usd.nativeElement.scrollIntoView()
      } else if( this.optionsSelect[item].name=="Project Brief"){
        this.project_brief.nativeElement.scrollIntoView()
      } else if( this.optionsSelect[item].name=="Shipping Cost"){
        this.shipping_cost.nativeElement.scrollIntoView()
      } else if (this.optionsSelect[item].name=="My Claims"){
        this.my_claims.nativeElement.scrollIntoView()
      } else if(this.optionsSelect[item].name=='Costs Structure') {
        this.costs_structure.nativeElement.scrollIntoView()
      } else {
        this.specifications.nativeElement.scrollIntoView()
      }
    }, 300);
  }

  /**
   * This function loads the project brief of the current project.
   */
  loadProjectBrief() {
    this.projectBrief = JSON.parse(ls.get('brief', { decrypt: true }) || '{}');
    this.customClaims = this.projectBrief.customClaims || [];
    this.otherComments = this.projectBrief.otherComments || '';
    this.loadDataProjectBrief();
    this.projectBrief.pound = this.projectBrief.peso * 2.2;
  }

  loadDataProjectBrief() {
    let shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    let packaging = JSON.parse(localStorage.getItem('packaging') || '{}');
    let chocolate = JSON.parse(localStorage.getItem('chocolate') || '{}');
    let user = this.activeUser;
    this.typeUser = user.tipo;
    this.projectBriefArr.push(
      { '': 'Cliente', ' ': this.projectBrief.cliente },
      { '': 'Usuario', ' ': user.nombre },
      { '': 'Nombre', ' ': this.projectBrief.nombre },
      { '': 'Producto', ' ': chocolate.chocolateId },
      { '': 'Fecha', ' ': this.projectBrief.fecha },
      { '': 'Pais', ' ': this.projectBrief.pais },
      { '': 'Peso', ' ': this.projectBrief.peso },
      { '': 'Precio cacao', ' ': this.projectBrief.precioCacao },
      { '': 'Precio dolar', ' ': this.projectBrief.precioDolar },
      { '': 'Chocolate', ' ': chocolate.texto },
      { '': 'Shape', ' ': shape.texto },
      { '': 'Packaging', ' ': packaging.empaque1?.descripcion },
      { '': 'Packaging', ' ': packaging.empaque2?.descripcion },
      { '': 'Base inclusion', ' ': this.finishedIngredient.inclusions?.base },
      { '': 'Flavor', ' ': this.finishedIngredient.flavors?.base },
      { '': 'Design', ' ': this.designSelections.design }
    );
  }

  /**This function load the costs of p&g */
  async loadPgInformation() {
    this.costsPg = await this.pgService.loadPgInformation();
  }

  /**async function uses a service to fetch all the data from the first part of the p&g */
  async operationsCostVariablesPg() {
    this.netSales = (this.prices.priceKg / this.costParameters.trm) * this.costParameters.trm;
    this.listDataPgOperationsCostVariables = this.pgService.operationsCostVariablesPg(
      this.netSales,
      this.manufacturingCost.zenr,
      this.manufacturingCost.zgas,
      this.rawMaterialCostOne || this.rawMaterialCostOneBespoke,
      this.rawMaterialCostTwo,
      this.packagingCost
    );
    this.dataPgListOne();
    return this.listDataPgOperationsCostVariables;
  }

  /**async function uses a service to fetch all the data from the second part of the p&g */
  async operationsVariableExpends() {
    await this.loadPgInformation();
    this.listDataPgOperationsVariableExpends = this.pgService.operationsVariableExpends(
      this.projectBrief.pais,
      this.netSales,
      this.costsPg.industryCommerceNational,
      this.costsPg.taxThousandNational,
      this.costsPg.briefcaseNational,
      this.costsPg.inventoriesMpNational,
      this.costsPg.inventoriesMfNational,
      this.listDataPgOperationsCostVariables[0].energy,
      this.manufacturingCost.zgas,
      this.manufacturingCost.zmod,
      this.manufacturingCost.zman,
      this.manufacturingCost.zoin,
      this.costsPg.providersNational,
      this.listDataPgOperationsCostVariables[0].packaging,
      this.rawMaterialCostOne || this.rawMaterialCostOneBespoke,
      this.rawMaterialCostTwo,
      this.costsPg.monthlyRateNational,
      this.costsPg.monthlyRateInternational,
      this.costsPg.industryCommerceInternational,
      this.costsPg.taxThousandExport,
      this.costsPg.briefcaseInternational,
      this.costsPg.inventoriesMpInternational,
      this.costsPg.inventoriesMfInternational,
      this.costsPg.providersInternational,
      this.listDataPgOperationsCostVariables[0].contribVariable,
      this.costPerKg || 0
    );
    this.dataPgListTwo();
    return this.listDataPgOperationsVariableExpends;
  }

  /**async function uses a service to fetch all the data from the third part of the p&g */
  async operationsFixedDirect() {
    await this.loadPgInformation();
    this.listDataPgOperationsFixedDirect = this.pgService.operationsFixedDirect(
      this.projectBrief.pais,
      this.netSales,
      this.costsPg.LogisticsWarehouseSaleNational,
      this.costsPg.marketingNational,
      this.manufacturingCost.zdep,
      this.manufacturingCost.zmod,
      this.manufacturingCost.zman,
      this.manufacturingCost.zoin,
      this.listDataPgOperationsVariableExpends[0].contribFijDirec,
      this.costsPg.LogisticsWarehouseSaleInternational,
      this.costsPg.marketingInternational
    );
    this.dataPgListThree();
    return this.listDataPgOperationsFixedDirect;
  }

  /**async function uses a service to fetch all the data from the fourth part of the p&g */
  async operationFixedPg() {
    await this.loadPgInformation();
    this.listDataPgOperationsFixed = this.pgService.operationFixedPg(
      this.projectBrief.pais,
      this.netSales,
      this.costsPg.sellingExpensesNational,
      this.costsPg.groupVariableNational,
      this.costsPg.generalCompanyNational,
      this.listDataPgOperationsFixedDirect[0].contribuCostGast,
      this.costsPg.sellingExpensesInternational,
      this.costsPg.groupVariableInternational,
      this.costsPg.generalCompanyInternational
    );
    this.dataPgListFour();
    return this.listDataPgOperationsFixed;
  }

  /**async function uses a service to fetch all the data from the fifth part of the p&g */
  async operationFinalPg() {
    await this.loadPgInformation();
    this.listDataPgOperationsFinal = this.pgService.operationFinalPg(
      this.rawMaterialCostOne || this.rawMaterialCostOneBespoke,
      this.rawMaterialCostTwo,
      this.packagingCost,
      this.manufacturingCost.zenr,
      this.manufacturingCost.zdep,
      this.manufacturingCost.zmod,
      this.manufacturingCost.zman,
      this.manufacturingCost.zoin,
      this.listDataPgOperationsFixed[0].contribution,
      this.listDataPgOperationsVariableExpends[0].costCapital,
      this.netSales,
      this.listDataPgOperationsCostVariables[0].costosVariables
    );
    this.dataPgListFive();
    return this.listDataPgOperationsFinal;
  }

  dataPgListOne() {
    let pricesUsdKg = this.prices.priceKg / this.costParameters.trm;
    this.arrCostsPg.push(
      {
        variable: 'Price USD ($/KG)',
        valor: Math.round(pricesUsdKg),
        porcentaje: 0,
      },
      {
        variable: 'Price cop ($/KG)',
        valor: Math.round(this.netSales),
        porcentaje: 0,
      },
      {
        variable: 'Ventas netas',
        valor: Math.round(this.netSales),
        porcentaje: 0,
      },
      {
        variable: 'Costos variables',
        valor: Math.round(this.listDataPgOperationsCostVariables[0]?.costosVariables),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajeCostosVariables),
      },
      {
        variable: 'Mp1',
        valor: Math.round(this.rawMaterialCostOne),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajeMp1),
      },
      {
        variable: 'Mp2',
        valor: Math.round(this.rawMaterialCostTwo),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajeMp2),
      },
      {
        variable: 'Empaque',
        valor: Math.round(this.packagingCost),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajePackaging),
      },
      {
        variable: 'Energia',
        valor: Math.round(this.listDataPgOperationsCostVariables[0]?.energy),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajeEnergia),
      },
      {
        variable: 'Contrib variable',
        valor: Math.round(this.listDataPgOperationsCostVariables[0]?.contribVariable),
        porcentaje: Math.round(this.listDataPgOperationsCostVariables[0]?.porcentajeContribVariable),
      }
    );
  }

  dataPgListTwo() {
    this.arrCostsPg.push(
      {
        variable: 'Variable expends',
        valor: Math.round(this.listDataPgOperationsVariableExpends[0]?.variableExpends),
        porcentaje: Math.round(this.listDataPgOperationsVariableExpends[0]?.porcentajeVariableExpends),
      },
      { variable: 'Ica', valor: 0, porcentaje: 0 },
      {
        variable: '4 por mil',
        valor: Math.round(this.listDataPgOperationsVariableExpends[0]?.taxThousand),
        porcentaje: Math.round(this.listDataPgOperationsVariableExpends[0]?.porcentajeTaxThousand),
      },
      {
        variable: 'Distribución - comex',
        valor: Math.round(this.listDataPgOperationsVariableExpends[0]?.distribucionComex),
        porcentaje: Math.round(this.listDataPgOperationsVariableExpends[0]?.porcentajeDistribucionComex),
      },
      {
        variable: 'Costo Capital',
        valor: Math.round(this.listDataPgOperationsVariableExpends[0]?.costCapital),
        porcentaje: Math.round(this.listDataPgOperationsVariableExpends[0]?.porcentajeCostCapital),
      },
      {
        variable: 'Contrib a los fijos direc',
        valor: Math.round(this.listDataPgOperationsVariableExpends[0]?.contribFijDirec),
        porcentaje: Math.round(this.listDataPgOperationsVariableExpends[0]?.porcentajeContribFijDirec),
      }
    );
  }

  dataPgListThree() {
    this.arrCostsPg.push(
      {
        variable: 'Fijos directos',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.fijosDirectos),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeFijosDirectos),
      },
      {
        variable: 'Logistica Almacen',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.logisticWarehouse),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeLogisticWarehouse),
      },
      {
        variable: 'Mercadeo',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.marketing),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeMarketing),
      },
      {
        variable: 'Depreciación',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.depreciacion),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeDepreciacion),
      },
      {
        variable: 'Mod',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.mod),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeMod),
      },
      {
        variable: 'Mantemiento',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.mantenimiento),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeMantenimiento),
      },
      {
        variable: 'MOI y Otros CIF',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.zoin),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeZoin),
      },
      {
        variable: 'Contribu a los cost y Gastos',
        valor: Math.round(this.listDataPgOperationsFixedDirect[0]?.contribuCostGast),
        porcentaje: Math.round(this.listDataPgOperationsFixedDirect[0]?.porcentajeContribCostGast),
      }
    );
  }

  dataPgListFour() {
    this.arrCostsPg.push(
      {
        variable: 'Fijos',
        valor: Math.round(this.listDataPgOperationsFixed[0]?.fixed),
        porcentaje: Math.round(this.listDataPgOperationsFixed[0]?.porcentajeFixed),
      },
      {
        variable: 'Gastos de ventas',
        valor: Math.round(this.listDataPgOperationsFixed[0]?.gastosVentas),
        porcentaje: Math.round(this.listDataPgOperationsFixed[0]?.porcentajeGastosVentas),
      },
      {
        variable: 'Granja+Desarrollo + Innova + py social',
        valor: Math.round(this.listDataPgOperationsFixed[0]?.groupVariable),
        porcentaje: Math.round(this.listDataPgOperationsFixed[0]?.porcentajeGroupVariable),
      },
      {
        variable: 'Administrativos',
        valor: Math.round(this.listDataPgOperationsFixed[0]?.administrative),
        porcentaje: Math.round(this.listDataPgOperationsFixed[0]?.porcentajeAdministrative),
      },
      {
        variable: 'Contribucion',
        valor: Math.round(this.listDataPgOperationsFixed[0]?.contribution),
        porcentaje: Math.round(this.listDataPgOperationsFixed[0]?.porcentajeContribucion),
      }
    );
  }

  dataPgListFive() {
    this.arrCostsPg.push(
      {
        variable: 'Ebitda',
        valor: Math.round(this.listDataPgOperationsFinal[0]?.ebitda),
        porcentaje: Math.round(this.listDataPgOperationsFinal[0]?.porcentajeEbitda),
      },
      {
        variable: 'Margen bruto',
        valor: Math.round(this.listDataPgOperationsFinal[0]?.margenBruto),
        porcentaje: Math.round(this.listDataPgOperationsFinal[0]?.porcentajeMargenBruto),
      },
      {
        variable: 'Total Costo Producción',
        valor: Math.round(this.listDataPgOperationsFinal[0]?.totalCost),
        porcentaje: Math.round(this.listDataPgOperationsFinal[0]?.porcentajeTotal),
      }
    );
  }

  openInput() {
    this.flagInputChangePrice = true;
    let variablePrice;
    variablePrice = this.prices.priceKg / this.costParameters.trm;
    this.prices.priceKg = variablePrice;
  }

  async onChangePrice(event: any) {
    this.prices.priceKg = event.target.value * this.costParameters.trm;
    this.operationsCostVariablesPg();
    this.operationsVariableExpends();
    this.loadPgInformation();
    this.operationsFixedDirect();
    this.operationFixedPg();
    this.operationFinalPg();
  }

  savePriceKg() {
    this.pricesKgFixed = this.prices.priceKg;
  }

  resetPriceKg() {
    this.prices.priceKg = this.pricesKgFixed;
  }

  async getCustomsTransporting() {
    this.informationTransporting = JSON.parse(localStorage.getItem('objectTransporting') || '{}');
    await this.projectComexService.getCustomsTransporting().then((response) => {
      this.pricesUsd = response.filter(
        (item) =>
          item.puertoOrigen == this.informationTransporting.portSource &&
          item.tipoContenedor == this.informationTransporting.typeContainer
      );
      this.priceCopTransporting = this.pricesUsd[0]?.valorUsd * this.costParameters.trm;
    });
  }

  async getTransportData() {
    await this.projectComexService.getTransportData().then((response) => {
      this.transportDataFilter = response.filter(
        (item) =>
          item.puertoDestino == this.informationTransporting.portSource &&
          item.tipoContenedor == this.informationTransporting.typeContainer
      );
      this.priceUsdTransporting = this.transportDataFilter[0]?.valorCop;
    });
  }

  async getItemsTransporting() {
    if (this.projectBrief.id) {
      await this.getDataComexByProject();
    }
    await this.getCustomsTransporting();
    await this.getTransportData();
    let priceCopTrans = this.priceCopTransporting;
    let priceUsdTrans = this.priceUsdTransporting;
    let freightUsd = this.informationTransporting.priceUsd * this.costParameters.trm;
    this.totalOperationShipping =
      (priceCopTrans + priceUsdTrans + freightUsd) * this.informationTransporting.numberOfContainers;
    this.costPerKg = this.totalOperationShipping / this.projectBrief.peso;
  }

  /**
   * This function sets the current flow the user is in.
   */
  setCurrentFlow() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.currentFlow = 'product';
    } else if (split.includes('ready-to-launch')) {
      this.currentFlow = 'launch';
    } else if (split.includes('portfolio-products')) {
      this.currentFlow = 'portfolio';
    }
  }

  /**
   * This function loads the selections made by the user in previous step.
   */
  loadUserSelections() {
    const chocolate = JSON.parse(localStorage.getItem('chocolate') || '{}');
    const bespoke = JSON.parse(localStorage.getItem('bespoke') || '{}');
    const rtl = JSON.parse(localStorage.getItem('rtl') || '{}');
    const shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    const packaging = JSON.parse(localStorage.getItem('packaging') || '{}');
    const inclusions = JSON.parse(localStorage.getItem('inclusion') || '{}');
    const flavor = JSON.parse(localStorage.getItem('flavor') || '{}');
    this.finishedIngredient = {
      shapePick: shape,
      packagingPick: packaging,
    };
    if (Object.keys(chocolate).length > 0) {
      this.finishedIngredient.chocolatePick = chocolate;
    }
    if (Object.keys(bespoke).length > 0) {
      this.finishedIngredient.bespoke = bespoke;
    }
    if (this.currentFlow == 'launch' && Object.keys(rtl).length > 0) {
      this.finishedIngredient.rtl = rtl;
    }
    if (Object.keys(inclusions).length > 0) {
      this.finishedIngredient.inclusions = inclusions;
      this.arrInclusions.push(this.finishedIngredient.inclusions);
    }
    if (Object.keys(flavor).length > 0) {
      this.finishedIngredient.flavor = flavor;
      this.arrInclusions.push(this.finishedIngredient.flavor);
    }
  }

  loadImagesPackaging() {
    if (this.finishedIngredient.packagingPick.empaque1) {
      this.imagesPackaging = [
        {
          img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque1.imagen}`,
          material: this.finishedIngredient.packagingPick.empaque1.material,
          typePackaging: this.finishedIngredient.packagingPick.empaque1.tipoEmpaque,
          finishesPackaging: this.finishedIngredient.packagingPick.empaque1.acabados,
          units: this.finishedIngredient.packagingPick?.unitsPerPackage1,
        },
        {
          img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque2.imagen}`,
          material: this.finishedIngredient.packagingPick.empaque2.material,
          typePackaging: this.finishedIngredient.packagingPick.empaque2.tipoEmpaque,
          finishesPackaging: this.finishedIngredient.packagingPick.empaque2.acabados,
          units: this.finishedIngredient.packagingPick?.unitsPerPackage2,
        },
      ];
    }
    if (this.finishedIngredient.packagingPick.empaque3) {
      this.imagesPackaging.push({
        img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque3?.imagen}`,
        material: this.finishedIngredient.packagingPick.empaque3?.material,
        typePackaging: this.finishedIngredient.packagingPick.empaque3?.tipoEmpaque,
        finishesPackaging: this.finishedIngredient.packagingPick.empaque3?.acabados,
        units: this.finishedIngredient.packagingPick?.unitsPerPackage3,
      });
      if (this.finishedIngredient.packagingPick.empaque4) {
        this.imagesPackaging.push({
          img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque4?.imagen}`,
          material: this.finishedIngredient.packagingPick.empaque4?.material,
          typePackaging: this.finishedIngredient.packagingPick.empaque4?.tipoEmpaque,
          finishesPackaging: this.finishedIngredient.packagingPick.empaque4?.acabados,
          units: this.finishedIngredient.packagingPick.unitsPerPackage4,
        });
      }
    }
  }

  /**Function to calculate the cubic capacity of packaging */
  loadPricesPackaging() {
    const packaging = JSON.parse(localStorage.getItem('packaging') || '{}');
    this.interfaceDataCubic.quantityUnits = this.projectBrief.peso / (packaging.weightPerUnit / 1000);
    let totalPackage = 1;
    let unitsPerPackage1 = packaging?.unitsPerPackage1;
    let unitsPerPackage2 = packaging?.unitsPerPackage2;
    let unitsPerPackage3 = packaging?.unitsPerPackage3;
    let unitsPerPackage4 = packaging?.unitsPerPackage4;
    let unitsPerPackage5 = packaging?.unitsPerPackage5;
    if (unitsPerPackage1 > 0) {
      totalPackage *= unitsPerPackage1;
    }
    if (unitsPerPackage2 > 0) {
      totalPackage *= unitsPerPackage2;
    }
    if (unitsPerPackage3 > 0) {
      totalPackage *= unitsPerPackage3;
    }
    if (unitsPerPackage4 > 0) {
      totalPackage *= unitsPerPackage4;
    }
    if (unitsPerPackage5 > 0) {
      totalPackage *= unitsPerPackage5;
    }
    this.interfaceDataCubic.unitsBox = totalPackage;
    this.interfaceDataCubic.palets20 = packaging.paletx20;
    this.interfaceDataCubic.palets40 = packaging.paletx40;
    this.interfaceDataCubic.boxPerPalet = packaging.boxPerpalet;
    this.interfaceDataCubic.unitsPerPalet =
      (this.interfaceDataCubic.boxPerPalet || 0) * this.interfaceDataCubic.unitsBox;
    this.interfaceDataCubic.kgPerPalet = (this.interfaceDataCubic.unitsPerPalet * packaging.weightPerUnit) / 1000;
    this.interfaceDataCubic.weightPerUnit = packaging.weightPerUnit;
    this.interfaceDataCubic.boxes = Math.ceil(this.interfaceDataCubic.quantityUnits / this.interfaceDataCubic.unitsBox);
    this.interfaceDataCubic.palets = Math.round(this.interfaceDataCubic.boxes / packaging.boxPerpalet);
    this.interfaceDataCubic.contX20 = this.interfaceDataCubic.boxes / packaging.boxPerpalet / packaging.paletx20;
    this.interfaceDataCubic.contX40 = this.interfaceDataCubic.boxes / packaging.boxPerpalet / packaging.paletx40;
  }

  /**
   * This function loads the selections made by the user in the design inspiration step.
   */
  loadDesignSelections() {
    const design = JSON.parse(localStorage.getItem('design') || '{}');
    const artist = JSON.parse(localStorage.getItem('artist') || '{}');
    if (Object.keys(design).length > 0) {
      design.nombre = design.texto.replace('_', ' ');
      this.designSelections.design = design;
    }
    if (Object.keys(artist).length > 0) {
      this.designSelections.artist = artist;
    }
    this.artistSelected.push({
      name: artist.nombreArtista,
      imagenCollage: artist.imagenCollage,
      imagenPerfil: artist.imagenPerfil,
    });
  }

  /**
   * Function which gets all the Z items costs by finished product.
   */
  async getManufacturingCost() {
    const shapeId = this.finishedIngredient.shapePick.formaId;
    const packageId = this.finishedIngredient.packagingPick.combinacionEmpaqueId;
    let productFinished;
    this.origin = localStorage.getItem('origin') || '';
    let chocolateType = '';
    if (this.finishedIngredient.chocolatePick) {
      chocolateType = this.finishedIngredient.chocolatePick.tipo;
    } else {
      chocolateType = this.finishedIngredient.bespoke.type;
    }
    if (this.origin === 'Portfolio products') {
      productFinished = localStorage.getItem('productFinished') || '';
      this.sapCodeProductFinished = productFinished;
      this.nameProductFinished = localStorage.getItem('nameProductFinished') || '';
    } else {
      productFinished = '';
    }
    await this.manufactureCombService.getCombinationsBySelections(
      shapeId,
      packageId,
      chocolateType,
      this.pickedInclusions.inclusion,
      productFinished
    );
    this.manufacturingCost = this.manufactureCombService.manufacturingCost;
    this.manufacturingCosts.push(this.manufacturingCost);
    this.manufactureCost =
      this.manufacturingCost.zmod +
      this.manufacturingCost.zman +
      this.manufacturingCost.zgas +
      this.manufacturingCost.zdep +
      this.manufacturingCost.zenr +
      this.manufacturingCost.zoin +
      this.manufacturingCost.maquila;
    this.arrManuFacturing.push(
      { '': 'zmod', ' ': this.manufacturingCost.zmod },
      { '': 'zman', ' ': this.manufacturingCost.zman },
      { '': 'zgas', ' ': this.manufacturingCost.zgas },
      { '': 'zdep', ' ': this.manufacturingCost.zdep },
      { '': 'zenr', ' ': this.manufacturingCost.zenr },
      { '': 'zoin', ' ': this.manufacturingCost.zoin },
      { '': 'maquila', ' ': this.manufacturingCost.maquila }
    );
  }

  /**
   * Function which gets all the raw material costs by finished product.
   */
  async getRawMaterialCost() {
    let extraPercentage = 0;
    this.pickedInclusions = this.validateInclusions();
    if (this.pickedInclusions) {
      for (let i = 0; i < this.pickedInclusions.inclusions.length; i++) {
        if (this.pickedInclusions.inclusions[i].percentage) {
          extraPercentage += this.pickedInclusions.inclusions[i].percentage;
        }
      }

      let selectedOptions = {
        application: {},
        chocolateType: {},
        ingredientSpecs: [],
        leadtime: '',
        recipe: '',
      };
      localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
      if (!this.projectBrief.projectModified) {
        this.inclusionsCostsDetails = await this.getInclusionsCostDetails(this.pickedInclusions);
      }
      await this.getInclusionsCost();
    }
    if (this.finishedIngredient.chocolatePick) {
      const finishedProduct = this.finishedIngredient.chocolatePick.materialSap;
      this.getRawMaterialTypeOneCost(finishedProduct, extraPercentage);
      this.getRawMaterialTypeTwoCost(finishedProduct, extraPercentage);
    } else {
      let bespokeMaterials = await this.bespokeFormulaService.getBespokeCost(
        this.finishedIngredient.bespoke.materials,
        this.costParameters.scale,
        extraPercentage
      );
      await this.getBespokeRawMaterialOne(bespokeMaterials);
      await this.getBespokeRawMaterialTwo(bespokeMaterials);
    }
  }

  /**
   * This function gets the cost of a bespoke chocolate raw materials of type 1.1.
   * @param bespokeMaterials The materials list of a bespoke chocolate.
   */
  async getBespokeRawMaterialOne(bespokeMaterials: any[]) {
    if (this.projectBrief.projectModified) {
      const rawMaterials = JSON.parse(ls.get('pricesModifed') || '{}');
      for (let element of rawMaterials.rawMaterialOne) {
        element.price = element.precioNeto;
        element.price *= parseFloat(element.proportion);
        this.saveDataRawMaterialBespokeTwo(element);
      }
    } else {
      let costs: any = await this.costService.getRawMaterialTypeOne(
        parseFloat(this.costParameters.trm),
        parseFloat(this.costParameters.cacaoPrice),
        parseFloat(this.costParameters.addCacaoPrice)
      );
      for (let item of this.inclusionsCostsDetails) {
        if (item.codigoSap === '2000692') {
          item.name = 'Ps Nibs Otros 1B Segundo Tamizado Rx';
          item.tipoMateriaPrima = 'Materia Prima 1';
          item.sap = item.codigoSap;
          item.proportion = item.porcentaje;
          bespokeMaterials.push(item);
        }
      }
      for (const element of bespokeMaterials) {
        if (element.tipoMateriaPrima == 'Materia Prima 1' || element.tipoMateriaPrima === 'SemiElaborados') {
          if (
            element.name.includes('LICOR') ||
            element.name.includes('PS COCOA NIBS') ||
            element.name.includes('PS NIBS CLUSTER') ||
            element.name.includes('Ps Nibs')
          ) {
            element.price = parseFloat(costs.precioLicor);
            element.precioNeto = Math.round(parseFloat(costs.precioLicor));
          } else if (element.name.includes('COCOA') || element.name.includes('VIRUTA')) {
            element.price = parseFloat(costs.precioCocoa);
            element.precioNeto = Math.round(parseFloat(costs.precioCocoa));
          } else if (element.name == 'PS MANTECA DESODORIZADA') {
            element.price = parseFloat(costs.precioMantecaDeso);
            element.precioNeto = Math.round(parseFloat(costs.precioMantecaDeso));
          } else if (element.name != 'PS MANTECA DESODORIZADA') {
            element.price = parseFloat(costs.precioManteca);
            element.precioNeto = Math.round(parseFloat(costs.precioManteca));
          }
          element.price *= parseFloat(element.proportion) || element.porcentaje;
          this.saveDataRawMaterialBespokeTwo(element);
        }
      }
    }
    this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a, b) => a + b.price, 0);
  }

  saveDataRawMaterialBespokeTwo(element: any) {
    this.rawMaterialCostDetailsTypeOne.push(element);
    this.arrCostsStructure.push({
      codSap: element.sap,
      material: element.name,
      factorKg: element.factorKg || element.porcentaje,
      precioNeto: element.precioNeto,
      costoKg: element.price,
      moneda: element.moneda,
      status: 'Vigente',
      registro: element.registro,
      validez: element.validez,
      desperdicio: 0,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
  }

  /**
   * This function gets the cost of a bespoke chocolate raw materials of type 2.
   * @param bespokeMaterials The materials list of a bespoke chocolate.
   */
  async getBespokeRawMaterialTwo(bespokeMaterials: any[]) {
    let rawMaterials;
    if (this.projectBrief.projectModified) {
      rawMaterials = JSON.parse(ls.get('pricesModifed') || '{}');
      for (let element of rawMaterials.rawMaterialTwo) {
        element.name = element.nombre;
        if (element.moneda === 'USD') {
          element.price *= parseFloat(this.costParameters.trm);
          this.rawMaterialCostTwo += element.price;
        } else {
          this.rawMaterialCostTwo += element.price;
        }
        this.setListElements(element);
      }
    } else {
      for (const element of bespokeMaterials) {
        element.nombre = element.name;
        if (element.tipoMateriaPrima == 'Materia Prima 2') {
          if (element.moneda == 'USD') {
            element.price *= parseFloat(this.costParameters.trm);
            this.rawMaterialCostTwo += element.price;
          } else {
            this.rawMaterialCostTwo += element.price;
          }
          this.setListElements(element);
        }
      }
    }
    this.rawMaterialCostTwo = this.rawMaterialCostDetailsTypeTwo.reduce((a, b) => a + b.price, 0);
  }

  setListElements(element: any) {
    this.rawMaterialCostDetailsTypeTwo.push(element);
    this.arrCostsStructure.push({
      codSap: element.sap,
      material: element.name,
      factorKg: element.proportion,
      precioNeto: element.precioNeto,
      costoKg: element.price,
      moneda: element.moneda,
      status: element.status,
      registro: element.registro,
      validez: element.validez,
      desperdicio: 0,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
    this.rawMaterialCostDetails.push({
      name: element.name,
      sap: element.sap || element.codigoSap,
      register: element.registro,
      valid: element.validez,
      status: element.status,
    });
  }

  /**
   * This function loads the type one raw material costs.
   * @param finishedProduct The sap code of the finished product.
   */
  async getRawMaterialTypeOneCost(finishedProduct: any, inclusionsPercentage: number) {
    let rawMaterials;
    // let desperdicio: any = await this.getDesperdicio();
    let finalPrice: any = 0;
    if (this.projectBrief.projectModified) {
      rawMaterials = JSON.parse(ls.get('pricesModifed') || '{}');
      this.rawMaterialsTypeOne = rawMaterials.rawMaterialOne;
      for (let item of this.rawMaterialsTypeOne) {
        item.costoKg = parseFloat(item.precioNeto) * item.proportion;
        let price = item.costoKg;
        const desperdicio = await this.priceFinalWaste(price);
        finalPrice = price; // TODO - A esta formula sumar la merma (merma)
        let nombreMateria = item.name;
        this.saveDataList(item, finalPrice, nombreMateria, desperdicio);
      }
    } else {
      await this.materialListService.getCostByRawMaterialTypeOne(finishedProduct, inclusionsPercentage);
      await this.costService.getRawMaterialTypeOne(
        parseFloat(this.costParameters.trm),
        parseFloat(this.costParameters.cacaoPrice),
        parseFloat(this.costParameters.addCacaoPrice)
      );
      this.rawMaterialsTypeOne = this.materialListService.rawMaterialsTypeOne;
      this.rawMaterialTypeOneCosts = this.costService.rawMaterialsTypeOne;
      for (let item of this.inclusionsCostsDetails) {
        if (item.codigoSap === '2000692') {
          item.nombre = 'Ps Nibs Otros 1B Segundo Tamizado Rx';
          this.rawMaterialsTypeOne.push(item);
        }
      }
      for (const element of this.rawMaterialsTypeOne) {
        let nombreMateria = element.nombre;
        if (
          nombreMateria.includes('PS LICOR') ||
          nombreMateria.includes('PS COCOA NIBS') ||
          nombreMateria.includes('PS NIBS CLUSTER') ||
          nombreMateria.includes('Ps Nibs')
        ) {
          element.precioNeto = this.rawMaterialTypeOneCosts.precioLicor;
        } else if (nombreMateria.includes('PS COCOA') || nombreMateria.includes('PS VIRUTA')) {
          element.precioNeto = this.rawMaterialTypeOneCosts.precioCocoa;
        } else if (nombreMateria == 'PS MANTECA DESODORIZADA') {
          element.precioNeto = this.rawMaterialTypeOneCosts.precioMantecaDeso;
        } else if (nombreMateria != 'PS MANTECA DESODORIZADA') {
          element.precioNeto = this.rawMaterialTypeOneCosts.precioManteca;
        }
        element.costoKg = parseFloat(element.precioNeto) * (parseFloat(element.factorKg) || element.porcentaje);
        const desperdicio = await this.priceFinalWaste(element.costoKg);
        finalPrice = element.costoKg * (1 + desperdicio.percentageExtraContenido + this.globalMerm / 4000);
        this.saveDataList(element, finalPrice, nombreMateria, desperdicio);
      }
    }
    if (this.rawMaterialCostOne === 0) {
      this.rawMaterialCostOneBespoke = this.rawMaterialCostDetailsTypeOne.reduce((a, b) => a + b.price, 0);
    }
    this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a, b) => a + b.price, 0);
  }

  saveDataList(element: any, finalPrice: any, nombreMateria: any, desperdicio: any) {
    this.rawMaterialCostDetailsTypeOne.push({
      name: nombreMateria,
      price: finalPrice,
      precioNeto: Math.round(element.precioNeto),
      sap: element.sap || element.codigoSap,
      moneda: element.moneda,
      proportion: element.factorKg || element.porcentaje || element.proportion,
      desperdicio: desperdicio.percentageExtraContenido || 0,
      desperdicioExtraInclusion: desperdicio.percentageExtraInclusion || 0,
      isInclusion: desperdicio.isInclusion,
      fullWaste: desperdicio,
    });
    this.rawMaterialCostDetails.push({
      name: nombreMateria,
      sap: element.sap || element.codigoSap,
      register: element.registro,
      valid: element.validez,
      status: element.status,
    });
    // console.log('desperdicio', desperdicio);
    this.arrWaste.push({
      name: nombreMateria,
      desperdicioExtraContenido: desperdicio.percentage,
      desperdicioExtraContenidoInclusion: desperdicio.percentageExtraInclusion,
    });
    this.arrCostsStructure.push({
      codSap: element.sap || element.codigoSap,
      material: nombreMateria,
      factorKg: element.factorKg || element.porcentaje || element.proportion,
      precioNeto: element.precioNeto,
      costoKg: finalPrice,
      moneda: element.moneda,
      status: element.status,
      registro: element.registro,
      validez: element.validez,
      desperdicio: desperdicio,
      inco1: element.inco1,
      inco2: element.inco2,
      factorIncoterm: element.factorIncoterm,
    });
  }

  /**
   * This function loads the type two raw material costs.
   * @param finishedProduct The sap code of the finished product.
   */
  async getRawMaterialTypeTwoCost(finishedProduct: any, inclusionsPercentage: number) {
    if (this.projectBrief.projectModified) {
      let rawMaterials = JSON.parse(ls.get('pricesModifed') || '{}');
      this.rawMaterialsTypeTwo = rawMaterials.rawMaterialTwo.filter((item: any) => item.inclusion !== 'inclusion');
    } else {
      await this.materialListService.getCostByRawMaterialTypeTwo(
        finishedProduct,
        parseFloat(this.costParameters.scale),
        inclusionsPercentage
      );
      this.rawMaterialsTypeTwo = this.materialListService.rawMaterialsTypeTwo;
    }
    let finalPrice: any = 0;
    /**
     * Coger el precio base (elemento.precioNeto) y sumar los desperdicios que es (* (1 + desperdicio.percentageExtraContenido))
     */
    for (const element of this.rawMaterialsTypeTwo) {
      // console.log('element', element);
      const desperdicio = await this.priceFinalWaste(element.precioNeto);
      if (element.moneda == 'COP') {
        finalPrice =
          element.precioNeto * element.factorKg * (1 + desperdicio.percentageExtraContenido + this.globalMerm / 4000); // TODO - A esta formula sumar la merma (merma) y el desperdicio extra inclusion solo a las inclusiones
        this.rawMaterialCostDetailsTypeTwo.push({
          nombre: element.nombre,
          price: finalPrice,
          sap: element.codigoSap || element.sap,
          proportion: element.factorKg,
          precioNeto: Math.round(element.precioNeto),
          moneda: element.moneda,
          factorKg: element.factorKg,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          factor: element.factorIncoterm,
          isInclusion: desperdicio.isInclusion, // TODO - Usar esta validación
          desperdicio: desperdicio.percentageExtraContenido,
          desperdicioExtraInclusion: desperdicio.percentageExtraInclusion,
        });
        this.rawMaterialCostTwo += finalPrice;
      } else {
        finalPrice =
          element.precioNeto *
          element.factorKg *
          (1 + desperdicio.percentageExtraContenido + this.globalMerm / 4000) *
          parseFloat(this.costParameters.trm);
        this.rawMaterialCostDetailsTypeTwo.push({
          nombre: element.nombre,
          price: finalPrice,
          sap: element.codigoSap || element.sap,
          proportion: element.factorKg,
          factorKg: element.factorKg,
          precioNeto: Number(element.precioNeto).toFixed(2),
          moneda: element.moneda,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          isInclusion: desperdicio.isInclusion,
          desperdicio: desperdicio.percentageExtraContenido,
          desperdicioExtraInclusion: desperdicio.percentageExtraInclusion,
        });
        this.rawMaterialCostTwo += finalPrice;
      }
      // console.log('desperdicio material 2', desperdicio);
      this.arrWaste.push({
        name: element.nombre,
        isInclusion: desperdicio.isInclusion,
        desperdicioExtraContenido: desperdicio.percentageExtraContenido,
        desperdicioExtraContenidoInclusion: desperdicio.percentageExtraInclusion,
      });
      this.arrCostsStructure.push({
        codSap: element.sap || element.codigoSap,
        material: element.nombre,
        factorKg: element.factorKg,
        precioNeto: element.precioNeto,
        costoKg: finalPrice,
        moneda: element.moneda,
        status: element.status,
        registro: element.registro,
        validez: element.validez,
        desperdicio: desperdicio,
        inco1: element.inco1,
        inco2: element.inco2,
        factorIncoterm: element.factorIncoterm,
      });
      this.rawMaterialCostDetails.push({
        name: element.nombre,
        sap: element.sap || element.codigoSap,
        register: element.registro,
        valid: element.validez,
        status: element.status,
      });
      this.arrMaterialRegisters.push(element.registro);
      this.listFactorIncoterm(element.nombre, element.factorIncoterm, element.inco1, element.inco2);
    }
  }

  listFactorIncoterm(material: any, factorIncoterm: any, incoterm: any, portOrigin: any) {
    this.arrFactorIncoterm.push({
      material: material,
      factor: factorIncoterm,
      incoterm: incoterm,
      portOrigin: portOrigin,
    });
  }

  /**Function calculate final price including data of extracontent service  */
  async priceFinalWaste(priceMaterial: { percentage: any; finalPrice: any }) {
    const desperdicio = await this.getDesperdicio();
    // console.log('desperdicio priceFinalWaste', desperdicio);
    const finalPrice = priceMaterial.finalPrice + (1 + desperdicio.extraContenido);
    // console.log('finalPrice priceFinalWaste', finalPrice);
    return {
      isInclusion: desperdicio.inclusion,
      percentageExtraContenido: desperdicio.extraContenido,
      percentageExtraInclusion: desperdicio.extraContenidoInclusion,
      finalPrice: finalPrice,
    };
  }

  // Get desperdicio
  async getDesperdicio() {
    const { formaId } = this.finishedIngredient.shapePick;
    const inclusions = this.validateInclusions().inclusions;
    const hasInclusions = inclusions.length > 0 ? 'SI' : 'NO';
    const item = this.wastePlantInclusion.find(
      (item: any) => item.idForma === formaId && item.inclusion === hasInclusions
    );
    return {
      inclusion: item.inclusion,
      extraContenido: item.extraContenido,
      extraContenidoInclusion: item.extraContenidoInclusion,
    };
  }

  /**Function validate the inclusion and calculate price MP1 */
  async priceWaste() {
    let inclusion = this.validateInclusions();
    let priceRawMaterial = 0;
    const bespoke = JSON.parse(localStorage.getItem('bespoke') || '{}');
    this.wastePlantMerm.forEach((merm: any) => {
      if (
        (this.finishedIngredient.chocolatePick?.tipo == merm.tipoChocolate && inclusion.inclusion == merm.inclusion) ||
        bespoke.type == merm.tipoChocolate
      ) {
        this.mermWaste = merm.merma;
        this.globalMerm = merm.merma;
        let desperdicio = merm.merma / this.projectBrief.peso;
        for (const i of this.rawMaterialCostDetailsTypeOne) {
          priceRawMaterial = i.price * (1 + desperdicio);
          i.price = priceRawMaterial;
        }
      }
    });
    this.rawMaterialCostOne = this.rawMaterialCostDetailsTypeOne.reduce((a, b) => a + b.price, 0);
  }

  /**This function brings the data from the waste tables to calculate in raw materials  */
  async getWastePlant() {
    this.wastePlantMerm = await this.desperdiciosService.getWasteMerm();
    this.wastePlantInclusion = await this.desperdiciosService.getWasteExtraInclusion();
  }

  manufacturingCustom() {
    let manufacturingCustom = [];
    if (this.manufacturingCost.rutas) {
      for (let item of this.manufacturingCost.rutas) {
        manufacturingCustom.push({
          producto_terminado: item.producto_terminado,
          descripcion_producto_terminado: item.descripcion_producto_terminado,
          material: item.material,
          descripcion_material: item.descripcion_material,
          arbid: item.arbid,
          ltxa_1: item.ltxa_1,
          version_ruta: item.version_ruta,
          version_lm: item.version_lm,
          fecha_creacion: item.createdAt,
          zmod: item.zmod,
          zman: item.zman,
          zenr: item.zenr,
          zgas: item.zgas,
          zdep: item.zdep,
          zoin: item.zoin,
          tarifa_Zmod: item.zmod_tarifa,
          tarifa_Zman: item.zman_tarifa,
          tarifa_Zenr: item.zenr_tarifa,
          tarifa_Zgas: item.zgas_tarifa,
          tarifa_Zdep: item.zdep_tarifa,
          tarifa_Zoin: item.zoin_tarifa,
          zmod_Final: '',
          zman_Final: '',
          zenr_Final: '',
          zgas_Final: '',
          zdep_Final: '',
          zoin_Final: '',
        });
      }
      manufacturingCustom[0].zmod_Final = this.manufacturingCost.zmod;
      manufacturingCustom[0].zman_Final = this.manufacturingCost.zman;
      manufacturingCustom[0].zenr_Final = this.manufacturingCost.zenr;
      manufacturingCustom[0].zgas_Final = this.manufacturingCost.zgas;
      manufacturingCustom[0].zdep_Final = this.manufacturingCost.zdep;
      manufacturingCustom[0].zoin_Final = this.manufacturingCost.zoin;
    }
    return manufacturingCustom;
  }

  /**This function will export brief data using the XLSX library */
  async exportExcel() {
    let manufacturing = this.manufacturingCustom();
    const worksheetProjectBrief: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.projectBriefArr);
    const worksheetCostStructure: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrCostsStructure);
    const worksheetManufacturing: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrManuFacturing);
    const worksheetFactorIncoterm: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrFactorIncoterm);
    const worksheetWastePackaging: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrWastePackaging);
    const worksheetManufacturingDetails: XLSX.WorkSheet = XLSX.utils.json_to_sheet(manufacturing);
    const worksheetPg: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrCostsPg);
    const worksheetComments: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listComments);
    let concatCosts = XLSX.utils.sheet_to_json(worksheetCostStructure, { header: 1 });
    let concatManufacturing = XLSX.utils.sheet_to_json(worksheetManufacturing, { header: 1 });
    let concatSpecifications = XLSX.utils.sheet_to_json(worksheetFactorIncoterm, { header: 1 });
    let concatWastePackaging = XLSX.utils.sheet_to_json(worksheetWastePackaging, { header: 1 });
    let concatComments = XLSX.utils.sheet_to_json(worksheetComments, { header: 1 });
    concatCosts = concatCosts.concat(['']).concat(concatManufacturing);
    let workSheetConcatCosts = XLSX.utils.json_to_sheet(concatCosts, { skipHeader: true });
    concatSpecifications = concatSpecifications.concat(['']).concat(concatWastePackaging, concatComments);
    let workSheetConcatSpecifications = XLSX.utils.json_to_sheet(concatSpecifications, { skipHeader: true });
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, workSheetConcatCosts, 'Cost Structure');
    XLSX.utils.book_append_sheet(workbook, worksheetProjectBrief, 'Project Brief');
    XLSX.utils.book_append_sheet(workbook, workSheetConcatSpecifications, 'Specifications');
    XLSX.utils.book_append_sheet(workbook, worksheetPg, 'P&G');
    XLSX.utils.book_append_sheet(workbook, worksheetManufacturingDetails, 'Details manufacturing');
    XLSX.writeFile(workbook, this.fileName);
  }

  /**
   * Function which gets all the costs of each package that composes a combination package.
   */
  async getPackagingCombinationCost() {
    const packaging = JSON.parse(localStorage.getItem('packagings') || '{}');
    if (this.origin === 'Portfolio products') {
      this.packagesCosts = packaging;
    } else {
      const combId = this.finishedIngredient.packagingPick.combinacionEmpaqueId;
      await this.packagingService.getPackagingCombinationCosts(combId, parseFloat(this.costParameters.scale));
      this.packagesCosts = this.packagingService.packagingCosts;
    }
    let finalPrice: any = 0;
    for (const element of this.packagesCosts) {
      if (element.moneda == 'COP') {
        finalPrice = element.costoKg;
        this.packagesCostsDetails.push({
          name: element.nombre,
          price: element.costoKg,
          sap: element.codigoSap,
          units: element.unidades,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          desperdicio: element.calculoDesperdicio,
          desperdicios: element.desperdicios,
        });
        this.packagingCost += element.costoKg;
      } else {
        let conversionCosto = element.costoKg * parseFloat(this.costParameters.trm);
        finalPrice = conversionCosto;
        this.packagesCostsDetails.push({
          name: element.nombre,
          price: conversionCosto,
          sap: element.codigoSap,
          units: element.unidades,
          status: element.status,
          register: element.registro,
          valid: element.validez,
          finalPrice: conversionCosto,
          desperdicio: element.calculoDesperdicio,
          desperdicios: element.desperdicios,
        });
        this.packagingCost += conversionCosto;
      }
      this.arrMaterialRegisters.push(element.registro);
      this.listDataWastePackaging(element.nombre, element.desperdicios * 100);
      this.arrCostsStructure.push({
        codSap: element.codigoSap,
        material: element.nombre,
        factorKg: element.unidades,
        precioNeto: element.precioNeto,
        costoKg: finalPrice,
        moneda: element.moneda,
        status: element.status,
        registro: element.registro,
        validez: element.validez,
        desperdicio: element.desperdicios,
        inco1: element.inco1,
        inco2: element.inco2,
        factorIncoterm: element.factorIncoterm,
      });
    }
  }

  listDataWastePackaging(material: any, percentage: any) {
    this.arrWastePackaging.push({ 'Desperdicio:': material, ' ': percentage });
  }

  /**
   * This functions validates if the user selected any flavors or inclusions, and returns their percentages.
   * @returns
   */
  validateInclusions() {
    // Objeto para almacenar las inclusiones seleccionadas
    let pickedInclusions: any = {};

    // Arreglo para almacenar inclusiones y sabores
    let inclusionsAndFlavors = [];

    // Obtener inclusiones y sabores del almacenamiento local (localStorage)
    let inclusions = JSON.parse(localStorage.getItem('inclusion') || '{}');
    let flavor = JSON.parse(localStorage.getItem('flavor') || '{}');

    // Comprobar si hay inclusiones o sabores seleccionados
    if (Object.keys(flavor).length > 0 || Object.keys(inclusions).length > 0) {
      // Se establece 'SI' en caso de que haya inclusiones o sabores seleccionados
      pickedInclusions.inclusion = 'SI';

      // Comprobar si hay información de sabor y porcentaje de base
      if (flavor?.codigoSap !== undefined && parseFloat(inclusions?.base?.percentage) / 100 !== undefined) {
        // Agregar información del sabor y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: flavor?.nombre,
          material: flavor?.codigoSap,
          percentage: parseFloat(flavor?.pickedConcentration),
          ...flavor,
        });
      }

      // Comprobar si hay información de inclusión base y porcentaje de base
      if (
        inclusions?.base?.inclusion?.codigoSap !== undefined &&
        parseFloat(inclusions?.base?.percentage) / 100 !== undefined
      ) {
        // Agregar información de la inclusión base y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: inclusions?.base?.inclusion?.nombre,
          material: inclusions?.base?.inclusion?.codigoSap,
          percentage: parseFloat(inclusions?.base?.percentage) / 100,
          ...flavor,
        });
      }

      // Comprobar si hay información de inclusión extra y porcentaje de extra
      if (
        inclusions?.extra?.inclusion?.codigoSap !== undefined &&
        parseFloat(inclusions?.extra?.percentage) / 100 !== undefined
      ) {
        // Agregar información de la inclusión extra y porcentaje al arreglo
        inclusionsAndFlavors.push({
          nombre: inclusions?.extra?.inclusion?.nombre,
          material: inclusions?.extra?.inclusion?.codigoSap,
          percentage: parseFloat(inclusions?.extra?.percentage) / 100,
          ...flavor,
        });
      }
    } else {
      // Se establece 'NO' en caso de que no haya inclusiones ni sabores seleccionados
      pickedInclusions.inclusion = 'NO';
    }

    // Calcular el total consolidado de sabores e inclusiones
    const totalConsolidado = inclusionsAndFlavors.length;

    if (this.aseoGlobal === 0 && this.aseoGlobalPersons === 0 && this.aseoGlobalHours === 0) {
      if (this.aseoIsCocoaPremium) {
        this.getAseosValues('COCOA PREMIUM');
      } else if (inclusionsAndFlavors.length > 0) {
        const pickedConcentration: number = parseFloat(inclusionsAndFlavors[0].pickedConcentration);
        const porcentajeAlto: number = parseFloat(inclusionsAndFlavors[0].porcentajeAlto);
        if (pickedConcentration === porcentajeAlto) {
          this.getAseosValues(inclusionsAndFlavors[0].subcategoria + ' - HIGH');
        } else {
          this.getAseosValues(inclusionsAndFlavors[0].subcategoria + ' - LOW');
        }
      } else {
        this.getAseosValues('DEFAULT');
      }
    }

    // Asignar el arreglo de inclusiones y sabores al objeto pickedInclusions
    pickedInclusions.inclusions = inclusionsAndFlavors;

    // Devolver el objeto pickedInclusions que contiene la información seleccionada
    return pickedInclusions;
  }

  /**
   * This function gets the cost details of the inclusions and flavors the user picked.
   * @param pickedInclusions The inclusions and flavors the user picked.
   * @returns The name, SAP code and cost per kilogram of inclusions and flavor.
   */
  async getInclusionsCostDetails(pickedInclusions?: any) {
    let inclusions: any[] = await this.registerInfoService.getInclusionsCost(
      parseFloat(this.costParameters.scale),
      pickedInclusions
    );
    return inclusions;
  }

  /**
   * This function converts the cost of the items depending on the currency.
   */
  async getInclusionsCost() {
    let inclusion = await this.validateInclusions();
    let rawMaterialsInclusions;
    let listInclusions;
    if (this.projectBrief.projectModified) {
      rawMaterialsInclusions = JSON.parse(ls.get('pricesModifed') || '{}');
      listInclusions = rawMaterialsInclusions.rawMaterialTwo.filter((item: any) => item.inclusion === 'inclusion');
    } else {
      for (let item of this.inclusionsCostsDetails) {
        item.precioNeto = item.precioMaterial;
      }
      listInclusions = this.inclusionsCostsDetails;
    }
    for (const element of listInclusions) {
      let priceFinal = element.precioNeto * parseFloat(element.porcentaje || element.percentage);
      if (element.moneda == 'COP') {
        this.inclusionsTotal += priceFinal;
        element.costoKg = priceFinal;
      } else {
        let conversionCosto = priceFinal * parseFloat(this.costParameters.trm);
        element.costoKg = conversionCosto;
        this.inclusionsTotal += conversionCosto;
      }
      let price = element.costoKg;
      let finalPrice = 0;
      let desperdicioExtraContenido = 0;
      let desperdicioExtraContenidoInclusion = 0;
      let isInclusion = '';
      this.wastePlantInclusion.forEach((item: any) => {
        if (this.finishedIngredient.shapePick.formaId == item.idForma && inclusion.inclusion == item.inclusion) {
          price = element.costoKg;
          finalPrice = price * (1 + item.extraContenidoInclusion + item.extraContenido + this.globalMerm / 4000);
          desperdicioExtraContenido = item.extraContenido;
          desperdicioExtraContenidoInclusion = item.extraContenidoInclusion;
          isInclusion = item.inclusion;
        }
      });
      this.saveDataInclusions(
        element,
        finalPrice,
        desperdicioExtraContenido,
        desperdicioExtraContenidoInclusion,
        isInclusion
      );
      this.rawMaterialCostTwo += finalPrice;
    }
  }

  saveDataInclusions(
    element: any,
    finalPrice: any,
    desperdicioExtraContenido: any,
    desperdicioExtraContenidoInclusion: any,
    isInclusion: string
  ) {
    if (element.moneda === 'USD') {
      if (element.codigoSap !== '2000692') {
        this.rawMaterialCostDetailsTypeTwo.push({
          name: element.nombre,
          nombre: element.nombre,
          price: finalPrice || element.costoKg,
          precioNeto: element.precioNeto,
          moneda: element.moneda,
          sap: element.codigoSap,
          desperdicioExtraContenido: desperdicioExtraContenido,
          desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
          desperdicio: desperdicioExtraContenidoInclusion,
          percentage: element.porcentaje,
          inclusion: 'inclusion',
          isInclusion: isInclusion,
        });
      }
    } else {
      this.rawMaterialCostDetailsTypeTwo.push({
        name: element.nombre,
        nombre: element.nombre,
        price: finalPrice || element.costoKg,
        precioNeto: element.precioNeto,
        moneda: element.moneda,
        sap: element.codigoSap,
        isInclusion: isInclusion,
        desperdicioExtraContenido: desperdicioExtraContenido,
        desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
        percentage: element.porcentaje,
        inclusion: 'inclusion',
      });
    }
    this.arrCostsStructure.push({
      codSap: element.codigoSap,
      material: element.nombre,
      costoKg: finalPrice || element.costoKg,
      factorKg: element.porcentaje,
      moneda: element.moneda,
      precioNeto: element.precioNeto,
      validez: element.validez,
      isInclusion: isInclusion,
      desperdicioExtraContenido: desperdicioExtraContenido,
      desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
      registro: element.registro,
      status: element.status,
    });
    this.arrWaste.push({
      name: element.nombre,
      isInclusion: isInclusion,
      desperdicioExtraContenido: desperdicioExtraContenido,
      desperdicioExtraContenidoInclusion: desperdicioExtraContenidoInclusion,
    });
  }

  getFinalPrice(item: any) {
    if (this.showDesperdicio(item)) {
      const waste = this.calculateDesperdicio(item);
      return item.price * waste;
    } else if (this.showDesperdicioExtraContenidoInclusion(item)) {
      const waste = this.calculateDesperdicioExtraContenidoInclusion(item);
      return item.price * waste;
    } else {
      return item.price;
    }
  }

  /**
   * This function get if material is not inclusion
   * @param item item of material
   * @returns <boolean>
   */
  showDesperdicio(item: any): boolean {
    return item.inclusion !== 'inclusion';
  }

  /**
   * This function get if material is inclusion
   * @param item item of material
   * @returns <boolean>
   */
  showDesperdicioExtraContenidoInclusion(item: any): boolean {
    return item.inclusion === 'inclusion';
  }

  /**
   * This function calculate desperdicio
   * @param item item of material
   * @returns <number>
   */
  calculateDesperdicio(item: any): number {
    return item.desperdicio * 100 + (this.mermWaste / 4000) * 100;
  }

  /**
   * Calcula el precio con los valores de desperdicio y contenido adicionales.
   * @param item El ítem para el cual se está calculando el precio.
   * @param extraContenido El valor de contenido extra.
   * @param extraContenidoInclusion El valor de contenido extra para inclusiones.
   * @param mermWaste El valor de desperdicio de merma.
   * @returns El precio calculado con los valores de desperdicio y contenido adicionales.
   */
  calculatePriceWithScrap(
    item: any,
    extraContenido: number,
    extraContenidoInclusion: number,
    mermWaste: number
  ): number {
    // console.log('item', item);
    let scrap: number = 0;

    // Calcular el valor de scrap en función de los valores de contenido extra y merma.
    if (item.desperdicioExtraContenidoInclusion * 100 > 0) {
      scrap = extraContenidoInclusion + extraContenido + (mermWaste / 4000) * 100;
    } else {
      scrap = extraContenido + (mermWaste / 4000) * 100;
    }
    // precioFinal * (1 + extraContenido)
    // Calcular el precio en función del tipo de desperdicio.
    // let price: number = 0;
    // if (item.desperdicio) {
    //   // Calcular el porcentaje de scrap para el tipo de desperdicio
    //   const scrapPercentage: number = item.desperdicio * 100;
    //   price = item.price - scrapPercentage;
    // } else if (item.desperdicioExtraContenido) {
    //   // Calcular el porcentaje de scrap para el tipo de desperdicio extra de contenido
    //   const scrapPercentage: number = item.desperdicioExtraContenido * 100;
    //   price = item.price - scrapPercentage;
    // }

    // Calcular el precio final sumando el precio y (1 + scrap).
    return item.price * (1 + scrap);

    // return item.price + (1 + scrap)
  }

  calculateDesperdicioAndExtraInclusion(
    item: any,
    extraContenio: number,
    extraContenidoInclusion: number,
    mermWaste: number
  ) {
    if (item.desperdicioExtraContenidoInclusion * 100 > 0) {
      return extraContenidoInclusion + extraContenio + (mermWaste / 4000) * 100;
    } else {
      return extraContenio + (mermWaste / 4000) * 100;
    }
  }

  /**
   * This function calculate desperdicio extra inclusion
   * @param item item of material
   * @returns <number>
   */
  calculateDesperdicioExtraContenidoInclusion(item: any): number {
    return (
      item.desperdicioExtraContenidoInclusion * 100 +
      item.desperdicioExtraContenido * 100 +
      (this.mermWaste / 4000) * 100
    );
  }

  tableNutritional() {
    const rawMaterials = [...this.rawMaterialCostDetailsTypeTwo, ...this.rawMaterialCostDetailsTypeOne];
    const body = {
      rawMaterials: rawMaterials,
    };
    this.nutritionTableService.getDataNutritionTable(body).then((res: any) => {
      this.totalNutritionalCost = res;
    });
  }

  missingData() {
    if (
      this.rawMaterialCostDetailsTypeOne.length === 0 ||
      this.rawMaterialCostDetailsTypeTwo.length === 0 ||
      this.packagesCostsDetails.length === 0
    ) {
      this.messageMissingData = true;
    } else {
      this.messageMissingData = false;
    }
  }

  /**
   * This function changes the trigger values, depending on which object was selected in the costs table.
   * @param item The triggered item which details will display.
   */
  showCostDetails(item: string) {
    switch (item) {
      case 'manufacture':
        this.displayManufactureDetails = !this.displayManufactureDetails;
        break;

      case 'material1':
        this.displayRawMaterialDetails = !this.displayRawMaterialDetails;
        break;

      case 'material2':
        this.displayRawMaterialDetailsTwo = !this.displayRawMaterialDetailsTwo;
        break;

      case 'packaging':
        this.displayPackagingDetails = !this.displayPackagingDetails;
        break;

      case 'inclusions':
        this.displayInclusionDetails = !this.displayInclusionDetails;
        break;

      default:
        console.log('default');
    }
  }

  showPgDetails(item: string) {
    switch (item) {
      case 'variableCost':
        this.displayVariableCostDetails = !this.displayVariableCostDetails;
        break;
      case 'variableExpends':
        this.displayVariableExpendsDetails = !this.displayVariableExpendsDetails;
        break;
      case 'fixedDirect':
        this.displayFixedDirectDetails = !this.displayFixedDirectDetails;
        break;
      case 'fixed':
        this.displayFixedDetails = !this.displayFixedDetails;
    }
  }

  /**
   * This function loads the costs parameters stored in the local storage, to later compute the costs.
   */
  loadCostParameters() {
    const costParameters = JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
    this.costParameters = {
      trm: costParameters.dollarPrice,
      cacaoPrice: costParameters.cacaoPrice,
      scale: costParameters.weight,
      addCacaoPrice: costParameters.cacaoBonus,
    };
    if (parseInt(costParameters.cacaoBonus) > 0) {
      this.aseoIsCocoaPremium = true;
    }
  }

  /**
   * This function loads the price per kilogram and per unit of the product, after multiplying the total cost by the margin.
   */
  async loadBriefPrices() {
    let weightPerUnit = this.finishedIngredient.packagingPick.weightPerUnit;
    let margin: any = await this.marginService.getMarginByScale(this.costParameters.scale);
    this.prices.priceKg =
      (this.manufactureCost + this.rawMaterialCostTwo + this.rawMaterialCostOne + this.packagingCost) /
      (1 - margin.margen);
    this.prices.pricePerUnit = (this.prices.priceKg / 1000) * weightPerUnit || 25;
    this.prices.ebitda = margin.ebitda;
    this.prices.margin = margin.margen;
    this.pricesArr.push(this.prices);
    this.pricesKgFixed = this.prices.priceKg;
  }

  /**
   * This function finds the expired registers if there's any ingredient with expired status.
   */
  validateExpiredRegisters() {
    // console.log(this.rawMaterialCostDetails, this.inclusionsCostsDetails, this.packagesCostsDetails);
    this.expiredRegisters = [
      ...this.rawMaterialCostDetails,
      ...this.inclusionsCostsDetails,
      ...this.packagesCostsDetails,
    ].filter((r: any) => r.status == 'Vencido');
  }

  /**
   * This function loads the comments made by the user in each section of the current flow.
   */
  loadUserComments() {
    this.comments = JSON.parse(localStorage.getItem('comments') || '{}');
    this.listComments.push(this.comments);
  }

  /**
   * This function loads the projects selected by the user in the go beyond section of the impact summary.
   */
  loadGoBeyondProjects() {
    this.goBeyondProjects = JSON.parse(localStorage.getItem('beyond') || '{}');
    if (this.goBeyondProjects.length > 0) {
      for (let i of this.goBeyondProjects.projectSocial) {
        this.listGoBeyondProjects.push(i);
      }
      for (let i of this.goBeyondProjects.projectMaintain) {
        this.listGoBeyondProjects.push(i);
      }
      for (let i of this.goBeyondProjects.projectIncome) {
        this.listGoBeyondProjects.push(i);
      }
    }
  }

  /**
   * This function opens a modal where the user can write the name of the current project.
   * @returns The name of the project the user wrote.
   */
  async openProjectNameModal() {
    let name = await this.sms
      .addModal(ProjectNameModalComponent, {}, { autoFocus: true, closeOnClickOutside: true })
      .toPromise();
    return name;
  }

  /**This function opens a modal where the user can change of type brief */
  async openPasswordModal() {
    switch (this.briefType) {
      case 'client':
        let password = await this.sms
          .addModal(PasswordModalComponent, {}, { autoFocus: true, closeOnClickOutside: true })
          .toPromise();
        if (password) {
          let payload = {
            email: this.activeUser.email,
            password: password,
          };
          let res: any = await this.authService.authenticateUser(payload);
          if (res.success) {
            this.briefType = 'commercial';
            this.optionsSelect.forEach((element: any) => {
              element.briefType = true;
            });
          } else {
            alert('Ingresa correctamente tu contraseña.');
          }
        }
        break;
      case 'commercial':
        this.briefType = 'client';
        this.optionsSelect.forEach((element: any) => {
          if (
            element.name === 'Costs Structure' ||
            element.name === 'Product Description' ||
            element.name === 'Specifications' ||
            element.name === 'Nutritional Table'
          ) {
            element.briefType = false;
          }
        });
        break;
      default:
        break;
    }
  }

  /**This function open to modal of forms, this function suscribe if result to change data **/
  openChangeData() {
    this.sms
      .addModal(ChangeDataModalComponent, { data: this.projectBrief }, { autoFocus: true, closeOnClickOutside: true })
      .subscribe((res: any) => {
        if (res.modifiedParameters) {
          this.projectBrief.precioDolar = res.precioDolar.toString();
          this.projectBrief.precioCacao = res.precioCacao.toString();
          this.projectBrief.primaCacao = res.primaCacao;
          this.projectBrief.peso = res.peso;
          ls.set('brief', JSON.stringify(this.projectBrief), { encrypt: true });
          this.costParameters.trm = +res.precioDolar;
          this.costParameters.dollarPrice = +res.precioDolar;
          this.costParameters.cacaoPrice = +res.precioCacao;
          this.costParameters.weight = res.peso;
          this.costParameters.scale = res.peso;
          this.costParameters.addCacaoPrice = res.primaCacao;
          this.costParameters.cacaoBonus = res.primaCacao;
          ls.set('parameters', JSON.stringify(this.costParameters), { encrypt: true });
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
  }

  openModalChangePrices() {
    this.sms
      .addModal(
        ModalEditPricesComponent,
        { rawMaterialOne: this.rawMaterialCostDetailsTypeOne, rawMaterialTwo: this.rawMaterialCostDetailsTypeTwo },
        { autoFocus: true, closeOnClickOutside: true }
      )
      .subscribe((res: any) => {
        if (res.editPrices) {
          this.projectBrief.projectModified = true;
          ls.set('brief', JSON.stringify(this.projectBrief), { encrypt: true });
          ls.set(
            'pricesModifed',
            JSON.stringify({
              rawMaterialOne: res.rawMaterialOne,
              rawMaterialTwo: res.rawMaterialTwo,
            })
          );
          window.location.reload();
        } else {
          this.projectBrief.projectModified = false;
        }
      });
  }

  /**
   * This function saves the consolidated product of the current flow.
   */
  async saveConsolidatedProduct() {
    let name;
    if (this.typeUserLogged !== 'Feria') {
      name = await this.openProjectNameModal();
    }
    if (name==false || name==undefined || name==null) return;
    switch (this.currentFlow) {
      case 'ingredient':
        this.saveIngredient(name);
        break;

      case 'product':
        this.saveProduct(name);
        break;

      case 'launch':
        this.saveRTL(name);
        break;

      case 'portfolio':
        this.saveProduct(name);
        break;

      default:
        break;
    }
  }

  /**
   * This function saves a product made in the create ingredient flow.
   * @param projectName The name of the current project.
   */
  saveIngredient(projectName: string) {
    let product: any = {
      tipo: 'Ingrediente',
      chocolateId: this.finishedIngredient.chocolatePick.chocolateId,
      chocolateSap: this.finishedIngredient.chocolatePick.materialSap,
      combinacionEmpaqueId: this.finishedIngredient.packagingPick.combinacionEmpaqueId,
      formaId: this.finishedIngredient.shapePick.formaId,
      forma: this.finishedIngredient.shapePick._id,
      tieneInclusion: 'NO',
      palets: this.interfaceDataCubic.palets,
      contPalets: this.interfaceDataCubic.contX20,
      ebitda: this.listDataPgOperationsFinal[0]?.porcentajeEbitda,
      margenBruto: this.listDataPgOperationsFinal[0]?.porcentajeMargenBruto,
      idSalesForce: this.projectBrief.idSalesForce,
      createdAt: Date.now(),
    };
    localStorage.setItem('brief', JSON.stringify(product));
    this.productService.postProduct(product).then((res: any) => {
      this.saveProject(projectName, res.data._id);
    });
  }

  /**
   * This function saves a product made in the create product from scratch flow.
   * @param projectName The name of the current project.
   */
  saveProduct(projectName: string) {
    let tipo;
    if (this.origin !== 'Portfolio products') {
      tipo = 'Producto';
    } else {
      tipo = 'Portfolio products';
    }
    let percentageEbitda;
    let margenBruto;
    if (this.listDataPgOperationsFinal) {
      percentageEbitda = this.listDataPgOperationsFinal[0]?.porcentajeEbitda;
      margenBruto = this.listDataPgOperationsFinal[0]?.porcentajeMargenBruto;
    } else {
      percentageEbitda = 0;
      margenBruto = 0;
    }
    let product: any = {
      tipo: tipo,
      chocolateId: this.finishedIngredient.chocolatePick ? this.finishedIngredient.chocolatePick.chocolateId : '',
      chocolateSap: this.finishedIngredient.chocolatePick ? this.finishedIngredient.chocolatePick.materialSap : '',
      combinacionEmpaqueId: this.finishedIngredient.packagingPick.combinacionEmpaqueId,
      formaId: this.finishedIngredient.shapePick.formaId,
      forma: this.finishedIngredient.shapePick._id,
      palets: this.interfaceDataCubic.palets,
      contPalets: this.interfaceDataCubic.contX20,
      ebitda: percentageEbitda,
      margenBruto: margenBruto,
      idSalesForce: this.projectBrief.idSalesForce,
      nombreProductoTerminado: this.nameProductFinished || '',
      codigoSapProductoTerminado: this.sapCodeProductFinished || '',
      createdAt: Date.now(),
    };
    if (!product.chocolateId) delete product.chocolateId;
    if (!product.chocolate) delete product.chocolate;
    product = this.saveConsolidatedProductInclusions(product);
    if (this.designSelections) {
      product = this.saveDesignSelections(product);
    }
    localStorage.setItem('brief', JSON.stringify(product));
    this.productService.postProduct(product).then((res: any) => {
      this.saveProject(projectName, res.data._id);
    });
  }

  /**
   * This function saves a ready to launch product.
   * @param projectName The name of the current project.
   */
  saveRTL(projectName: string) {
    const rtlProduct = JSON.parse(localStorage.getItem('rtl') || '{}');
    let percentageEbitda;
    let percentageMargin;
    if (this.listDataPgOperationsFinal) {
      percentageEbitda = this.listDataPgOperationsFinal[0]?.porcentajeEbitda;
      percentageMargin = this.listDataPgOperationsFinal[0]?.porcentajeMargenBruto;
    } else {
      percentageEbitda = 0;
      percentageMargin = 0;
    }
    let product: any = {
      tipo: 'Ready to Launch',
      rtlId: rtlProduct.idRtl,
      rtl: rtlProduct._id,
      chocolateId: this.finishedIngredient.chocolatePick.chocolateId,
      chocolateSap: this.finishedIngredient.chocolatePick.materialSap,
      combinacionEmpaqueId: this.finishedIngredient.packagingPick.combinacionEmpaqueId,
      formaId: this.finishedIngredient.shapePick.formaId,
      forma: this.finishedIngredient.shapePick._id,
      palets: this.interfaceDataCubic.palets,
      contPalets: this.interfaceDataCubic.contX20,
      ebitda: percentageEbitda,
      margenBruto: percentageMargin,
      idSalesForce: this.projectBrief.idSalesForce,
      createdAt: Date.now(),
    };
    product = this.saveConsolidatedProductInclusions(product);
    localStorage.setItem('brief', JSON.stringify(product));
    this.productService.postProduct(product).then((res: any) => {
      this.saveProject(projectName, res.data._id);
    });
  }

  /**
   * This function validates if the product has inclusions or flavors, and then adds it to the product to save.
   * @param product The product to be checked for inclusions and flavors.
   * @returns The product with its inclusions and flavors.
   */
  saveConsolidatedProductInclusions(product: any) {
    let baseInclusion = this.finishedIngredient.inclusions?.base;
    let extraInclusion = this.finishedIngredient.inclusions?.extra;
    let flavor = this.finishedIngredient.flavor;
    product.tieneInclusion = baseInclusion || extraInclusion || flavor ? 'SI' : 'NO';
    if (baseInclusion) {
      product.inclusion1Id = baseInclusion.inclusion.ingredienteId;
      product.inclusion1Porcentaje = parseFloat(baseInclusion.percentage) / 100;
      product.inclusion1Sap = baseInclusion.inclusion.codigoSap;
    }
    if (extraInclusion) {
      product.inclusion2Id = extraInclusion.inclusion.ingredienteId;
      product.inclusion2Porcentaje = parseFloat(extraInclusion.percentage) / 100;
      product.inclusion2Sap = extraInclusion.inclusion.codigoSap;
    }
    if (flavor) {
      product.saborizante1Id = flavor.ingredienteId;
      product.saborizante1Porcentaje = parseFloat(flavor.pickedConcentration);
      product.saborizante1Sap = flavor.codigoSap;
    }
    return product;
  }

  /**
   * This function validates if the product has design inspiration selections, and then adds it to the product to save.
   * @param product The product to be checked for design inspiration selections.
   * @returns The product with its design selections.
   */
  saveDesignSelections(product: any) {
    if (this.designSelections.design) {
      product.disenoId = this.designSelections.design.texto;
      product.diseno = this.designSelections.design._id;
    }
    if (this.designSelections.artist) {
      product.artistaId = this.designSelections.artist.artistaId;
      product.artista = this.designSelections.artist._id;
    }
    return product;
  }

  /**
   * This function saves the the project brief and attachs a consolidated product to it.
   * @param projectName The name of the current project.
   * @param productId The database id of the consolidated product.
   */
  saveProject(projectName: string, productId: any) {
    const typeProject = localStorage.getItem('typeProject');
    let project: any = {
      nombre: projectName || this.projectBrief.nombre,
      usuario: this.activeUser.id,
      producto: productId,
      fecha: this.projectBrief.fecha,
      cliente: this.projectBrief.cliente,
      pais: this.projectBrief.pais,
      peso: this.projectBrief.peso,
      cantidadUnidades: this.interfaceDataCubic.quantityUnits,
      costoKg: this.prices.priceKg,
      precioUnidad: this.prices.pricePerUnit,
      primaCacao: this.projectBrief.primaCacao,
      precioCacao: this.projectBrief.precioCacao,
      precioDolar: this.projectBrief.precioDolar,
      emailFeria: this.projectBrief.emailFeria,
      clientGroup: this.projectBrief.clientGroup,
      salesExecutive: this.projectBrief.salesExecutive,
      projectModified: this.projectBrief.projectModified,
      tipoProyecto: typeProject || 'Experimental',
      otherComments: this.otherComments,
      customClaims: this.customClaims,
      createdAt: Date.now(),
    };
    this.projectService.postProject(project).then((res: any) => {
      if (typeProject === 'seguimiento') {
        this.postDataSalesForce(project);
      }
      this.saveDataProject(res.data._id);
      this.saveDataComexByProject(res.data._id);
      this.sms.addModal(ModalConfirmationComponent, {});
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 1000);
    });
  }

  saveDataProject(idProject: any) {
    let ebitdaPercentage;
    let marginPercentage;
    let dataRawMaterialOne;
    if (this.projectBrief.projectModified) {
      dataRawMaterialOne = this.rawMaterialsTypeOne;
    } else {
      dataRawMaterialOne = this.rawMaterialCostDetailsTypeOne;
    }
    if (this.listDataPgOperationsFinal) {
      ebitdaPercentage = this.listDataPgOperationsFinal[0]?.porcentajeEbitda;
      marginPercentage = this.listDataPgOperationsFinal[0]?.porcentajeMargenBruto;
    } else {
      ebitdaPercentage = 0;
      marginPercentage = 0;
    }
    let dataProject: any = {
      materiaPrima: this.arrMaterialRegisters,
      rawMaterialCostOne: this.rawMaterialCostOne,
      rawMaterialCostTwo: this.rawMaterialCostTwo,
      packagingCost: this.packagingCost,
      comex: this.totalOperationShipping,
      manufacturingCost: this.manufactureCost,
      manufacturingList: this.manufacturingCost,
      listRawMaterialOne: dataRawMaterialOne,
      listRawMaterialTwo: this.rawMaterialCostDetailsTypeTwo,
      listPackaging: this.packagesCostsDetails,
      inclusionsDetails: this.arrInclusions,
      ebitdaPercentage: ebitdaPercentage,
      marginPercentage: marginPercentage,
      id_proyecto: idProject,
    };
    this.projectService.postDataByProject(dataProject).then();
  }

  async postDataSalesForce(dataProject: any) {
    let totalProject = (this.projectBrief.peso * this.pricesKgFixed) / this.costParameters.trm;
    let dateTimeNow = moment.now();
    let threeMonthsAgo = moment(dateTimeNow).add(3, 'months').format('YYYY-MM-DD');
    let inclusion: any;
    if (this.finishedIngredient.inclusions) {
      inclusion = this.finishedIngredient.inclusions.base.inclusion.nombre;
    } else {
      inclusion = '';
    }
    let data = JSON.parse(ls.get('lead', { decrypt: true }) || '{}');
    let contactId;
    let accountId: any;
    if (data.typeConsult === 'lead') {
      if (data.newAccount) {
        let account: any = await this.salesForceService.createAccount(data.accountData);
        accountId = account.id;
      } else {
        accountId = data.clientSelected;
      }
      this.salesForceService
        .createContact({
          FirstName: data.contactData.FirstName,
          LastName: data.contactData.LastName,
          Email: data.contactData.Email,
        })
        .then((res: any) => {
          contactId = res.id;
          let body = this.bodyOpportunity(dataProject, totalProject, threeMonthsAgo, inclusion, contactId, accountId);
          if (data.newLead) {
            this.salesForceService
              .createLeads({
                FirstName: data.leadData.FirstName,
                LastName: data.leadData.LastName,
                Company: data.leadData.Company,
                Status: 'Converted',
                LeadSource: 'Digital',
                CLK_Approach__c: 'Social Media',
                CLK_SocialMedia__c: 'Facebook',
              })
              .then((res: any) => {
                this.salesForceService.postClientSalesForce(body).then();
              });
          } else {
            let dataLead = {
              Status: 'Converted',
              LeadSource: 'Digital',
              CLK_Approach__c: 'Social Media',
              CLK_SocialMedia__c: 'Facebook',
            };
            this.salesForceService.patchLeadSalesForce(data.idLeadSelected, dataLead).then((res: any) => {
              this.salesForceService.postClientSalesForce(body).then();
            });
          }
        });
    } else {
      let body = this.bodyOpportunityPatch(dataProject, totalProject, threeMonthsAgo, inclusion);
      if (data.idOpportunitySelected === '') {
        this.salesForceService.postClientSalesForce(body).then();
      } else {
        this.salesForceService.patchOpportunitySalesForce(data.idOpportunitySelected, body).then();
      }
    }
  }

  bodyOpportunityPatch(dataProject: any, totalProject: any, threeMonthsAgo: any, inclusion: any) {
    return {
      Name: dataProject.nombre,
      Description: 'Lukercreator',
      StageName: 'Proposal',
      Amount: totalProject,
      Probability: 10.0,
      AccountId: this.projectBrief.idSalesForce,
      TotalOpportunityQuantity: this.projectBrief.peso,
      CloseDate: threeMonthsAgo,
      LeadSource: 'Other',
      ForecastCategoryName: 'Pipeline',
      CurrencyIsoCode: 'USD',
      Inclussions__c: inclusion,
      Chocolate_Type__c: null,
      Form__c: this.finishedIngredient.shapePick.texto,
      OwnerId: '0058Z000007sPIfQAM',
      CLK_DescriptionoFirstTouchPoint__c: 'lukercreator',
      Country__c: this.projectBrief.pais,
      Packaging__c: `
      ${this.finishedIngredient.packagingPick.empaque1.material},
      ${this.finishedIngredient.packagingPick.empaque1.tipoEmpaque},
      ${this.finishedIngredient.packagingPick.empaque2.material},
      ${this.finishedIngredient.packagingPick.empaque2.tipoEmpaque}
      `,
    };
  }

  bodyOpportunity(
    dataProject: any,
    totalProject: any,
    threeMonthsAgo: any,
    inclusion: any,
    contactId: any,
    accountId: any
  ) {
    return {
      Name: dataProject.nombre,
      Description: 'Lukercreator',
      StageName: 'Proposal',
      Amount: totalProject,
      Probability: 10.0,
      AccountId: accountId,
      TotalOpportunityQuantity: this.projectBrief.peso,
      CloseDate: threeMonthsAgo,
      LeadSource: 'Other',
      ForecastCategoryName: 'Pipeline',
      CurrencyIsoCode: 'USD',
      Inclussions__c: inclusion,
      Chocolate_Type__c: null,
      ContactId: contactId,
      Form__c: this.finishedIngredient.shapePick.texto,
      OwnerId: '0058Z000007sPIfQAM',
      CLK_DescriptionoFirstTouchPoint__c: 'lukercreator',
      Country__c: this.projectBrief.pais,
      Packaging__c: `
      ${this.finishedIngredient.packagingPick.empaque1.material},
      ${this.finishedIngredient.packagingPick.empaque1.tipoEmpaque},
      ${this.finishedIngredient.packagingPick.empaque2.material},
      ${this.finishedIngredient.packagingPick.empaque2.tipoEmpaque}
      `,
    };
  }

  saveDataComexByProject(idProject: any) {
    if (this.typeUserLogged !== 'Feria') {
      let dataProject: any = {
        country: this.informationTransporting.country,
        incoterm: this.informationTransporting.incoterm,
        numberOfContainers: this.informationTransporting.numberOfContainers,
        portDestination: this.informationTransporting.portDestination,
        portSource: this.informationTransporting.portSource,
        priceUsd: this.informationTransporting.priceUsd,
        typeContainer: this.informationTransporting.typeContainer,
        projectId: idProject,
      };
      this.projectComexService.postDataComexByProject(dataProject);
    }
  }

  async getDataComexByProject() {
    await this.projectComexService.getDataComexByProject(this.projectBrief.id).then((res: any) => {
      this.informationTransporting = res;
      localStorage.setItem('objectTransporting', JSON.stringify(this.informationTransporting));
    });
  }

  /**
   * This function navigates to a specific step back.
   * @param step The number of the step the user wants to return to.
   */
  returnToStep(step: number) {
    this.router.navigateByUrl(`/create-${this.currentFlow}/steps/${step}`);
  }

  public onExportPDF({ exportTo }: { exportTo: string }) {
    if (exportTo == 'client') {
      this.clientPDF();
    } else if (exportTo == 'commercial') {
      this.commercialPDF();
    }
  }

  async transformUrlImagesToCanvas(idHtml: string) {
    const prueba: any = document.getElementById(idHtml);
    let fileUri: any;
    if (prueba) {
      return domtoimage.toPng(prueba).then((canvas) => {
        fileUri = canvas;
        return fileUri;
      });
    }
  }

  async transFormImageToBase64(url: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ctx.drawImage(img, 0, 0);
        resolve(canvas);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url + '?not-from-cache-please';
      img.crossOrigin = 'anonymous';
    });
  }

  async loadImages() {
    this.interfaceImageCanvas.background1 = await this.transFormImageToBase64(
      'https://d169su6y068qsd.cloudfront.net/create/newsfeed/fondoluker.png'
    );
    this.interfaceImageCanvas.frontPage1 = await this.transFormImageToBase64(
      'https://d169su6y068qsd.cloudfront.net/create/newsfeed/portada-luker.png'
    );
    this.interfaceImageCanvas.frontPage2 = await this.transFormImageToBase64(
      'https://d169su6y068qsd.cloudfront.net/create/newsfeed/portada-luker.png'
    );
    this.interfaceImageCanvas.sustainability = await this.transFormImageToBase64(
      'https://d169su6y068qsd.cloudfront.net/create/newsfeed/sustainability1.png'
    );
    this.interfaceImageCanvas.sustainability2 = await this.transFormImageToBase64(
      'https://d169su6y068qsd.cloudfront.net/create/newsfeed/sustainability2.png'
    );
  }

  public async commercialPDF() {
    if (!this.messageMissingData) {
      this.spinner.show();
      const doc = new jsPDF({ compress: true });
      // Download PDF
      this.displayManufactureDetails = true;
      this.displayPackagingDetails = true;
      this.displayRawMaterialDetails = true;
      this.displayRawMaterialDetailsTwo = true;
      this.hideDataPdf = true;
      doc.addImage(this.interfaceImageCanvas.frontPage1, 0, 0, 210, 300);
      doc.setFont('Helvetica', 'normal', '700');
      doc.setFontSize(32);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor('#333');
      let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      let splitTitle = doc.splitTextToSize(this.projectBrief.cliente, 120);
      doc.text(splitTitle, pageWidth / 2, 120, { align: 'center' });
      doc.addPage();
      doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
      let projectBriefCommercial = await this.transformUrlImagesToCanvas('project-brief');
      doc.setFont('Helvetica', 'normal', '700');
      doc.setFontSize(20);
      doc.text('Project Brief', 20, 55);
      doc.addImage(projectBriefCommercial, 'PNG', 20, 60, 190, 70);
      doc.setFont('Helvetica', 'normal', '700');
      doc.setFontSize(20);
      doc.text('My Claims', 20, 140);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(20, 145, 25, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Slave free', 24, 151);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(50, 145, 37, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Child labour free', 55, 151);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(90, 145, 35, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('0 Deforestation', 95, 151);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(130, 145, 35, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Crafted at origin', 134, 151);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(20, 160, 42, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Cacao fino de aroma', 24, 166);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(68, 160, 42, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Sustainable Source', 73, 166);
      doc.setFillColor('#F6D24C');
      doc.setDrawColor('#F6D24C');
      doc.roundedRect(115, 160, 42, 10, 5, 5, 'F');
      doc.setFontSize(10);
      doc.text('Ethical Chocolate', 121, 166);
      if (this.origin !== 'Portfolio products') {
        let productDescription = await this.transformUrlImagesToCanvas('description-pdf');
        doc.addImage(productDescription, 'PNG', 20, 175, 180, 100);
      }
      doc.addPage();
      doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
      doc.setFontSize(20);
      doc.text('Pricing (USD)', 20, 40);
      let pricing = await this.transformUrlImagesToCanvas('pricing-usd-pdf');
      doc.addImage(pricing, 'PNG', 20, 45, 170, 30);
      doc.setFillColor('#ffffff');
      doc.setDrawColor('#ffffff');
      doc.roundedRect(20, 80, 175, 180, 2, 2, 'F');
      let costStructure = await this.transformUrlImagesToCanvas('cost-structure-pdf');
      doc.addImage(costStructure, 'PNG', 27, 85, 160, 170);
      doc.addPage();
      doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
      doc.setFontSize(20);
      doc.text('Specifications', 20, 40);
      doc.setFontSize(15);
      let shippingDimensions = await this.transformUrlImagesToCanvas('table-incoterm');
      if (shippingDimensions) {
        doc.text('Shipping dimensions table', 20, 50);
        doc.addImage(shippingDimensions, 'PNG', 20, 55, 130, 50);
      }
      if (this.currentFlow !== 'portfolio') {
        doc.text('Packaging scrap', 20, 115);
        let tablePackagingScrap = await this.transformUrlImagesToCanvas('table-scrap');
        doc.addImage(tablePackagingScrap, 'PNG', 20, 120, 130, 35);
        doc.text('Incoterm factor table', 20, 160);
        let tableComex = await this.transformUrlImagesToCanvas('table-comex');
        doc.addImage(tableComex, 'PNG', 20, 165, 130, 38);
      } else {
        doc.text('Packaging scrap', 20, 50);
        let tablePackagingScrap = await this.transformUrlImagesToCanvas('table-scrap');
        doc.addImage(tablePackagingScrap, 'PNG', 20, 55, 130, 30);
        doc.text('Incoterm factor table', 20, 93);
        let tableComex = await this.transformUrlImagesToCanvas('table-comex');
        doc.addImage(tableComex, 'PNG', 20, 98, 130, 38);
      }
      let tableRawMaterialScrap = await this.transformUrlImagesToCanvas('table-raw');
      if (tableRawMaterialScrap) {
        doc.text('Raw Material Scrap', 20, 232);
        doc.addImage(tableRawMaterialScrap, 'PNG', 20, 235, 130, 20);
      }
      doc.addPage();
      doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
      // if (this.typeUser == 'Administrador' || this.typeUser == 'EjecutivoSr') {
      if (this.typeUser == 'ADMIN' || this.typeUser == 'SUPERADMIN' || this.typeUser == 'EJECUTIVOSR') {
        doc.setFontSize(20);
        doc.text('Ingredients list', 20, 40);
        let ingredientsList = await this.transformUrlImagesToCanvas('ingredient-list');
        if (ingredientsList) {
          doc.addImage(ingredientsList, 'PNG', 35, 43, 150, 90);
        }
      }
      // if (this.typeUser === 'Administrador' || this.typeUser === 'EjecutivoSr' || this.currentFlow === 'portfolio') {
      if (this.typeUser == 'ADMIN' || this.typeUser == 'SUPERADMIN' || this.typeUser == 'EJECUTIVOSR' || this.currentFlow === 'portfolio') {
        doc.text('Shipping information costs', 20, 140);
        let informationComex = await this.transformUrlImagesToCanvas('information-comex');
        doc.addImage(informationComex, 'PNG', 20, 145, 80, 50);
        let informationTransport = await this.transformUrlImagesToCanvas('information-transport');
        doc.addImage(informationTransport, 'PNG', 110, 145, 80, 50);
      } else {
        doc.text('Shipping information costs', 20, 50);
        let informationComex = await this.transformUrlImagesToCanvas('information-comex');
        doc.addImage(informationComex, 'PNG', 20, 55, 80, 50);
        let informationTransport = await this.transformUrlImagesToCanvas('information-transport');
        doc.addImage(informationTransport, 'PNG', 110, 55, 80, 50);
      }

      // if (this.typeUser == 'Administrador' || this.typeUser == 'EjecutivoSr') {
      if (this.typeUser == 'ADMIN' || this.typeUser == 'SUPERADMIN' || this.typeUser == 'EJECUTIVOSR') {
        doc.addPage();
        doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
        doc.setFontSize(20);
        doc.text('P&L', 20, 40);
        doc.setFillColor('#ffffff');
        doc.setDrawColor('#ffffff');
        doc.roundedRect(20, 43, 180, 230, 2, 2, 'F');
        let pl = await this.transformUrlImagesToCanvas('pl-pdf');
        doc.addImage(pl, 'PNG', 25, 45, 170, 250);
      }
      doc.addPage();
      doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
      doc.setFontSize(10);
      doc.text('Comments', 20, 40);
      doc.setFillColor('#ffffff');
      doc.setDrawColor('#ffffff');
      doc.roundedRect(20, 43, 180, 60, 2, 2, 'F');
      doc.text('Nutritional Table', 20, 110);
      let nutritionalTable = await this.transformUrlImagesToCanvas('nutritional-table-pdf');
      doc.addImage(nutritionalTable, 'PNG', 20, 115, 90, 90);
      if (this.comments || this.otherComments !== '') {
        let comments = await this.transformUrlImagesToCanvas('comments');
        doc.addImage(comments, 'PNG', 25, 45, 170, 20);
      }
      doc.setFontSize(10);
      doc.setTextColor('#333');
      doc.text('Approved by: ', 20, 260);
      doc.setFillColor('#333');
      doc.setDrawColor('#333');
      doc.roundedRect(40, 260, 80, 1, 1, 1, 'F');
      setTimeout(() => {
        this.hideDataPdf = false;
      }, 200);
      let dateFormat = moment().format('YYYY-MM-DD');
      this.spinner.hide();
      doc.save(`Product Summary Comercial ${dateFormat}.pdf`);
    }
  }

  public async clientPDF() {
    this.spinner.show();
    const doc = new jsPDF({ compress: true });
    this.hideDataPdf = true;
    let dateFormat = moment().format('YYYY-MM-DD');
    doc.addImage(this.interfaceImageCanvas.frontPage2, 0, 0, 210, 300, '', 'FAST');
    doc.setFontSize(32);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#333');
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    let splitTitle = doc.splitTextToSize(this.projectBrief.cliente, 120);
    doc.text(splitTitle, pageWidth / 2, 120, { align: 'center' });
    doc.addPage();
    doc.setTextColor('#000000');
    doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300, '', 'MEDIUM');
    let projectBrief = await this.transformUrlImagesToCanvas('project-brief');
    doc.setFont('Helvetica', 'normal', '700');
    doc.setFontSize(20);
    doc.text('Project Brief', 20, 55);
    doc.addImage(projectBrief, 'PNG', 20, 60, 190, 50);
    doc.setFont('Helvetica', 'normal', '700');
    doc.setFontSize(20);
    doc.text('My Claims', 20, 118);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(20, 123, 25, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Slave free', 24, 129);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(50, 123, 37, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Child labour free', 55, 129);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(90, 123, 35, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('0 Deforestation', 95, 129);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(130, 123, 35, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Crafted at origin', 134, 129);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(20, 136, 42, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Cacao fino de aroma', 24, 142);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(68, 136, 42, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Sustainable Source', 73, 142);
    doc.setFillColor('#F6D24C');
    doc.setDrawColor('#F6D24C');
    doc.roundedRect(115, 136, 42, 10, 5, 5, 'F');
    doc.setFontSize(10);
    doc.text('Ethical Chocolate', 121, 142);
    doc.setFont('Helvetica', 'normal', '700');
    doc.setFontSize(20);
    let verticalPositionProduct;
    if (this.customClaims.length > 0) {
      doc.text('Custom claims', 20, 155);
      doc.setFontSize(10);
      doc.text(this.customClaims.join(', '), 20, 162);
      verticalPositionProduct = 168;
    } else {
      verticalPositionProduct = 155;
    }

    if (this.currentFlow === 'portfolio') {
      doc.text('', 20, 150);
    } else {
      let product_description = await this.transformUrlImagesToCanvas('description-pdf');
      doc.addImage(product_description, 'PNG', 20, verticalPositionProduct, 180, 100);
    }
    doc.addPage();
    doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300, '', 'MEDIUM');
    let comexBrief = await this.transformUrlImagesToCanvas('shipping-cost');
    doc.addImage(comexBrief, 'PNG', 20, 40, 90, 60, '', 'MEDIUM');
    let shippingDimensionTable = await this.transformUrlImagesToCanvas('table-incoterm');
    doc.addImage(shippingDimensionTable, 'PNG', 113, 40, 90, 60, '', 'MEDIUM');
    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.roundedRect(20, 115, 180, 100, 2, 2, 'F');
    doc.addImage(this.interfaceImageCanvas.sustainability, 'PNG', 25, 120, 170, 90, '', 'MEDIUM');
    doc.addPage();
    doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300, '', 'MEDIUM');
    doc.text('Comments', 20, 180);
    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.roundedRect(20, 185, 170, 60, 2, 2, 'F');
    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.roundedRect(20, 40, 170, 100, 2, 2, 'F');
    doc.addImage(this.interfaceImageCanvas.sustainability2, 'PNG', 45, 40, 120, 90, '', 'MEDIUM');
    let pricingUsd = await this.transformUrlImagesToCanvas('pricing-usd-pdf');
    doc.setFontSize(10);
    doc.text('Pricing', 20, 150);
    doc.addImage(pricingUsd, 'PNG', 20, 155, 180, 20, '', 'MEDIUM');
    doc.setFontSize(10);
    if (this.comments) {
      let comments = await this.transformUrlImagesToCanvas('comments');
      doc.addImage(comments, 'PNG', 25, 190, 150, 40, '', 'MEDIUM');
    }
    doc.addPage();
    doc.addImage(this.interfaceImageCanvas.background1, 0, 0, 210, 300);
    doc.text('Nutritional Table', 20, 50);
    let nutritionalTable = await this.transformUrlImagesToCanvas('nutritional-table-pdf');
    doc.addImage(nutritionalTable, 'PNG', 20, 55, 90, 90, '', 'MEDIUM');
    doc.setFillColor('#FAE8A5');
    doc.setDrawColor('#FAE8A5');
    doc.roundedRect(20, 150, 165, 10, 2, 2, 'F');
    doc.setFontSize(10);
    doc.text('The following quotation is valid for 30 days', 68, 156);
    doc.setFontSize(10);
    doc.setTextColor('#333');
    doc.text('Approved by: ', 20, 260);
    doc.setFillColor('#333');
    doc.setDrawColor('#333');
    doc.roundedRect(40, 260, 80, 1, 1, 1, 'F');
    setTimeout(() => {
      this.hideDataPdf = false;
    }, 200);
    this.spinner.hide();
    doc.save(`Product Summary ${dateFormat}.pdf`);
  }
}
