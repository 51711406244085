import { Component, OnInit } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import { MoneyApisService } from '../../../core/services/money-apis/money-apis.service';
import { ModalConsultComponent } from '../../rd-projects/modal-consult/modal-consult.component';
import { RdServicesService } from '../../../core/services/rd-project/rd-services.service';
import { CostService } from '../../../core/services/costs/cost.service';

@Component({
  selector: 'app-raw-material-one',
  templateUrl: './raw-material-one.component.html',
  styleUrls: ['./raw-material-one.component.css'],
})
export class RawMaterialOneComponent implements OnInit {
  /** Icons **/
  faMagnifyingGlass = faMagnifyingGlass;

  /** Price of currently trm**/
  dollarPrices: any;

  /** List of cacao prices **/
  cocoaPrices: any;

  /**Sap code to search **/
  sapCode = '';

  /**Dollar selected **/
  selectedDollar = '';

  /**Cacao selected **/
  selectedCocoa = '';

  /**validation of custom cacao or dollar **/
  otherCacao = false;
  otherDollar = false;

  /**Price of material to consult **/
  priceRawMaterial: any;

  /**name of raw material **/
  nameRawMaterial: any;

  /**code of raw material **/
  codeRawMaterial: any;

  /**loading to consult information **/
  loading = false;

  /**Global message of error **/
  messageError: any;

  /**All costs of raw materials **/
  allCostRawMaterial: any;

  /**Variable validate of type raw material consult, if code sap or show all costs raw material **/
  validationTypeConsult = false;

  constructor(
    private sms: SimpleModalService,
    private moneyService: MoneyApisService,
    private rdServices: RdServicesService,
    private costService: CostService
  ) {}

  ngOnInit(): void {
    this.consultDollar();
    this.consultCocoaPrices();
  }

  /**Async function to open modal of search raw material one **/
  async openModal() {
    localStorage.setItem('tipoMateriaPrima', JSON.stringify('Materia prima 1'));
    localStorage.setItem('tipoConsulta', JSON.stringify('Ingrediente'));
    let sap = await this.sms
      .addModal(ModalConsultComponent, { autoFocus: true, closeOnClickOutside: true })
      .toPromise();
    if (sap) {
      this.sapCode = sap;
    }
  }

  /**Function to consult in service of trm currently **/
  consultDollar() {
    this.moneyService.returnArrayDollar().then((res: any) => {
      this.dollarPrices = res;
    });
  }

  /**Function to consult in service and return of list prices cocoa  **/
  consultCocoaPrices() {
    this.moneyService.returnCocoaPrices().then((res: any) => {
      this.cocoaPrices = res;
    });
  }

  /** Function onclick to search raw material and assigned prices and name of raw material **/
  async searchPriceRawMaterial() {
    this.validationTypeConsult = false;
    this.loading = true;
    this.rdServices
      .getRawMaterialTypeOne(this.sapCode)
      .then(async (res: any) => {
        this.messageError = '';
        let costsRawMaterialOne: any = await this.costService.getRawMaterialTypeOne(
          parseFloat(this.selectedDollar),
          parseFloat(this.selectedCocoa)
        );
        this.nameRawMaterial = res[0].nombreComponente;
        this.codeRawMaterial = res[0].componente;
        if (
          this.nameRawMaterial.includes('PS LICOR') ||
          this.nameRawMaterial.includes('PS COCOA NIBS') ||
          this.nameRawMaterial.includes('PS NIBS CLUSTER') ||
          this.nameRawMaterial.includes('Ps Nibs')
        ) {
          this.priceRawMaterial = costsRawMaterialOne.precioLicor;
        } else if (this.nameRawMaterial.includes('PS MANTECA')) {
          this.priceRawMaterial = costsRawMaterialOne.precioManteca;
        } else if (this.nameRawMaterial.includes('PS COCOA') || this.nameRawMaterial.includes('PS VIRUTA')) {
          this.priceRawMaterial = costsRawMaterialOne.precioCocoa;
        } else if (this.codeRawMaterial=='2000926'){
          this.priceRawMaterial = costsRawMaterialOne.precioCafeColombiano
        }
        this.loading = false;
      })
      .catch((err: any) => {
        this.loading = false;
        this.nameRawMaterial = '';
        this.priceRawMaterial = '';
        this.messageError = 'Not found this product';
      });
  }

  /**This function consult all prices of raw material type one **/
  searchAllRawMaterial() {
    this.validationTypeConsult = true;
    this.loading = true;
    this.costService
      .getRawMaterialTypeOne(parseFloat(this.selectedDollar), parseFloat(this.selectedCocoa))
      .then((res: any) => {
        this.loading = false;
        this.allCostRawMaterial = res;
        this.messageError = '';
      })
      .catch((err: any) => {
        this.loading = false;
        this.messageError = 'Not found prices';
      });
  }
}
