<div class="container-brief" *ngIf="typeUserLogged != 'Feria'">
  <div class="row h-100">
    <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 10px 40px">
      <div class="row desktop-options">
        <div class="col-xl-12 col-lg-12 mb-3 " [hidden]="item.briefType === false"
             *ngFor="let item of optionsSelect;let i = index">
          <a href="/create-product/product-summary{{item.redirect}}"
             class="select-section"
             [class.selected-item]="item.activated"
             (click)="selectItem(i)">{{item.name}}
          </a>
        </div>
      </div>
      <div class="mobile-section">
        <div  *ngFor="let item of optionsSelect;let i = index" [hidden]="item.briefType === false" style="margin-right: 30px;">
          <a
             class="select-section"
             [class.selected-item]="item.activated"
             (click)="selectItem(i)">{{item.name}}
          </a>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xl-10 col-md-12 col-sm-12 col-xs-12">
      <div class="container-results-brief">
        <div class="step-info">
          <p class="step-title" *ngIf="briefType == 'commercial'">Sales executive product summary</p>
          <p class="step-title" *ngIf="briefType == 'client'">Customer product summary</p>
          <div class="exports-container">
            <div class="btn-icon" (click)="openChangeData()" *ngIf="sessionData.role == 'admin'" [hidden]="projectBrief.projectModified">
              <div class="icon-container">
                <fa-icon [icon]="faPencil" class="edit-icon"></fa-icon>
              </div>
              <p class="export-title">Change data</p>
            </div>
            <div class="btn-icon" (click)="openPasswordModal()" *ngIf="sessionData.role == 'admin'">
              <div class="icon-container">
                <img src="./../../../assets/summary/swap.png" alt="logo-export" class="export-img" />
              </div>
              <p class="export-title">Change brief type</p>
            </div>
            <div class="btn-icon" (click)="onExportPDF({ exportTo: briefType })"
                 [class.disabledPdf]="messageMissingData">
              <div class="icon-container">
                <img src="./../../../assets/summary/PDF.png" alt="logo-export" class="export-img" />
              </div>
              <p class="export-title">Export as PDF</p>
              <p *ngIf="messageMissingData" class="text-danger">Missing data</p>
            </div>
            <div class="btn-icon" (click)="exportExcel()" *ngIf="briefType == 'commercial'">
              <div class="icon-container">
                <img src="./../../../assets/summary/PDF.png" alt="logo-export" class="export-img" />
              </div>
              <p class="export-title">Export as EXCEL</p>
            </div>
          </div>
        </div>
        <ng-container *ngIf="briefType == 'commercial'">
          <div class="brief-container">
            <label class="costs-subtitle mb-3" #project_brief id="project-brief">Project Brief</label>
            <div class="row p-1">
              <div class="col-6 column-data mb-3 mr-3">
                <div class="brief-col">
                  <label class="title-col-brief">Date:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.fecha}}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Client: </label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.cliente}}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Prepared by:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{ activeUser.nombre + ' ' + activeUser.apellido }}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Country:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.pais}}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Dollar price:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.precioDolar}} $</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Cacao price:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{ projectBrief.precioCacao }} $</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Quantity</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief" *ngIf="projectBrief.pais !== 'USA'">{{ projectBrief.peso }} kg</label>
                  <label class="title-col-brief" *ngIf="projectBrief.pais === 'USA'">{{ projectBrief.pound }} lb</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Additional bonus:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{ projectBrief.primaCacao }}</label>
                </div>
              </div>
              <div class="col-12">
                <p class="title-col-brief">This quotation is valid for 30 days</p>
              </div>
            </div>
          </div>
          <div class="brief-container" #my_claims id="my-claims">
            <p class="selection-subtitle mt-2">My Claims</p>
            <div class="claims-list mt-2">
              <div class="claim-item">
                <label>Slave free</label>
              </div>
              <div class="claim-item">
                <label>Child labour free</label>
              </div>
              <div class="claim-item">
                <label>0 Deforestation</label>
              </div>
              <div class="claim-item">
                <label>Crafted at origin</label>
              </div>
              <div class="claim-item">
                <label>Cacao Fino de aroma</label>
              </div>
              <div class="claim-item">
                <label>Sustainable Source</label>
              </div>
              <div class="claim-item">
                <label>Ethical Chocolate</label>
              </div>
            </div>
            <p-chips [(ngModel)]="customClaims" [max]="5" separator=","></p-chips>
          </div>
          <div class="costs-info" #costs_structure id="costs-structure">
            <p class="costs-subtitle">Costs Structure</p>
            <p class="costs-text">Cost breakdown by product segment.</p>
            <div class="d-flex justify-content-end w-100 mb-3">
              <button class="btn-others" (click)="openModalChangePrices()">Edit prices</button>
            </div>
            <div class="costs-table" id="cost-structure-pdf">
              <table class="table table-costs" id="excel-table">
                <caption style="display: none">Tabale cost structure</caption>
                <thead>
                <tr>
                  <th scope="col">Detail</th>
                  <th scope="col">Item</th>
                  <th scope="col">Subtotal</th>
                  <th scope="col">% of Total</th>
                  <th scope="col">Scrap</th>
                </tr>
                </thead>

                <tbody>
                <tr class="accordion-toggle">
                  <td>
                    <button
                      class="show-details-costs"
                      (click)="showCostDetails('manufacture')"
                      [class.hide-details-costs]="displayManufactureDetails"></button>
                  </td>
                  <td>Manufacturing</td>
                  <td>{{ manufactureCost | number: '1.0-0' }} $</td>
                  <td>
                    {{
                    (manufactureCost / (manufactureCost + rawMaterialCostTwo + (rawMaterialCostOne || rawMaterialCostOneBespoke) + packagingCost)) * 100
                      | number: '1.2-2'
                    }}
                    %
                  </td>
                  <td></td>
                </tr>

                <ng-container *ngIf="displayManufactureDetails">
                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>Direct Labour</td>
                    <td>{{ manufacturingCost.zmod | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zmod / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>

                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>Maintance</td>
                    <td>{{ manufacturingCost.zman | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zman / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>

                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>Energy</td>
                    <td>{{ manufacturingCost.zenr | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zenr / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>

                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>Gas</td>
                    <td>{{ manufacturingCost.zgas | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zgas / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>

                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>Depreciation</td>
                    <td>{{ manufacturingCost.zdep | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zdep / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>

                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>MOI and Other CIF</td>
                    <td>{{ manufacturingCost.zoin | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.zoin / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>
                  <tr [@inOutAnimation]>
                    <td></td>
                    <td>External manufacturing</td>
                    <td>{{ manufacturingCost.maquila | number: '1.0-0' }} $</td>
                    <td>
                      {{ (manufacturingCost.maquila / manufactureCost) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td></td>
                  </tr>
                </ng-container>

                <tr class="accordion-toggle">
                  <td>
                    <button
                      class="show-details-costs"
                      (click)="showCostDetails('material1')"
                      [class.hide-details-costs]="displayRawMaterialDetails"></button>
                  </td>
                  <td>Raw material 1</td>
                  <td>{{ (rawMaterialCostOne || rawMaterialCostOneBespoke) | number: '1.0-0' }} $</td>
                  <td>
                    {{
                    (rawMaterialCostOne / (manufactureCost + rawMaterialCostTwo + (rawMaterialCostOne || rawMaterialCostOneBespoke) + packagingCost)) *
                    100 | number: '1.2-2'
                    }}
                    %
                  </td>
                  <td></td>
                </tr>

                <ng-container *ngIf="displayRawMaterialDetails">
                  <tr *ngFor="let item of rawMaterialCostDetailsTypeOne" [@inOutAnimation]>
                    <td></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.price | number: '1.0-0' }} $</td>
                    <!--<td>{{ calculatePriceWithScrap(item, arrWaste[1]?.desperdicioExtraContenido * 100 || 0,
                                                                                  arrWaste[1]?.desperdicioExtraContenidoInclusion * 100 || 0, mermWaste) | number: '1.0-0' }} $</td>-->
                    <td>
                      {{ (item.price / rawMaterialCostOne) * 100 | number: '1.2-2' }}
                      %
                    </td>
                    <td *ngIf="projectBrief.pais !== 'USA'">{{ ((mermWaste / 4000) * 100 ) + (arrWaste[1]?.desperdicioExtraContenido * 100
                      || 0 * 100) | number:
                      '1.0-0' }}%</td>
                    <td *ngIf="projectBrief.pais === 'USA'">{{ ((mermWaste / 4000) * 100) + (arrWaste[1]?.desperdicioExtraContenido * 100 ||
                      0 * 100) | number:
                      '1.0-0' }}%</td>
                  </tr>
                </ng-container>
                <tr class="accordion-toggle">
                  <td>
                    <button
                      class="show-details-costs"
                      (click)="showCostDetails('material2')"
                      [class.hide-details-costs]="displayRawMaterialDetailsTwo"></button>
                  </td>
                  <td>Raw material 2</td>
                  <td>{{ rawMaterialCostTwo | number: '1.0-0' }} $</td>
                  <td>
                    {{
                    (rawMaterialCostTwo / (manufactureCost + (rawMaterialCostOne || rawMaterialCostOneBespoke) + rawMaterialCostTwo + packagingCost)) *
                    100 | number: '1.2-2'
                    }}
                    %
                  </td>
                  <td></td>
                </tr>
                <ng-container *ngIf="displayRawMaterialDetailsTwo">
                  <tr *ngFor="let item of rawMaterialCostDetailsTypeTwo" [@inOutAnimation]>
                    <td></td>
                    <td>{{ item.nombre }}</td>
                    <!--<td>{{ getFinalPrice(item) | number: '1.0-0' }} $</td>-->
                    <td>{{ item.price | number: '1.0-0' }} $</td>
                    <!-- <td>{{ calculatePriceWithScrap(item, arrWaste[1]?.desperdicioExtraContenido * 100 || 0,
                                          arrWaste[1]?.desperdicioExtraContenidoInclusion * 100 || 0, mermWaste) | number: '1.0-0' }} $</td> -->
                    <td>{{ (item.price / rawMaterialCostTwo) * 100 | number: '1.2-2' }}%</td>
                    <td>{{ calculateDesperdicioAndExtraInclusion(item, arrWaste[1]?.desperdicioExtraContenido * 100 || 0,
                      arrWaste[1]?.desperdicioExtraContenidoInclusion * 100 || 0, mermWaste) | number: '1.0-0' }}%</td>
                    <!--<td *ngIf="showDesperdicio(item)">{{ calculateDesperdicio(item) | number: '1.0-0' }}%</td>
                    <td *ngIf="showDesperdicioExtraContenidoInclusion(item)">
                      {{ calculateDesperdicioExtraContenidoInclusion(item) | number: '1.0-0' }}%
                    </td>-->
                  </tr>
                </ng-container>

                <tr class="accordion-toggle">
                  <td>
                    <button
                      class="show-details-costs"
                      (click)="showCostDetails('packaging')"
                      [class.hide-details-costs]="displayPackagingDetails"></button>
                  </td>
                  <td>Packaging</td>
                  <td>{{ packagingCost | number: '1.0-0' }} $</td>
                  <td>
                    {{
                    (packagingCost / (manufactureCost + rawMaterialCostTwo + (rawMaterialCostOne || rawMaterialCostOneBespoke) + packagingCost)) * 100
                      | number: '1.2-2'
                    }}
                    %
                  </td>
                  <td></td>
                </tr>

                <ng-container *ngIf="displayPackagingDetails">
                  <tr *ngFor="let item of packagesCostsDetails" [@inOutAnimation]>
                    <td></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.price | number : '1.2-2' }} $</td>
                    <td>{{ (item.price / packagingCost) * 100 | number: '1.2-2' }} %</td>
                    <td>{{ item.desperdicios * 100 | number: '1.0-0' }} %</td>
                  </tr>
                </ng-container>

                <tr>
                  <td></td>
                  <td>Total</td>
                  <td>
                    {{ manufactureCost + rawMaterialCostTwo + (rawMaterialCostOne || rawMaterialCostOneBespoke) + packagingCost | number: '1.0-0' }}
                    $
                  </td>
                  <td>100 %</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pricing-container" #pricing_usd id="pricing-usd">
            <p class="costs-subtitle">Pricing (USD)</p>
            <div>
              <div class="selections-container" id="pricing-usd-pdf">
                <div class="pricing-table">
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Description</p>
                    </div>
                    <p class="brief-value" *ngIf="finishedIngredient.chocolatePick && origin !== 'Portfolio products'">
                      {{
                      finishedIngredient.chocolatePick.texto || '' +
                      ' ' +
                      finishedIngredient.shapePick.texto || '' +
                      ' ' +
                      finishedIngredient.packagingPick.empaque1?.material || '' +
                      finishedIngredient.packagingPick.empaque1?.tipoEmpaque || ''
                        | titlecase
                      }}
                    </p>
                    <p *ngIf="origin === 'Portfolio products'" class="brief-value">
                      {{nameProductFinished
                    + ' ' + sapCodeProductFinished}}
                    </p>
                    <p class="brief-value" *ngIf="finishedIngredient.bespoke">
                      {{ finishedIngredient.bespoke.type | titlecase }} Bespoke Chocolate
                      {{ finishedIngredient.bespoke.percentage * 100 | number: '1.0-0' }} %
                    </p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">EBITDA (FOB) Policy</p>
                    </div>
                    <p class="brief-value">{{ prices.ebitda * 100 || 0 }} %</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Margin Policy</p>
                    </div>
                    <p class="brief-value">{{ prices.margin * 100 || 0 }} %</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Price/kg</p>
                    </div>
                    <p class="brief-value">{{ pricesKgFixed / costParameters.trm | number: '1.2-2' }} $</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Price/unit</p>
                    </div>
                    <p class="brief-value">{{ prices.pricePerUnit / costParameters.trm | number: '1.2-2' }} $</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field" *ngIf="projectBrief.pais !== 'USA'">Quantity (kg)</p>
                      <p class="brief-field" *ngIf="projectBrief.pais === 'USA'">Quantity (lb)</p>
                    </div>
                    <p class="brief-value" *ngIf="projectBrief.pais === 'USA'">{{ projectBrief.pound }} lb</p>
                    <p class="brief-value" *ngIf="projectBrief.pais !== 'USA'">{{ projectBrief.peso }}</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Total</p>
                    </div>
                    <p class="brief-value">
                      {{ (projectBrief.peso * pricesKgFixed) / costParameters.trm | number: '1.2-2' }}
                      $
                    </p>
                  </div>
                </div>
              </div>
              <div class="contain-pg" *ngIf="typeUser == 'ADMIN' || typeUser == 'EJECUTIVOSR' || typeUser == 'SUPERADMIN'">
              <!-- <div class="contain-pg" *ngIf="typeUser == 'Administrador' || typeUser == 'EjecutivoSr'"> -->
                <div class="mt-5 text-center pl-width">
                  <div class="row">
                    <div class="col-12 text-center title-pg">P&L</div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-xl-3 col-lg-3 col-md-12 col-xs-12 col-sm-12">
                      <div class="price-pg d-flex justify-content-between p-2 container">
                        <div>Price (USD/kg)</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-md-12 col-xs-12 col-sm-12">
                      <div class="desktop-input-pl">
                        <input
                          type="number"
                          class="input-change"
                          (change)="onChangePrice($event)"
                          [ngModel]="prices.priceKg / costParameters.trm | number: '1.2-2'" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div class="col d-flex justify-content-between container_reset_save">
                        <div class="text-center" style="width: 40%">
                          <button class="btn-reset" (click)="resetPriceKg()">Reset</button>
                        </div>
                        <div class="text-center" style="width: 51%">
                          <button class="btn-save" (click)="savePriceKg()">Guardar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div class="price-pg d-flex justify-content-between p-2 container">
                        <div>Price (COP/kg)</div>
                        <div>
                          {{ netSales | number: '1.0-0' }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="pl-pdf" class="h-100">
                    <div class="row mt-3">
                      <div class="col">
                        <div class="specification-sales text-center p-2">NET SALES</div>
                      </div>
                      <div class="col d-flex justify-content-between">
                        <div class="specification-sales text-center p-2 mr-2" style="width: 40%">
                          {{ netSales | number: '1.0-0' }}
                        </div>
                        <div class="specification-sales text-center p-2">100%</div>
                      </div>
                    </div>
                    <!-- Costos variables -->
                    <div class="row mt-3" *ngIf="displayVariableCostDetails">
                      <div class="col column-pg">
                        <div class="row p-2">
                          <div class="col">
                            <div class="text-center text-pl">Costos Variables</div>
                          </div>
                          <div class="col d-flex justify-content-between">
                            <div class="col text-center text-pl">
                              {{ listDataPgOperationsCostVariables[0]?.contribVariable | number: '1.0-0' }}
                            </div>
                            <div class="col text-center text-pl">
                              <div>{{ listDataPgOperationsCostVariables[0]?.porcentajeContribVariable | number: '1.2-2' }}%</div>
                            </div>
                            <div class="text-right">
                              <button
                                class="show-details"
                                (click)="showPgDetails('variableCost')"
                                [class.hide-details]="displayVariableCostDetails"
                                style="margin-left: 30px"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="displayVariableCostDetails == false">
                      <div class="row mt-3">
                        <div class="col column-pg-active">
                          <div class="row p-2">
                            <div class="col">
                              <div class="text-center text-pl">Costos Variables</div>
                            </div>
                            <div class="col d-flex justify-content-end">
                              <div>
                                <button
                                  class="show-details"
                                  (click)="showPgDetails('variableCost')"
                                  [class.hide-details]="displayVariableCostDetails"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row bodypg">
                        <div class="col">
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Materia prima 1</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ rawMaterialCostOne | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajeMp1 | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Materia prima 2</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ rawMaterialCostTwo | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajeMp2 | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Material de Empaque</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ packagingCost | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajePackaging | number: '1.0-0' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Energeticos</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsCostVariables[0]?.energy | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajeEnergia | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Total</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsCostVariables[0]?.costosVariables | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajeCostosVariables | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Contrib variable</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsCostVariables[0]?.contribVariable | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsCostVariables[0]?.porcentajeContribVariable | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Gastos variables -->
                    <div class="row mt-3" *ngIf="displayVariableExpendsDetails">
                      <div class="col column-pg">
                        <div class="row p-2">
                          <div class="col">
                            <div class="text-center text-pl">Gastos variables</div>
                          </div>
                          <div class="col d-flex justify-content-between">
                            <div class="col text-center text-pl">
                              {{ listDataPgOperationsVariableExpends[0]?.contribFijDirec | number: '1.0-0' }}
                            </div>
                            <div class="col text-center text-pl">
                              <div>
                                {{ listDataPgOperationsVariableExpends[0]?.porcentajeContribFijDirec | number: '1.2-2' }}%
                              </div>
                            </div>
                            <div class="text-right">
                              <button
                                class="show-details"
                                (click)="showPgDetails('variableExpends')"
                                [class.hide-details]="displayVariableExpendsDetails"
                                style="margin-left: 30px"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="displayVariableExpendsDetails == false">
                      <div class="row mt-3">
                        <div class="col column-pg-active">
                          <div class="row p-2">
                            <div class="col">
                              <div class="text-center text-pl">Gastos variable</div>
                            </div>
                            <div class="col d-flex justify-content-end">
                              <div>
                                <button
                                  class="show-details"
                                  (click)="showPgDetails('variableExpends')"
                                  [class.hide-details]="displayVariableExpendsDetails"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row bodypg">
                        <div class="col">
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Ica</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.ica | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsVariableExpends[0]?.porcentajeIca | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">4 por mil</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.taxThousand | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsVariableExpends[0]?.porcentajeTaxThousand | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Distribución - comex</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.distribucionComex | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">{{ listDataPgOperationsVariableExpends[0]?.porcentajeDistribucionComex | number: '1.2-2' }}%</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Costo Capital</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.costCapital | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center text-pl">
                                    {{ listDataPgOperationsVariableExpends[0]?.porcentajeCostCapital | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Aseos</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center text-pl">
                                    <div *ngIf="projectBrief.pais !== 'USA'">{{ aseoGlobal / projectBrief.peso | number: '1.2-2' }}</div>
                                    <div *ngIf="projectBrief.pais === 'USA'">{{ aseoGlobal / projectBrief.pound | number: '1.2-2' }}</div>
                                  </div>
                                  <div class="text-center text-pl">
                                    <!-- this.netSales -->
                                    <span *ngIf="projectBrief.pais !== 'USA'">
                                      {{ (aseoGlobal / projectBrief.peso) / netSales * 100 || 0 | number: '1.2-2' }}%
                                    </span>
                                    <span *ngIf="projectBrief.pais=== 'USA'">
                                      {{ (aseoGlobal / projectBrief.pound) / netSales * 100 || 0 | number: '1.2-2' }}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Total</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.variableExpends | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsVariableExpends[0]?.porcentajeVariableExpends | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Contrib a los fijos direc</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsVariableExpends[0]?.contribFijDirec | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsVariableExpends[0]?.porcentajeContribFijDirec | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Fijos directos -->
                    <div class="row mt-3" *ngIf="displayFixedDirectDetails">
                      <div class="col column-pg">
                        <div class="row p-2">
                          <div class="col">
                            <div class="text-center text-pl">Fijos directos</div>
                          </div>
                          <div class="col d-flex justify-content-between">
                            <div class="col text-center text-pl">
                              {{ listDataPgOperationsFixedDirect[0]?.contribuCostGast | number: '1.0-0' }}
                            </div>
                            <div class="col text-center text-pl">
                              <div>{{ listDataPgOperationsFixedDirect[0]?.porcentajeContribCostGast | number: '1.2-2' }}%</div>
                            </div>
                            <div class="text-right">
                              <button
                                class="show-details"
                                (click)="showPgDetails('fixedDirect')"
                                [class.hide-details]="displayFixedDirectDetails"
                                style="margin-left: 30px"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="displayFixedDirectDetails == false">
                      <div class="row mt-3">
                        <div class="col column-pg-active">
                          <div class="row p-2">
                            <div class="col">
                              <div class="text-center text-pl">Fijos directos</div>
                            </div>
                            <div class="col d-flex justify-content-end">
                              <div>
                                <button
                                  class="show-details"
                                  (click)="showPgDetails('fixedDirect')"
                                  [class.hide-details]="displayFixedDirectDetails"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row bodypg">
                        <div class="col">
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Logistica Almacen</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.logisticWarehouse | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeLogisticWarehouse | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Mercadeo</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.marketing | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeMarketing | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Depreciación</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.depreciacion | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeDepreciacion | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Mod</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.mod | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeMod | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Mantenimiento</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.mantenimiento | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeMantenimiento | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">MOI y Otros CIF</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.zoin | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeZoin | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Total</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.fijosDirectos | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeFijosDirectos | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Contribu a los cost y Gastos</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixedDirect[0]?.contribuCostGast | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixedDirect[0]?.porcentajeContribCostGast | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Fijos -->
                    <div class="row mt-3" *ngIf="displayFixedDetails">
                      <div class="col column-pg">
                        <div class="row p-2">
                          <div class="col">
                            <div class="text-center text-pl">Fijos</div>
                          </div>
                          <div class="col d-flex justify-content-between">
                            <div class="col text-center text-pl">
                              {{ listDataPgOperationsFixed[0]?.contribution | number: '1.0-0' }}
                            </div>
                            <div class="col text-center text-pl">
                              <div>{{ listDataPgOperationsFixed[0]?.porcentajeContribucion| number: '1.2-2' }}%</div>
                            </div>
                            <div class="text-right">
                              <button
                                class="show-details"
                                (click)="showPgDetails('fixed')"
                                [class.hide-details]="displayFixedDetails"
                                style="margin-left: 30px"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="displayFixedDetails == false">
                      <div class="row mt-3">
                        <div class="col column-pg-active">
                          <div class="row p-2">
                            <div class="col">
                              <div class="text-center text-pl">Fijos</div>
                            </div>
                            <div class="col d-flex justify-content-end">
                              <div>
                                <button
                                  class="show-details"
                                  (click)="showPgDetails('fixed')"
                                  [class.hide-details]="displayFixedDetails"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row bodypg">
                        <div class="col">
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Gastos de ventas</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixed[0]?.gastosVentas | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixed[0]?.porcentajeGastosVentas | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Granja+Desarrollo + Innova + py social</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixed[0]?.groupVariable | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixed[0]?.porcentajeGroupVariable | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Administrativos</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixed[0]?.administrative | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixed[0]?.porcentajeAdministrative | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Total</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixed[0]?.fixed | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixed[0]?.porcentajeFixed | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-1 total-price">
                            <div class="col">
                              <div class="row p-2">
                                <div class="col">
                                  <div class="text-center text-pl">Contribución</div>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <div class="text-center col text-pl">
                                    <div>{{ listDataPgOperationsFixed[0]?.contribution | number: '1.0-0' }}</div>
                                  </div>
                                  <div class="text-center col text-pl">
                                    {{ listDataPgOperationsFixed[0]?.porcentajeContribucion | number: '1.2-2' }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="row mt-3">
                      <div class="col">
                        <div class="specification-final text-center p-2">Ebitda</div>
                      </div>
                      <div class="col d-flex justify-content-between">
                        <div class="specification-final text-center p-2 mr-2" style="width: 40%">
                          {{ listDataPgOperationsFinal[0]?.ebitda | number: '1.0-0' }}
                        </div>
                        <div class="specification-final text-center p-2">
                          {{ listDataPgOperationsFinal[0]?.porcentajeEbitda | number: '1.2-2' }}%
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">
                        <div class="specification-final text-center p-2">Margen Bruto</div>
                      </div>
                      <div class="col d-flex justify-content-between">
                        <div class="specification-final text-center p-2 mr-2" style="width: 40%">
                          {{ listDataPgOperationsFinal[0]?.margenBruto | number: '1.0-0' }}
                        </div>
                        <div class="specification-final text-center p-2">
                          {{ listDataPgOperationsFinal[0]?.porcentajeMargenBruto | number: '1.2-2' }}%
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">
                        <div
                          class="specification-sales text-center p-2"
                          style="width: 80%; font: normal normal 900 25px/30px Montserrat, sans-serif">
                          Total Costo Producción
                        </div>
                      </div>
                      <div class="col d-flex justify-content-between">
                        <div
                          class="specification-sales text-center p-2 mr-2"
                          style="width: 40%; font: normal normal 900 25px/30px Montserrat, sans-serif">
                          {{ listDataPgOperationsFinal[0]?.totalCost | number: '1.0-0' }}
                        </div>
                        <div class="specification-sales text-center p-2" style="font: normal normal 900 25px/30px Montserrat, sans-serif">
                          {{ listDataPgOperationsFinal[0]?.porcentajeTotal | number: '1.2-2' }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" #shipping_cost id="shipping-cost">
              <p class="selection-subtitle">Shipping Cost</p>
              <div class="row">
                <div class="col-xl-6 col-m-12 col-lg-6 col-xs-12 col-sm-12">
                  <div class="d-flex justify-content-around card-shipping pt-3">
                    <div>kg project: {{ projectBrief.peso }}</div>
                    <div>Dollar: {{ costParameters.trm }}</div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div class="selections-container" id="information-comex">
                    <p class="title-shipping">Information</p>
                    <div class="row">
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Incoterm
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.incoterm }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Origin Port
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.portSource }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Country
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.country }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Destination port
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.portDestination }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Container Type
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.typeContainer }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Quantity
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          {{ informationTransporting?.numberOfContainers }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 column-info-transporting">
                  <div class="selections-container" id="information-transport">
                    <p class="title-shipping">FOB AND CIF</p>
                    <div class="row">
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Customs Expenses (Est) (COP)
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ pricesUsd[0]?.valorUsd * costParameters.trm | number: '1.0-0' }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          National Freight (COP)
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ transportDataFilter[0]?.valorCop | number: '1.0-0' }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          International Freight (USD)
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ informationTransporting?.priceUsd | number: '1.0-0' }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          International Freight (COP)
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ informationTransporting?.priceUsd * costParameters.trm | number: '1.0-0' }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Total Operation (COP)
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ totalOperationShipping | number: '1.0-0' }}
                        </div>
                      </div>
                      <div class="col-6 column-data">
                        <div class="title-column-shipping">
                          Cost per KG
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="specification-column-shipping">
                          ${{ totalOperationShipping / projectBrief.peso | number: '1.0-0' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="user-choices">
            <div class="product-description">
              <div class="selections-container" id="description-pdf">
                <div class="row-container">
                  <div
                    class="selection-container"
                    *ngIf="
                      currentFlow == 'launch' ||
                      (currentFlow == 'product' && (designSelections.artist || designSelections.design))
                    ">
                    <p class="selection-subtitle">Design</p>
                    <div class="selections-row">
                      <div class="component-card" *ngIf="currentFlow == 'launch'">
                        <div class="img-container">
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/rtl/{{ finishedIngredient.rtl?.idRtl }}.png?dummy={{dateEarly}}"
                            alt="design"
                            crossorigin="anonymous"
                            class="rtl-img" />
                        </div>
                        <div class="selection-value">
                          {{ finishedIngredient.rtl?.nombre }}
                        </div>
                      </div>

                      <div class="component-card" *ngIf="currentFlow == 'product' && designSelections.design">
                        <div class="img-container">
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/design/{{ designSelections.design.texto }}.png?dummy={{dateEarly}}"
                            alt=""
                            crossorigin="anonymous"
                            class="rtl-img" />
                        </div>
                        <div class="selection-value">
                          {{ designSelections.design.nombre }}
                        </div>
                      </div>

                      <div class="component-card" *ngIf="currentFlow == 'product' && designSelections.artist">
                        <div class="img-container">
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/design/artists/photo/{{ designSelections.artist.imagenPerfil }}.PNG?dummy={{dateEarly}}"
                            alt="img-artist"
                            crossorigin="anonymous"
                            class="rtl-img" />
                        </div>
                        <div class="selection-value">
                          {{ designSelections.artist.nombreArtista }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="selection-container">
                    <p class="selection-subtitle">Ingredients</p>
                    <div class="selections-row section-info">
                      <div>
                        <ng-container *ngIf="finishedIngredient.chocolatePick">
                          <div class="container__ingredient">
                            <div class="img-container">
                              <img
                                src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.chocolatePick.tipo }}.PNG?dummy={{dateEarly}}"
                                alt=""
                                crossorigin="anonymous"
                                class="chocolate-step-img" />
                            </div>
                            <div class="selection-value">
                              {{ finishedIngredient.chocolatePick.texto | titlecase }}
                            </div>
                          </div>
                        </ng-container>

                        <div *ngIf="finishedIngredient.bespoke" class="selections-row">
                          <div class="packaging-item ingredient-brief">
                            <div class="img-container">
                              <img
                                src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.bespoke.type }}.PNG?dummy={{dateEarly}}"
                                alt=""
                                crossorigin="anonymous"
                                class="chocolate-step-img" />
                            </div>
                            <div class="selection-value">
                              {{ finishedIngredient.bespoke.type | titlecase }} Bespoke Chocolate
                              {{ finishedIngredient.bespoke.percentage * 100 | number: '1.0-0' }}%
                            </div>
                          </div>

                          <div *ngFor="let item of finishedIngredient.bespoke.materials">
                            <ng-container *ngIf="item.categoria == 'SUGAR'">
                              <div class="packaging-item ingredient-brief">
                                <div class="img-container">
                                  <img [src]="item.img+'?'+dateEarly" alt="" class="chocolate-step-img" crossorigin="anonymous" />
                                </div>
                                <div class="selection-value">
                                  {{ item.nombre | titlecase }}
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="item.categoria == 'MILK'">
                              <div class="packaging-item ingredient-brief">
                                <div class="img-container">
                                  <img [src]="item.img+'?'+dateEarly" alt="img" class="chocolate-step-img" crossorigin="anonymous" />
                                </div>
                                <div class="selection-value">
                                  {{ item.nombre | titlecase }}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                      <ng-container *ngIf="finishedIngredient.inclusions || finishedIngredient.flavor">
                        <div *ngIf="finishedIngredient.inclusions?.base" class="packaging-item ingredient-brief" >
                          <div class="container__ingredient">
                            <div class="img-container">
                              <img
                                src="{{ finishedIngredient.inclusions.base.inclusion.img }}?{{dateEarly}}"
                                alt=""
                                crossorigin="anonymous"
                                class="chocolate-step-img"
                                id="base-inclusion"
                              />
                            </div>
                            <div class="selection-value">
                              {{ finishedIngredient.inclusions.base.inclusion.nombre | titlecase }}
                              -
                              {{
                              finishedIngredient.inclusions.base.application +
                              ' ' +
                              finishedIngredient.inclusions.base.percentage +
                              '%' | titlecase
                              }}
                            </div>
                          </div>
                        </div>

                        <div *ngIf="finishedIngredient.inclusions?.extra" class="packaging-item ingredient-brief">
                          <div class="container__ingredient">
                            <div class="img-container">
                              <img
                                src="{{ finishedIngredient.inclusions?.extra?.inclusion?.img }}?{{dateEarly}}"
                                alt=""
                                crossorigin="anonymous"
                                class="chocolate-step-img" />
                            </div>
                            <div class="selection-value">
                              {{ finishedIngredient.inclusions?.extra?.inclusion?.nombre | titlecase }}
                              -
                              {{
                              finishedIngredient.inclusions.extra.application +
                              ' ' +
                              finishedIngredient.inclusions.extra.percentage +
                              '%' | titlecase
                              }}
                            </div>
                          </div>
                        </div>

                        <div *ngIf="finishedIngredient.flavor" class="packaging-item ingredient-brief">
                          <div class="container__ingredient">
                            <div class="img-container">
                              <img
                                src="https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/{{
                                  finishedIngredient.flavor?.codigoSap
                                }}.jpg?{{dateEarly}}"
                                crossorigin="anonymous"
                                alt=""
                                class="chocolate-step-img" />
                            </div>
                            <div class="selection-value">
                              {{
                              finishedIngredient.flavor.nombre +
                              ' - ' +
                              finishedIngredient.flavor.pickedConcentration * 100 +
                              '%' | titlecase
                              }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row-container">
                  <div class="selection-container" *ngIf="origin != 'Portfolio products'">
                    <p class="selection-subtitle">Shape & Size</p>
                    <div class="selections-row">
                      <div class="component-card">
                        <div class="img-container">
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/shape-and-size/{{ finishedIngredient.chocolatePick.tipo }}/{{
                              finishedIngredient.shapePick.formaId
                            }}.jpg?{{dateEarly}}"
                            crossorigin="anonymous"
                            alt=""
                            class="step-img"
                            id="img-step"
                            *ngIf="finishedIngredient.chocolatePick" />
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.bespoke.type }}.PNG?dummy={{dateEarly}}"
                            crossorigin="anonymous"
                            alt=""
                            class="step-img"
                            *ngIf="finishedIngredient.bespoke" />
                        </div>
                        <div class="selection-value">
                          {{ finishedIngredient.shapePick?.texto || '' }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="selection-container" *ngIf="origin != 'Portfolio products'">
                    <p class="selection-subtitle">Packaging</p>
                    <div class="selection-row">

                      <div class="component-card" *ngIf="currentFlow == 'product' || currentFlow == 'launch' || currentFlow == 'ingredient'">
                        <div class="packaging-items">
                          <div class="packaging-item" *ngFor="let item of imagesPackaging">
                            <ng-container *ngIf="item">
                              <div class="img-container">
                                <img
                                  src="{{item.img}}?{{dateEarly}}"
                                  alt="images of packagings"
                                  crossorigin="anonymous"
                                  class="step-img" />
                              </div>
                              <div class="selection-value">
                                {{ item.material }}
                                {{ item.typePackaging | titlecase }}
                              </div>
                              <div class="selection-value" *ngIf="item.finishesPackaging">
                                {{ item.finishesPackaging | titlecase }}
                              </div>
                              <div class="selection-value">
                                Units:
                                {{ item.units }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="product-nutritional">
                <div class="ingredients-info" [class.container-ingredient]="hideDataPdf" *ngIf="typeUser == 'ADMIN' || typeUser == 'EJECUTIVOSR' || typeUser == 'SUPERADMIN'">
                <!-- <div class="ingredients-info" [class.container-ingredient]="hideDataPdf" *ngIf="typeUser === 'Administrador' || typeUser === 'EjecutivoSr'"> -->
                  <p class="step-name">Ingredient List:</p>
                  <div class="ingredients-container" id="ingredient-list">
                    <div class="ingredient-item row" *ngFor="let item of rawMaterialCostDetailsTypeOne">
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.sap }}</p>
                      </div>
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.name }}</p>
                      </div>
                      <div class="col-4">
                        <p class="ingredient-detail align-proportions">{{ item.proportion * 100 | number: '1.2-2' }} %</p>
                      </div>
                    </div>
                    <div class="ingredient-item row" *ngFor="let item of rawMaterialCostDetailsTypeTwo">
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.sap }}</p>
                      </div>
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.name || item.nombre }}</p>
                      </div>
                      <div *ngIf="item.proportion" class="col-4">
                        <p class="ingredient-detail align-proportions">{{ item.proportion * 100 | number: '1.2-2'}} %</p>
                      </div>
                      <div *ngIf="item.percentage" class="col-4">
                        <p class="ingredient-detail align-proportions">{{ item.percentage * 100 | number: '1.2-2'}} %</p>
                      </div>
                    </div>
                    <div class="ingredient-item row" *ngFor="let item of packagesCostsDetails">
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.sap }}</p>
                      </div>
                      <div class="col-4">
                        <p class="ingredient-detail">{{ item.name }}</p>
                      </div>
                      <div class="col-4">
                        <p class="ingredient-detail align-proportions">{{ item.units || 0 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="impact-and-comments" id="impact-summary">
              <!--
              <div class="impact-summary">
                <p class="costs-subtitle">Impact Summary</p>
                <div class="impact-container">
                  <div class="our-impact-container">
                    <p class="impact-title">Our Impact</p>
                    <img src="./../../../assets/impact/impact-summary.jpg" alt="" class="impact-img" />
                  </div>
                </div>
              </div>

              <div class="comments-container" *ngIf="goBeyondProjects">
                <p class="impact-title">Go Beyond</p>
                <div class="comments-box">
                  <div class="comment-row" *ngIf="goBeyondProjects?.education?.length > 0">
                    <p class="project-category col">Education</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.education">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="goBeyondProjects?.projectSocial?.length > 0">
                    <p class="project-category col">Strengthen the social wellbeing</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectSocial">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="goBeyondProjects?.projectIncome?.length > 0">
                    <p class="project-category col">Enhance Farmers income</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectIncome">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="goBeyondProjects?.projectMaintain?.length > 0">
                    <p class="project-category col">Maintain environmental balance</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectMaintain">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="comment-row" *ngIf="goBeyondProjects?.entrepreneurship?.length > 0">
                    <p class="project-category col">Entrepreneurship</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.entrepreneurship">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="comment-row" *ngIf="goBeyondProjects?.producers?.length > 0">
                    <p class="project-category col">Small Producers</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.producers">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="comment-row" *ngIf="goBeyondProjects?.art?.length > 0">
                    <p class="project-category col">Art, culture and sports</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.art">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="comment-row" *ngIf="goBeyondProjects?.forests?.length > 0">
                    <p class="project-category col">Cocoa Forests</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.forests">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>

                  <div class="comment-row" *ngIf="goBeyondProjects?.social?.length > 0">
                    <p class="project-category col">Social Innovation</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of goBeyondProjects?.social">
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              -->
              <div class="comments-container">
                <div class="d-flex justify-content-between">
                  <p class="impact-title">Comments</p>
                  <button (click)="otherCommentsValidation = !otherCommentsValidation" class="btn-others">
                    <p *ngIf="!otherCommentsValidation" class="step-name">Other comments</p>
                    <p *ngIf="otherCommentsValidation" class="step-name">Save</p>
                  </button>
                </div>
                <div class="comments-box" id="comments" [class.border-coments]="hideDataPdf">
                  <div class="comment-row" *ngIf="comments?.shape?.length > 0">
                    <p class="comment-step col">Shape and Size</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of comments?.shape">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="comments?.chocolate?.length > 0">
                    <p class="comment-step col">Chocolate</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of comments?.chocolate">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="comments?.inclusions?.length > 0">
                    <p class="comment-step col">Inclusions and flavors</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of comments?.inclusions">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="comments?.packaging?.length > 0">
                    <p class="comment-step col">Packaging</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of comments?.packaging">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="comments?.design?.length > 0">
                    <p class="comment-step col">Design Inspiration</p>
                    <div class="col">
                      <p class="comment-text" *ngFor="let item of comments?.design">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="otherComments !== '' && !otherCommentsValidation">
                    <p class="comment-step col">Other comments</p>
                    <div>
                      <p class="">
                        {{otherComments}}
                      </p>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="otherCommentsValidation">
                    <textarea class="other-comments"  placeholder="Write your comments" [(ngModel)]="otherComments" maxlength="200" rows="5" cols="30"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="additional-details expiration-container" *ngIf="expiredRegisters.length > 0">
            <div class="comments-container">
              <p class="costs-subtitle">Expired Registers</p>
              <div class="expired-registers">
                <div class="expiration-warning" *ngFor="let item of expiredRegisters">
                  <div class="warning-sign">
                    <img src="./../../../assets/summary/warning.png" alt="" class="warning-img" />
                  </div>
                  <span>
                      The ingredient {{ item.sap }} ({{ item.name }}) register {{ item.register }} has expired. Date of
                      expiration: {{ item.valid }}. Please inform to the admin.
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="additional-details">
            <div class="comments-container" #specifications id="specifications">
              <p class="costs-subtitle">Specifications</p>
              <div class="comments_box-especification">
                <div style="margin: 10px; overflow-x: scroll;">
                  <div class="comment-row" *ngIf="origin != 'Portfolio products'">
                    <p class="mr-4 comment-step">Shipping dimensions table:</p>
                    <div class="col" id="table-incoterm">
                      <table class="table-incoterm">
                        <caption>Shipping dimensions table</caption>
                        <caption>All the data is shown on pallets and REEFER container</caption>
                        <thead>
                        <tr>
                          <th scope="specification-table">Item</th>
                          <th scope="specification-table">Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="info-inco">Units per box</td>
                          <td class="info-inco">{{ interfaceDataCubic.unitsBox | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Box per palet</td>
                          <td class="info-inco">{{ interfaceDataCubic.boxPerPalet | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Unit per palet</td>
                          <td class="info-inco">{{ interfaceDataCubic.unitsPerPalet | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Kg per palet</td>
                          <td class="info-inco">{{ interfaceDataCubic.kgPerPalet | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Palet 20</td>
                          <td class="info-inco">{{ interfaceDataCubic.palets20 | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Palet 40</td>
                          <td class="info-inco">{{  interfaceDataCubic.palets40 | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Kg del brief</td>
                          <td class="info-inco">{{ projectBrief.peso }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Unit weight (gr)</td>
                          <td class="info-inco">{{ interfaceDataCubic.weightPerUnit }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Units quantity</td>
                          <td class="info-inco">{{ interfaceDataCubic.quantityUnits | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">Boxes</td>
                          <td class="info-inco">{{ interfaceDataCubic.boxes | number: '1.0-0' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">cont x 20</td>
                          <td class="info-inco">{{ interfaceDataCubic.contX20| number: '1.1-1' }}</td>
                        </tr>
                        <tr>
                          <td class="info-inco">cont x 40</td>
                          <td class="info-inco">{{ interfaceDataCubic.contX40 | number: '1.1-1' }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="comment-row">
                    <p class="mr-4 comment-step">Incoterm factor table:</p>
                    <div class="col" id="table-comex">
                      <table class="table-incoterm">
                        <caption>Incoterm factor table</caption>
                        <thead>
                        <tr>
                          <th scope="specification-table">Material</th>
                          <th scope="specification-table">Factor</th>
                          <th scope="specification-table">Incoterm</th>
                          <th scope="specification-table">Puerto Origen</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of rawMaterialsTypeTwo">
                          <td class="info-inco">{{ item.nombre }}</td>
                          <td class="info-inco">
                            <p *ngIf="item.factorIncoterm == undefined; else mostrar">NA</p>
                            <ng-template #mostrar>{{ item.factorIncoterm * 100 | number: '1.0-0' }}%</ng-template>
                          </td>
                          <td class="info-inco">{{ item.inco1 }}</td>
                          <td class="info-inco">{{ item.inco2 }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="comment-row">
                    <p class="mr-4 comment-step">Packaging scrap:</p>
                    <div class="col" id="table-scrap">
                      <table class="table-incoterm">
                        <caption>Packaging scrap</caption>
                        <thead>
                        <tr>
                          <th scope="specification-table">Material</th>
                          <th scope="specification-table">Desperdicio</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of packagesCosts">
                          <td class="info-inco">{{ item.nombre }}</td>
                          <td class="info-inco">{{ item.desperdicios * 100 | number: '1.0-0' }} %</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="comment-row" *ngIf="origin != 'Portfolio products'">
                    <p class="mr-4 comment-step">Raw Material Scrap:</p>
                    <div class="col" id="table-raw">
                      <table class="table-incoterm" >
                        <caption>Raw Material Scrap</caption>
                        <thead>
                        <tr>
                          <th scope="specification-table">Extra contenido</th>
                          <th scope="specification-table">Extra contenido inclusion</th>
                          <th scope="specification-table">Merma</th>
                          <th scope="specification-table">Aseo</th>
                          <th scope="specification-table">Personas</th>
                          <th scope="specification-table">Horas</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="info-inco">{{ arrWaste[1]?.desperdicioExtraContenido * 100 || 0 }}%</td>
                          <td class="info-inco">{{ arrWaste[1]?.desperdicioExtraContenidoInclusion * 100 || 0 }} %</td>
                          <td class="info-inco">{{ mermWaste }}</td>
                          <td class="info-inco">$ {{ aseoGlobal | number: '1.0-0' }}</td>
                          <td class="info-inco">{{ aseoGlobalPersons }}</td>
                          <td class="info-inco">{{ aseoGlobalHours }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <!-- <div class="additional-details">
            <div class="comments-container" id="nutritional-table">
              <p class="costs-subtitle">Nutritional Table</p>
              <div class="comments_box-especification">
                <app-nutritional-table [totalNutritionalCost]="totalNutritionalCost"></app-nutritional-table>
              </div>
            </div>
          </div> -->
        </ng-container>
        <ng-container *ngIf="briefType == 'client'">
          <div class="client-brief border-brief">
            <label class="costs-subtitle mb-3" #project_brief id="project-brief">Project Brief</label>
            <div class="row">
              <div class="col-6 column-data mb-3 mr-3">
                <div class="brief-col">
                  <label class="title-col-brief">Date:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.fecha}}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Client: </label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.cliente}}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Prepared by:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{ activeUser.nombre + ' ' + activeUser.apellido }}</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Country:</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">{{projectBrief.pais}}</label>
                </div>
              </div>

              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief">Quantity</label>
                </div>
              </div>
              <div class="col-6 column-data mb-3">
                <div class="brief-col">
                  <label class="title-col-brief" *ngIf="projectBrief.pais !== 'USA'">{{ projectBrief.peso }} kg</label>
                  <label class="title-col-brief" *ngIf="projectBrief.pais === 'USA'">{{ projectBrief.pound }} lb</label>
                </div>
              </div>
              <div class="col-12" [class.no-display-pdf]="hideDataPdf">
                <p class="title-col-brief">This quotation is valid for 30 days</p>
              </div>
            </div>
            <div class="section-info">
              <p class="selection-subtitle mt-3" #my_claims id="my-claims">My Claims</p>
              <div class="claims-list mt-2">
                <div class="claim-item">
                  <label>Slave free</label>
                </div>
                <div class="claim-item">
                  <label>Child labour free</label>
                </div>
                <div class="claim-item">
                  <label>0 Deforestation</label>
                </div>
                <div class="claim-item">
                  <label>Crafted at origin</label>
                </div>
                <div class="claim-item">
                  <label>Cacao Fino de aroma</label>
                </div>
                <div class="claim-item">
                  <label>Sustainable Source</label>
                </div>
                <div class="claim-item">
                  <label>Ethical Chocolate</label>
                </div>
              </div>
              <p-chips [(ngModel)]="customClaims" [max]="5" separator=","></p-chips>
            </div>
            <div class="selections-container" id="description-pdf">
              <div class="row-container">
                <div
                  class="selection-container"
                  *ngIf="
                      currentFlow == 'launch' ||
                      (currentFlow == 'product' && (designSelections.artist || designSelections.design))
                    ">
                  <p class="selection-subtitle">Design</p>
                  <div class="selections-row">
                    <div class="component-card" *ngIf="currentFlow == 'launch'">
                      <div class="img-container">
                        <img
                          src="https://d169su6y068qsd.cloudfront.net/create/rtl/{{ finishedIngredient.rtl?.idRtl }}.png?dummy={{dateEarly}}"
                          alt="design"
                          crossorigin="anonymous"
                          class="rtl-img" />
                      </div>
                      <div class="selection-value">
                        {{ finishedIngredient.rtl?.nombre }}
                      </div>
                    </div>

                    <div class="component-card" *ngIf="currentFlow == 'product' && designSelections.design">
                      <div class="img-container">
                        <img
                          src="https://d169su6y068qsd.cloudfront.net/create/design/{{ designSelections.design.texto }}.png?dummy={{dateEarly}}"
                          alt=""
                          crossorigin="anonymous"
                          class="rtl-img" />
                      </div>
                      <div class="selection-value">
                        {{ designSelections.design.nombre }}
                      </div>
                    </div>

                    <div class="component-card" *ngIf="currentFlow == 'product' && designSelections.artist">
                      <div class="img-container">
                        <img
                          src="https://d169su6y068qsd.cloudfront.net/create/design/artists/photo/{{ designSelections.artist.imagenPerfil }}.PNG?dummy={{dateEarly}}"
                          alt="img-artist"
                          crossorigin="anonymous"
                          class="rtl-img" />
                      </div>
                      <div class="selection-value">
                        {{ designSelections.artist.nombreArtista }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection-container">
                  <p class="selection-subtitle">Ingredients</p>
                  <div class="selections-row section-info">
                    <div>
                      <ng-container *ngIf="finishedIngredient.chocolatePick">
                        <div class="container__ingredient">
                          <div class="img-container">
                            <img
                              src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.chocolatePick.tipo }}.PNG?dummy={{dateEarly}}"
                              alt=""
                              crossorigin="anonymous"
                              class="chocolate-step-img" />
                          </div>
                          <div class="selection-value">
                            {{ finishedIngredient.chocolatePick.texto | titlecase }}
                          </div>
                        </div>
                      </ng-container>

                      <div *ngIf="finishedIngredient.bespoke" class="selections-row">
                        <div class="packaging-item ingredient-brief">
                          <div class="img-container">
                            <img
                              src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.bespoke.type }}.PNG?dummy={{dateEarly}}"
                              alt=""
                              crossorigin="anonymous"
                              class="chocolate-step-img" />
                          </div>
                          <div class="selection-value">
                            {{ finishedIngredient.bespoke.type | titlecase }} Bespoke Chocolate
                            {{ finishedIngredient.bespoke.percentage * 100 | number: '1.0-0' }}%
                          </div>
                        </div>

                        <div *ngFor="let item of finishedIngredient.bespoke.materials">
                          <ng-container *ngIf="item.categoria == 'SUGAR'">
                            <div class="packaging-item ingredient-brief">
                              <div class="img-container">
                                <img [src]="item.img+'?'+dateEarly" alt="" class="chocolate-step-img" crossorigin="anonymous" />
                              </div>
                              <div class="selection-value">
                                {{ item.nombre | titlecase }}
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="item.categoria == 'MILK'">
                            <div class="packaging-item ingredient-brief">
                              <div class="img-container">
                                <img [src]="item.img+'?'+dateEarly" alt="img" class="chocolate-step-img" crossorigin="anonymous" />
                              </div>
                              <div class="selection-value">
                                {{ item.nombre | titlecase }}
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="finishedIngredient.inclusions || finishedIngredient.flavor">
                      <div *ngIf="finishedIngredient.inclusions?.base" class="packaging-item ingredient-brief" >
                        <div class="img-container">
                          <img
                            src="{{ finishedIngredient.inclusions.base.inclusion.img }}?{{dateEarly}}"
                            alt=""
                            crossorigin="anonymous"
                            class="chocolate-step-img"
                            id="base-inclusion"
                          />
                        </div>
                        <div class="selection-value">
                          {{ finishedIngredient.inclusions.base.inclusion.nombre | titlecase }}
                          -
                          {{
                          finishedIngredient.inclusions.base.application +
                          ' ' +
                          finishedIngredient.inclusions.base.percentage +
                          '%' | titlecase
                          }}
                        </div>
                      </div>

                      <div *ngIf="finishedIngredient.inclusions?.extra" class="packaging-item ingredient-brief">
                        <div class="img-container">
                          <img
                            src="{{ finishedIngredient.inclusions?.extra?.inclusion?.img }}?{{dateEarly}}"
                            alt=""
                            crossorigin="anonymous"
                            class="chocolate-step-img" />
                        </div>
                        <div class="selection-value">
                          {{ finishedIngredient.inclusions?.extra?.inclusion?.nombre | titlecase }}
                          -
                          {{
                          finishedIngredient.inclusions.extra.application +
                          ' ' +
                          finishedIngredient.inclusions.extra.percentage +
                          '%' | titlecase
                          }}
                        </div>
                      </div>

                      <div *ngIf="finishedIngredient.flavor" class="packaging-item ingredient-brief">
                        <div class="img-container">
                          <img
                            src="https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/{{
                                finishedIngredient.flavor?.codigoSap
                              }}.jpg?{{dateEarly}}"
                            crossorigin="anonymous"
                            alt=""
                            class="chocolate-step-img" />
                        </div>
                        <div class="selection-value">
                          {{
                          finishedIngredient.flavor.nombre +
                          ' - ' +
                          finishedIngredient.flavor.pickedConcentration * 100 +
                          '%' | titlecase
                          }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="row-container">
                <div class="selection-container" *ngIf="origin != 'Portfolio products'">
                  <p class="selection-subtitle">Shape & Size</p>
                  <div class="selections-row">
                    <div class="component-card">
                      <div class="img-container">
                        <img
                          src="https://d169su6y068qsd.cloudfront.net/create/shape-and-size/{{ finishedIngredient.chocolatePick.tipo }}/{{
                              finishedIngredient.shapePick.formaId
                            }}.jpg?{{dateEarly}}"
                          crossorigin="anonymous"
                          alt=""
                          class="step-img"
                          id="img-step"
                          *ngIf="finishedIngredient.chocolatePick" />
                        <img
                          src="https://d169su6y068qsd.cloudfront.net/create/basics/{{ finishedIngredient.bespoke.type }}.PNG?dummy={{dateEarly}}"
                          crossorigin="anonymous"
                          alt=""
                          class="step-img"
                          *ngIf="finishedIngredient.bespoke" />
                      </div>
                      <div class="selection-value">
                        {{ finishedIngredient.shapePick?.texto || '' }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection-container" *ngIf="origin != 'Portfolio products'">
                  <p class="selection-subtitle">Packaging</p>
                  <div class="selection-row">

                    <div class="component-card" *ngIf="currentFlow == 'product' || currentFlow == 'launch' || currentFlow == 'ingredient'">
                      <div class="packaging-items">
                        <div class="packaging-item" *ngFor="let item of imagesPackaging">
                          <ng-container *ngIf="item">
                            <div class="img-container">
                              <img
                                src="{{item.img}}?{{dateEarly}}"
                                alt="images of packagings"
                                crossorigin="anonymous"
                                class="step-img" />
                            </div>
                            <div class="selection-value">
                              {{ item.material }}
                              {{ item.typePackaging | titlecase }}
                            </div>
                            <p class="selection-value" *ngIf="item.finishesPackaging">
                              {{ item.finishesPackaging | titlecase }}
                            </p>
                            <p class="selection-value">
                              Units:
                              {{ item.units }}
                            </p>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row-container">
              <app-nutritional-table [totalNutritionalCost]="totalNutritionalCost"></app-nutritional-table>
            </div> -->
            <div class="section-info mt-2" #pricing_usd id="pricing-usd">
              <p class="selection-subtitle">Pricing (USD)</p>
              <div class="selections-container" id="pricing-usd-pdf">
                <div class="pricing-table">
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Description</p>
                    </div>
                    <p class="brief-value" *ngIf="finishedIngredient.chocolatePick && origin !== 'Portfolio products'">
                      {{
                      finishedIngredient.chocolatePick.texto || '' +
                      ' ' +
                      finishedIngredient.shapePick.texto || '' +
                      ' ' +
                      finishedIngredient.packagingPick.empaque1?.material || '' +
                      finishedIngredient.packagingPick.empaque1?.tipoEmpaque || ''
                        | titlecase
                      }}
                    </p>
                    <p *ngIf="origin === 'Portfolio products'" class="brief-value">
                      {{nameProductFinished
                    + ' ' + sapCodeProductFinished}}
                    </p>
                    <p class="brief-value" *ngIf="finishedIngredient.bespoke">
                      {{ finishedIngredient.bespoke.type | titlecase }} Bespoke Chocolate
                      {{ finishedIngredient.bespoke.percentage * 100 | number: '1.0-0' }}%
                    </p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Price/kg</p>
                    </div>
                    <p class="brief-value">{{ prices.priceKg / costParameters.trm | number: '1.2-2' }} $</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Price/unit</p>
                    </div>
                    <p class="brief-value">{{ prices.pricePerUnit / costParameters.trm | number: '1.2-2' }} $</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field" *ngIf="projectBrief.pais === 'USA'">Quantity (lb)</p>
                      <p class="brief-field" *ngIf="projectBrief.pais !== 'USA'">Quantity (kg)</p>
                    </div>
                    <p class="brief-value" *ngIf="projectBrief.pais === 'USA'">{{ projectBrief.pound }} lb</p>
                    <p class="brief-value" *ngIf="projectBrief.pais !== 'USA'">{{ projectBrief.peso }}</p>
                  </div>
                  <div class="pricing-item">
                    <div class="pricing-header">
                      <p class="brief-field">Total</p>
                    </div>
                    <p class="brief-value">
                      {{ (projectBrief.peso * prices.priceKg) / costParameters.trm | number: '1.2-2' }}
                      $
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-xl-6 col-m-12 col-lg-6 col-xs-12 col-sm-12" #shipping_cost id="shipping-cost">
                <div class="selections-container">
                  <p class="selection-subtitle">Shipping Information</p>
                  <p class="title-shipping">Information</p>
                  <div class="row">
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Incoterm
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.incoterm }}
                      </div>
                    </div>
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Origin Port
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.portSource }}
                      </div>
                    </div>
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Country
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.country }}
                      </div>
                    </div>
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Destination port
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.portDestination }}
                      </div>
                    </div>
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Container Type
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.typeContainer }}
                      </div>
                    </div>
                    <div class="col-6 column-data">
                      <div class="title-column-shipping">
                        Quantity
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="specification-column-shipping">
                        {{ informationTransporting?.numberOfContainers }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-12 col-lg-6 col-xs-12 col-sm-12" id="table-incoterm">
                <div class="selections-container" style="margin-top: 29px">
                  <p class="title-shipping">Shipping dimensions table</p>
                  <table class="table-incoterm-brief">
                    <caption style="display: none">Shipping dimensions table</caption>
                    <caption style="display: none">All the data is shown on pallets and REEFER container</caption>
                    <thead>
                    <tr>
                      <th scope="specification-table">Item</th>
                      <th scope="specification-table">Valor</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="info-inco">KG del brief</td>
                      <td class="info-inco">{{ projectBrief.peso  }}</td>
                    </tr>
                    <tr>
                      <td class="info-inco">Unit weight</td>
                      <td class="info-inco">{{ interfaceDataCubic.weightPerUnit  }}</td>
                    </tr>
                    <tr>
                      <td class="info-inco">Units quantity</td>
                      <td class="info-inco">{{ interfaceDataCubic.quantityUnits  | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td class="info-inco">Boxes</td>
                      <td class="info-inco">{{ interfaceDataCubic.boxes | number: '1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td class="info-inco">Cont x 20</td>
                      <td class="info-inco">{{ interfaceDataCubic.contX20 | number: '1.1-1' }}</td>
                    </tr>
                    <tr>
                      <td class="info-inco">Cont x 40</td>
                      <td class="info-inco">{{ interfaceDataCubic.contX40  | number: '1.1-1' }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--
            <div class="section-info mt-4" id="impact-summary">
              <p class="selection-subtitle">Your Impact Summary</p>
              <div class="client-impact-container">
                <div class="client-impact">
                  <p class="impact-title">Our Impact</p>
                  <img src="./../../../assets/impact/impact-summary.jpg" alt="" class="impact-img" />
                </div>
                <div class="client-go-beyond">
                  <p class="impact-title">Go Beyond</p>
                  <div class="comments-box">
                    <div class="comment-row" *ngIf="goBeyondProjects?.education?.length > 0">
                      <p class="project-category col">Education</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.education">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                    <div class="comment-row" *ngIf="goBeyondProjects?.projectSocial?.length > 0">
                      <p class="project-category col">Strengthen the social wellbeing</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectSocial">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                    <div class="comment-row" *ngIf="goBeyondProjects?.projectMaintain?.length > 0">
                      <p class="project-category col">Maintain environmental balance</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectMaintain">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                    <div class="comment-row" *ngIf="goBeyondProjects?.projectIncome?.length > 0">
                      <p class="project-category col">Enhance Farmers income</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.projectIncome">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>

                    <div class="comment-row" *ngIf="goBeyondProjects?.entrepreneurship?.length > 0">
                      <p class="project-category col">Entrepreneurship</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.entrepreneurship">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>

                    <div class="comment-row" *ngIf="goBeyondProjects?.producers?.length > 0">
                      <p class="project-category col">Small Producers</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.producers">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>

                    <div class="comment-row" *ngIf="goBeyondProjects?.art?.length > 0">
                      <p class="project-category col">Art, culture and sports</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.art">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>

                    <div class="comment-row" *ngIf="goBeyondProjects?.forests?.length > 0">
                      <p class="project-category col">Cocoa Forests</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.forests">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>

                    <div class="comment-row" *ngIf="goBeyondProjects?.social?.length > 0">
                      <p class="project-category col">Social Innovation</p>
                      <div class="col">
                        <p class="comment-text" *ngFor="let item of goBeyondProjects?.social">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
            <br>
            <br>
            <div class="section-info">
              <p class="selection-subtitle">Additional Notes</p>
              <div class="comments-box" id="comments" [class.border-coments]="hideDataPdf">
                <div class="comment-row" *ngIf="comments?.shape?.length > 0">
                  <p class="comment-step col">Shape and Size</p>
                  <div class="col">
                    <p class="comment-text" *ngFor="let item of comments?.shape">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <div class="comment-row" *ngIf="comments?.chocolate?.length > 0">
                  <p class="comment-step col">Chocolate</p>
                  <div class="col">
                    <p class="comment-text" *ngFor="let item of comments?.chocolate">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <div class="comment-row" *ngIf="comments?.inclusions?.length > 0">
                  <p class="comment-step col">Inclusions and flavors</p>
                  <div class="col">
                    <p class="comment-text" *ngFor="let item of comments?.inclusions">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <div class="comment-row" *ngIf="comments?.packaging?.length > 0">
                  <p class="comment-step col">Packaging</p>
                  <div class="col">
                    <p class="comment-text" *ngFor="let item of comments?.packaging">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <div class="comment-row" *ngIf="comments?.design?.length > 0">
                  <p class="comment-step col">Design Inspiration</p>
                  <div class="col">
                    <p class="comment-text" *ngFor="let item of comments?.design">
                      {{ item }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="prices row">
        <div class="col-xl-4 col-lg-4 col-xs-12 col-sm-12 pt-2">
          <div class="price-container">
            <p class="price-label">Project Size</p>
            <p class="price-value">{{ costParameters.scale / 1000 }} tons</p>
          </div>
          <div class="price-container">
            <p class="price-label">Price Per kg</p>
            <p class="price-value">{{ prices.priceKg / costParameters.trm | number: '1.2-2' }} $</p>
          </div>
          <div class="price-container">
            <p class="price-label">Price Per unit</p>
            <p class="price-value">{{ prices.pricePerUnit / costParameters.trm | number: '1.2-2' }} $</p>
          </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <div class="footer-container">
            <button class="footer-button" (click)="saveConsolidatedProduct()">
              Finish Creation (send to sales ex and client)
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-brief-feria *ngIf="typeUserLogged === 'Feria'" [finishedIngredient]="finishedIngredient" [briefInformation]="projectBrief" (completeBrief)="saveConsolidatedProduct()"></app-brief-feria>
