<div class="main-container">
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-12" style="margin-bottom: 40px; margin-top: 30px">
          <p class="step-name">{{ pickedProduct.nombre }}</p>
        </div>
        <div class="col-4">
          <div class="d-flex justify-content-center" style="width: 100%; height: 100%">
            <div class="container-img-rtl">
              <swiper
                [spaceBetween]="30"

                [pagination]="{
                  clickable: true
                }">
                <ng-template swiperSlide>
                  <img
                    src="https://d169su6y068qsd.cloudfront.net/create/rtl/{{ pickedProduct.idRtl }}.png"
                    alt="rtl-img" />
                </ng-template>
                <ng-template swiperSlide>
                  <img
                    src="https://d169su6y068qsd.cloudfront.net/create/shape-and-size/{{this.chocolate?.tipo}}/{{this.pickedProduct.formaId}}.jpg"
                    alt="rtl-img" />
                </ng-template>
              </swiper>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-12">
              <div>
                <p class="title-sections">{{ pickedProduct.categoria }}</p>
              </div>
              <div>
                <p class="description-rtl">
                  {{ pickedProduct.descCategoria }}
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-2">
                <p class="title-sections">Ingredients</p>
                <p class="description-rtl">{{pickedProduct.ingredients}}</p>
              </div>
            </div>
            <div class="col-12" style="margin-top: 20px; margin-bottom: 20px">
              <div class="d-flex mt-2">
                <p class="title-sections">MOQ</p>
                <p class="description-rtl ms-3">{{pickedProduct.moq}}</p>
              </div>
            </div>
            <div class="col-12 responsive-claims">
              <div class="mt-2">
                <p class="title-sections">Claims</p>
              </div>
              <app-claims-item [claims]="claims"></app-claims-item>
            </div>
          </div>
        </div>
        <div class="col-12 responsive-desktop-claims">
          <div class="mt-2">
            <p class="title-sections">Claims</p>
          </div>
          <app-claims-item [claims]="claims"></app-claims-item>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-6">
      <div class="col-12" style="margin-top: 40px">
        <div>
          <p class="title-sections">Suggested configuration</p>
        </div>
        <div class="d-flex pt-2">
          <div class="columns-items" *ngFor="let item of ingredientsList">
            <div class="d-flex justify-content-center" *ngIf="item.img !== undefined">
              <img class="img-packaging" src="{{ item.img }}" alt="items" />
            </div>
            <div class="mt-2">
              <p class="title-items">{{ item.name | titlecase }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="d-flex pt-2">
          <div class="columns-items" *ngFor="let item of packagesImages">
            <div class="d-flex justify-content-center" *ngIf="item.img !== undefined">
              <img
                class="img-packaging"
                src="https://d169su6y068qsd.cloudfront.net/create/packaging/{{ item?.img }}"
                alt="items" />
            </div>
            <div class="mt-2">
              <p class="title-items-packaging" style="margin: 0">{{ item?.typePackaging | titlecase }}</p>
              <p *ngIf="item?.units" class="title-items-packaging" style="margin: 0">units: {{ item?.units }}</p>
              <p class="title-items-packaging" style="margin: 0" *ngIf="item.acabados">
                Finishing: {{ item?.acabados }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-5">
        <button class="btn-redirect" (click)="goToBasics()">Want something else</button>
      </div>
    </div>
  </div>
</div>
