<div class="modal-dialog" style="max-width: 700px" >
  <div class="modal-content modal-container">
    <p-button styleClass="close-button" icon="pi pi-times" (click)="close()"></p-button>
    <p class="product-name">{{product.txt_material}}</p>
    <div class="modal-content-accordions">
        <div class="mb-3" style="min-width: 485px;">
            <div class="w-full field-left mb-3">
                <p-accordion styleClass="accordion-style" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up">
                    <p-accordionTab  iconPos="end">
                        <ng-template pTemplate="header" >
                            <table class="accordion-table-header">
                                <thead>
                                <tr>
                                    <td >
                                        Processes
                                    </td>
                                    <td >
                                        {{product.cost_processes|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{(product.cost_processes*100/product.total_cost)|number:'1.0-0':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                        </ng-template>
                        <table class="accordion-table-body" >
                            <thead>
                                <tr *ngFor="let contentRow of product.processes_list; let i=index;" class=".accordion-table-tr">
                                    <td >
                                      <input type="text" [readOnly]="true" [value]="contentRow['ltxa_1']">

                                    </td>
                                    <td >
                                        {{contentRow['subtotal']||(contentRow['zdep_costo']+contentRow['zenr_costo']+contentRow['zgas_costo']+contentRow['zman_costo']+contentRow['zmod_costo']+contentRow['zoin_costo'])|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{((contentRow['subtotal']||(contentRow['zdep_costo']+contentRow['zenr_costo']+contentRow['zgas_costo']+contentRow['zman_costo']+contentRow['zmod_costo']+contentRow['zoin_costo']))!=0?((contentRow['subtotal']||(contentRow['zdep_costo']+contentRow['zenr_costo']+contentRow['zgas_costo']+contentRow['zman_costo']+contentRow['zmod_costo']+contentRow['zoin_costo']))*100/product.total_cost):0)|number:'1.0-2':'en-US'}}%
                                    </td>
                                </tr>
                                <tr *ngIf="plantaTriunfo">
                                    <td >
                                        <input type="text" [readOnly]="true" [value]="'Planta triunfo'">
                                      </td>
                                      <td >
                                          {{product.planta_triunfo.cif+product.planta_triunfo.labour|number:'1.0-0':'en-US'}}
                                      </td>
                                      <td>
                                          {{((product.planta_triunfo.cif+product.planta_triunfo.labour)!=0?((product.planta_triunfo.cif+product.planta_triunfo.labour)*100/product.total_cost):0)|number:'1.0-2':'en-US'}}%
                                      </td>
                                </tr>
                            </thead>
                            </table>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="w-full field-right">
                <p-accordion styleClass="accordion-style" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up">
                    <p-accordionTab styleClass="p-accordion-success" iconPos="end">
                        <ng-template pTemplate="header" >
                            <table class="accordion-table-header">
                                <thead>
                                <tr>
                                    <td >
                                        Raw materials one
                                    </td>
                                    <td >
                                        {{product.cost_raw_material_one|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{(product.cost_raw_material_one*100/product.total_cost)|number:'1.0-0':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                        </ng-template>
                        <table class="accordion-table-body" >
                            <thead>
                                <tr *ngFor="let contentRow of product.raw_material_one_list; let i=index;" class=".accordion-table-tr">
                                    <td >
                                      <input type="text" [readOnly]="true" [value]="contentRow['nombreComponente']||contentRow['name']">
                                    </td>
                                    <td >
                                        {{contentRow['costo']||contentRow['price']||0|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{((contentRow['costo']||contentRow['price']||0)!=0?((contentRow['costo']||contentRow['price']||0)*100/product.total_cost):0)|number:'1.0-2':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="mb-3" style="min-width: 485px;">
            <div class="w-full field-right">
                <p-accordion styleClass="accordion-style" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up">
                    <p-accordionTab styleClass="p-accordion-success" iconPos="end">
                        <ng-template pTemplate="header" >
                            <table class="accordion-table-header">
                                <thead>
                                <tr>
                                    <td >
                                        Raw materials two
                                    </td>
                                    <td >
                                        {{product.cost_raw_material_two|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{(product.cost_raw_material_two*100/product.total_cost)|number:'1.0-0':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                        </ng-template>
                        <table class="accordion-table-body" >
                            <thead>
                                <tr *ngFor="let contentRow of product.raw_material_two_list; let i=index;" class=".accordion-table-tr">
                                    <td >
                                      <input type="text" [readOnly]="true" [value]="contentRow['nombreComponente']||contentRow['nombre']||contentRow['name']">
                                    </td>
                                    <td >
                                        {{contentRow['costo']||contentRow['price']||0|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{((contentRow['costo']||contentRow['price']||0)!=0?((contentRow['costo']||contentRow['price']||0)*100/product.total_cost):0)|number:'1.0-2':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="mb-3" style="min-width: 485px;">
            <div class="w-full field-left mb-3">
                <p-accordion styleClass="accordion-style" expandIcon = "pi pi-chevron-down" collapseIcon= "pi pi-chevron-up">
                    <p-accordionTab  iconPos="end">
                        <ng-template pTemplate="header" >
                            <table class="accordion-table-header">
                                <thead>
                                <tr>
                                    <td >
                                        Packaging
                                    </td>
                                    <td >
                                        {{product.cost_packaging|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{(product.cost_packaging*100/product.total_cost)|number:'1.0-0':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                        </ng-template>
                        <table class="accordion-table-body" >
                            <thead>
                                <tr *ngFor="let contentRow of product.packaging_list; let i=index;" class="accordion-table-tr">
                                    <td >
                                      <input type="text" [readOnly]="true" [value]="contentRow['nombreComponente']||contentRow['name']">
                                    </td>
                                    <td >
                                        {{contentRow['costo']||contentRow['price']||0|number:'1.0-0':'en-US'}}
                                    </td>
                                    <td>
                                        {{((contentRow['costo']||contentRow['price']||0)!=0?((contentRow['costo']||contentRow['price']||0)*100/product.total_cost):0)|number:'1.0-2':'en-US'}}%
                                    </td>
                                </tr>
                            </thead>
                            </table>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="yellow-textbar-total-cost">
                <table class="yellow-textbar-table-total-cost">
                    <thead>
                    <tr>
                        <td>
                            Total cost
                        </td>
                        <td >
                            {{product.total_cost|number:'1.0-0':'en-US'}}
                        </td>
                        <td>
                            {{100}}%
                        </td>
                    </tr>
                </thead>
                </table>
        </div>
    </div>
  </div>
</div>
