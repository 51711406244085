<ng-container *ngIf="currentFlow == 'ingredient'">
  <div class="main-container">
    <div class="step-info">
      <div>
        <p class="step-title">{{ stepName }}</p>
        <p class="step-description">{{ stepDescription }}</p>
      </div>
      <div class="buttons-container">
        <button class="request-btn" (click)="openRequestModal()">
          + Request Other Packages
        </button>
      </div>
    </div>
    <div class="packaging-container">
      <div class="packaging-options">
        <div class="package-card" *ngFor="let item of packagingOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection(i)">
          <div class="package-description">
            <p>Use: {{item.use}}</p>
            <p>Subtrend: {{item.subtrend}}</p>
            <p>Sustainable: {{item.sustainable | titlecase}}</p>
          </div>
          <div class="total">
            <p class="total-label">TOTAL</p>
            <p class="total-value">{{(item.weightPerUnit/1000)}} KG</p>
          </div>
          <div class="packaging-materials">
            <p>Packaging materials:</p>
            <p>{{item.empaque1.material}} {{item.empaque1.tipoEmpaque}}</p>
            <p *ngIf="item.empaque2">{{item.empaque2.material}} {{item.empaque2.tipoEmpaque}}</p>
            <p *ngIf="item.empaque3">{{item.empaque3.material}} {{item.empaque3.tipoEmpaque}}</p>
            <p *ngIf="item.empaque4">{{item.empaque4.material}} {{item.empaque4.tipoEmpaque}}</p>
            <p *ngIf="item.empaque5">{{item.empaque5.material}} {{item.empaque5.tipoEmpaque}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentFlow == 'product'">
  <div class="main-container">
    <div class="step-info-product">
      <div>
        <p class="step-title">Packaging Material</p>
      </div>
      <div class="buttons-container">
        <button class="request-btn" (click)="openRequestModal()" [disabled]="stepValidation">
          + Request Other Packages
        </button>
      </div>
    </div>
    <div class="packaging-container row">
      <div class="col-md-12 col-lg-12 col-xl-6 col-sm-12">
        <div class="package-row">
          <p class="step-description">First, choose your primary packaging.</p>
          <div class="package-selector" *ngIf="stepValidation === false">
            <div class="package-preview" *ngFor="let item of primaryPackages; let i = index">
              <div class="img-container" (click)="handlePackageClick('primary', i)" [class.selectedPackage]="item.selected" [class.isDisabled]="stepValidation">
                <img src="https://d169su6y068qsd.cloudfront.net/create/packaging/{{item.imagen}}" alt="" class="package-img">
              </div>
              <div>
                <p class="package-type">{{item.tipoEmpaque | titlecase}}</p>
                <p class="package-material">{{item.material | titlecase}}</p>
                <p class="package-material" *ngIf="item.acabados">{{item.acabados | titlecase}}</p>
                <p class="package-material">Units: {{item.units}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="primaryPackages.length === 0" class="text-danger">No hay empaques para tu combinación de chocolate.</div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="package-row" *ngIf="primarySelected">
          <p class="step-description">Next, choose your secondary packaging. </p>
        </div>
        <div class="package-selector" id="secondary-packaging" *ngIf="stepValidation === false">
          <div class="package-preview" *ngFor="let item of secondaryPackages; let i = index">
            <div class="img-container" (click)="handlePackageClick('secondary', i)" [class.selectedPackage]="item.selected">
              <img src="https://d169su6y068qsd.cloudfront.net/create/packaging/{{item.imagen}}" alt="" class="package-img">
            </div>
            <div>
              <p class="package-type">{{item.tipoEmpaque | titlecase}}</p>
              <p class="package-material">{{item.material | titlecase}}</p>
              <p class="package-material">Units: {{item.units}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6" *ngIf="thirdPackages.length > 0">
        <div class="package-row" *ngIf="secondarySelected">
          <p class="step-description">Next, choose your third packaging. </p>
        </div>
        <div class="package-selector" *ngIf="stepValidation === false">
          <div class="package-preview" *ngFor="let item of thirdPackages; let i = index">
            <div class="img-container" (click)="handlePackageClick('thirty', i)" [class.selectedPackage]="item.selected">
              <img src="https://d169su6y068qsd.cloudfront.net/create/packaging/{{item.imagen}}" alt="" class="package-img">
            </div>
            <div>
              <p class="package-type">{{item.tipoEmpaque | titlecase}}</p>
              <p class="package-material">{{item.material | titlecase}}</p>
              <p class="package-material">Units: {{item.units}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6" *ngIf="fourthPackages.length > 0">
        <div class="package-row" *ngIf="thirdSelected">
          <p class="step-description">Next, choose your fourth packaging. </p>
        </div>
        <div class="package-selector" *ngIf="stepValidation === false">
          <div class="package-preview" *ngFor="let item of fourthPackages; let i = index">
            <div class="img-container" (click)="handlePackageClick('fourth', i)" [class.selectedPackage]="item.selected">
              <img src="https://d169su6y068qsd.cloudfront.net/create/packaging/{{item.imagen}}" alt="" class="package-img">
            </div>
            <div>
              <p class="package-type">{{item.tipoEmpaque | titlecase}}</p>
              <p class="package-material">{{item.material | titlecase}}</p>
              <p class="package-material">Units: {{item.units}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
