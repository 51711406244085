import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import ls from 'localstorage-slim';
import { Workbook, Worksheet } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalProductDetailsComponent } from '../modal-product-details/modal-product-details.component';
import * as fs from 'file-saver';
import { PgService } from 'src/app/core/services/tablepg/pg.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Router } from '@angular/router';
import { QuoteActualProductsService } from 'src/app/core/services/quote-actual-products/quote-actual-products.service';
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-download-quote',
  templateUrl: './download-quote.component.html',
  styleUrls: ['./download-quote.component.css'],
})
export class DownloadQuoteComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  faMagnifyingGlass = faMagnifyingGlass;

  constructor(
    private sms: SimpleModalService,
    private spinner: NgxSpinnerService,
    private tipsService: TipsService,
    private formBuilder: FormBuilder,
    private pgService: PgService,
    private quoteActualProductsService: QuoteActualProductsService,
    private router: Router,


  ) {}

  logisticsExpensesCostTotal = 0

  datepipe: DatePipe = new DatePipe('en-US')

  /**Table of costs p&g */
  costsPg: any;
  showingSavedQuote = false

  totalCost = 0
  dollarPrice= 0
  productsList:any=[]

  columns: any[] = [
    { name: 'Actions' },
    { name: 'Sapcode' },
    { name: 'Txt Material' },
    { name: 'Total cost' },
    { name: 'Sale price (COP)' },
    { name: 'Margin (%)' },
    { name: 'EBITDA (%)' },
    { name: 'Quantity (KG)' },
    { name: 'Sale price / Kg (USD)' },
    { name: 'Sale price / Pound (USD)' }
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
    ])
  });

  tips:any=[]
  brief:any = {}
  user:any = {}
  parameters:any = {}
  project:any = {}

  kgToPoundFactor = 1/2.204
 
  comments = new FormControl("", [])


  async ngOnInit() {
    await this.loadPgInformation()
    this.setParameters();
    await this.setProject();
    this.setComments();
    this.setAdditionalCots();
    this.setLogisticsExpenses();
    this.setProducts();
    this.loadAssistantTips();
  }

  setComments(){
    this.comments.setValue(sessionStorage.getItem('comments') || "");
  }

  setLogisticsExpenses(){
    const national_freight = +(sessionStorage.getItem('national_freight')??0)||0
    const travel_expenses = +(sessionStorage.getItem('travel_expenses')??0)||0
    const custom_expenses = +(sessionStorage.getItem('custom_expenses')??0)||0

    const quantity = this.parameters.weight

    let costPerKgNationalFreight = quantity>0?(national_freight/quantity):0
    let costPerKgTravelExpenses = quantity>0?(travel_expenses/quantity):0
    let costPerKgCustomExpenses = quantity>0?(custom_expenses/quantity):0

    this.national_freight.setValue(national_freight);
    this.travel_expenses.setValue(travel_expenses);
    this.custom_expenses.setValue(custom_expenses);
    
    this.logisticsExpensesCostTotal =costPerKgTravelExpenses + costPerKgNationalFreight + costPerKgCustomExpenses

  }

  setAdditionalCots(){
    const freight = +(sessionStorage.getItem('freight')??0)||0
    const insurance = +(sessionStorage.getItem('insurance')??0)||0
    const others = +(sessionStorage.getItem('others')??0)||0

    this.freight.setValue(freight);
    this.insurance.setValue(insurance);
    this.others.setValue(others);
    
  }

  saveComments(){
    sessionStorage.setItem('comments',  this.comments.value);
  }

  updateLogisticsExpenses(){
    sessionStorage.setItem('national_freight',  this.national_freight.value);
    sessionStorage.setItem('travel_expenses',  this.travel_expenses.value);
    sessionStorage.setItem('custom_expenses',  this.custom_expenses.value);

    const quantity = this.parameters.weight

    let currentTravelExpensesValue = (+this.travel_expenses.value)||0
    let currentNationalFreightValue = (+this.national_freight.value)||0
    let currentCustomExpensesValue = (+this.custom_expenses.value)||0

    let costPerKgTravelExpenses = quantity>0?(currentTravelExpensesValue/quantity):0
    let costPerKgNationalFreight = quantity>0?(currentNationalFreightValue/quantity):0
    let costPerKgCustomExpenses = quantity>0?(currentCustomExpensesValue/quantity):0
    
    this.logisticsExpensesCostTotal =costPerKgTravelExpenses + costPerKgNationalFreight + costPerKgCustomExpenses

    const formArray = this.getFormArray().controls;

    for (let index = 0; index < formArray.length; index++) {
      const formControls = formArray[index];
      const priceUsd = formControls.value.price_usd || 0
      this.updatePrice(priceUsd.toString(), index)
    }
    
  }

  updateAdditionalCosts(){
    sessionStorage.setItem('freight',  this.freight.value);
    sessionStorage.setItem('insurance',  this.insurance.value);
    sessionStorage.setItem('others',  this.others.value);
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Logistics expenses form controls.
   */
  national_freight = new FormControl(0, []);
  travel_expenses= new FormControl(0, []);
  custom_expenses= new FormControl(0, []);
  
  /**
   * additional costs form controls.
   */
  freight = new FormControl(0, []);
  insurance= new FormControl(0, []);
  others= new FormControl(0, []);
  

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */
  async addNewRow(index: number) {
    const price_usd =this.productsList[index].price_usd||0
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const quantity_kg = this.productsList[index].quantity_kg || 0
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda = this.calculateEbitda(price_usd, this.productsList[index])
    const ebitda_percentage = price_usd!=0?((ebitda / price_cop) * 100):0

    const formGroup = this.formBuilder.group(
      {
        sapCode: this.productsList[index].sapCode,
        txt_material: this.productsList[index].txt_material,
        total_cost: Math.round(this.productsList[index].total_cost),
        price_cop: Math.round(price_cop),
        gross_margin: gross_margin_percentage==0?0:gross_margin_percentage.toFixed(2),
        ebitda: ebitda_percentage==0?0:ebitda_percentage.toFixed(2),
        quantity_kg: quantity_kg,
        price_usd: price_usd==0?0:price_usd.toFixed(2),
        price_usd_pound: price_usd==0?0:(price_usd*this.kgToPoundFactor).toFixed(2),
      }
    );
    this.getFormArray().push(formGroup);
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    formControls.get('price_usd')?.valueChanges.subscribe(v => this.updatePrice(v, index));
    formControls.get('price_usd_pound')?.valueChanges.subscribe(v => this.updatePriceKgToPound(v, index));
    formControls.get('gross_margin')?.valueChanges.subscribe(v => this.updateMargin(v, index));
    formControls.get('ebitda')?.valueChanges.subscribe(v => this.updateEbitda(v, index));
    formControls.get('quantity_kg')?.valueChanges.subscribe(v => this.updateQuantityKg(v, index));

  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {

      const product = this.productsList[index]
      this.totalCost-=this.productsList[index].total_cost;
      this.getFormArray().controls.splice(index, 1);
      await this.productsList.splice(index, 1)
      if (this.productsList.length==0) {
        this.completed.emit({ flow: 'quote', step: 2, completed: false });
        this.completed.emit({ flow: 'quote', step: 1, completed: false });
      }
      sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
      return;
  }

  setProducts(){
    this.productsList = JSON.parse(sessionStorage.getItem('productsList')||"[]");
    for (let index = 0; index < this.productsList.length; index++) {
      this.addNewRow(index)
      this.totalCost+=this.productsList[index].total_cost;
    }
  }

  getBase64ImageFromURL(url: string) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
    
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        var ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);
    
        var dataURL = canvas.toDataURL("image/png");
    
        resolve(dataURL);
      };
    
      img.onerror = error => {
        reject(error);
      };
    
      img.src = url;
    });
  }

  async getProductsRows(productsList:any){
    const products = []
    let total = 0;
    for (let index = 0; index < productsList.length; index++) {
      const product = productsList[index]
      const price_per_unit = Math.round(product.quantity_kg * product.price_usd)
      total += price_per_unit;
      products.push([{text: product.sapCode, colSpan: 1, alignment: 'center'}, {text: product.txt_material, colSpan: 3, alignment: 'center',}, {},  {}, {text: product.quantity_kg, alignment: 'center'}, {text: product.price_usd.toFixed(2), alignment: 'center'}, {text: price_per_unit, alignment: 'center', colSpan: 2}],)
    }
    return {products: products, total_price: total}
  }

  async downloadAsPDF(){
    const cliente = this.project.client || this.brief.cliente;
    const preparadoPor = this.project.user != null?(this.project.user.nombre + " " + this.project.user.apellido):(this.user.nombre + " " + this.user.apellido);
    const fecha = this.project.date!=null?(this.datepipe.transform(this.project.date, 'YYYY-MM-dd')):this.brief.fecha;
    const pais = this.project.country || this.brief.pais;
    const productsList = this.productsList
    const productsRows = await this.getProductsRows(productsList)
    const total_price_products = productsRows.total_price
    const freight = (+this.freight.value)||0
    const insurance = (+this.insurance.value)||0
    const others = (+this.others.value)||0
    const total_price = total_price_products + freight + insurance + others
    var docDefinition:any = {
      content: [
        {
          pageBreak: 'after',
          color: '#444',
          table: {
            widths: [50, 60, 55, 55, 60, 90, 50, 40],
            headerRows: 1,
            heights: [80],
            body: [
              [
                {text: 'COTIZACIÓN PRODUCTOS NACIONALES', style: 'tableHeader', colSpan: 5, alignment: 'center', height: 80, fontsize: 50}, {}, {}, {}, {}, 
                {image: await this.getBase64ImageFromURL('../../../../assets/header/luker-logo.png'), fit: [140, 100], colSpan: 3, alignment: 'center', margin: [0, 10, 0, 0],}, {}, {}
              ],
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: 'COTIZACION No.', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '', colSpan: 2, alignment: 'start'}, {}, {text: 'FECHA', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: fecha, colSpan: 2, alignment: 'start'}, {}],
                [{text: 'CLIENTE', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: cliente, colSpan: 2, alignment: 'start'}, {}, {text: 'CÓDIGO', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '-', colSpan: 2, alignment: 'start'}, {}],
              [{text: 'DIRECCION', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '', colSpan: 2, alignment: 'start'}, {}, {text: 'INCOTERM 2010', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: 'FOB', colSpan: 2, alignment: 'start'}, {}],
              [{text: 'PAIS', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: pais, colSpan: 2, alignment: 'start'}, {}, {text: 'PREPARADO POR', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: preparadoPor, colSpan: 2, alignment: 'start'}, {}],
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: 'CÓDIGO', colSpan: 1, alignment: 'center', fillColor: '#d3d3d3', style: 'title', margin: [0, 7, 0, 0]}, {text: 'DESCRIPCIÓN', colSpan: 3, alignment: 'center', fillColor: '#d3d3d3', style: 'title', margin: [0, 7, 0, 0]}, {},  {}, {text: 'Cantidad KG', alignment: 'center', fillColor: '#d3d3d3', style: 'title'}, {text: 'PRECIO\nUSD X KG', alignment: 'center', fillColor: '#d3d3d3', style: 'title'}, {text: 'PRECIO\nUNIDAD', alignment: 'center', fillColor: '#d3d3d3', colSpan: 2, style: 'title'}],
              ...(await this.getProductsRows(productsList)).products,
              [{text: 'TÉRMINOS DE PAGO', colSpan: 2, alignment: 'left', fillColor: '#d3d3d3', style: 'title'}, {},  {text: '', colSpan: 3, alignment: 'center',}, {}, {}, {text: 'TOTAL COP', alignment: 'right', border: [true, true, false, true], fillColor: '#d3d3d3', style: 'title'}, {text: total_price_products, alignment: 'right', colSpan: 2, border: [false, true, true, true], fillColor: '#d3d3d3'}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Flete', alignment: 'right', border: [false, true, false, false]}, {text: freight, alignment: 'right', colSpan: 2, border: [false, true, true, false]}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Seguro', alignment: 'right', border: [false, false, false, false]}, {text: insurance, alignment: 'right', colSpan: 2, border: [false, false, true, false]}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Otros', alignment: 'right', border: [false, false, false, false]}, {text: others, alignment: 'right', colSpan: 2, border: [false, false, true, false]}, {}],
              [{text: '', colSpan: 5, alignment: 'center', fillColor: '#d3d3d3', border: [true, true, false, true],}, {},  {}, {}, {}, {text: 'TOTAL CFR USD', alignment: 'right', border: [false, true, false, true], fillColor: '#d3d3d3', style: 'title'}, {text: total_price, alignment: 'right', colSpan: 2, border: [false, true, true, true], fillColor: '#d3d3d3'}, {}],
              
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              
              [{text: 'INSTRUCCIONES DE PAGO', colSpan: 4, alignment: 'left', fillColor: '#d3d3d3', style: 'title'}, {}, {},{}, {text: 'OBSERVACIONES', alignment: 'left', colSpan: 4, fillColor: '#d3d3d3', style: 'title'}, {}, {}, {}],
              [{text: 'Pendiente acordar', colSpan: 4, alignment: 'left', border: [true, true, true, false]}, {}, {},{}, {text: '', alignment: 'left', colSpan: 4, border: [true, true, true, false]}, {}, {}, {}],
              [{text: '', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: '* Precios sujetos a futuros de cacao en bolsa', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: '', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: '', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: [{ text:'FABRICANTE: ', bold: true}, 'SUCESORES DE JOSE JESUS RESTREPO Y CIA S.A CASALUKER S.A'], border: [true, true, true, false], colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: [{ text: 'PAÍS DE ORIGEN: ', bold: true}, 'COLOMBIA'], colSpan: 8, alignment: 'center',  border: [true, false, true, true],}, {}, {}, {}, {}, {}, {}, {}],
            ]
          }
        },
        {
          color: '#444',
          table: {
            widths: [50, 60, 55, 55, 70, 90, 40, 40],
            headerRows: 1,
            heights: [80],
            body: [
              [
                {text: 'EXPORT PRODUCTS QUOTE', style: 'tableHeader', colSpan: 5, alignment: 'center', height: 80, fontsize: 50}, {}, {}, {}, {}, 
                {image: await this.getBase64ImageFromURL('../../../../assets/header/luker-logo.png'), fit: [140, 100], colSpan: 3, alignment: 'center', margin: [0, 10, 0, 0],}, {}, {}
              ],
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: 'QUOTE No.', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '', colSpan: 2, alignment: 'start'}, {}, {text: 'DATE', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: fecha, colSpan: 2, alignment: 'start'}, {}],
                [{text: 'CLIENT', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: cliente, colSpan: 2, alignment: 'start'}, {}, {text: 'CUSTOMER CODE', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '-', colSpan: 2, alignment: 'start'}, {}],
              [{text: 'ADDRESS', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: '', colSpan: 2, alignment: 'start'}, {}, {text: 'INCOTERM', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: 'DDP', colSpan: 2, alignment: 'start'}, {}],
              [{text: 'COUNTRY', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: pais, colSpan: 2, alignment: 'start'}, {}, {text: 'PREPARED BY', colSpan: 2, alignment: 'start', fillColor: '#d3d3d3', style: 'title'}, {}, {text: preparadoPor, colSpan: 2, alignment: 'start'}, {}],
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: 'CODE', colSpan: 1, alignment: 'center', fillColor: '#d3d3d3', style: 'title', margin: [0, 7, 0, 0]}, {text: 'PRODUCT DESCRIPTION', colSpan: 3, alignment: 'center', fillColor: '#d3d3d3', style: 'title', margin: [0, 7, 0, 0]}, {},  {}, {text: 'QUANTITY (Kg)', alignment: 'center', fillColor: '#d3d3d3', style: 'title'}, {text: 'FOB PRICE\nUSD/KG', alignment: 'center', fillColor: '#d3d3d3', style: 'title'}, {text: 'TOTAL PRICE\nUSD', alignment: 'center', fillColor: '#d3d3d3', colSpan: 2, style: 'title'}],
              ...(await this.getProductsRows(productsList)).products,
              [{text: 'PAYMENT TERMS', colSpan: 2, alignment: 'left', fillColor: '#d3d3d3', style: 'title'}, {},  {text: '', colSpan: 3, alignment: 'center',}, {}, {}, {text: 'TOTAL DDP USD', alignment: 'right', border: [true, true, false, true], fillColor: '#d3d3d3', style: 'title'}, {text: total_price_products, alignment: 'right', colSpan: 2, border: [false, true, true, true], fillColor: '#d3d3d3'}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Freight', alignment: 'right', border: [false, true, false, false]}, {text: freight, alignment: 'right', colSpan: 2, border: [false, true, true, false]}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Insurance', alignment: 'right', border: [false, false, false, false]}, {text: insurance, alignment: 'right', colSpan: 2, border: [false, false, true, false]}, {}],
              [{text: '', colSpan: 5, alignment: 'center', border: [true, false, false, false]}, {},  {}, {}, {}, {text: 'Others', alignment: 'right', border: [false, false, false, false]}, {text: others, alignment: 'right', colSpan: 2, border: [false, false, true, false]}, {}],
              [{text: '', colSpan: 4, alignment: 'center', fillColor: '#d3d3d3', border: [true, true, false, true],}, {},  {}, {}, {text: 'TOTAL DDP CHICAGO WH USD', alignment: 'right', border: [false, true, false, true], fillColor: '#d3d3d3', style: 'title', colSpan: 2}, {}, {text: total_price, alignment: 'right', colSpan: 2, border: [false, true, true, true], fillColor: '#d3d3d3'}, {}],
              
              [{text: ' ', colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              
              [{text: 'PAYMENT INSTRUCTIONS', colSpan: 4, alignment: 'left', fillColor: '#d3d3d3', style: 'title'}, {}, {},{}, {text: 'Notes:', alignment: 'center', colSpan: 4, fillColor: '#d3d3d3', style: 'title'}, {}, {}, {}],
              [{text: 'Final Beneficiary: CASA LUKER S.A.', colSpan: 4, alignment: 'left', border: [true, true, true, false]}, {}, {},{}, {text: 'Our quotation remains without engagement ', alignment: 'center', colSpan: 4, border: [true, true, true, false]}, {}, {}, {}],
              [{text: "Beneficiary's Account Number: 80110000730", colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: 'until final order confirmation. ', alignment: 'center', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'Beneficiary Bank: BANCOLOMBIA PANAMA', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'Swift code Beneficiary Bank: COLOPAPA', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'Intermediary bank: CITIBANK NY', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: "Intermediary bank' swift: code: CITIUS33'", colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'Account Number in intermediary bank: 36009162#', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'ABA: 021000089', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: 'CHIP: 0008', colSpan: 4, alignment: 'left', border: [true, false, true, false]}, {}, {},{}, {text: ' ', alignment: 'left', colSpan: 4, border: [true, false, true, false]}, {}, {}, {}],
              [{text: [{ text:'MANUFACTURER: ', bold: true}, 'SUCESORES DE JOSE JESUS RESTREPO Y CIA S.A, CASALUKER S.A'], border: [true, true, true, false], colSpan: 8, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}],
              [{text: [{ text: 'COUNTRY OF ORIGIN: ', bold: true}, 'COLOMBIA, SOUTH AMERICA'], colSpan: 8, alignment: 'center',  border: [true, false, true, false],}, {}, {}, {}, {}, {}, {}, {}],
              [{text: 'We declare under oath that the prices hereby established are the ones fixed for export.', colSpan: 8, alignment: 'center',  border: [true, false, true, true],}, {}, {}, {}, {}, {}, {}, {}],
            ]
          }
        },
      ],
      styles: {
        title: {
            bold: true,
          fontSize: 12,
          color: 'black'
        },
        tableHeader: {
            margin: [0, 30, 0, 0],
          bold: true,
          fontSize: 16,
          color: 'black'
        }
      },
      defaultStyle: {
          fontSize: 12,
        // alignment: 'justify'
      }
    }

    pdfMake.createPdf(docDefinition).download("portfolio-products.pdf");
  }
  
  async downloadAsExcel(){
    const cliente = this.project.client || this.brief.cliente;
    const preparadoPor = this.project.user != null?(this.project.user.nombre + " " + this.project.user.apellido):(this.user.nombre + " " + this.user.apellido);
    const fecha = this.project.date!=null?(this.datepipe.transform(this.project.date, 'YYYY-MM-dd')):this.brief.fecha;
    const pais = this.project.country || this.brief.pais;
    const productsList = this.productsList
    const freight = (+this.freight.value)||0
    const insurance = (+this.insurance.value)||0
    const others = (+this.others.value)||0
    const res = await fetch("../../../../assets/excel/quote.xlsx")
    const arrayBuffer = await res.arrayBuffer()
    var workbook = await new Workbook();
    await workbook.xlsx.load(arrayBuffer)
    let worksheet = workbook.getWorksheet(1);
    let worksheetEnglish = workbook.getWorksheet(2);

    //For quote in spanish
    worksheet.getCell('D9').value = cliente
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H11').value = preparadoPor
    worksheet.getCell('D11').value = pais
    //For quote in english
    worksheetEnglish.getCell('D9').value = cliente
    worksheetEnglish.getCell('H8').value = fecha
    worksheetEnglish.getCell('H8').value = fecha
    worksheetEnglish.getCell('H11').value = preparadoPor
    worksheetEnglish.getCell('D11').value = pais

    for (let index = 0; index < productsList.length; index++) {
      const product = productsList[index]
      if (index<1) {
        //For quote in spanish
        worksheet.getRow(14+index).getCell(2).value = product.sapCode
        worksheet.getRow(14+index).getCell(3).value = product.txt_material
        worksheet.getRow(14+index).getCell(7).value = product.quantity_kg
        worksheet.getRow(14+index).getCell(8).value = product.price_usd
        //For quote in english
        worksheetEnglish.getRow(14+index).getCell(2).value = product.sapCode
        worksheetEnglish.getRow(14+index).getCell(3).value = product.txt_material
        worksheetEnglish.getRow(14+index).getCell(7).value = product.quantity_kg
        worksheetEnglish.getRow(14+index).getCell(8).value = product.price_usd

        this.createSheet(product, workbook)
      } else {

        const rowToDuplicate = worksheet.getRow(14)

        const newRow = worksheet.insertRow(14+index, []); 
        rowToDuplicate.eachCell((cell, colNumber) => {
          const newCell:any = newRow.getCell(colNumber);
          newCell.style = cell.style;
          newCell.alignment = cell.alignment;
          newCell.numFmt = cell.numFmt;
          newCell.border = cell.border;
        });
        newRow.height = rowToDuplicate.height;

        const rowToDuplicateEnglish = worksheetEnglish.getRow(14)

        const newRowEnglish = worksheetEnglish.insertRow(14+index, []); 
        rowToDuplicateEnglish.eachCell((cell, colNumber) => {
          const newCell:any = newRowEnglish.getCell(colNumber);
          newCell.style = cell.style;
          newCell.alignment = cell.alignment;
          newCell.numFmt = cell.numFmt;
          newCell.border = cell.border;
        });
        newRowEnglish.height = rowToDuplicateEnglish.height;
        
        //For quote in spanish
        worksheet.getRow(14+index).getCell(2).value = product.sapCode
        worksheet.getRow(14+index).getCell(3).value = product.txt_material
        worksheet.getRow(14+index).getCell(7).value = product.quantity_kg
        worksheet.getRow(14+index).getCell(8).value = product.price_usd
        worksheet.getRow(14+index).getCell(9).value = {
          formula: `IFERROR(H${14+index}*G${14+index}," ")`,
          sharedFormula: `IFERROR(H${14+index}*G${14+index}," ")`,
          date1904: false
        };
        worksheet.mergeCells(`C${14+index}`, `F${14+index}`);

        //For quote in english
        worksheetEnglish.getRow(14+index).getCell(2).value = product.sapCode
        worksheetEnglish.getRow(14+index).getCell(3).value = product.txt_material
        worksheetEnglish.getRow(14+index).getCell(7).value = product.quantity_kg
        worksheetEnglish.getRow(14+index).getCell(8).value = product.price_usd
        worksheetEnglish.getRow(14+index).getCell(9).value = {
          formula: `IFERROR(H${14+index}*G${14+index}," ")`,
          sharedFormula: `IFERROR(H${14+index}*G${14+index}," ")`,
          date1904: false
        };
        worksheetEnglish.mergeCells(`C${14+index}`, `F${14+index}`);
        this.createSheet(product, workbook)
        
      }
    }

    //Spanish Quote
    await this.spanishWorksheetAdjustments(worksheet, productsList, freight, insurance, others)
    
    //English Quote
    await this.englishWorksheetAdjustments(worksheetEnglish, productsList, freight, insurance, others)
    

    workbook.xlsx.writeBuffer().then((res: any) => {
      const blob = new Blob([res]);
      fs.saveAs(blob, 'portfolio-products.xlsx');
    });

  }

  async spanishWorksheetAdjustments(worksheet: Worksheet, productsList:any, freight:number, insurance:number, others:number){
    worksheet.mergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.mergeCells(`E${14+productsList.length}`, `G${14+productsList.length}`);
    worksheet.getRow(14+productsList.length).getCell(9).value = {
      formula: `+SUM(I14:I${13+productsList.length})`,
      sharedFormula: `+SUM(I14:I${13+productsList.length})`,
      date1904: false
    };
    worksheet.getRow(14+productsList.length).getCell(5).style.border = {
      right: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      left: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
    }
    worksheet.unMergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.mergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.getRow(14+productsList.length).getCell(9).value = {
      formula: `+SUM(I14:I${13+productsList.length})`,
      sharedFormula: `+SUM(I14:I${13+productsList.length})`,
      date1904: false
    };
    worksheet.getRow(14+productsList.length).getCell(9).style.border = {
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      top: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
    };
    worksheet.getRow(15+productsList.length).getCell(9).value = freight
    worksheet.getRow(16+productsList.length).getCell(9).value = insurance
    worksheet.getRow(17+productsList.length).getCell(9).value = others

    worksheet.getRow(18+productsList.length).getCell(9).value = {
      formula: `+SUM(I${14+productsList.length}:I${17+productsList.length})`,
      sharedFormula: `+SUM(I${14+productsList.length}:I${17+productsList.length})`,
      date1904: false
    };
    worksheet.mergeCells(`B${18+productsList.length}`, `H${18+productsList.length}`);
    worksheet.mergeCells(`B${20+productsList.length}`, `F${20+productsList.length}`);
    worksheet.mergeCells(`G${20+productsList.length}`, `I${20+productsList.length}`);
    worksheet.mergeCells(`B${21+productsList.length}`, `F${22+productsList.length}`);
    worksheet.mergeCells(`G${21+productsList.length}`, `I${22+productsList.length}`);
    const observationsRow =  worksheet.getRow(20+productsList.length)
    const observationsContentRow =  worksheet.getRow(21+productsList.length)
    observationsContentRow.height = 70
    observationsRow.getCell(7).style.border = {
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      top: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      left: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
    }
    observationsContentRow.getCell(7).style.border = {
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      top: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      left: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
    }

    const manufacturerRow =  worksheet.getRow(23+productsList.length)
    const countryOfOriginRow =  worksheet.getRow(24+productsList.length)
    manufacturerRow.getCell(2).style.border = {
      top: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      left: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
    }
    countryOfOriginRow.getCell(2).style.border = {
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      left: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
    }
    worksheet.mergeCells(`B${23+productsList.length}`, `I${23+productsList.length}`);
    worksheet.mergeCells(`B${24+productsList.length}`, `I${24+productsList.length}`);
  }

  async englishWorksheetAdjustments(worksheet: Worksheet, productsList:any, freight:number, insurance:number, others:number){
    worksheet.unMergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.mergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.getRow(14+productsList.length).getCell(9).value = {
      formula: `+SUM(I14:I${13+productsList.length})`,
      sharedFormula: `+SUM(I14:I${13+productsList.length})`,
      date1904: false
    };
    worksheet.unMergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.mergeCells(`B${14+productsList.length}`, `D${14+productsList.length}`);
    worksheet.getRow(14+productsList.length).getCell(9).value = {
      formula: `+SUM(I14:I${13+productsList.length})`,
      sharedFormula: `+SUM(I14:I${13+productsList.length})`,
      date1904: false
    };
    worksheet.getRow(14+productsList.length).getCell(9).style.border = {
      right: {
        style: 'medium',
        color: {
          argb: '"FF000000"'
        }
      },
      top: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
      bottom: {
        style: 'thin',
        color: {
          argb: '"FF000000"'
        }
      },
    };
    worksheet.getRow(15+productsList.length).getCell(9).value = freight
    worksheet.getRow(16+productsList.length).getCell(9).value = insurance
    worksheet.getRow(17+productsList.length).getCell(9).value = others

    worksheet.getRow(18+productsList.length).getCell(9).value = {
      formula: `+SUM(I${14+productsList.length}:I${17+productsList.length})`,
      sharedFormula: `+SUM(I${14+productsList.length}:I${17+productsList.length})`,
      date1904: false
    };
    worksheet.mergeCells(`B${18+productsList.length}:H${18+productsList.length}`);
    worksheet.mergeCells(`B${20+productsList.length}`, `F${20+productsList.length}`);
    worksheet.mergeCells(`G${20+productsList.length}`, `I${20+productsList.length}`);
    worksheet.mergeCells(`B${21+productsList.length}`, `F${29+productsList.length}`);
    worksheet.mergeCells(`G${21+productsList.length}`, `I${29+productsList.length}`);

    worksheet.mergeCells(`B${30+productsList.length}`, `I${30+productsList.length}`);
    worksheet.mergeCells(`B${31+productsList.length}`, `I${31+productsList.length}`);
    worksheet.mergeCells(`B${32+productsList.length}`, `I${32+productsList.length}`);
  }

  async createSheet(product:any, workbook:any){
    let worksheet = workbook.addWorksheet(product.sapCode,  {properties:{defaultColWidth:17}});
    worksheet.addRow(["Packaging"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "factorKg",
      "costo",
      "status",
      "registro",
      "validez",
      "inco1",
      "inco2"
    ])
    const packagingList = product.packaging_list.map(({descElemento, grupoArticuloComponente, importeEscala, nombrePt, factorDivision, ump, moneda,  ...keepAttrs}:any) => keepAttrs)
    const orderPackaging = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let packaging of packagingList) {
      const sortedPackaging = Object.assign(orderPackaging, packaging);
      const values = Object.values(sortedPackaging)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials one"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "factorKg",
      "costo"
    ])
    const rawMaterialOneList = product.raw_material_one_list.map(({descElemento, grupoArticuloComponente, nombrePt, categoria,  ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialOne = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let material of rawMaterialOneList) {
      const sortedRawMaterialOne = Object.assign(orderRawMaterialOne, material);
      const values = Object.values(sortedRawMaterialOne)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials two"])
      worksheet.addRow([
        "componente",
        "nombreComponente",
        "precio_neto",
        "factorKg",
        "costo",
        "status",
        "registro",
        "validez",
        "inco1",
        "inco2"
    ])
    const rawMaterialTwoList = product.raw_material_two_list.map(({descElemento, grupoArticuloComponente, importeEscala, nombrePt, factorDivision, ump, moneda,  ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialTwo = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let material of rawMaterialTwoList) {
      const sortedRawMaterialTwo = Object.assign(orderRawMaterialTwo, material);
      const values = Object.values(sortedRawMaterialTwo)
      worksheet.addRow(values)
      
    }
    worksheet.addRow()
    worksheet.addRow(["Processes"])
      worksheet.addRow([
        "ltxa_1", "arbid", "oee",
          "zmod",
          "zman",
          "zenr",
          "zgas",
          "zdep",
          "zoin",
          "subtotal"
    ])
    const processesList = product.processes_list
    const orderProcesses = {
      ltxa_1:null,
      arbid:null,
      oee:null,
      zmod:null,
      zman:null,
      zenr:null,
      zgas:null,
      zdep:null,
      zoin:null,
      subtotal:null
    } 
    
    for (let process of processesList) {
      const sortedProcesses = Object.assign(orderProcesses, process);
      const values = Object.values(sortedProcesses)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow(["Sale Price / Kg (USD)", "Sale Price / Pound (USD)",	"Sale Price (COP)",	"Total cost", "Gross margin (COP)",	"Gross margin (%)", "EBITDA (COP)",	"EBITDA (%)"])
    worksheet.addRow([product.price_usd??0, ((product.price_usd??0)*this.kgToPoundFactor), product.price_cop??0, product.total_cost, product.gross_margin??0, product.gross_margin_percentage??0, product.ebitda??0, product.ebitda_percentage??0])
    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow(["weight", "Dolar price",	"Cocoa price USD/Ton",	"Cocoa Premium USD/Ton", "Euro price"])
    worksheet.addRow([this.parameters.weight||0, this.parameters.dollarPrice||0, this.parameters.cacaoPrice||0, this.parameters.cacaoBonus||0, this.parameters.euroPrice||0])

    worksheet.mergeCells('A1', 'J1');
    worksheet.mergeCells(`A${(packagingList.length + 4)}`, `E${(packagingList.length + 4)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + 7)}`, `J${(rawMaterialOneList.length + packagingList.length + 7)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`, `J${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`);
  }

  updatePrice(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_usd = +(value||0)
    const price_cop = this.dollarPrice*price_usd
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0
    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage

    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_usd_pound: price_usd_pound.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0
    }, {emitEvent: false, onlySelf: true})
  }

  updatePriceKgToPound(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_usd_pound = +(value||0) 
    const price_usd = price_usd_pound / this.kgToPoundFactor
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0
    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage

    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_usd: price_usd.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0
    }, {emitEvent: false, onlySelf: true})
  }

  updateMargin(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const gross_margin_percentage = value||0
    const price_cop = gross_margin_percentage==100 || gross_margin_percentage==0?0:(this.productsList[index].total_cost)/(1 - gross_margin_percentage/100)
    const price_usd = price_cop/this.dollarPrice
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const gross_margin = price_cop!=0?price_cop-this.productsList[index].total_cost:0
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0
    
    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage
    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_usd_pound: price_usd_pound.toFixed(2),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0
     }, {emitEvent: false, onlySelf: true})
  }

  updateEbitda(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_cop =  this.calculateNetsalesFromEbitda(value, this.productsList[index])
    const price_usd = price_cop/this.dollarPrice
    const price_usd_pound = price_usd * this.kgToPoundFactor
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda_percentage = value||0
    const ebitda = ebitda_percentage*price_cop/100

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage
    
    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      price_cop: Math.round(price_cop),
      price_usd_pound: price_usd_pound.toFixed(2),
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
     }, {emitEvent: false, onlySelf: true})
  }

  updateQuantityKg(value:any, index:any){
    this.productsList[index].quantity_kg = +(value||0)
    
    sessionStorage.setItem('productsList', JSON.stringify(this.productsList));
  }

  

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }

  setParameters(){
    const parameters = JSON.parse(sessionStorage.getItem('parameters')||"{}");
    this.parameters = parameters
    this.brief =JSON.parse(ls.get('brief', { decrypt: true }) || '{}');
    this.user =JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    if (parameters.dollarPrice!=null) {
      this.dollarPrice=parameters.dollarPrice;
    }
  }
  
  openModalProductInfo(index:number) {
    this.sms.addModal(ModalProductDetailsComponent,
      {product: this.productsList[index]},
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe((res: any) => {
    });
  }

    /**This function load the costs of p&g */
    async loadPgInformation() {
      this.costsPg = await this.pgService.loadPgInformation();
    }
  
    calculateEbitda(priceUSD:any, product:any){
      if(!(priceUSD>0)) return 0
      const pais = this.brief.pais;
      const netSales = priceUSD * this.dollarPrice;

      const ebitda = this.pgService.EbitdaFromPrice(
        netSales,
        pais,
        product.processes.zenr,
        product.processes.zgas,
        product.processes.zmod,
        product.processes.zman,
        product.processes.zoin,
        product.cost_packaging,
        product.cost_raw_material_one,
        product.cost_raw_material_two,
        this.costsPg.sellingExpensesNational,
        this.costsPg.sellingExpensesInternational,
        this.costsPg.groupVariableNational,
        this.costsPg.groupVariableInternational,
        this.costsPg.generalCompanyNational,
        this.costsPg.generalCompanyInternational,
        this.costsPg.industryCommerceNational,
        this.costsPg.industryCommerceInternational,
        this.costsPg.taxThousandNational,
        this.costsPg.taxThousandExport,
        this.costsPg.LogisticsWarehouseSaleNational,
        this.costsPg.LogisticsWarehouseSaleInternational,
        this.costsPg.marketingNational,
        this.costsPg.marketingInternational,
        this.logisticsExpensesCostTotal,
      )
      return ebitda * netSales / 100
    }
  
    calculateNetsalesFromEbitda(ebitda_percentage:any, product:any){
      const pais = this.brief.pais;
      const netSales = this.pgService.netSalesFromEbitdaPercentage(
        ebitda_percentage,
        pais,
        product.processes.zenr,
        product.processes.zgas,
        product.processes.zmod,
        product.processes.zman,
        product.processes.zoin,
        product.cost_packaging,
        product.cost_raw_material_one,
        product.cost_raw_material_two,
        this.costsPg.sellingExpensesNational,
        this.costsPg.sellingExpensesInternational,
        this.costsPg.groupVariableNational,
        this.costsPg.groupVariableInternational,
        this.costsPg.generalCompanyNational,
        this.costsPg.generalCompanyInternational,
        this.costsPg.industryCommerceNational,
        this.costsPg.industryCommerceInternational,
        this.costsPg.taxThousandNational,
        this.costsPg.taxThousandExport,
        this.costsPg.LogisticsWarehouseSaleNational,
        this.costsPg.LogisticsWarehouseSaleInternational,
        this.costsPg.marketingNational,
        this.costsPg.marketingInternational,
        this.logisticsExpensesCostTotal,
      )
      return netSales
    }

    async setProject(){
      this.project = JSON.parse(sessionStorage.getItem('current_actual_products_project') || "{}");
      if (Object.keys(this.project).length > 0) {
        sessionStorage.setItem('comments', this.project.comments||"");
        if (this.project.logistics_expenses!=undefined && this.project.logistics_expenses!=null) {
          sessionStorage.setItem('national_freight', this.project.logistics_expenses.national_freight||"");
          sessionStorage.setItem('travel_expenses', this.project.logistics_expenses.travel_expenses||"");
          sessionStorage.setItem('custom_expenses', this.project.logistics_expenses.custom_expenses||"");
        }
        if (this.project.additional_costs!=undefined && this.project.additional_costs != null) {
          sessionStorage.setItem('freight', this.project.additional_costs.freight||"");
          sessionStorage.setItem('insurance', this.project.additional_costs.insurance||"");
          sessionStorage.setItem('others', this.project.additional_costs.others||"");
        }
        this.showingSavedQuote = true
      }
    }

    
    async saveProject(){
      const cliente = this.project.client || this.brief.cliente;
      const preparadoPor = this.project.user || this.user._id;
      const fecha = this.project.date || this.brief.fecha;
      const pais = this.project.country || this.brief.pais;
      const productsList = this.productsList
      const logistics_expenses = {
        national_freight: this.national_freight.value||0,
        travel_expenses: this.travel_expenses.value||0,
        custom_expenses: this.custom_expenses.value||0,
      }
      const additional_costs = {
        freight: this.freight.value||0,
        insurance: this.insurance.value||0,
        others: this.others.value||0,
      }
      const project = {
        client: cliente,
        user: preparadoPor,
        date: fecha,
        country: pais,
        products: productsList,
        logistics_expenses: logistics_expenses,
        additional_costs: additional_costs,
        parameters: this.parameters,
        comments: this.comments.value
      }
      if (this.project._id != null) {
        const result:any = await this.quoteActualProductsService.updateProject(this.project._id, project)
        if (result.success == true) {
          this.router.navigateByUrl("actual-products-projects");
        }
      } else {
        const result:any = await this.quoteActualProductsService.saveProject(project)
        if (result.success == true) {
          this.router.navigateByUrl("actual-products-projects");
        }
      }
      
    }

    async createNewVersion(){
      const cliente = this.project.client
      const preparadoPor = this.user._id;
      const fecha = new Date();
      const pais = this.project.country;
      const productsList = this.productsList
      const logistics_expenses = {
        national_freight: this.national_freight.value||0,
        travel_expenses: this.travel_expenses.value||0,
        custom_expenses: this.custom_expenses.value||0,
      }
      const additional_costs = {
        freight: this.freight.value||0,
        insurance: this.insurance.value||0,
        others: this.others.value||0,
      }
      const project = {
        client: cliente,
        user: preparadoPor,
        date: fecha,
        country: pais,
        products: productsList,
        logistics_expenses: logistics_expenses,
        additional_costs: additional_costs,
        parameters: this.parameters,
        comments: this.comments.value
      }
      const result:any = await this.quoteActualProductsService.createNewVersion(this.project.projectId, project)
      if (result.success == true) {
        this.router.navigateByUrl("actual-products-projects");
      }
    }
}