<div class="main-container">
  <section class="content">
    <div style="overflow-y: scroll; height: 100%;">
      <p class="added-products">Added Products</p>
      <div class="container__added-products">
        <div class="form-table">
          <table aria-describedby="form table">
            <thead>
              <tr>
                <th scope="col" *ngFor="let header of columns">{{ header.name }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container [formGroup]="tableForm">
                <ng-container formArrayName="form_array">
                  <tr [formGroupName]="iForm" *ngFor="let form of getFormArray().controls; let iForm = index;" id="row-{{ iForm }}">
                    <td>
                      <div class="row-actions">
                          <img *ngIf="!showingSavedQuote" src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" (click)="removeRow(iForm)">
                          <img *ngIf="showingSavedQuote" src="assets/icons/trash-can.svg" width="17" height="17" alt="Remove" style="opacity:0.4;filter:alpha(opacity=100);">
                          <img src="assets/icons/magnifier.svg" width="27" height="27" alt="Search" (click)="openModalProductInfo(iForm)">
                      </div>
                    </td>
                    <td *ngFor="let property of getFormArrayControls(form); let i = index;">
                      <div>
                        <div >
                          <input class="input-table" *ngIf="(property != 'price_usd' && property != 'price_usd_pound' && property != 'gross_margin' && property != 'price_cop' && property != 'ebitda' && property != 'total_cost' && property != 'cost_per_unit')"  [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                          <input class="input-table" *ngIf="(property != 'price_usd' && property != 'price_usd_pound' && property != 'gross_margin' && property != 'ebitda' && (property == 'price_cop' || property == 'total_cost' || property == 'cost_per_unit'))" currencyMask [readonly]="true" type="text" pInputText placeholder="" [formControlName]="property" id="input-{{ property }}">
                          <input *ngIf="(property == 'price_usd') || (property == 'gross_margin') || (property == 'ebitda') || (property == 'price_usd_pound')" 
                          appTwoDigitDecimaNumber
                            class="white-background" 
                            type="text" pInputText placeholder="Enter price" [readonly]="false" [formControlName]="property" id="input-{{ property }}">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td colspan="3">
                      Total
                      
                    </td>
                    <td  colspan="9">
                      <div>{{ totalCost|number:'1.0-0':'en-US' }}</div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <p class="added-products" style="padding: 0px;">Comments</p>
        <div class="container__comments">
          <textarea placeholder="Input text" rows="5" cols="50" [formControl]="comments" [readOnly]="showingSavedQuote" (ngModelChange)="saveComments()" pInputTextarea>
          </textarea>
        </div>
      </div>
    </div>
  </section>

</div>
