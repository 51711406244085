import { InclusionsFlavorsService } from './../../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RTLProductService } from '../../../../core/services/rtl-product/rtlproduct.service';
import { WebSocketService } from '../../../../core/services/web-socket/web-socket.service';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-ready-to-launch',
  templateUrl: './ready-to-launch.component.html',
  styleUrls: ['./ready-to-launch.component.css'],
})
export class ReadyToLaunchComponent implements OnInit {
  /** This array stores the RTL products currently available. */
  rtlProducts: any[] = [];

  /** The values of the fields used as filters. */
  filters: any = {};

  /** The models binded to the select values. */
  filterSelections: any = {
    selectedType: '',
    selectedUnits: '',
    selectedCategory: '',
    selectedSubcategory: '',
    selectedFormat: '',
  };

  filter = true;

  /** The Ready To Launch product selected by the user. */
  pickedProduct: any = {};

  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();

  constructor(
    private rtlProductService: RTLProductService,
    private webSocketService: WebSocketService,
    private inclusionsFlavorsService: InclusionsFlavorsService
  ) {}

  async ngOnInit() {
    await this.getRTLProducts();
    await this.loadFiltersValues();
    this.getValidationCategory();
    this.listenSocket();
  }

  /**Function validates that the category selected stream is true to
   * automatically select the category and filter the categories on ready to launch
   */
  getValidationCategory() {
    let categorySelected = this.rtlProductService.getValidationCategory();
    let categoryNameSelected = this.rtlProductService.getCategorySelected();
    if (categorySelected) {
      this.filterSelections.selectedCategory = categoryNameSelected;
      this.filterIngredient();
    }
  }

  /**
   * This functions gets all the RTL products.
   */
  async getRTLProducts() {
    this.rtlProducts = await this.rtlProductService.getAllRTLProducts();
  }

  /**
   * This function handles the RTL products selections.
   * @param index The position of the selected item.
   */
  handleSelection(index: number) {
    for (let i = 0; i < this.rtlProducts.length; i++) {
      this.rtlProducts[i].selected = false;
    }
    this.rtlProducts[index].selected = !this.rtlProducts[index].selected;
    this.pickedProduct = this.rtlProducts[index];
    this.saveRTLSelection();
    if (this.webSocketService.active) {
      if (this.webSocketService.getRole == 'admin') {
        this.webSocketService.emitToClient({ type: 'select', itemId: this.pickedProduct.idRtl });
      }
    }
  }

  /**
   * This function listens to the socket, waiting for changes in the selection of the commercial, and selects the RTL product in the screen of the client.
   */
  listenSocket() {
    this.webSocketService.listen().subscribe((data: any) => {
      for (let i = 0; i < this.rtlProducts.length; i++) {
        this.rtlProducts[i].selected = false;
      }
      let index = this.rtlProducts.findIndex((rtl) => rtl.idRtl == data.object.itemId);
      this.rtlProducts[index].selected = !this.rtlProducts[index].selected;
      this.pickedProduct = this.rtlProducts[index];
      this.saveRTLSelection();
    });
  }

  /**
   * Loads all the values of the RTL products filter.
   */
  async loadFiltersValues() {
    this.filters.typeFilter = await this.rtlProductService.getFilterValues('tipo');
    this.filters.units = await this.rtlProductService.getFilterValues('unidades');
    this.filters.categories = await this.rtlProductService.getFilterValues('categoria');
    this.filters.subcategories = await this.rtlProductService.getFilterValues('subcategoria');
    this.filters.format = await this.rtlProductService.getFilterValues('format');
  }

  /**
   * This function filters the ready to launch products depending on the filters the user clicked.
   */
  filterIngredient() {
    let rtlQuery: any = {};
    if (this.filterSelections.selectedType) {
      rtlQuery.tipo = this.filterSelections.selectedType;
    }
    if (this.filterSelections.selectedUnits) {
      rtlQuery.unidades = this.filterSelections.selectedUnits;
    }
    if (this.filterSelections.selectedCategory) {
      rtlQuery.categoria = this.filterSelections.selectedCategory;
    }
    if (this.filterSelections.selectedSubcategory) {
      rtlQuery.subcategoria = this.filterSelections.selectedSubcategory;
    }
    if (this.filterSelections.selectedFormat) {
      rtlQuery.format = this.filterSelections.selectedFormat;
    }
    this.rtlProductService.filter(rtlQuery).then((res) => {
      this.rtlProducts = res as any[];
    });
  }

  /**
   * This functions removes all the filters selected.
   */
  clearFilters() {
    this.filterSelections.selectedType = '';
    this.filterSelections.selectedUnits = '';
    this.filterSelections.selectedCategory = '';
    this.filterSelections.selectedSubcategory = '';
    this.rtlProductService.filter({}).then((res) => {
      this.rtlProducts = res as any[];
    });
  }

  /**
   * This function saves all the items needed to calculate the costs of the RTL product.
   */
  async saveRTLSelection() {
    localStorage.setItem('rtl', JSON.stringify(this.pickedProduct));
    localStorage.setItem('packaging', JSON.stringify({ combinacionEmpaqueId: this.pickedProduct.combEmpaque }));
    ls.set('shape', JSON.stringify({ formaId: this.pickedProduct.formaId }), { encrypt: true });
    let inclusion: any = {};
    if (this.pickedProduct.inclusion1Id) {
      let inclusion1 = await this.inclusionsFlavorsService.filterOne({
        ingredienteId: this.pickedProduct.inclusion1Id,
      });
      inclusion1.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${inclusion1.codigoSap}.jpg`;
      inclusion.base = {
        inclusion: inclusion1,
        percentage: this.pickedProduct.porcentInclusion1,
        application: 'superficial',
      };
    }
    if (this.pickedProduct.inclusion2Id) {
      let inclusion2 = await this.inclusionsFlavorsService.filterOne({
        ingredienteId: this.pickedProduct.inclusion2Id,
      });
      inclusion2.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${inclusion2.codigoSap}.jpg`;
      inclusion.extra = {
        inclusion: inclusion2,
        percentage: this.pickedProduct.porcentInclusion2,
        application: 'superficial',
      };
    }
    localStorage.setItem('inclusion', JSON.stringify(inclusion));
    if (this.pickedProduct.saborizanteId) {
      let flavor = await this.inclusionsFlavorsService.filterOne({ ingredienteId: this.pickedProduct.saborizanteId });
      flavor.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/${flavor.codigoSap}.jpg`;
      flavor.pickedConcentration = this.pickedProduct.porcentSaborizante;
      localStorage.setItem('flavor', JSON.stringify(flavor));
    }
    this.completed.emit({ flow: 'launch', step: 1, completed: true });
  }
}
