import { Injectable } from '@angular/core';

import { reject } from 'lodash';

import { MainService } from '../main/main.service';
import { tablePg } from '../../interfaces/tablePg';

@Injectable({
  providedIn: 'root',
})
export class PgService {
  constructor(private mainService: MainService) {}
  listData: any[] = [];
  listDataOperationsCostVariablesPg: any[] = [];
  listDataOperationsVariableExpends: any[] = [];
  listDataoperationsFixedDirect: any[] = [];
  listDataOperationFixedPg: any[] = [];
  listDataOperationFinalPg: any[] = [];

  /** Interface that contains all the p&g data */
  interfacePg = {} as tablePg;

  /**This variable contains the data of p&g */
  costsPg: any;

  /**This function return a promise of the all prices the table p&g.
   * @returns A promise of the all prices the table p&g.
   */
  getTableCostPg() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`costos_pg`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  calculateAseosCost(ceco: string, activity: string, type: string, qty: number) {
    return new Promise<any>((resolve, reject) => {
      this.mainService.get(`aseos/${ceco}/${activity}/${type}/${qty}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**Function that consumes the p&g table service and walks through doing validations */
  async loadPgInformation() {
    this.costsPg = await this.getTableCostPg();
    this.costsPg.forEach((i: any) => {
      if (i.descripcion == '4xMIL') {
        this.interfacePg.taxThousandNational = i.nacional;
        this.interfacePg.taxThousandExport = i.exportacion;
      }
      if (i.descripcion == 'Industria y comercio') {
        this.interfacePg.industryCommerceNational = i.nacional;
        this.interfacePg.industryCommerceInternational = i.exportacion;
      }
      if (i.descripcion == 'Logistica almacen (%/Venta)') {
        this.interfacePg.LogisticsWarehouseSaleNational = i.nacional;
        this.interfacePg.LogisticsWarehouseSaleInternational = i.exportacion;
      }
      if (i.descripcion == 'Gastos de Ventas') {
        this.interfacePg.sellingExpensesNational = i.nacional;
        this.interfacePg.sellingExpensesInternational = i.exportacion;
      }
      if (i.descripcion == 'Granja+Desarrollo+Innova+Sostenibilidad') {
        this.interfacePg.groupVariableNational = i.nacional;
        this.interfacePg.groupVariableInternational = i.exportacion;
      }
      if (i.descripcion == 'Generales de la compañía') {
        this.interfacePg.generalCompanyNational = i.nacional;
        this.interfacePg.generalCompanyInternational = i.exportacion;
      }
      if (i.descripcion == 'Cartera') {
        this.interfacePg.briefcaseNational = i.nacional;
        this.interfacePg.briefcaseInternational = i.exportacion;
      }
      if (i.descripcion == 'Inventarios - MP') {
        this.interfacePg.inventoriesMpNational = i.nacional;
        this.interfacePg.inventoriesMpInternational = i.exportacion;
      }
      if (i.descripcion == 'Inventarios - Otras MF') {
        this.interfacePg.inventoriesMfNational = i.nacional;
        this.interfacePg.inventoriesMfInternational = i.exportacion;
      }
      if (i.descripcion == 'Proveedores') {
        this.interfacePg.providersNational = i.nacional;
        this.interfacePg.providersInternational = i.exportacion;
      }
      if (i.descripcion == 'Tasa Anual') {
        this.interfacePg.annualRateNational = i.nacional;
        this.interfacePg.annualRateInternational = i.exportacion;
      }
      if (i.descripcion == 'Tasa Mensual') {
        this.interfacePg.monthlyRateNational = i.nacional;
        this.interfacePg.monthlyRateInternational = i.exportacion;
      }
      if (i.descripcion == 'Mercadeo') {
        this.interfacePg.marketingNational = i.nacional;
        this.interfacePg.marketingInternational = i.exportacion;
      }
    });
    return this.interfacePg;
  }

  /**Tthese functions return a list of values and do operations
   *  to display that value in the component */
  operationsCostVariablesPg(
    netSales?: any,
    energy?: any,
    gas?: any,
    rawMaterialCostOne?: any,
    packagingCost?: any,
    rawMaterialCostTwo?: any
  ) {
    let totalEnergy = energy + gas;
    let variableCosts = rawMaterialCostOne + rawMaterialCostTwo + packagingCost + totalEnergy;
    let contribVariable = netSales - variableCosts;
    this.listDataOperationsCostVariablesPg.unshift({
      costosVariables: variableCosts,
      mp1: rawMaterialCostOne,
      mp2: rawMaterialCostTwo,
      packaging: packagingCost,
      energy: totalEnergy,
      contribVariable: contribVariable,
      porcentajeMp1: (rawMaterialCostOne / netSales) * 100,
      porcentajeMp2: (rawMaterialCostTwo / netSales) * 100,
      porcentajePackaging: (packagingCost / netSales) * 100,
      porcentajeEnergia: (totalEnergy / netSales) * 100,
      porcentajeContribVariable: (contribVariable / netSales) * 100,
      porcentajeCostosVariables: (variableCosts / netSales) * 100,
    });
    return this.listDataOperationsCostVariablesPg;
  }

  operationsCostVariablesPgObject(
    netSales?: any,
    energy?: any,
    gas?: any,
    rawMaterialCostOne?: any,
    packagingCost?: any,
    rawMaterialCostTwo?: any
  ) {
    let totalEnergy = energy + gas;
    let variableCosts = rawMaterialCostOne + rawMaterialCostTwo + packagingCost + totalEnergy;
    let contribVariable = netSales - variableCosts;
    return {
      costosVariables: variableCosts,
      energy: totalEnergy,
      contribVariable: contribVariable,
    };
  }

  operationsVariableExpends(
    pais: any,
    netSales: any,
    industryCommerceNational: any,
    taxThousandNational: any,
    briefcaseNational: any,
    inventoriesMpNational: any,
    inventoriesMfNational: any,
    energy: any,
    gas: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    providersNational: any,
    packaging: any,
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    monthlyRateNational: any,
    monthlyRateInternational: any,
    industryCommerceInternational: any,
    taxThousandExport: any,
    briefcaseInternational: any,
    inventoriesMpInternational: any,
    inventoriesMfInternational: any,
    providersInternational: any,
    contribVariable: any,
    comex: any
  ) {
    if (pais === 'Colombia') {
      let ica = netSales * (industryCommerceNational / 100) || 0;
      let taxThousand = netSales * (taxThousandNational / 1000);
      let costCapital =
        ((netSales / 30) * briefcaseNational +
          (rawMaterialCostOne / 30) * inventoriesMpNational +
          (rawMaterialCostTwo / 30) * inventoriesMfNational -
          ((packaging + energy + gas + mod + mantenimiento + zoin) / 30) * providersNational) *
        monthlyRateNational;
      let variableExpends = ica + taxThousand + comex + costCapital;
      let contribFijDirec = contribVariable - variableExpends;
      this.listDataOperationsVariableExpends.unshift({
        ica,
        taxThousand: taxThousand,
        distribucionComex: comex,
        costCapital,
        variableExpends,
        contribFijDirec,
        porcentajeIca: (ica / netSales) * 100,
        porcentajeTaxThousand: (taxThousand / netSales) * 100,
        porcentajeDistribucionComex: (comex / netSales) * 100,
        porcentajeCostCapital: (costCapital / netSales) * 100,
        porcentajeVariableExpends: (variableExpends / netSales) * 100,
        porcentajeContribFijDirec: (contribFijDirec / netSales) * 100,
      });
    } else {
      let ica = netSales * (industryCommerceInternational / 100);
      let taxThousand = netSales * (taxThousandExport / 1000);
      let costCapital =
        ((netSales / 30) * briefcaseInternational +
          (rawMaterialCostOne / 30) * inventoriesMpInternational +
          (rawMaterialCostTwo / 30) * inventoriesMfInternational -
          ((packaging + energy + gas + mod + mantenimiento + zoin) / 30) * providersInternational) *
        monthlyRateInternational;
      let variableExpends = ica + taxThousand + comex + costCapital;
      let contribFijDirec = contribVariable - variableExpends;
      this.listDataOperationsVariableExpends.unshift({
        ica: ica,
        taxThousand: taxThousand,
        distribucionComex: comex,
        costCapital: costCapital,
        variableExpends: variableExpends,
        contribFijDirec: contribFijDirec,
        porcentajeIca: (ica / netSales) * 100,
        porcentajeTaxThousand: (taxThousand / netSales) * 100,
        porcentajeDistribucionComex: (comex / netSales) * 100,
        porcentajeCostCapital: (costCapital / netSales) * 100,
        porcentajeVariableExpends: (variableExpends / netSales) * 100,
        porcentajeContribFijDirec: (contribFijDirec / netSales) * 100,
      });
    }
    return this.listDataOperationsVariableExpends;
  }

  operationsVariableExpendsObject(
    pais: any,
    netSales: any,
    industryCommerceNational: any,
    taxThousandNational: any,
    briefcaseNational: any,
    inventoriesMpNational: any,
    inventoriesMfNational: any,
    energy: any,
    gas: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    providersNational: any,
    packaging: any,
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    monthlyRateNational: any,
    monthlyRateInternational: any,
    industryCommerceInternational: any,
    taxThousandExport: any,
    briefcaseInternational: any,
    inventoriesMpInternational: any,
    inventoriesMfInternational: any,
    providersInternational: any,
    contribVariable: any,
    comex: any
  ) {
    if (pais === 'Colombia') {
      let ica = netSales * (industryCommerceNational / 100) || 0;
      let taxThousand = netSales * (taxThousandNational / 1000);
      let costCapital =
        ((netSales / 30) * briefcaseNational +
          (rawMaterialCostOne / 30) * inventoriesMpNational +
          (rawMaterialCostTwo / 30) * inventoriesMfNational -
          ((packaging + energy + gas + mod + mantenimiento + zoin) / 30) * providersNational) *
        monthlyRateNational;
      let variableExpends = ica + taxThousand + comex + costCapital;
      let contribFijDirec = contribVariable - variableExpends;
      return {
        ica,
        taxThousand: taxThousand,
        distribucionComex: comex,
        costCapital,
        variableExpends,
        contribFijDirec,
      };
    } else {
      let ica = netSales * (industryCommerceInternational / 100);
      let taxThousand = netSales * (taxThousandExport / 1000);
      let costCapital =
        ((netSales / 30) * briefcaseInternational +
          (rawMaterialCostOne / 30) * inventoriesMpInternational +
          (rawMaterialCostTwo / 30) * inventoriesMfInternational -
          ((packaging + energy + gas + mod + mantenimiento + zoin) / 30) * providersInternational) *
        monthlyRateInternational;
      let variableExpends = ica + taxThousand + comex + costCapital;
      let contribFijDirec = contribVariable - variableExpends;
      return {
        ica: ica,
        taxThousand: taxThousand,
        distribucionComex: comex,
        costCapital: costCapital,
        variableExpends: variableExpends,
        contribFijDirec: contribFijDirec,
      };
    }
  }

  operationsFixedDirect(
    pais: any,
    netSales: any,
    LogisticsWarehouseSaleNational: any,
    marketingNational: any,
    depreciacion: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    contribFijDirect: any,
    LogisticsWarehouseSaleInternational: any,
    marketingInternational: any
  ) {
    if (pais === 'Colombia') {
      let logisticWarehouse = netSales * LogisticsWarehouseSaleNational;
      let mercadeo = netSales * marketingNational || 0;
      let fijosDirectos = logisticWarehouse + mercadeo + mod + mantenimiento + zoin + depreciacion;
      let contribCostGast = contribFijDirect - fijosDirectos;
      this.listDataoperationsFixedDirect.unshift({
        logisticWarehouse: logisticWarehouse,
        marketing: mercadeo || 0,
        depreciacion: depreciacion,
        mod: mod,
        mantenimiento: mantenimiento,
        zoin: zoin,
        fijosDirectos: fijosDirectos,
        contribuCostGast: contribCostGast,
        porcentajeLogisticWarehouse: (logisticWarehouse / netSales) * 100,
        porcentajeMarketing: (mercadeo / netSales) * 100 || 0,
        porcentajeDepreciacion: (depreciacion / netSales) * 100,
        porcentajeMod: (mod / netSales) * 100,
        porcentajeMantenimiento: (mantenimiento / netSales) * 100,
        porcentajeZoin: (zoin / netSales) * 100,
        porcentajeFijosDirectos: (fijosDirectos / netSales) * 100,
        porcentajeContribCostGast: (contribCostGast / netSales) * 100,
      });
    } else {
      let logisticWarehouse = netSales * LogisticsWarehouseSaleInternational;
      let mercadeo = netSales * marketingInternational || 0;
      let fijosDirectos = logisticWarehouse + mercadeo + mod + mantenimiento + zoin + depreciacion;
      let contribCostGast = contribFijDirect - fijosDirectos;
      this.listDataoperationsFixedDirect.unshift({
        logisticWarehouse: logisticWarehouse,
        marketing: mercadeo || 0,
        depreciacion: depreciacion,
        mod: mod,
        mantenimiento: mantenimiento,
        zoin: zoin,
        fijosDirectos: fijosDirectos,
        contribuCostGast: contribCostGast,
        porcentajeLogisticWarehouse: (logisticWarehouse / netSales) * 100,
        porcentajeMarketing: (mercadeo / netSales) * 100 || 0,
        porcentajeDepreciacion: (depreciacion / netSales) * 100,
        porcentajeMod: (mod / netSales) * 100,
        porcentajeMantenimiento: (mantenimiento / netSales) * 100,
        porcentajeZoin: (zoin / netSales) * 100,
        porcentajeFijosDirectos: (fijosDirectos / netSales) * 100,
        porcentajeContribCostGast: (contribCostGast / netSales) * 100,
      });
    }
    return this.listDataoperationsFixedDirect;
  }
  operationsFixedDirectObject(
    pais: any,
    netSales: any,
    LogisticsWarehouseSaleNational: any,
    marketingNational: any,
    depreciacion: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    contribFijDirect: any,
    LogisticsWarehouseSaleInternational: any,
    marketingInternational: any
  ) {
    if (pais === 'Colombia') {
      let logisticWarehouse = netSales * LogisticsWarehouseSaleNational;
      let mercadeo = netSales * marketingNational || 0;
      let fijosDirectos = logisticWarehouse + mercadeo + mod + mantenimiento + zoin + depreciacion;
      let contribCostGast = contribFijDirect - fijosDirectos;
      return{
        logisticWarehouse: logisticWarehouse,
        marketing: mercadeo || 0,
        fijosDirectos: fijosDirectos,
        contribuCostGast: contribCostGast,
      };
    } else {
      let logisticWarehouse = netSales * LogisticsWarehouseSaleInternational;
      let mercadeo = netSales * marketingInternational || 0;
      let fijosDirectos = logisticWarehouse + mercadeo + mod + mantenimiento + zoin + depreciacion;
      let contribCostGast = contribFijDirect - fijosDirectos;
      return {
        logisticWarehouse: logisticWarehouse,
        marketing: mercadeo || 0,
        fijosDirectos: fijosDirectos,
        contribuCostGast: contribCostGast,
      };
    }
  }

  operationFixedPg(
    pais: any,
    netSales: any,
    sellingExpensesNational: any,
    groupVariableNational: any,
    generalCompanyNational: any,
    contribCostGast: any,
    sellingExpensesInternational: any,
    groupVariableInternational: any,
    generalCompanyInternational: any
  ) {
    if (pais === 'Colombia') {
      let gastVentas = netSales * sellingExpensesNational;
      let groupVariable = netSales * groupVariableNational;
      let administrative = netSales * generalCompanyNational;
      let fixed = gastVentas + groupVariable + administrative;
      let contribution = contribCostGast - fixed;
      this.listDataOperationFixedPg.unshift({
        gastosVentas: gastVentas,
        groupVariable: groupVariable,
        administrative: administrative,
        fixed: fixed,
        contribution: contribution,
        porcentajeGastosVentas: (gastVentas / netSales) * 100,
        porcentajeGroupVariable: (groupVariable / netSales) * 100,
        porcentajeAdministrative: (administrative / netSales) * 100,
        porcentajeFixed: (fixed / netSales) * 100,
        porcentajeContribucion: (contribution / netSales) * 100,
      });
    } else {
      let gastVentas = netSales * sellingExpensesInternational;
      let groupVariable = netSales * groupVariableInternational;
      let administrative = netSales * generalCompanyInternational;
      let fixed = gastVentas + groupVariable + administrative;
      let contribution = contribCostGast - fixed;
      this.listDataOperationFixedPg.unshift({
        gastosVentas: gastVentas,
        groupVariable: groupVariable,
        administrative: administrative,
        fixed: fixed,
        contribution: contribution,
        porcentajeGastosVentas: (gastVentas / netSales) * 100,
        porcentajeGroupVariable: (groupVariable / netSales) * 100,
        porcentajeAdministrative: (administrative / netSales) * 100,
        porcentajeFixed: (fixed / netSales) * 100,
        porcentajeContribucion: (contribution / netSales) * 100,
      });
    }
    return this.listDataOperationFixedPg;
  }

  operationFixedPgObject(
    pais: any,
    netSales: any,
    sellingExpensesNational: any,
    groupVariableNational: any,
    generalCompanyNational: any,
    contribCostGast: any,
    sellingExpensesInternational: any,
    groupVariableInternational: any,
    generalCompanyInternational: any
  ) {
    if (pais === 'Colombia') {
      let gastVentas = netSales * sellingExpensesNational;
      let groupVariable = netSales * groupVariableNational;
      let administrative = netSales * generalCompanyNational;
      let fixed = gastVentas + groupVariable + administrative;
      let contribution = contribCostGast - fixed;
      return {
        gastosVentas: gastVentas,
        groupVariable: groupVariable,
        administrative: administrative,
        fixed: fixed,
        contribution: contribution,
      };
    } else {
      let gastVentas = netSales * sellingExpensesInternational;
      let groupVariable = netSales * groupVariableInternational;
      let administrative = netSales * generalCompanyInternational;
      let fixed = gastVentas + groupVariable + administrative;
      let contribution = contribCostGast - fixed;
      return {
        gastosVentas: gastVentas,
        groupVariable: groupVariable,
        administrative: administrative,
        fixed: fixed,
        contribution: contribution,
      };
    }
  }

  operationFinalPg(
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    packagingCost: any,
    energy: any,
    depreciacion: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    contribution: any,
    costCapital: any,
    netSales: any,
    variableCosts: any
  ) {
    let totalCost =
      rawMaterialCostOne + rawMaterialCostTwo + packagingCost + energy + depreciacion + mod + mantenimiento + zoin;
    let ebitda = contribution + depreciacion + costCapital;
    let margenBruto = netSales - variableCosts - depreciacion - mod - mantenimiento - zoin;
    this.listDataOperationFinalPg.unshift({
      ebitda: ebitda,
      margenBruto: margenBruto,
      totalCost: totalCost,
      porcentajeEbitda: (ebitda / netSales) * 100,
      porcentajeMargenBruto: (margenBruto / netSales) * 100,
      porcentajeTotal: (totalCost / netSales) * 100,
    });
    return this.listDataOperationFinalPg;
  }

  operationFinalPgObject(
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    packagingCost: any,
    energy: any,
    depreciacion: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    contribution: any,
    costCapital: any,
    netSales: any,
    variableCosts: any
  ) {
    let totalCost =
      rawMaterialCostOne + rawMaterialCostTwo + packagingCost + energy + depreciacion + mod + mantenimiento + zoin;
    let ebitda = contribution + depreciacion + costCapital;
    return {
      ebitda: ebitda,
    };
  }

  EbitdaFromPrice(
    netSales:number,
    pais:any,
    energy: any,
    gas: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    packagingCost: any,
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    sellingExpensesNational: any,
    sellingExpensesInternational: any,
    groupVariableNational: any,
    groupVariableInternational: any,
    generalCompanyNational: any,
    generalCompanyInternational: any,
    industryCommerceNational: any,
    industryCommerceInternational: any,
    taxThousandNational: any,
    taxThousandExport: any,
    LogisticsWarehouseSaleNational:any,
    LogisticsWarehouseSaleInternational:any,
    marketingNational:any,
    marketingInternational:any,
    comex: any,

  ){
    
    const sellingExpenses = pais=="Colombia"?sellingExpensesNational:sellingExpensesInternational
    const groupVariable = pais=="Colombia"?groupVariableNational:groupVariableInternational
    const generalCompany = pais=="Colombia"?generalCompanyNational:generalCompanyInternational
    const industryCommerce = pais=="Colombia"?industryCommerceNational:industryCommerceInternational
    const taxThousand = pais=="Colombia"?taxThousandNational:taxThousandExport
    const LogisticsWarehouseSale = pais=="Colombia"?LogisticsWarehouseSaleNational:LogisticsWarehouseSaleInternational
    const marketing = pais=="Colombia"?marketingNational:marketingInternational


    const costosVariables = (
      rawMaterialCostOne +
      rawMaterialCostTwo +
      packagingCost +
      energy +
      gas
    );
  
    const ebitda = 100 * (
      (netSales - (
        costosVariables +
          mod +
          mantenimiento +
          zoin +
          comex
      )) / netSales - (
          (industryCommerce / 100) +
          (taxThousand / 1000) +
          LogisticsWarehouseSale +
          marketing +
          sellingExpenses +
          groupVariable +
          generalCompany
      )
  );
    return ebitda
  }

  netSalesFromEbitdaPercentage(
    percentage:number,
    pais:any,
    energy: any,
    gas: any,
    mod: any,
    mantenimiento: any,
    zoin: any,
    packagingCost: any,
    rawMaterialCostOne: any,
    rawMaterialCostTwo: any,
    sellingExpensesNational: any,
    sellingExpensesInternational: any,
    groupVariableNational: any,
    groupVariableInternational: any,
    generalCompanyNational: any,
    generalCompanyInternational: any,
    industryCommerceNational: any,
    industryCommerceInternational: any,
    taxThousandNational: any,
    taxThousandExport: any,
    LogisticsWarehouseSaleNational:any,
    LogisticsWarehouseSaleInternational:any,
    marketingNational:any,
    marketingInternational:any,
    comex: any,

  ){
    const sellingExpenses = pais=="Colombia"?sellingExpensesNational:sellingExpensesInternational
    const groupVariable = pais=="Colombia"?groupVariableNational:groupVariableInternational
    const generalCompany = pais=="Colombia"?generalCompanyNational:generalCompanyInternational
    const industryCommerce = pais=="Colombia"?industryCommerceNational:industryCommerceInternational
    const taxThousand = pais=="Colombia"?taxThousandNational:taxThousandExport
    const LogisticsWarehouseSale = pais=="Colombia"?LogisticsWarehouseSaleNational:LogisticsWarehouseSaleInternational
    const marketing = pais=="Colombia"?marketingNational:marketingInternational

    const netSales = (
      rawMaterialCostOne +
      rawMaterialCostTwo +
      packagingCost +
      energy +
      gas +
      mod +
      mantenimiento +
      zoin +
      comex
  ) / (
      1 - (
          (percentage / 100) +
          (industryCommerce / 100) +
          (taxThousand / 1000) +
          LogisticsWarehouseSale +
          marketing +
          sellingExpenses +
          groupVariable +
          generalCompany
      )
  );


    return netSales
  }
}
