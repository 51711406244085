import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChocolateService } from '../../../../core/services/chocolate/chocolate.service';
import { InclusionsFlavorsService } from '../../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { PackagingService } from '../../../../core/services/packaging/packaging.service';
import { ShapeAndSizeService } from '../../../../core/services/shape-and-size/shape-and-size.service';
import { RTLProductService } from '../../../../core/services/rtl-product/rtlproduct.service';
import * as _ from 'lodash';
import ls from 'localstorage-slim';

import SwiperCore, { Navigation, Pagination, Virtual } from 'swiper';
import { Router } from '@angular/router';
import { BespokeFormulaService } from '../../../../core/services/bespoke-formula/bespoke-formula.service';

SwiperCore.use([Pagination, Navigation, Virtual]);

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css'],
})
export class ProductDescriptionComponent implements OnInit {
  /** This object stores the Ready To Launch product selected by the user. */
  pickedProduct: any = {};

  /** The chocolate of the picked product. */
  chocolate: any = {};

  /** The shape of the picked product. */
  shape: any = {};

  /** The list of ingredients used in the chocolate of the picked product. */
  ingredients: any = {};

  claims: any = [];

  packagesImages: any = [];

  ingredientsList: any = [];
  materialOptionsRTL: any = [];
  milkType = false;

  /** This array contains the filtered sweeteners available for a chocolate. */
  filteredSweeteners: any[] = [];

  /** This array contains the filtered milk avaible for a chocolate**/
  filteredMilk: any[] = [];

  filteredLicitine: any = [];

  constructor(
    private chocolateService: ChocolateService,
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private titlecase: TitleCasePipe,
    private packageService: PackagingService,
    private shapeAndSizeService: ShapeAndSizeService,
    private rtlProductService: RTLProductService,
    private router: Router,
    private bespokeFormulaService: BespokeFormulaService
  ) {}

  async ngOnInit() {
    this.loadRTLProduct();
    await this.setShapeData();
    await this.loadProductIngredients();
    await this.setPackagesData();
    this.saveChocolate();
    await this.getMaterialsRTL();
    this.setChocolateMilkType();
    await this.getAllByCategory('SUGAR');
    await this.getAllByCategory('MILK');
    await this.getAllByCategory('LECITHIN');
    this.filterLicitine();
  }

  /**
   * This function loads the previously selected Ready To Launch product.
   */
  loadRTLProduct() {
    this.pickedProduct = JSON.parse(localStorage.getItem('rtl') || '{}');
    let str = this.pickedProduct.claims;
    this.claims = str.split(',');
  }

  /**
   * This function loads the ingredients of the ready to launch product.
   */
  async loadProductIngredients() {
    this.chocolate = await this.chocolateService.filter({ chocolateId: this.pickedProduct.chocolateId });
    let imageChocolate;
    let nameChocolate;
    let shapeImage;
    if (this.pickedProduct.tipo === 'POWDER') {
      imageChocolate = 'https://d169su6y068qsd.cloudfront.net/create/basics/POWDER.PNG';
      nameChocolate = this.chocolate?.texto;
      shapeImage = `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/${this.chocolate?.tipo}/${this.pickedProduct.formaId}.jpg`;
    } else if (this.pickedProduct.format === 'DRAGEE') {
      imageChocolate = `https://d169su6y068qsd.cloudfront.net/create/basics/${this.pickedProduct.tipo}.png`;
      nameChocolate = this.pickedProduct.tipo;
      shapeImage = `https://d169su6y068qsd.cloudfront.net/create/basics/${this.pickedProduct.tipo}.png`;
    } else {
      imageChocolate = `./../../../../../assets/basics/${this.chocolate?.tipo}.PNG`;
      nameChocolate = this.chocolate?.texto;
      shapeImage = `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/${this.chocolate?.tipo}/${this.pickedProduct.formaId}.jpg`;
    }
    this.ingredientsList = [
      {
        name: nameChocolate,
        img: imageChocolate,
      },
      {
        name: this.shape.texto,
        img: shapeImage,
      },
    ];
    await this.loadInclusions();
  }

  /**
   * This function loads the inclusions of the previously selected product, if it has any.
   */
  async loadInclusions() {
    if (this.pickedProduct.inclusion1Id) {
      this.ingredients.inclusion1 = await this.inclusionsFlavorsService.filterOne({
        ingredienteId: this.pickedProduct.inclusion1Id,
      });
      this.ingredientsList.push({
        name: this.ingredients.inclusion1.nombre,
        img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${this.ingredients.inclusion1.codigoSap}.jpg`,
      });
    }
    if (this.pickedProduct.inclusion2Id) {
      this.ingredients.inclusion2 = await this.inclusionsFlavorsService.filterOne({
        ingredienteId: this.pickedProduct.inclusion2Id,
      });
      this.ingredientsList.push({
        name: this.ingredients.inclusion2.texto,
        img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${this.ingredients.inclusion2.codigoSap}.jpg`,
      });
    }
    if (this.pickedProduct.saborizanteId) {
      this.ingredients.flavor = await this.inclusionsFlavorsService.filterOne({
        ingredienteId: this.pickedProduct.saborizanteId,
      });
      this.ingredientsList.push({
        name: this.ingredients.flavor.nombre,
        img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/${this.ingredients.flavor.codigoSap}.jpg`,
      });
    }
    this.setInclusionsAndFlavorData();
  }

  /**
   * This function sets the data of the package combination used for the RTL Product.
   */
  async setPackagesData() {
    let combPackage: any = (
      await this.packageService.filterCombination({ combinacionEmpaqueId: this.pickedProduct.combEmpaque })
    )[0];
    this.packagesImages = [
      {
        img: combPackage.empaque1.imagen,
        typePackaging: combPackage.empaque1.tipoEmpaque,
        units: combPackage.unitsPerPackage1,
        acabados: combPackage.empaque1.acabados,
      },
      {
        img: combPackage.empaque2.imagen,
        typePackaging: combPackage.empaque2.tipoEmpaque,
        units: combPackage.unitsPerPackage2,
        acabados: combPackage.empaque2.acabados,
      },
    ];

    if (combPackage.empaque3 || combPackage.empaque4) {
      this.packagesImages.push(
        {
          img: combPackage.empaque3.imagen,
          typePackaging: combPackage.empaque3.tipoEmpaque,
          units: combPackage.unitsPerPackage3,
          acabados: combPackage.empaque3.acabados,
        },
        {
          img: combPackage?.empaque4?.imagen,
          typePackaging: combPackage?.empaque4?.tipoEmpaque,
          units: combPackage.unitsPerPackage4,
          acabados: combPackage?.empaque4?.acabados,
        }
      );
    }
    localStorage.setItem('packaging', JSON.stringify(combPackage));
  }

  /**
   * This function saves the shape used in the RTL product to show it in the product summary.
   */
  async setShapeData() {
    this.shape = await this.shapeAndSizeService.filter({ formaId: this.pickedProduct.formaId });
    ls.set('shape', JSON.stringify(this.shape), { encrypt: true });
  }

  /**
   * This function sets the data of the inclusions and flavors used for the RTL Product.
   */
  async setInclusionsAndFlavorData() {
    let inclusion: any = {};
    ls.set('ingredients', JSON.stringify(this.ingredients), { encrypt: true });
    if (this.pickedProduct.inclusion1Id) {
      let factorInclusion1 = await this.getFactorBespoke();
      let percentageInclusion1;
      if (this.pickedProduct.format === 'DRAGEE') {
        percentageInclusion1 = factorInclusion1.factorBespoke * 100;
        inclusion.base = {
          percentage: percentageInclusion1,
          inclusion: {
            percentage: percentageInclusion1,
            codigoSap: factorInclusion1.codigoSap,
            nombre: factorInclusion1.nombre,
            img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${factorInclusion1.codigoSap}.jpg`,
          },
        };
      } else {
        percentageInclusion1 = this.pickedProduct.porcentInclusion1;
        inclusion.base = {
          inclusion: this.pickedProduct.inclusion1Id,
          percentage: percentageInclusion1,
          codigoSap: factorInclusion1.codigoSap,
          nombre: factorInclusion1.nombre,
        };
      }
      if (this.pickedProduct.inclusion2) {
        let factorInclusion2 = await this.getFactorBespoke();
        let percentageInclusion2;
        if (this.pickedProduct.format === 'DRAGEE') {
          percentageInclusion2 = factorInclusion2.factorBespoke * 100;
          inclusion.extra = {
            inclusion: {
              codigoSap: factorInclusion2.codigoSap,
              nombre: factorInclusion2.nombre,
              img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${factorInclusion2.codigoSap}.jpg`,
            },
            percentage: percentageInclusion2,
          };
        } else {
          percentageInclusion2 = this.pickedProduct.porcentInclusion2;
          inclusion.extra = { inclusion: this.pickedProduct.inclusion2Id, percentage: percentageInclusion2 };
        }
      }
    }
    if (this.pickedProduct.saborizanteId) {
      let flavor = this.ingredients.flavor;
      flavor.pickedConcentration = this.pickedProduct.porcentSaborizante;
      localStorage.setItem('flavor', JSON.stringify(flavor));
    }
    localStorage.setItem('inclusion', JSON.stringify(inclusion));
  }

  /**
   * This function gets the factor bespoke of the inclusions and flavors used for the RTL Product.
   */
  async getFactorBespoke() {
    return await this.inclusionsFlavorsService.getByIngredientId(this.pickedProduct.inclusion1Id);
  }

  /**
   * This function saves the chocolate of the selected Ready To Launch product.
   */
  saveChocolate() {
    const bespoke: any = {};
    if (this.pickedProduct.format !== 'DRAGEE') {
      localStorage.setItem('chocolate', JSON.stringify(this.chocolate));
    } else {
      this.bespokeFormulaService
        .getNewMaterialsList({ llave: `${this.pickedProduct.notas.replace('.', ',')}${this.pickedProduct.tipo}` }, [])
        .then((materials) => {
          bespoke.type = this.pickedProduct.tipo;
          bespoke.percentage = this.pickedProduct.notas;
          bespoke.materials = materials;
          localStorage.setItem('bespoke', JSON.stringify(bespoke));
        });
    }
  }

  /**
   * This function get the materials of the selected chocolate.
   */
  async getMaterialsRTL() {
    if (this.chocolate) {
      this.materialOptionsRTL = await this.rtlProductService.getMaterialsRTL(this.chocolate.materialSap);
    }
  }

  /**
   * This function sets the milk type of the selected chocolate.
   */
  setChocolateMilkType() {
    if (this.chocolate) {
      if (this.chocolate.tipo === 'MILK') {
        this.milkType = true;
      }
    }
  }

  /**
   * This functions filtered of suggar selected by chocolate.
   */
  filterSweetenersSuggar() {
    let filtered = this.materialOptionsRTL.filter((material: any) => {
      if (
        (material.grupoArticuloComponente == '4101' || material.grupoArticuloComponente == '4123') &&
        material.nivelArbol == 1
      )
        return material;
    });
    let filteredSweet: any = [];
    let filteredSweetenersRtl: any = _.uniq(filtered);
    for (let i = 0; i < this.filteredSweeteners.length; i++) {
      filteredSweet = this.filteredSweeteners.filter((item) => {
        if (item.codigoSap == filteredSweetenersRtl[i]?.componente) {
          item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/sweeteners/${filteredSweetenersRtl[i].componente}.jpg`;
          item.selected = true;
        }
      });
    }
    let sweetenersSelected = this.filteredSweeteners.filter((item: any) => item.selected);
    ls.set(
      'sugar',
      JSON.stringify({
        name: sweetenersSelected[0]?.nombre,
        img: sweetenersSelected[0]?.img,
      }),
      { encrypt: true }
    );
    this.ingredientsList.push({
      name: sweetenersSelected[0]?.nombre,
      img: sweetenersSelected[0]?.img,
    });
  }

  /**
   * This functions filtered of milk selected by chocolate.
   */
  filterMilkRtl() {
    if (this.milkType) {
      let filtered = this.materialOptionsRTL.filter((material: any) => {
        if (material.grupoArticuloComponente == '4108' && material.nivelArbol == 1) return material;
      });
      let filteredMilkRtl: any = _.uniq(filtered);
      for (let i = 0; i < this.filteredMilk.length; i++) {
        let filtered = this.filteredMilk.filter((item) => {
          if (item.codigoSap == filteredMilkRtl[i]?.componente) {
            item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/milk/${filteredMilkRtl[i].componente}.jpg`;
            item.selected = true;
          }
        });
        filtered = this.filteredMilk;
      }
      let milkSelected = this.filteredMilk.filter((item: any) => item.selected);
      ls.set(
        'milk',
        JSON.stringify({
          name: milkSelected[0].nombre,
          img: milkSelected[0].img,
        }),
        { encrypt: true }
      );
      this.ingredientsList.push({
        name: milkSelected[0].nombre,
        img: milkSelected[0].img,
      });
    }
  }

  filterLicitine() {
    let filtered = this.materialOptionsRTL.filter((material: any) => {
      if (material.grupoArticuloComponente == '4109' && material.nivelArbol == 1) return material;
    });
    let filteredLicitineRtl: any = _.uniq(filtered);
    for (let i = 0; i < this.filteredLicitine.length; i++) {
      let filtered = this.filteredLicitine.filter((item: any) => {
        if (item.codigoSap == filteredLicitineRtl[i]?.componente) {
          item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/${filteredLicitineRtl[i].componente}.jpg`;
          item.selected = true;
        }
      });
      filtered = this.filteredLicitine;
    }
    let filteredSelected = this.filteredLicitine.filter((item: any) => item.selected);
    ls.set(
      'lecitine',
      JSON.stringify({
        name: filteredSelected[0]?.nombre,
        img: filteredSelected[0]?.img,
      }),
      { encrypt: true }
    );
  }

  /**
   * This functions get all category of product selected.
   */
  async getAllByCategory(category: string) {
    if (category === 'SUGAR') {
      this.filteredSweeteners = await this.inclusionsFlavorsService.getByCategory('SUGAR');
      for (let item of this.filteredSweeteners) {
        item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/sweeteners/${item.codigoSap}.jpg`;
      }
      this.filterSweetenersSuggar();
    } else if (category === 'MILK') {
      this.filteredMilk = await this.inclusionsFlavorsService.getByCategory('MILK');
      for (let item of this.filteredMilk) {
        item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/milk/${item.codigoSap}.jpg`;
      }
      this.filterMilkRtl();
    } else if (category === 'LECITHIN') {
      this.filteredLicitine = await this.inclusionsFlavorsService.getByCategory('LECITHIN');
      for (let item of this.filteredLicitine) {
        item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/sweeteners/${item.codigoSap}.jpg`;
      }
      this.filterLicitine();
    }
  }

  goToBasics() {
    this.router.navigate(['/create-product/basics']);
    let itemsLocalStorageRemove = [
      'parameters',
      'selectedOptions',
      'chocolate',
      'rtl',
      'packaging',
      'inclusion',
      'type',
      'brief',
      'shape',
      'sugar',
      'licitine',
      'flavor',
      'ingredients',
      'design',
      'milk',
      'type',
      'lecitine',
    ];
    for (let item of itemsLocalStorageRemove) {
      localStorage.removeItem(item);
    }
  }
}
