import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';


@Component({
  selector: 'app-home-options',
  templateUrl: './home-options.component.html',
  styleUrls: ['./home-options.component.css'],
})
export class HomeOptionsComponent implements OnInit {

  cards_data:any =[
    {
      title:"Consult",
      detail:"Get immediate help with our price consulting.",
      imgPathWhite:"../../../assets/home/consult-white.png",
      imgPathBrown:"../../../assets/home/consult-brown.png",
      redirectTo:"consult-price",
      origin:"Consult",
    },
    {
      title:"Quote actual products",
      detail:"Enter SAP code for create a valid quotation changing TRM and cacao price.",
      imgPathWhite:"../../../assets/home/quote-white.png",
      imgPathBrown:"../../../assets/home/quote-brown.png",
      redirectTo:"project-brief",
      origin:"Portfolio products",
      params: {origin: "actual_products"}

    },
    {
      title:"Validate product feasibility",
      detail:"Choose your ingredients, shape and packaging.",
      imgPathWhite:"../../../assets/home/feasibility-white.png",
      imgPathBrown:"../../../assets/home/feasibility-brown.png",
      redirectTo:"project-brief",
      origin: "Create a Product From Scratch"
    },
    {
      title:"Quote private label portfolio",
      detail:"Enter private label Code to create a valid quotation changing TRM and cacao price.",
      imgPathWhite:"../../../assets/home/private-label-portfolio-white.png",
      imgPathBrown:"../../../assets/home/private-label-portfolio-brown.png",
      redirectTo:"project-brief",
      origin: "Ready to Launch Portfolio",
      params: {origin: "private_label"}
    },
  ]

  user:string =''

  canGoToPortfolioProducts = ['ADMIN', 'COMERCIAL', 'EJECUTIVOSR', "SUPERADMIN"]

  constructor(private authService: AuthService) {
    this.getUserType()
  }

  ngOnInit(): void {
  }

  getUserType(){
    this.user = this.authService.getTypeUser()
    if(!this.canGoToPortfolioProducts.includes(this.user)){
      this.cards_data.splice(1, 1);
    }
  }

}
