import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { MaterialListService } from '../../../../core/services/material-list/material-list.service';
import { RadialChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChocolateProfileService } from '../../../../core/services/chocolate-profile/chocolate-profile.service';
import { TitleCasePipe } from '@angular/common';
import { InclusionsFlavorsService } from '../../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { RequestModalComponent } from '../../../shared/request-modal/request-modal.component';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-chocolate-sweeteners',
  templateUrl: './chocolate-sweeteners.component.html',
  styleUrls: ['./chocolate-sweeteners.component.css'],
})
export class ChocolateSweetenersComponent implements OnInit {
  /** The chocolate picked by the user in the previous step. */
  pickedChocolate: any;

  /** The shape picked by the user in the first step. */
  pickedShape: any;

  /** This array contains all the available material options by chocolate. */
  materialOptions: any[] = [];

  /** This array contains the filtered milk available for a chocolate, if the chocolate type is milk. */
  filteredMilk: any[] = [];

  /** This array contains the filtered sweeteners available for a chocolate. */
  filteredSweeteners: any[] = [];

  /** Auxiliary attribute to render the milk options, if the chocolate type is milk. */
  isMilkType = false;

  /** List of lecithin of chocolate  **/
  filteredLecitine: any[] = [];

  /**variable validate of chocolate have lecithin **/
  validationLecitine: any;

  // Radar
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    scale: {
      ticks: {
        beginAtZero: true,
        suggestedMax: 5,
        suggestedMin: 0,
        stepSize: 1,
      },
    },
  };

  /** This array contains the labels used in the profile of Milk type chocolates. */
  public radarChartLabelsMilk: Label[] = ['Dairy', 'Sweet', 'Flavor Strength', 'Candy'];

  /** This array contains the labels used in the profile of Dark type chocolates. */
  public radarChartLabelsDark: Label[] = ['Cocoa', 'Acid', 'Astringent', 'Bitter', 'Fruity', 'Nut'];

  /** This array contains the default values of the chocolate profile, which are changed later depending on the chocolate type. */
  public radarChartData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0] }];

  /** Type of chart to display. */
  public radarChartType: ChartType = 'radar';

  /** Color of the painted area on the chart. */
  public chartColors: Color[] = [{ backgroundColor: 'rgba(246, 210, 75, 0.70)' }];

  constructor(
    private chocolateProfileService: ChocolateProfileService,
    private materialService: MaterialListService,
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private titlecase: TitleCasePipe,
    private sms: SimpleModalService
  ) {}

  async ngOnInit() {
    this.isMilkType = false;
    this.pickedChocolate = this.getPickedChocolate();
    this.pickedShape = this.getPickedShape();
    this.setChocolateAsMilkType();
    await this.getChocolateProfile();
    await this.getMaterials();
    await this.getAllByCategory('SUGAR');
    await this.getAllByCategory('MILK');
    await this.getAllByCategory('LECITINE');
    let filteredSweet = this.filteredSweeteners.filter((item: any) => item.selected);
    if (filteredSweet.length>0) {
      ls.set(
        'sugar',
        JSON.stringify({
          name: filteredSweet[0].nombre,
          img: filteredSweet[0].img,
        }),
        { encrypt: true }
      );
    }

    let filteredMilk = this.filteredMilk.filter((item: any) => item.selected);
    if (filteredMilk.length>0) {
      ls.set(
        'milk',
        JSON.stringify({
          name: filteredMilk[0].nombre,
          img: filteredMilk[0].img,
        }),
        { encrypt: true }
      );
    }
    this.filterLecitine();
    let filteredLicitine = this.filteredLecitine.filter((item: any) => item.selected);
    if (filteredLicitine.length>0) {
      ls.set(
        'lecitine',
        JSON.stringify({
          name: filteredLicitine[0].nombre,
          img: filteredLicitine[0].img,
        }),
        { encrypt: true }
      );
    }
  }

  /**
   * This function gets the chocolate object picked in the previous step and stored in the local storage.
   * @returns The chocolate object that is currently in local storage.
   */
  getPickedChocolate() {
    return JSON.parse(localStorage.getItem('chocolate') || '{}');
  }

  /**
   * This function gets the shape object picked in the first step and stored in the local storage.
   * @returns The shape object that is currently in local storage.
   */
  getPickedShape() {
    return JSON.parse(localStorage.getItem('shape') || '{}');
  }

  /**
   * This function gets all the materials available by chocolate, using its SAP code.
   */
  async getMaterials() {
    await this.materialService.getMaterialsByChocolate(this.pickedChocolate.materialSap);
    this.materialOptions = this.materialService.materialsInfo;
  }

  /**
   * This function sets the profile values, depending on which type the chocolate is.
   */
  async getChocolateProfile() {
    await this.chocolateProfileService.getChocolateProfileByChocId(this.pickedChocolate.chocolateId);
    const chocolateProfile = this.chocolateProfileService.chocolateProfile;
    if (chocolateProfile.tipo == 'Origen' || chocolateProfile.tipo == 'DARK') {
      this.radarChartData = [
        {
          data: [
            chocolateProfile.cacao,
            chocolateProfile.acidez,
            chocolateProfile.floral,
            chocolateProfile.bitter,
            chocolateProfile.frutal,
            chocolateProfile.nuez,
          ],
        },
      ];
    } else if (chocolateProfile.tipo == 'MILK' || chocolateProfile.tipo == 'WHITE') {
      this.radarChartData = [
        {
          data: [
            chocolateProfile.lacteo,
            chocolateProfile.dulce,
            chocolateProfile.flavorStrength,
            chocolateProfile.caramelo,
          ],
        },
      ];
    }
  }

  /**
   * This function filters the available sweeteners for a chocolate by code.
   */
  filterSweeteners() {
    let filtered = this.materialOptions.filter((c: any) => {
      if ((c.grupoArticuloComponente == '4101' || c.grupoArticuloComponente == '4123') && c.nivelArbol == 1) return c;
    });
    let filteredSweeteners = _.uniq(filtered);
    for (let i = 0; i < this.filteredSweeteners.length; i++) {
      let filteredSweet = this.filteredSweeteners.filter((s) => {
        if (s.codigoSap == filteredSweeteners[i]?.componente) {
          s.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/sweeteners/${filteredSweeteners[i].componente}.jpg`;
          s.selected = true;
        }
      });
      filteredSweet = this.filteredSweeteners;
    }
    this.filteredSweeteners = this.filteredSweeteners.filter((s) => s.selected == true);
  }

  /**
   * This function gets an ingredient by an specific category.
   * @param category The category of the ingredient.
   */
  async getAllByCategory(category: string) {
    if (category == 'SUGAR') {
      this.filteredSweeteners = await this.inclusionsFlavorsService.getByCategory('SUGAR');
      for (let i = 0; i < this.filteredSweeteners.length; i++) {
        this.filteredSweeteners[
          i
        ].img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/sweeteners/${this.filteredSweeteners[i].codigoSap}.jpg`;
        this.filteredSweeteners[i].selectable = false;
      }
      this.filterSweeteners();
    } else if (category == 'MILK') {
      this.filteredMilk = await this.inclusionsFlavorsService.getByCategory('MILK');
      for (let i = 0; i < this.filteredMilk.length; i++) {
        this.filteredMilk[
          i
        ].img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/milk/${this.filteredMilk[i].codigoSap}.jpg`;
        this.filteredMilk[i].selectable = false;
      }
      this.filterMilk();
    } else if (category == 'LECITINE') {
      this.filteredLecitine = await this.inclusionsFlavorsService.getByCategory('LECITHIN');
      for (let i = 0; i < this.filteredLecitine.length; i++) {
        this.filteredLecitine[
          i
        ].img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/lecithin/${this.filteredLecitine[i].codigoSap}.jpg`;
        this.filteredLecitine[i].selectable = false;
      }
      this.filterLecitine();
    }
  }

  /**
   * This function filters the available milk for a chocolate by code, only if the chocolate type is milk.
   */
  filterMilk() {
    if (this.isMilkType) {
      let filtered = this.materialOptions.filter((c: any) => {
        if (c.grupoArticuloComponente == '4108' && c.nivelArbol == 1) return c;
      });
      let filteredMilk = _.uniq(filtered);
      for (let i = 0; i < this.filteredMilk.length; i++) {
        let filtered = this.filteredMilk.filter((m) => {
          if (m.codigoSap == filteredMilk[i]?.componente) {
            m.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/milk/${filteredMilk[i].componente}.jpg`;
            m.selected = true;
          }
        });
        filtered = this.filteredMilk;
      }
    this.filteredMilk = this.filteredMilk.filter((s) => s.selected == true);
    }
  }

  filterLecitine() {
    let filtered = this.materialOptions.filter((c: any) => {
      if (c.grupoArticuloComponente == '4109' && c.nivelArbol == 1) return c;
    });
    let filteredLecitine = _.uniq(filtered);
    for (let i = 0; i < this.filteredLecitine.length; i++) {
      let filtered = this.filteredLecitine.filter((item: any) => {
        if (item.codigoSap == filteredLecitine[i]?.componente) {
          item.img = `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/${filteredLecitine[i].componente}.jpg`;
          item.selected = true;
        }
      });
      filtered = this.filteredLecitine;
    }
    this.validationLecitine = this.filteredLecitine.some((item) => item.selected);
    this.filteredLecitine = this.filteredLecitine.filter((s) => s.selected == true);
  }

  /**
   * This function sets the auxiliary attribute to true depending on the type of chocolate picked in the previous step.
   */
  setChocolateAsMilkType() {
    if (this.pickedChocolate.tipo == 'MILK') {
      this.isMilkType = true;
    }
  }

  /**
   * This function opens the request submission modal.
   */
  openRequestModal() {
    let modal = this.sms.addModal(RequestModalComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }
}
