import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import ls from 'localstorage-slim';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ProjectService } from '../../../core/services/project/project.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-brief-feria',
  templateUrl: './brief-feria.component.html',
  styleUrls: ['./brief-feria.component.css'],
  providers: [MessageService],
})
export class BriefFeriaComponent implements OnInit {
  /** Input get information of ingredients of parent component **/
  @Input() finishedIngredient: any;

  /**Brief information get of parent component **/
  @Input() briefInformation: any;

  @Output() completeBrief = new EventEmitter<any>();

  /**Generate date early to manage cache of iamges export in pdf **/
  dateEarly: any = Date.now();

  /** variable show buttons when export
   * buttons this variable convert in false
   */
  buttonsShow = true;

  /** List of ingredients **/
  ingredientsPackaging: any = [];

  ingredients: any = [];
  packaging: any = [];

  /**List of claims **/
  claimsProduct: any = [];

  imgProduct: any;

  origin: any;

  mergeIngredients: any;

  pdfOutput: any;

  constructor(
    private router: Router,
    private sms: SimpleModalService,
    private projectService: ProjectService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadIngredientsPackaging();
    this.setDefaultImage();
  }

  /**Load ingredients and packaging to show in the component **/
  loadIngredientsPackaging() {
    let suggar = JSON.parse(ls.get('sugar', { decrypt: true }) || '{}');
    let milk = JSON.parse(ls.get('milk', { decrypt: true }) || '{}');
    let flavorInclusion = JSON.parse(ls.get('ingredients', { decrypt: true }) || '{}');
    let rtl = JSON.parse(localStorage.getItem('rtl') || '{}');
    let licitine = JSON.parse(ls.get('lecitine', { decrypt: true }) || '{}');
    this.origin = localStorage.getItem('origin');
    this.setClaims(rtl.claims);
    let imageChocolate;
    let chocolateType;
    let chocolatePick;
    if (this.finishedIngredient.chocolatePick) {
      chocolateType = this.finishedIngredient.chocolatePick.tipo;
      chocolatePick = this.finishedIngredient.chocolatePick;
      if (chocolateType.tipo === 'POWDER') {
        imageChocolate = `https://d169su6y068qsd.cloudfront.net/create/basics/POWDER.PNG?${this.dateEarly}`;
      } else {
        imageChocolate = `https://d169su6y068qsd.cloudfront.net/create/basics/${this.finishedIngredient.chocolatePick.tipo}.PNG?${this.dateEarly}`;
      }
    } else {
      chocolateType = this.finishedIngredient.bespoke.type;
      chocolatePick = this.finishedIngredient.bespoke;
      imageChocolate = `https://d169su6y068qsd.cloudfront.net/create/basics/${this.finishedIngredient.bespoke.type}.PNG`;
    }

    this.packaging = [
      {
        title: this.finishedIngredient.packagingPick.empaque1.tipoEmpaque,
        img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque1.imagen}?${this.dateEarly}`,
        units: this.finishedIngredient.packagingPick.unitsPerPackage1,
        acabados: this.finishedIngredient.packagingPick.empaque1.acabados,
      },
      {
        title: this.finishedIngredient.packagingPick.empaque2.tipoEmpaque,
        img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque2.imagen}?${this.dateEarly}`,
        units: this.finishedIngredient.packagingPick.unitsPerPackage2,
        acabados: this.finishedIngredient.packagingPick.empaque2.acabados,
      },
    ];
    if (this.finishedIngredient.packagingPick.empaque3) {
      this.packaging.push({
        title: this.finishedIngredient.packagingPick.empaque3.tipoEmpaque,
        img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque3.imagen}?${this.dateEarly}`,
        units: this.finishedIngredient.packagingPick.unitsPerPackage3,
        acabados: this.finishedIngredient.packagingPick.empaque3.acabados,
      });
      if (this.finishedIngredient.packagingPick.empaque4) {
        this.packaging.push({
          title: this.finishedIngredient.packagingPick.empaque4.tipoEmpaque,
          img: `https://d169su6y068qsd.cloudfront.net/create/packaging/${this.finishedIngredient.packagingPick.empaque4.imagen}?${this.dateEarly}`,
          units: this.finishedIngredient.packagingPick.unitsPerPackage4,
          acabados: this.finishedIngredient.packagingPick.empaque4.acabados,
        });
      }
    }
    this.ingredients = [
      {
        title: this.finishedIngredient.shapePick.texto,
        img: `https://d169su6y068qsd.cloudfront.net/create/shape-and-size/${chocolateType}/${this.finishedIngredient.shapePick.formaId}.jpg?${this.dateEarly}`,
      },
      {
        title: `${chocolatePick.texto} ${chocolatePick.porcentaje * 100}%`,
        img: imageChocolate,
      },
      {
        title: suggar.name,
        img: `${suggar.img}?${this.dateEarly}`,
      },
    ];
    if (milk.name) {
      this.ingredients.push({
        title: milk.name,
        img: `${milk.img}?${this.dateEarly}`,
      });
    }
    if (this.origin === 'Ready to Launch Portfolio') {
      if (flavorInclusion.inclusion1) {
        this.ingredients.push({
          title: flavorInclusion.inclusion1.nombre,
          img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${flavorInclusion.inclusion1.codigoSap}.jpg?${this.dateEarly}`,
        });
        if (flavorInclusion.inclusion2) {
          this.ingredients.push({
            title: flavorInclusion.inclusion2.nombre,
            img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/inclusions/${flavorInclusion.inclusion2.codigoSap}.jpg?${this.dateEarly}`,
          });
        }
      }

      if (flavorInclusion.flavor) {
        this.ingredients.push({
          title: flavorInclusion.flavor.nombre,
          img: `https://d169su6y068qsd.cloudfront.net/create/inclusions-and-flavors/flavors/${flavorInclusion.flavor.codigoSap}.jpg?${this.dateEarly}`,
        });
      }
    } else {
      let inclusion = JSON.parse(localStorage.getItem('inclusion') || '{}');
      let flavor = JSON.parse(localStorage.getItem('flavor') || '{}');
      if (inclusion.base) {
        this.ingredients.push({
          title: inclusion.base.inclusion.nombre,
          img: `${inclusion.base.inclusion.img}?${this.dateEarly}`,
        });
      }
      if (flavor.nombre) {
        this.ingredients.push({
          title: flavor.nombre,
          img: `${flavor.img}?${this.dateEarly}`,
        });
      }
    }
    if (licitine.name) {
      this.ingredients.push({
        title: licitine.name,
        img: `${licitine.img}?${this.dateEarly}`,
      });
    }
    this.mergeIngredients = [...this.ingredients, ...this.packaging];
  }

  setClaims(claims: any) {
    if (this.origin === 'Ready to Launch Portfolio') {
      this.claimsProduct = claims.split(',');
    } else {
      this.claimsProduct = ['Child Labour Free', 'Crafted at origin', 'Cacao fino de aroma'];
    }
  }

  setDefaultImage() {
    let design = JSON.parse(localStorage.getItem('design') || '{}');
    if (this.origin === 'Create a Product From Scratch') {
      this.imgProduct = design.img;
    } else {
      this.imgProduct = `https://d169su6y068qsd.cloudfront.net/create/rtl/${this.finishedIngredient.rtl['idRtl']}.png?${this.dateEarly}`;
    }
  }

  /**Action route navigation to home **/
  goToHome() {
    this.router.navigate(['/']);
  }

  /**Export pdf obtain of the id html in the brief **/
  converPdfBrief(email: boolean) {
    this.buttonsShow = false;
    const brief = document.getElementById('brief')!;
    const briefHeight = brief.clientHeight;
    const briefWidth = brief.clientWidth;
    const options = { width: briefWidth, height: briefHeight };
    setTimeout(() => {
      domtoimage
        .toPng(brief, options)
        .then((dataUrl: string) => {
          const doc = new jsPDF(briefWidth > briefHeight ? 'l' : 'p', 'mm', [briefWidth, briefHeight]);
          const imgProps = doc.getImageProperties(dataUrl);
          const pdfWidth = imgProps.width;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
          if (email) {
            doc.save('brief.pdf');
          } else {
            let pdfOutput = doc.output('dataurlstring');
            this.sendEmail(pdfOutput.replace('data:', '').replace(/^.+,/, ''));
          }
          this.buttonsShow = true;
        })
        .catch((error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al generar pdf' });
          this.buttonsShow = true;
        });
    }, 200);
  }

  /**
   * Function to send email to client
   * @param pdfOutput pdf in base64
   * **/
  sendEmail(pdfOutput: any) {
    let data = {
      email: this.briefInformation.emailFeria,
      brief: pdfOutput,
      clientName: this.briefInformation.cliente,
    };
    this.projectService
      .sendEmailProject(data)
      .then((res: any) => {
        this.completeBrief.emit(true);
      })
      .catch((error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar proyecto' });
      });
  }
}
